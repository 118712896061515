import { FC, useMemo } from 'react';

import capitalize from 'lodash/capitalize';

import useFormat from 'hooks/useFormat';

import { CustomHeaderProps } from 'components/common/DatePicker/DatePickerPropsType';

const DatePickerHeader: FC<CustomHeaderProps> = ({
  increaseYear,
  decreaseYear,
  increaseMonth,
  decreaseMonth,
  date,
}) => {
  const { formatDate } = useFormat();

  const year = useMemo(() => date.getFullYear(), [date]);
  const month = useMemo(() => capitalize(formatDate(date, 'LLLL') ?? ''), [date, formatDate]);

  return (
    <div className='react-datepicker-header'>
      <div className='react-datepicker-select'>
        <button
          type='button'
          className='react-datepicker-navigation react-datepicker-navigation-prev'
          onClick={decreaseYear}
        >
          <span className='react-datepicker-navigation-icon react-datepicker-navigation-icon-prev'>
            Previous Year
          </span>
        </button>

        <p className='react-datepicker-date'>{year}</p>

        <button
          type='button'
          className='react-datepicker-navigation react-datepicker-navigation-next'
          onClick={increaseYear}
        >
          <span className='react-datepicker-navigation-icon react-datepicker-navigation-icon-next'>
            Next Year
          </span>
        </button>
      </div>

      <div className='react-datepicker-select'>
        <button
          type='button'
          className='react-datepicker-navigation react-datepicker-navigation-prev'
          onClick={decreaseMonth}
        >
          <span className='react-datepicker-navigation-icon react-datepicker-navigation-icon-prev'>
            Previous Month
          </span>
        </button>

        <p className='react-datepicker-date'>{month}</p>

        <button
          type='button'
          className='react-datepicker-navigation react-datepicker-navigation-next'
          onClick={increaseMonth}
        >
          <span className='react-datepicker-navigation-icon react-datepicker-navigation-icon-next'>
            Next Month
          </span>
        </button>
      </div>
    </div>
  );
};

export default DatePickerHeader;
