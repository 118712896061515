import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';

import {
  CreateAnimalForSaleDraftInput,
  CreateAnimalForSaleDraftMutation,
  useCreateAnimalForSaleDraftMutation,
} from 'generated/graphql';

type CreateAnimalForSaleDraft = (
  values: CreateAnimalForSaleDraftInput,
) => Promise<FetchResult<CreateAnimalForSaleDraftMutation>>;

type UseCreateAnimalForSaleDraft = () => {
  createAnimalForSaleDraft: CreateAnimalForSaleDraft;
  loading: boolean;
};

const useCreateAnimalForSaleDraft: UseCreateAnimalForSaleDraft = () => {
  const [createAnimalForSaleDraftRaw, { loading }] = useCreateAnimalForSaleDraftMutation();

  const createAnimalForSaleDraft = useCallback<CreateAnimalForSaleDraft>(
    values =>
      createAnimalForSaleDraftRaw({
        variables: { data: { ...values } },
        refetchQueries: ['animal'],
        awaitRefetchQueries: true,
      }),
    [createAnimalForSaleDraftRaw],
  );

  return { createAnimalForSaleDraft, loading };
};

export default useCreateAnimalForSaleDraft;
