import { FC, useCallback, useMemo, useEffect, Fragment, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider, Form } from 'formik';

import isEqual from 'lodash/isEqual';
// import isNumber from 'lodash/isNumber';
// import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';
import capitalize from 'lodash/capitalize';
import throttle from 'lodash/throttle';
import { toast } from 'react-hot-toast';

import { useLocalStorage } from 'react-use';

import { Row, Col } from 'reactstrap';
import { components, OptionProps } from 'react-select';

import Button from 'components/common/buttons/Button';
import Input from 'components/common/form/Input';
import SelectField from 'components/common/form/SelectField';
import DatePickerField from 'components/common/form/DatePickerField';
import MultiSelectField from 'components/common/form/MultiSelectField';
import CreatableGroupSelectField from 'components/common/form/CreatableGroupSelectField';
import { GroupedOption, Target } from 'components/common/select/CreatableGroupSelect';
import Tooltip from 'components/common/Tooltip';

import StatusInput from 'components/animals/form/StatusInput';
import { animalProfileValidationSchema } from 'components/animals/form/validation';
import CreateAlpacaModal from 'components/animals/createAnimal/CreateAlpacaModal';

import useAnimalOptions from 'hooks/animals/useAnimalOptions';
import useUpdateAnimal, { UpdateAnimalValues } from 'hooks/animals/animal/useUpdateAnimal';
import useCreateAnimal, { CreateAnimalValues } from 'hooks/animals/animal/useCreateAnimal';
import useAnimalFromProvider from 'hooks/animals/animal/useAnimalFromProvider';
import useAnimalColors from 'hooks/animals/useAnimalColors';
import useUnclaimedAnimals from 'hooks/animals/animal/useUnclaimedAnimals';
import useClaimAnimal from 'hooks/animals/animal/useClaimAnimal';
import useIsHerdOwner from 'hooks/herds/members/useIsHerdOwner';
import useHerdsOptions from 'hooks/herds/useHerdOptions';
import useAnimalsRegistry from 'hooks/animals/pedigree/useAnimalsRegistry';
import useHerds from 'hooks/herds/useHerds';
import useCountriesOptions from 'hooks/user/useCountriesOptions';
import useUser from 'hooks/user/useUser';

import { HERDS_ROUTE } from 'helpers/constants';
import getObjectDifference from 'helpers/getObjectDifference';
import getGroupedOptions from 'helpers/getGrupedOptions';
// import getNumberFieldValue from 'helpers/getNumberFieldValue';

import app from 'helpers/app';

import {
  AlpacaProfile,
  AnimalStatus,
  CreateAnimalMutation,
  Herd,
  Maybe,
  RegistryAnimal,
  UpdateAnimalMutation,
  useAchievementsQuery,
} from 'generated/graphql';

type AnimalFormValues = UpdateAnimalValues &
  CreateAnimalValues &
  Pick<RegistryAnimal, 'dam' | 'sire'>;
type CreateAnimal = (data: CreateAnimalValues) => void;
type OnUpdateAnimal = (
  data: UpdateAnimalValues,
  id?: string,
) => Promise<Maybe<UpdateAnimalMutation> | undefined>;

interface CustomOption {
  value: string;
  label: string;
  color: string;
  isFixed?: boolean;
  isDisabled?: boolean;
}

// const convertIntValue = (value?: Maybe<string | number>): Maybe<number> => {
//   if (isNumber(value)) return value;
//   if (isString(value)) return parseInt(value, 10);

//   return null;
// };

const AnimalProfileForm: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { herdId, animalId: animalIdParam } = useParams<{ animalId: string; herdId: string }>();

  const [country, setCountry] = useLocalStorage('animal-country-selected', '');

  const user = useUser();

  const { refetch } = useAchievementsQuery();
  const { herds } = useHerds();

  const hasAnimal = useMemo(() => herds?.find(herd => herd?.animals.length === 0), [herds]);
  const { animal, loading: animalLoading } = useAnimalFromProvider();

  const [modalShow, setModalShow] = useState(false);
  const [createdAnimalName, setCreatedAnimalName] = useState('');
  const [registrySearch, setRegistrySearch] = useState(null);
  const [unclaimedAnimal, setUnclaimedAnimal] = useState(null);
  const [currentPedigreeType, setCurrentPedigreeType] = useState('');
  const [selectedValue, setSelectedValue] = useState<{
    value?: string;
    action?: string;
    label?: string;
  }>({});

  const [unclaimedAnimalOption, setUnclaimedAnimalOption] = useState({
    label: createdAnimalName,
    value: '',
  });

  const toggleModalShow = useCallback((): void => setModalShow(!modalShow), [modalShow]);

  const { herdsOptions } = useHerdsOptions();

  const checkIsOwner = useIsHerdOwner();
  const isOwner = useMemo(() => checkIsOwner(animal?.herd as Herd), [animal?.herd, checkIsOwner]);

  const { updateAnimal, loading: updateAnimalLoading } = useUpdateAnimal();
  const { createAnimal, loading: createAnimalLoading } = useCreateAnimal();
  const { claimAnimal } = useClaimAnimal();

  const countriesOptions = useCountriesOptions();

  const { unclaimedAnimals: unclaimedAnimalsData, loading: unclaimedAnimalLoading } =
    useUnclaimedAnimals(unclaimedAnimal);

  const { registrySearch: animalsFromRegistry, loading: registrySearchLoading } =
    useAnimalsRegistry(registrySearch);

  const groupedOptions = getGroupedOptions(animalsFromRegistry);

  const groupedUnclaimedAnimalsOptions = getGroupedOptions(unclaimedAnimalsData);

  const groupedAnimalsFromRegistry = useMemo(() => {
    if (unclaimedAnimalOption.value !== '') {
      return [
        ...groupedUnclaimedAnimalsOptions,
        ...groupedOptions,
        { label: 'New', options: [unclaimedAnimalOption] },
      ];
    }

    return [...groupedUnclaimedAnimalsOptions, ...groupedOptions];
  }, [groupedOptions, groupedUnclaimedAnimalsOptions, unclaimedAnimalOption]);

  const loading = useMemo(
    () => updateAnimalLoading || createAnimalLoading,
    [updateAnimalLoading, createAnimalLoading],
  );

  const isEditMode = useMemo(() => !!animal, [animal]);

  const initialValues = useMemo<AnimalFormValues>(() => {
    return {
      name: animal?.name ?? '',
      // animalId: getNumberFieldValue(animal?.animalId) as number,
      birthday: animal?.birthday,
      coloration: animal?.coloration ?? [],
      deathDate: animal?.deathDate,
      // temporary
      damId: animal?.dam?.animalId,
      sireId: animal?.sire?.animalId,
      saleDate: animal?.saleDate,
      sex: animal?.sex,
      status: animal?.status ?? AnimalStatus.Active,
      statusNotes: animal?.statusNotes ?? '',
      transferredTo: animal?.transferredTo ?? '',
      herdId: animal?.herd?.id,
      sireLinkType: animal?.sireLinkType,
      damLinkType: animal?.damLinkType,
      microchip: animal?.microchip ?? '',
      country: animal?.country || user?.country || '',
      alpacaProfile: {
        phenotype: (animal?.profile as AlpacaProfile)?.phenotype,
        otherId: (animal?.profile as AlpacaProfile)?.otherId || '',
        otherIssuer: (animal?.profile as AlpacaProfile)?.otherIssuer,
      },
    };
  }, [animal, country, user?.country]);

  const onHandleInputChange = useMemo(
    () =>
      throttle((newValue): void => setRegistrySearch(newValue || null), 500, {
        leading: false,
      }),
    [],
  );

  const onHandleInputChangeUnclaimed = useMemo(
    () =>
      throttle((newValue): void => setUnclaimedAnimal(newValue || null), 500, {
        leading: false,
      }),
    [],
  );

  const onCreateAnimal: CreateAnimal = useCallback(
    ({ /* animalId, */ ...rawValues }) => {
      const values = mapValues(rawValues, value => value || null);

      const animalData = {
        ...values,
        // animalId: convertIntValue(animalId),
      };

      void createAnimal(animalData, herdId)
        .then(({ data }) => {
          const { createAnimal: { id = null } = {} } = data as CreateAnimalMutation;

          if (hasAnimal) {
            void refetch();
          }

          setCountry(animalData.country);

          if (id) history.replace(`/${HERDS_ROUTE}/${herdId}/${id}/profile`);
        })
        .catch(() => null);
    },
    [createAnimal, hasAnimal, herdId, history, refetch, setCountry],
  );

  const onUpdateAnimal: OnUpdateAnimal = useCallback(
    (data, id) => {
      return new Promise((resolve, reject) => {
        if (!isEqual(initialValues, data)) {
          const updatedValues = getObjectDifference(initialValues, data);

          void toast.promise(
            updateAnimal(
              {
                ...updatedValues,
              },
              id || animal?.id || '',
            ),
            {
              loading: t('common.saving'),
              success: result => {
                const resultData = result.data as UpdateAnimalMutation;
                const {
                  updateAnimal: { herd },
                } = resultData;

                if (data.country !== country) setCountry(data.country || '');

                if (herd?.id !== herdId) {
                  history.push(`/${HERDS_ROUTE}/${herd?.id}/${animalIdParam}/profile`);
                }

                resolve(result?.data);

                return t('common.successSaving', { item: t('animals.profile') });
              },
              error: () => {
                reject();

                return t('common.errorSaving');
              },
            },
          );
        } else {
          toast.success(t('common.successSaving', { item: t('animals.profile') }));
          resolve(undefined);
        }
      });
    },
    [
      initialValues,
      updateAnimal,
      animal?.id,
      t,
      country,
      setCountry,
      herdId,
      history,
      animalIdParam,
    ],
  );

  const onCreateLabelSelect = (inputVale: string): JSX.Element => (
    <div>
      {t('common.create')}
      <b>{` ${inputVale}`}</b>
    </div>
  );

  const onClaimAnimal = useCallback(
    data => {
      void claimAnimal(herdId, selectedValue.value || '')
        .then((resData: any) => {
          void onUpdateAnimal(data, resData.claimAnimal.id).then(res => {
            const { updateAnimal: { id = '' } = {} } = res || {};

            if (id) history.replace(`/${HERDS_ROUTE}/${herdId}/${id}/profile`);
          });
        })
        .catch(() => null);
    },
    [claimAnimal, herdId, history, onUpdateAnimal, selectedValue.value],
  );

  const onSubmit = useCallback(
    data => {
      if (!isEditMode) {
        if (selectedValue?.action === 'create-option' || app.appName !== 'alpaca') {
          void onCreateAnimal(data);
        } else {
          void onClaimAnimal(data);
        }
      } else {
        void onUpdateAnimal(data);
      }
    },
    [selectedValue, isEditMode, onClaimAnimal, onCreateAnimal, onUpdateAnimal],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: animalProfileValidationSchema,
    onSubmit,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const { handleSubmit, values, resetForm, setFieldValue } = formik;

  useEffect(() => {
    // If animal is updated, the initialValues will updated too
    // For this case we need reinit Form with updated values
    if (animal) resetForm();
  }, [animal, resetForm]);

  const {
    animalStatusOptions,
    animalSexOptions,
    phenotypeOptions,
    linkTypesOptions,
    otherIssuerOptions,
  } = useAnimalOptions(values?.sex);
  const colorOptions = useAnimalColors();

  const createAnimalHandler = (inputValue: string, pedigreeType: string): void => {
    setCreatedAnimalName(inputValue);
    setCurrentPedigreeType(pedigreeType);
    toggleModalShow();
  };

  const CustomOption = (props: OptionProps<CustomOption, any>): JSX.Element => {
    const { data, innerProps, selectProps } = props;

    const place = useMemo(() => {
      switch (selectProps.id) {
        case 'damLinkType':
          return 'right';
        case 'sireLinkType':
          return 'left';
        default:
          return 'left';
      }
    }, [selectProps.id]);

    return (
      <Tooltip id={innerProps.id} content={data.tooltip} place={place}>
        <components.Option {...props} />
      </Tooltip>
    );
  };

  const MenuList = (props: any): JSX.Element | void => {
    const { children, hasValue } = props;

    if (hasValue && children.option) {
      return (
        <components.MenuList {...props}>
          {children?.filter(({ props: option = {} }: any) => option?.data.__isNew__)}
          <div className='custom-select-label'>{t('animals.selectFromUnclaimed')}</div>
          <div className='custom-select-options'>
            {children?.filter((child: any) => !child.props.data.__isNew__ && child)}
          </div>
        </components.MenuList>
      );
    }

    return children;
  };

  const formatGroupLabel = (data: GroupedOption): JSX.Element => (
    <span className='react-custom-select group-label'>{data.label}</span>
  );

  return (
    <Fragment>
      <CreateAlpacaModal
        show={modalShow}
        toggle={toggleModalShow}
        animalName={createdAnimalName}
        herdId={herdId}
        pedigreeType={currentPedigreeType}
        setHerdIdCallback={setUnclaimedAnimalOption}
        setSelectorValue={setFieldValue}
        title={`${t('common.create')} ${t('animals.alpaca')}`}
      />

      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6}>
              {app.appName !== 'alpaca' || isEditMode ? (
                <Input
                  autoFocus={!animal}
                  id='name'
                  name='name'
                  label={t('animals.animalName')}
                  placeholder={t('animals.animalName')}
                  maxLength={50}
                />
              ) : (
                <CreatableGroupSelectField
                  autoFocus={!animal}
                  id='name'
                  name='name'
                  type='text'
                  maxLength={50}
                  components={{ MenuList }}
                  label={t('animals.animalName')}
                  placeholder={t('animals.animalName')}
                  allowCreateWhileLoading
                  formatCreateLabel={onCreateLabelSelect}
                  createOptionPosition='first'
                  formatGroupLabel={formatGroupLabel}
                  options={groupedUnclaimedAnimalsOptions}
                  isLoading={unclaimedAnimalLoading}
                  inputOptions={{
                    value: selectedValue.value,
                    label: selectedValue.label,
                  }}
                  onInputChange={onHandleInputChangeUnclaimed}
                  onChange={({ target }: Target) => {
                    setSelectedValue(target);
                    void setFieldValue('name', target.label);
                  }}
                />
              )}
            </Col>

            {/* <Col xs={12} md={6}>
              <Input
                id='animalId'
                type='number'
                name='animalId'
                label={t('animals.animalID')}
                placeholder={t('animals.form.animalIDPlaceholder')}
              />
            </Col> */}

            <Col xs={12} md={6}>
              <SelectField
                name='sex'
                options={animalSexOptions}
                label={t('animals.sex')}
                initialValue={initialValues.sex}
                isSearchable={false}
                openMenuOnFocus
              />
            </Col>

            <Col xs={12} md={6}>
              <MultiSelectField
                name='coloration'
                options={colorOptions}
                label={t('animals.coloration')}
                initialValues={initialValues.coloration}
                closeMenuOnSelect={false}
                placeholder={t('animals.coloration')}
                openMenuOnFocus
              />
            </Col>
            <Col xs={12} md={6}>
              <SelectField
                name='status'
                options={animalStatusOptions}
                label={t('animals.status')}
                isSearchable={false}
                openMenuOnFocus
              />
            </Col>

            <Col xs={12} md={6}>
              <DatePickerField name='birthday' label={t('animals.birthday')} />
            </Col>

            {isEditMode && (isOwner || animalLoading) ? (
              <Col xs={12} md={6}>
                <SelectField
                  loading={animalLoading}
                  name='herdId'
                  options={herdsOptions ?? []}
                  label={capitalize(t('herds.herd'))}
                  isSearchable
                />
              </Col>
            ) : null}

            {app.appName === 'alpaca' && (
              <Fragment>
                <Col xs={12} md={6}>
                  <SelectField
                    loading={animalLoading}
                    name='alpacaProfile.phenotype'
                    options={phenotypeOptions}
                    label={t('animals.type')}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <SelectField
                    name='country'
                    options={countriesOptions}
                    label={t('profile.country')}
                    initialValue={initialValues.country}
                    openMenuOnFocus
                  />
                </Col>

                <Col xs={12} md={6}>
                  <Input
                    id='microchip'
                    name='microchip'
                    label='Microchip'
                    maxLength={99}
                    type='text'
                  />
                </Col>

                {animal && <Col xs={12} md={6} />}

                <Col xs={12} md={6}>
                  <SelectField
                    name='alpacaProfile.otherIssuer'
                    options={otherIssuerOptions}
                    label={t('animals.otherIdIssuer')}
                    isSearchable={false}
                    placeholder={`${t('common.select')} ${t('animals.otherIdIssuerPlaceholder')}`}
                    openMenuOnFocus
                  />
                </Col>

                <Col xs={12} md={6}>
                  <Input
                    id='otherId'
                    type='text'
                    name='alpacaProfile.otherId'
                    label={`${t('common.other')} ID`}
                    placeholder={`${t('common.enter')} ID`}
                  />
                </Col>
              </Fragment>
            )}

            <Col xs={12} md={6}>
              <StatusInput loading={animalLoading} status={values.status} sex={values.sex} />
            </Col>
          </Row>

          {app.appName === 'alpaca' && (
            <Fragment>
              <div className='alpaca-guild pedigree'>
                <h2 className='tab-content-heading' id='tab-content-heading'>
                  {t('animals.pedigree')}
                </h2>
              </div>

              <Row>
                <Col xs={12} md={6}>
                  <CreatableGroupSelectField
                    id='damId'
                    name='damId'
                    type='text'
                    label={t('animals.dam')}
                    placeholder={t('animals.dam')}
                    isCreatable
                    createOptionPosition='first'
                    formatCreateLabel={onCreateLabelSelect}
                    defaultOptions={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                    options={groupedAnimalsFromRegistry}
                    isLoading={registrySearchLoading}
                    inputOptions={{
                      value: animal?.dam?.animalId,
                      label: animal?.dam?.animalName,
                    }}
                    allowCreateWhileLoading
                    onInputChange={onHandleInputChange}
                    onCreateOption={(inputValue: string) => {
                      createAnimalHandler(inputValue, 'Dam');
                    }}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <CreatableGroupSelectField
                    isCreatable
                    createOptionPosition='first'
                    id='sireId'
                    name='sireId'
                    type='text'
                    label={t('animals.sire')}
                    placeholder={t('animals.sire')}
                    formatCreateLabel={onCreateLabelSelect}
                    defaultOptions={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                    options={groupedAnimalsFromRegistry}
                    isLoading={registrySearchLoading}
                    inputOptions={{
                      value: animal?.sire?.animalId,
                      label: animal?.sire?.animalName,
                    }}
                    allowCreateWhileLoading
                    onInputChange={onHandleInputChange}
                    onCreateOption={(inputValue: string) => {
                      createAnimalHandler(inputValue, 'Sire');
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6}>
                  <SelectField
                    components={{ Option: CustomOption }}
                    name='damLinkType'
                    id='damLinkType'
                    initialValue={initialValues.damLinkType}
                    options={linkTypesOptions}
                    label={t('animals.damLinkType')}
                    isSearchable={false}
                    openMenuOnFocus
                  />
                </Col>

                <Col xs={12} md={6}>
                  <SelectField
                    components={{ Option: CustomOption }}
                    name='sireLinkType'
                    id='sireLinkType'
                    initialValue={initialValues.sireLinkType}
                    options={linkTypesOptions}
                    label={t('animals.sireLinkType')}
                    isSearchable={false}
                    openMenuOnFocus
                  />
                </Col>
              </Row>
            </Fragment>
          )}

          <div className='submit-btn-container'>
            <Button loading={loading} type='submit' color='primary'>
              {isEditMode ? t('common.save') : t('common.create')}
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </Fragment>
  );
};

export default AnimalProfileForm;
