import React, { FC, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import useCopy from 'use-copy';

import { ControlFormValues } from 'utils/control/parseControlValues';
import { MemberWebsiteFragmentFragment } from 'generated/graphql';

import { link } from 'helpers/constants';

import Input from 'components/common/form/Input';

import { ControlAccordionProps } from '../ControlAccordions';
import ControlAccordion from '../../../common/ControlAccordion';
import ControlField from '../../../common/ControlField';
import NSRecordsTable from '../../tables/NSRecordsTable';

const staticLink = link.slice(0, -1).replace('https://', 'www.');

const StaticLink: FC = () => {
  return (
    <React.Fragment>
      <span style={{ cursor: 'pointer' }} className='text-truncate text-muted'>
        {staticLink}
      </span>
      <span style={{ cursor: 'pointer' }} className='text-muted'>
        /
      </span>
    </React.Fragment>
  );
};

interface HostingAccordionProps extends ControlAccordionProps {
  memberWebsite?: MemberWebsiteFragmentFragment;
}

const HostingAccordion: FC<HostingAccordionProps> = ({ memberWebsite, isOpen, setIsOpen }) => {
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation();
  const { values } = useFormikContext<ControlFormValues>();
  const { slug } = values;

  const [, copy] = useCopy(`${link}${slug}`);

  useEffect(() => {
    const handleResize = (): void => setIsMobile(window.innerWidth < 450);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ControlAccordion
      title={t('profile.controlPage.hosting.title')}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      completed={!!slug}
    >
      <ControlField
        required
        htmlFor='slug'
        label={{
          text: t('profile.controlPage.hosting.address.title'),
          helper: isMobile
            ? `${staticLink}/${values.slug}`
            : t('profile.controlPage.hosting.address.helper'),
        }}
      >
        <Input
          validateWithoutTouch
          isRequired
          type='text'
          id='slug'
          name='slug'
          className='m-0'
          inputClassName={!isMobile ? 'border-left-0 pl-0 flex-shrink-2' : ''}
          placeholder={t('profile.controlPage.hosting.address.placeholder')}
          staticValue={!isMobile && <StaticLink />}
          onStaticClick={() => {
            copy();
            toast.success('Address copied!');
          }}
        />
      </ControlField>

      <ControlField
        className='mt-3'
        htmlFor='domain'
        label={{
          text: t('profile.controlPage.hosting.domain.title'),
          helper: t('profile.controlPage.hosting.domain.helper'),
        }}
      >
        <Input id='domain' name='domain' className='m-0' placeholder='www.placeholder.com' />
      </ControlField>

      <ControlField
        className='mt-3'
        htmlFor='gaId'
        label={{
          text: t('profile.controlPage.hosting.google.title'),
          helper: t('profile.controlPage.hosting.google.helper'),
        }}
      >
        <Input id='gaId' name='gaId' className='m-0' placeholder='G-12345' />
      </ControlField>

      <NSRecordsTable className='mt-4' memberWebsite={memberWebsite} />
    </ControlAccordion>
  );
};

export default HostingAccordion;
