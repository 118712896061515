import { FC, useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import useHerds from 'hooks/herds/useHerds';
import useAnimalsForSale from 'hooks/animals/sale/useAnimalsForSale';
import useUser from 'hooks/user/useUser';

import { ControlFormValues } from 'utils/control/parseControlValues';
import validateEditorState from 'utils/editor/validateEditorState';

import { CONTROL_EDITOR_CONFIG, CONTROL_EDITOR_ICONS } from 'constants/control';

import { Option } from 'components/common/select/Select';
import EditorField from 'components/common/form/EditorField';
import MultiSelectField from 'components/common/form/MultiSelectField';
import ImageUploaderField from 'components/common/image-uploader/ImageUploaderField';

import { ControlAccordionProps } from '../ControlAccordions';
import ControlAccordion from '../../../common/ControlAccordion';
import ControlField from '../../../common/ControlField';

export const saleValidationSchema = Yup.object().shape({
  salePageContent: validateEditorState,
  salePageHeroImageUrl: Yup.string().required(),
  animalsForSale: Yup.array().min(1).required(),
});

const SaleAccordion: FC<ControlAccordionProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ControlFormValues>();

  const { id: userId } = useUser() || {};

  const { herds, loading: loadingHerds } = useHerds();
  const { animalsForSale, loading: loadingAnimalsForSale } = useAnimalsForSale({
    where: { userId },
  });

  const herdsOptions = useMemo(() => {
    const options: Option[] = [];

    (herds || []).forEach(({ animals }) =>
      animals.forEach(anim =>
        options.push({
          label: anim.name,
          value: anim.id,
        }),
      ),
    );

    return options;
  }, [herds]);

  const animalsForSaleValue = useMemo(() => {
    return animalsForSale?.map(animalForSale => animalForSale?.id);
  }, [animalsForSale]);

  const isCompleted = saleValidationSchema.isValidSync(values);
  const isDisabled = !values.salePageLive;

  return (
    <ControlAccordion
      title={t('profile.controlPage.sale.title')}
      toggle='salePageLive'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      completed={isCompleted}
      disabled={isDisabled}
    >
      <ControlField
        required
        label={{
          text: t('profile.controlPage.hero.title'),
          helper: t('profile.controlPage.hero.helper'),
        }}
      >
        <ImageUploaderField name='salePageHeroImageUrl' disabled={isDisabled} />
      </ControlField>

      <ControlField
        required
        className='mt-3'
        label={{
          text: t('profile.controlPage.content.title'),
          helper: t('profile.controlPage.content.helper'),
        }}
      >
        <EditorField
          name='salePageContent'
          placeholder={t('profile.controlPage.content.placeholder')}
          readOnly={isDisabled}
          toolbar={CONTROL_EDITOR_CONFIG}
          editorIcons={CONTROL_EDITOR_ICONS}
        />
      </ControlField>

      <ControlField
        required
        className='mt-3'
        label={{
          text: t('profile.controlPage.sale.animals.title'),
          helper: t('profile.controlPage.sale.animals.helper'),
        }}
      >
        <MultiSelectField
          openMenuOnFocus
          name='animalsForSale'
          placeholder={t('profile.controlPage.sale.animals.placeholder')}
          closeMenuOnSelect={false}
          isDisabled={isDisabled}
          options={herdsOptions}
          initialValues={animalsForSaleValue}
          loading={loadingHerds || loadingAnimalsForSale}
        />
      </ControlField>
    </ControlAccordion>
  );
};

export default SaleAccordion;
