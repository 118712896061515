import { FC, useEffect } from 'react';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

import { ApolloProvider } from '@apollo/client';

import Router from 'routes/Router';

import UserProvider from 'providers/UserProvider';
import ConfirmProvider from 'providers/ConfirmProvider';
import ShowWelcomeProvider from 'providers/ShowWelcomeProvider';
import SelectProvider from 'providers/CurrencyProvider';

import ToasterNotifications from 'components/common/ToasterNotifications';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';

import IosToolBarBackground from 'components/ios/IosToolBarBackground';

import app from 'helpers/app';

import client from 'startup/apollo';

const App: FC = () => {
  useEffect(() => {
    GoogleAuth.initialize({
      clientId: app.googleClientId,
      scopes: ['profile', 'email'],
    });

    if (Capacitor.isNativePlatform()) {
      void Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <SelectProvider>
          <ConfirmProvider>
            <ShowWelcomeProvider>
              <IosToolBarBackground />
              <Router />
            </ShowWelcomeProvider>
          </ConfirmProvider>
        </SelectProvider>
      </UserProvider>
      <ToasterNotifications />
    </ApolloProvider>
  );
};

export default withErrorBoundary(App);
