import gql from 'graphql-tag';

import ANIMAL_FRAGMENT from 'graphql/fragments/animals/animal';
import MATING_EVENT_FRAGMENT from 'graphql/fragments/mating/event/matingEventFragment';

const MATING_RECORD_FRAGMENT = gql`
  fragment matingRecord on Mating {
    id

    cria {
      ...animalFragment
    }

    dam {
      ...animalFragment
    }

    sire {
      ...animalFragment
    }

    outcome

    events {
      ...matingEvent
    }

    createdAt
    updatedAt
  }

  ${MATING_EVENT_FRAGMENT}
  ${ANIMAL_FRAGMENT}
`;

export default MATING_RECORD_FRAGMENT;
