import countries from 'i18n-iso-countries';

import enLocale from 'i18n-iso-countries/langs/en.json';
import ruLocale from 'i18n-iso-countries/langs/ru.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import deLocale from 'i18n-iso-countries/langs/de.json';
import nlLocale from 'i18n-iso-countries/langs/nl.json';

const useCountry = (language: string): { countriesLocale: Record<string, string> } => {
  countries.registerLocale(enLocale);
  countries.registerLocale(ruLocale);
  countries.registerLocale(frLocale);
  countries.registerLocale(deLocale);
  countries.registerLocale(nlLocale);

  const countriesLocale = countries.getNames(language, { select: 'official' });

  return {
    countriesLocale,
  };
};

export default useCountry;
