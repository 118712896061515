import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as DocumentIcon } from 'icons/document.svg';

import { Sanity_ResourceToolkit } from 'generated/sanity_graphql';

const ResourceItem: FC<{ resources: Sanity_ResourceToolkit[] }> = ({ resources }) => {
  const { t } = useTranslation();

  return (
    <div className='blog-resources'>
      {resources.length ? (
        resources?.map(({ _id, name, file }) => (
          <a
            key={_id}
            href={file?.asset?.url || ''}
            target='_blank'
            rel='noopener noreferrer'
            className='resource-item'
          >
            <DocumentIcon className='doc-icon' />
            {name}
          </a>
        ))
      ) : (
        <span>
          {t('common.notFound', {
            context: 'many',
          })}
        </span>
      )}
    </div>
  );
};

export default ResourceItem;
