import { FC, createContext, useMemo } from 'react';

import Loading from 'components/common/Loading';

import { useMeQuery, Maybe, UserFragmentFragment } from 'generated/graphql';

export const UserContext = createContext<Maybe<UserFragmentFragment>>(null);

const UserProvider: FC = ({ children }) => {
  const { data, loading } = useMeQuery();
  const { me = null } = data || {};

  const value = useMemo(() => me, [me]);

  if (loading) return <Loading fullscreen />;

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
