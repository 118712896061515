import { FC, Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';

import ButtonGoBack from 'components/common/buttons/ButtonGoBack';

const VerificationPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <HelmetWithTemplate title={t('route.verification')} />

      <div className='page auth-page verify-email'>
        <div className='auth-form'>
          <h2 className='auth-form-heading verify-heading'>{t('auth.checkEmail')}</h2>

          <p className='verify-email-text'>{t('auth.verifyEmail')}</p>

          <ButtonGoBack color='primary' outline className='custom-submit' />
        </div>
      </div>
    </Fragment>
  );
};

export default withErrorBoundary(VerificationPage);
