import {
  AnimalForSaleStatus,
  HerdFragmentFragment,
  MemberWebsiteFragmentFragment,
} from 'generated/graphql';
import useCreateAnimalForSaleDraft from 'hooks/animals/sale/useCreateAnimalForSaleDraft';
import useHerds from 'hooks/herds/useHerds';
import { useMemo } from 'react';

const useCreateMemberWebsiteAnimalsForSale = () => {
  const { createAnimalForSaleDraft, loading } = useCreateAnimalForSaleDraft();
  const { herds } = useHerds();

  const userAnimals = useMemo(() => {
    const options: HerdFragmentFragment['animals'] = [];

    (herds || []).forEach(({ animals }) => animals.forEach(anim => options.push(anim)));

    return options;
  }, [herds]);

  const createMemberWebsiteAnimalsForSale = (
    newData: string[],
    initialData: MemberWebsiteFragmentFragment['animalsForSale'],
  ): void => {
    /* ========== 
    Animals For Sale (if user add some animals to select)
     ============= */
    for (let i = 0; i < newData.length; i++) {
      const newAnimal = newData[i];
      const animal = initialData.find(initialAnimal => initialAnimal.id === newAnimal);

      if (!animal) {
        const correctAnimal = userAnimals.find(userAnimal => userAnimal.id === newAnimal)!;

        void createAnimalForSaleDraft({
          animalId: correctAnimal.id,
          animalDetails: correctAnimal.draft?.animalDetails || '',
          status: AnimalForSaleStatus.ForSale,
        });
      }
    }

    /* ========== 
    Animals Not For Sale (if user removed some animals to select)
     ============= */
    for (let i = 0; i < initialData.length; i++) {
      const initialAnimal = initialData[i];
      const animal = newData.find(newAnimal => newAnimal === initialAnimal.id);

      if (!animal) {
        void createAnimalForSaleDraft({
          animalId: initialAnimal.id,
          animalDetails: initialAnimal.details?.animalDetails,
          status: AnimalForSaleStatus.NotForSale,
        });
      }
    }
  };

  return { createMemberWebsiteAnimalsForSale, loading };
};

export default useCreateMemberWebsiteAnimalsForSale;
