import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'helpers/constants';

import ME_QUERY from 'graphql/queries/users/me';

import {
  SignInInput,
  SignInMutation,
  SignUpInput,
  SignUpMutation,
  useSignInMutation,
  useSignUpMutation,
  useVerifyEmailMutation,
  VerifyEmailInput,
  VerifyEmailMutation,
} from 'generated/graphql';

type SignIn = (data: SignInInput) => Promise<FetchResult<SignInMutation>>;
type SignUp = (data: SignUpInput) => Promise<FetchResult<SignUpMutation>>;
type VerifyEmail = (data: VerifyEmailInput) => Promise<FetchResult<VerifyEmailMutation>>;

interface UseSignInResult {
  signIn: SignIn;
  signUp: SignUp;
  verifyEmail: VerifyEmail;
  loading: boolean;
}

const useAuth = (): UseSignInResult => {
  const [signInMutation, { loading: signInLoading }] = useSignInMutation();
  const [signUpMutation, { loading: signUpLoading }] = useSignUpMutation();
  const [verifyEmailMutation, { loading: verifyEmailLoading }] = useVerifyEmailMutation();

  const signIn: SignIn = useCallback(
    data =>
      signInMutation({
        variables: { data },
        update: (cache, { data: response }) => {
          if (!response?.signIn) return;

          const { user, accessToken, refreshToken } = response?.signIn ?? {};

          if (accessToken) {
            localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

            cache.writeQuery({
              query: ME_QUERY,
              data: { me: user },
            });
          }

          if (accessToken && refreshToken) localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
        },
      }),
    [signInMutation],
  );

  const verifyEmail: VerifyEmail = useCallback(
    data => {
      return verifyEmailMutation({
        variables: { data },
        update: (cache, { data: response }) => {
          if (!response?.verifyEmail) return;

          const { user, accessToken, refreshToken } = response?.verifyEmail ?? {};

          if (accessToken) {
            localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

            cache.writeQuery({
              query: ME_QUERY,
              data: { me: user },
            });
          }

          if (accessToken && refreshToken) localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
        },
      });
    },
    [verifyEmailMutation],
  );

  const signUp: SignUp = useCallback(
    data =>
      signUpMutation({
        variables: { data },
      }),
    [signUpMutation],
  );

  return {
    signIn,
    signUp,
    verifyEmail,
    loading: signInLoading || signUpLoading || verifyEmailLoading,
  };
};

export default useAuth;
