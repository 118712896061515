import { useMemo } from 'react';

import { AnimalSex, Maybe, useAnimalsRegistryQuery } from 'generated/graphql';

export type RegistryType = {
  sex?: Maybe<AnimalSex>;
  animalId: string;
  animalName: string;
  herdName: string;
};

type AnimalsRegistry = {
  (value: string | null): {
    loading: boolean;
    registrySearch?: RegistryType[];
  };
};

const useAnimalsRegistry: AnimalsRegistry = value => {
  const variables = useMemo(() => ({ where: { value } }), [value]);

  const { data: { registrySearch } = {}, loading } = useAnimalsRegistryQuery({
    variables,
    fetchPolicy: 'network-only',
  });

  return { registrySearch, loading };
};

export default useAnimalsRegistry;
