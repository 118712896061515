import { FC, useMemo, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider, FormikHelpers, Form } from 'formik';

import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import classNames from 'classnames';

import Button from 'components/common/buttons/Button';
import Input from 'components/common/form/Input';

import DeleteHerdButton from 'components/herds/HerdModal/DeleteHerdButton';
import UploadImageModalBody from 'components/common/Image/UploadImageModalBody';

import useCreateHerd from 'hooks/herds/useCreateHerd';
import useUpdateHerd from 'hooks/herds/useUpdateHerd';
import useHerdsFromProvider from 'hooks/herds/useHerdsFromProvider';

import app from 'helpers/app';

import { HerdFragmentFragment, useAchievementsQuery } from 'generated/graphql';

interface Values {
  name: string;
}

type OnSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => Promise<any> | void;

interface Props {
  herd?: HerdFragmentFragment;
  toggle?: () => void;
}

const HerdModalForm: FC<Props> = ({ herd, toggle }) => {
  const { t } = useTranslation();

  const { createHerd, loading: createHerdLoading } = useCreateHerd();
  const { updateHerd, loading: updateHerdLoading } = useUpdateHerd();
  const { herds } = useHerdsFromProvider();
  const { refetch } = useAchievementsQuery();

  const isEditMode = !!herd;
  const initialValues = useMemo(() => ({ name: herd?.name ?? '' }), [herd?.name]);

  const uploadImageEvent = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onUpload: (herdId?: string) => {},
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().min(2, t('herds.herdNameLength')).required(t('herds.herdNameRequired')),
      }),
    [t],
  );

  const onSubmit: OnSubmit = useCallback(
    ({ name }) => {
      if (isEditMode) {
        toggle?.();
        uploadImageEvent.onUpload();
        if (initialValues.name !== name) {
          return toast.promise(updateHerd(name, herd?.id ?? ''), {
            loading: t('common.updating'),
            success: t('common.successUpdate', {
              item: t('herds.herdName'),
            }),
            error: t('common.errorUpdate'),
          });
        }
      } else {
        return toast.promise(createHerd(name, uploadImageEvent.onUpload), {
          loading: t('herds.creatingHerd', { name }),
          success: () => {
            toggle?.();

            if (!herds?.length) {
              void refetch();
            }

            return t('herds.createdHerd', { name });
          },
          error: err => err.message,
        });
      }
    },
    [
      createHerd,
      herd?.id,
      herds,
      initialValues.name,
      isEditMode,
      refetch,
      t,
      toggle,
      updateHerd,
      uploadImageEvent,
    ],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (...args) => {
      void onSubmit(...args)?.catch(() => null);
    },
    validateOnMount: false,
    validateOnBlur: false,
  });

  const {
    values: { name },
    handleChange,
  } = formik;

  const loading = useMemo(
    () => createHerdLoading || updateHerdLoading,
    [createHerdLoading, updateHerdLoading],
  );

  const customLoadImageButton = useMemo(
    () => (
      <span className={classNames('custom-load-text', { 'new-image': isEditMode })}>
        {isEditMode ? t('common.uploadNewImage') : t('common.addHerdImage')}
      </span>
    ),
    [isEditMode, t],
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <Input
          autoFocus
          name='name'
          label={t('herds.herdName', { context: app.appName })}
          placeholder={t('herds.herdNamePlaceholder', { context: app.appName })}
          value={name}
          onChange={handleChange}
        />

        <UploadImageModalBody
          toggle={toggle}
          aspect={2}
          autoload
          bodyClassName='custom-modal-body mb-4'
          uploadImageEvent={uploadImageEvent}
          customLoadImageButton={customLoadImageButton}
        />

        {herd ? (
          <div className='button-group'>
            <DeleteHerdButton toggle={toggle} />

            <Button type='submit' className='btn btn-primary' color='primary' loading={loading}>
              {isEditMode ? t('common.save') : t('common.create')}
            </Button>
          </div>
        ) : (
          <Button type='submit' className='btn btn-primary' color='primary' loading={loading}>
            {isEditMode ? t('common.save') : t('common.create')}
          </Button>
        )}
      </Form>
    </FormikProvider>
  );
};

export default HerdModalForm;
