import app from 'helpers/app';

export enum Language {
  En = 'en',
  De = 'de',
  Fr = 'fr',
  RU = 'ru',
  Nl = 'nl',
}

export const ACCESS_TOKEN_KEY = 'animal-manager-access-token';
export const REFRESH_TOKEN_KEY = 'animal-manager-refresh-token';
export const REDIRECT_PATH = 'animal-manager-redirect-path';

export const LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Русский', value: 'ru' },
  { label: 'Français', value: 'fr' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Nederlandse', value: 'nl' },
];

export const HERDS_ROUTE = app.appName === 'sheep' ? 'flocks' : 'herds';
export const IS_ALPACA = app.appName === 'alpaca';

export const PHONE_PLACEHOLDER = '+1 (123) 456 99 00';

const INITIAL_ALPACA_COLORS = [
  'white',
  'beige',
  'lightFawn',
  'darkFawn',
  'mediumBrown',
  'darkBrown',
  'bayBlack',
  'trueBlack',
  'darkSilverGrey',
  'mediumSilverGrey',
  'lightSilverGrey',
  'darkRoseGrey',
  'mediumRoseGrey',
  'lightRoseGrey',
  'pinto',
  'appaloosa',
];
const INITIAL_SHEEP_COLORS = ['white', 'black', 'blackAndWhite'];

export const INITIAL_ANIMAL_COLORS = IS_ALPACA ? INITIAL_ALPACA_COLORS : INITIAL_SHEEP_COLORS;

export const ROWS_PER_PAGE_OPTIONS = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '100', value: '100' },
];

export const BREEDER = 'breeder';
export const BILLING_PLANS = {
  free: 'Free',
  bronze: 'Bronze',
  breeder: 'Alpaca Breeder',
  silver: 'Silver',
};

export const link =
  app.envName !== 'alpaca.production'
    ? 'https://alpacaweb-dev.netlify.app/'
    : 'https://alpacaweb.netlify.app/';

export const currencyList = ['NZD', 'AUD', 'USD', 'EUR', 'GBP'];
