import { FC, Fragment, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import BlogMenu from 'components/library/BlogMenu';
import ResourceItem from 'components/library/ResourceItem';
import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import CardSkeleton from 'components/common/skeletons/library/CardSkeleton';
import ResourceSkeleton from 'components/common/skeletons/library/ResourceSkeleton';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';

import { useAllAgPostsQuery, useAllResourceToolkitsQuery } from 'generated/sanity_graphql';

const LibraryPage: FC = () => {
  const { t } = useTranslation();
  const { data: { allAgPosts = [] } = {}, loading: postLoading } = useAllAgPostsQuery();
  const { data: { allResourceToolkits = [] } = {}, loading: resLoading } =
    useAllResourceToolkitsQuery();

  const renderCardSkeletons = useMemo(
    (): JSX.Element => (
      <div className='blog-cards-skeleton'>
        {[1, 2, 3].map(key => (
          <CardSkeleton key={key} />
        ))}
      </div>
    ),
    [],
  );

  const renderResSkeletons = useMemo(
    (): JSX.Element => (
      <div className='resource-skeleton'>
        {[1, 2, 3, 4, 5].map(key => (
          <ResourceSkeleton key={key} />
        ))}
      </div>
    ),
    [],
  );

  return (
    <Fragment>
      <HelmetWithTemplate title={t('library.library')} />

      <div className='page library'>
        <div className='header'>
          <h1 className='heading'>{t('library.blog')}</h1>
        </div>

        <div className='page-body'>
          <div className='content'>
            {!postLoading ? <BlogMenu blogs={allAgPosts} /> : renderCardSkeletons}
          </div>
        </div>

        <div className='header'>
          <h1 className='heading'>{t('library.resources')}</h1>
        </div>

        <div className='page-body'>
          <div className='content'>
            {!resLoading ? <ResourceItem resources={allResourceToolkits} /> : renderResSkeletons}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withErrorBoundary(LibraryPage);
