import { gql } from '@apollo/client';

const ANIMAL_TRANSFER = gql`
  fragment animalTransferFragment on AnimalTransferRequest {
    animal {
      name
    }

    id
    ownerName
    recipientName
    recipientEmail
    status
    createdAt
  }
`;

export default ANIMAL_TRANSFER;
