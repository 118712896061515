/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, memo } from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';

import app from 'helpers/app';
import classNames from 'classnames';

const checkedIcon = (): JSX.Element => (
  <div style={{ width: '100%', textAlign: 'center', lineHeight: '31px', color: '#fff' }} />
);

const SwitchToggle: FC<ReactSwitchProps> = ({ disabled, className, ...props }) => {
  return (
    <div onClick={e => e.stopPropagation()}>
      <Switch
        width={51}
        height={31}
        handleDiameter={27}
        borderRadius={16}
        offColor='#888'
        onColor={app.themeAccentColor}
        checkedIcon={checkedIcon()}
        uncheckedIcon={checkedIcon()}
        activeBoxShadow={`0px 0px 2px 3px ${app.themeAccentColor}`}
        className={classNames(className, { '--is-disabled': disabled })}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default memo(SwitchToggle);
