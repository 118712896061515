import { useMemo } from 'react';

import { RegistryType } from 'hooks/animals/pedigree/useAnimalsRegistry';

import { useUnclaimedAnimalsQuery } from 'generated/graphql';

type UnclaimedAnimalsResult = {
  unclaimedAnimals?: RegistryType[];
  loading: boolean;
};

type UseUnclaimedAnimals = {
  (name: string | null): UnclaimedAnimalsResult;
};

const useUnclaimedAnimals: UseUnclaimedAnimals = name => {
  const variables = useMemo(
    () => ({
      where: {
        value: name,
      },
    }),
    [name],
  );

  const { data: { unclaimedAnimals } = {}, loading } = useUnclaimedAnimalsQuery({
    variables,
    fetchPolicy: 'network-only',
  });

  return { unclaimedAnimals, loading };
};

export default useUnclaimedAnimals;
