import { gql } from '@apollo/client';

const BREEDERS_SUBSCRIPTION = gql`
  subscription breedersSubscription {
    breeders {
      mutationType

      breeder {
        id
        city
        email
        firstName
        lastName
        phone
      }
    }
  }
`;

export default BREEDERS_SUBSCRIPTION;
