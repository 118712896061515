import React, { FC, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import Table from 'components/common/Table';
import { MatingEventFragment } from 'generated/graphql';
import EmptyMessage from 'components/common/EmptyMessage';

import useFormat from 'hooks/useFormat';

import CreateMatingEventButton from '../buttons/CreateMatingEventButton';
import { formatEventSpitOffReverse } from '../../utils/formatEventSpitOff';

interface MatingEventsTableProps {
  matingId: string;
  matingEvents?: MatingEventFragment[];
}

const MatingEventsTable: FC<MatingEventsTableProps> = ({ matingId, matingEvents }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentMatingEvent, setCurrentMatingEvent] = useState<MatingEventFragment>();

  const { t } = useTranslation();
  const { formatDate } = useFormat();

  const columns = useMemo(() => {
    return [
      {
        Header: t('mating.event.table.date'),
        accessor: 'date',
        Cell: ({ value }: CellProps<MatingEventFragment>) => formatDate(value, 'd MMMM yyyy'),
        disableSortBy: true,
      },
      {
        Header: t('mating.event.table.location'),
        accessor: 'location',
        Cell: ({ value }: CellProps<MatingEventFragment>) =>
          t(`mating.event.location.${value.toLowerCase()}`),
        disableSortBy: true,
      },
      {
        Header: t('mating.event.table.setting'),
        accessor: 'setting',
        Cell: ({ value }: CellProps<MatingEventFragment>) =>
          t(`mating.event.setting.${value.toLowerCase()}`),
        disableSortBy: true,
      },
      {
        Header: t('mating.event.table.spitOff'),
        accessor: 'spitOff',
        Cell: ({ value }: CellProps<MatingEventFragment>) =>
          t(`mating.event.spitOff.${formatEventSpitOffReverse(value).toLowerCase()}`),
        disableSortBy: true,
      },
    ];
  }, [formatDate, t]);

  const handleRowOnClick = useCallback(matingEvent => {
    setCurrentMatingEvent(matingEvent);
    setShowModal(true);
  }, []);

  if (!matingEvents?.length) {
    return (
      <div>
        <EmptyMessage
          message={t('mating.event.empty.title')}
          hint={t('mating.event.empty.multiple')}
        >
          <CreateMatingEventButton
            showModal={showModal}
            setShowModal={setShowModal}
            currentMatingEvent={currentMatingEvent}
            setCurrentMatingEvent={setCurrentMatingEvent}
            matingId={matingId}
            className='empty'
          />
        </EmptyMessage>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Table
        className='animals-table'
        columns={columns}
        data={matingEvents ?? []}
        rowOnClick={handleRowOnClick}
      />

      <CreateMatingEventButton
        showModal={showModal}
        setShowModal={setShowModal}
        currentMatingEvent={currentMatingEvent}
        setCurrentMatingEvent={setCurrentMatingEvent}
        matingId={matingId}
        className='mt-4'
      />
    </React.Fragment>
  );
};

export default MatingEventsTable;
