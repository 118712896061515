import dagre from 'dagre';

import { Edge } from 'reactflow';

import { CustomNode } from 'helpers/react-flow/getInitialEdges';

const getLayoutedElements = (
  height: number,
  width: number,
  direction: string,
  nodes?: CustomNode[],
  edges?: Edge[],
): { nodes?: CustomNode[]; edges?: Edge[] } => {
  const dagreGraph = new dagre.graphlib.Graph();

  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });

  nodes?.forEach(node => {
    dagreGraph.setNode(node.id, { width, height });
  });

  edges?.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes?.forEach(node => {
    const nodeWithPosition = dagreGraph.node(node.id);

    node.position = {
      x: nodeWithPosition.x - width / 2,
      y: nodeWithPosition.y - height / 2,
    };

    return node;
  });

  return { nodes, edges };
};

export default getLayoutedElements;
