import { FC, useMemo } from 'react';

import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { MatingEventSetting } from 'generated/graphql';

import SelectField from 'components/common/form/SelectField';

export type MatingEventSettingOption = {
  value: MatingEventSetting;
  label: string;
};

const MatingEventSettingInput: FC = () => {
  const { t } = useTranslation();

  const settings: MatingEventSettingOption[] = useMemo(() => {
    return [
      {
        value: MatingEventSetting.Paddock,
        label: t('mating.event.setting.paddock'),
      },
      {
        value: MatingEventSetting.Pen,
        label: t('mating.event.setting.pen'),
      },
    ];
  }, [t]);

  return (
    <Row>
      <Col xs={12}>
        <SelectField
          name='setting'
          label={t('mating.event.table.setting')}
          options={settings}
          required
          isSearchable
        />
      </Col>
    </Row>
  );
};

export default MatingEventSettingInput;
