import { FC } from 'react';

import Logo from 'components/common/Logo';
import SelectLanguage from 'components/auth/SelectLanguage';
import SelectCurrency from 'components/common/SelectCurrency';

import { useLocation } from 'react-router-dom';

const AuthHeader: FC = () => {
  const location = useLocation();

  return (
    <header className='auth-header'>
      <Logo />
      {location.pathname === '/trial-period' && <SelectCurrency />}
      <SelectLanguage />
    </header>
  );
};

export default AuthHeader;
