import {
  ApolloClient,
  InMemoryCache,
  from,
  fromPromise,
  split,
  Operation,
  NextLink,
  HttpLink,
} from '@apollo/client';
import { removeDirectivesFromDocument } from '@apollo/client/utilities';

import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

// import { WebSocketLink } from '@apollo/client/link/ws';
import { createClient } from 'graphql-ws';
import { OperationDefinitionNode, StringValueNode } from 'graphql';

import getNewToken from 'helpers/getNewToken';
import app from 'helpers/app';

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'helpers/constants';

import cacheOptions from 'startup/apollo/cache-options';
import typePolicies from 'startup/apollo/type-policies';
import { toast } from 'react-hot-toast';

import i18n from 'startup/i18next';

const t = i18n.getFixedT(i18n.language);

const cache = new InMemoryCache({ ...cacheOptions, typePolicies });

const wsLink = new GraphQLWsLink(
  createClient({
    url: app.subscriptionUrl as string,
    connectionParams: (): any => ({
      Authorization: localStorage.getItem(ACCESS_TOKEN_KEY) ?? '',
      app: app.appName?.toUpperCase() ?? '',
    }),
    // options: {
    //   timeout: 30000,
    //   reconnect: true,
    //   lazy: true,
    // },
  }),
);

const errorLink = onError(({ graphQLErrors, operation, forward, response }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const { message, extensions } = err;

      const oldRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

      if (extensions.code === 'INTERNAL_SERVER_ERROR') {
        toast.error(t('common.somethingWrong'));
      } else if (message === 'Unauthorized') {
        if (oldRefreshToken) {
          return fromPromise(getNewToken()).flatMap((accessToken: string) => {
            const oldHeaders = operation.getContext().headers;

            operation.setContext({
              headers: {
                ...oldHeaders,
                Authorization: accessToken,
              },
            });

            return forward(operation);
          });
        }
        if (response) response.errors = undefined;
      } else {
        toast.error(message);
      }
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  const appName = app.appName?.toUpperCase();

  return {
    headers: {
      ...headers,
      app: appName,
      Authorization: token,
    },
  };
});

const splitLink = split(
  ({ query: { definitions } }): boolean =>
    definitions.some(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
    ),
  wsLink,
  (operation: Operation, forward: NextLink) => {
    const httpLink = new HttpLink();
    // const httpLink = createHttpLink({ uri: app.apiUrlGraphql });

    const apiName: string = (
      (
        operation.query.definitions.find(
          definition => definition.kind === 'OperationDefinition',
        ) as OperationDefinitionNode
      )?.directives
        ?.find(directive => directive.name?.value === 'api')
        ?.arguments?.find(argument => argument.name?.value === 'name')?.value as StringValueNode
    )?.value;

    const query = removeDirectivesFromDocument([{ name: 'api', remove: true }], operation.query);

    if (!query) throw new Error('Error while removing directive api');

    operation.query = query;

    switch (apiName) {
      case 'sanity': {
        operation.setContext({
          uri: app.sanityApiGql,
          headers: {},
        });

        break;
      }

      default: {
        const { headers } = operation.getContext();

        operation.setContext({
          uri: app.apiUrlGraphql,
          headers,
        });
      }
    }

    return httpLink.request(operation, forward);
  },
);

const link = from([errorLink, authLink, splitLink]);

const client = new ApolloClient({ cache, link, name: app.envName, version: app.appVersion });

export default client;
