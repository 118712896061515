import useFragmentFromCache from 'hooks/useFragmentFromCache';

import MATING_RECORD_FRAGMENT from 'graphql/fragments/mating/record/matingRecordFragment';

import { Maybe, useMatingRecordQuery, MatingRecordFragment } from 'generated/graphql';

type UseMatingRecord = (matingId?: string) => {
  loading?: boolean;
  matingRecord?: Maybe<MatingRecordFragment>;
};

const useMatingRecord: UseMatingRecord = (matingId = '') => {
  const { data: { mating } = {}, loading } = useMatingRecordQuery({
    variables: { where: { id: matingId } },
  });

  const cachedMating = useFragmentFromCache({
    id: `MatingRecord:${matingId}`,
    fragment: MATING_RECORD_FRAGMENT,
  }) as MatingRecordFragment;

  return {
    loading,
    matingRecord: loading ? cachedMating : mating,
  };
};

export default useMatingRecord;
