import { FC, Fragment, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';
import MainContent from 'components/welcome/MainContent';

const WelcomePage: FC = () => {
  const { t } = useTranslation();

  const welcomeToAlpacaGuild = useMemo(() => `${t('profile.welcomeTo')} Alpaca Guild`, [t]);

  return (
    <Fragment>
      <HelmetWithTemplate title={welcomeToAlpacaGuild} />

      <MainContent title={welcomeToAlpacaGuild} />
    </Fragment>
  );
};

export default withErrorBoundary(WelcomePage);
