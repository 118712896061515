import { FC } from 'react';

import ContentLoader from 'react-content-loader';

const CardSkeleton: FC = () => (
  <ContentLoader
    className='card-skeleton'
    speed={2}
    backgroundColor='#FFFFFF'
    foregroundColor='#D4D4D4'
    height='195'
    width='271'
  >
    <rect x='0' y='0' rx='8' width='271' height='195' />
  </ContentLoader>
);

export default CardSkeleton;
