import React, { createContext, useState, useMemo, useEffect } from 'react';

import { useBillingPlansQuery } from 'generated/graphql';

type SelectValue = {
  selectedCurrency: string;
  setSelectedCurrency: (value: string) => void;
};

export const SelectContext = createContext<SelectValue>({
  selectedCurrency: 'USD',
  setSelectedCurrency: () => {},
});

const SelectProvider: React.FC = ({ children }) => {
  const { data, loading } = useBillingPlansQuery({
    variables: {
      where: {
        currency: null,
      },
    },
  });

  const initial = 'USD';

  const [selectedCurrency, setSelectedCurrency] = useState<string>(initial);

  useEffect(() => {
    if (data?.billingPlans?.[1]?.currency && selectedCurrency === initial) {
      setSelectedCurrency(data?.billingPlans?.[1]?.currency);
    }
  }, [data, loading, selectedCurrency]);

  const handleSetCurrency = (newValue: string): void => {
    setSelectedCurrency(newValue);
  };

  const valueObject = useMemo(
    () => ({ selectedCurrency, setSelectedCurrency: handleSetCurrency }),
    [selectedCurrency],
  );

  return <SelectContext.Provider value={valueObject}>{children}</SelectContext.Provider>;
};

export default SelectProvider;
