/* eslint-disable react/jsx-one-expression-per-line */
import { FC, useCallback, useMemo, useState } from 'react';

import { FormGroup, Label } from 'reactstrap';
import RSCreatableGroupSelect from 'react-select/creatable';

import { Props as ReactSelectProps } from 'react-select';

import { Option } from 'components/common/select/Select';

import useUpdateWhenResize from 'hooks/useUpdateWhenResize';

export interface GroupedOption {
  label: string;
  options: Option[];
}

export interface Target {
  target: { value: string; name: string; action: string; label: string };
}

interface Props extends Omit<ReactSelectProps<Option>, 'onChange' | 'value'> {
  required?: boolean;
  label?: string;
  value: string;
  onChange: (target: Target) => void;
}

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    maxHeight: 300,
    overflow: 'auto',
  }),
};

const CreatableGroupSelect: FC<Props> = ({
  id,
  label,
  required,
  name,
  value: currentValue,
  options,
  className,
  children,
  onChange,
  onInputChange,
  inputOptions,
  ...props
}) => {
  const [inputValue, setInputValue] = useState<null | string>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleOnMenuOpen = useCallback(() => setMenuIsOpen(true), []);
  const toggleOnMenuClose = useCallback(() => setMenuIsOpen(false), []);

  useUpdateWhenResize(menuIsOpen);

  const value = useMemo(() => {
    if (inputOptions.value !== undefined && currentValue === inputOptions.value) {
      return inputOptions;
    }

    return options
      ?.find((o: any) => o.options.find((v: any) => v.value === currentValue))
      ?.options.find((v: any) => v.value === currentValue);
  }, [currentValue, inputOptions, options]);

  const onChangeHandler = useCallback(
    (target, { action }) => {
      onChange({ target: { ...target, action, name } });
    },
    [name, onChange],
  );

  return (
    <FormGroup>
      {label && (
        <Label htmlFor={id}>
          {label} {required && <span style={{ color: 'red' }}>*</span>}
        </Label>
      )}

      <RSCreatableGroupSelect
        styles={customStyles}
        cacheOptions
        isSearchable
        className={`react-custom-select-container group ${className}`}
        classNamePrefix='react-custom-select'
        menuPosition='absolute'
        menuPortalTarget={document.body}
        isValidNewOption={() => {
          return !!(inputValue && inputValue?.length >= 1);
        }}
        allowCreateWhileLoading
        value={value}
        options={options}
        onChange={onChangeHandler}
        onMenuOpen={toggleOnMenuOpen}
        onMenuClose={toggleOnMenuClose}
        inputValue={inputValue || ''}
        onInputChange={newValue => {
          setInputValue(newValue);
          onInputChange(newValue);
        }}
        {...props}
      />

      {children}
    </FormGroup>
  );
};

export default CreatableGroupSelect;
