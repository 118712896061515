import { FC } from 'react';

import classNames from 'classnames';

import DataProvider from 'providers/DataProvider';

import Sidebar from 'components/sidebar/Sidebar';
import BurgerMenu from 'components/common/BurgerMenu';

import useSidebar from 'hooks/useSidebar';

const Layout: FC<{ trialBlock?: boolean }> = ({ children, trialBlock }) => {
  const { visible, active, toggleSidebar } = useSidebar();

  if (trialBlock) {
    return null;
  }

  return (
    <div className='layout'>
      <DataProvider value={{ visible, active, toggleSidebar }}>
        <Sidebar />
        <div className={classNames('layout-content', { 'sidebar-hidden': active })}>
          <BurgerMenu />
          {children}
        </div>
      </DataProvider>
    </div>
  );
};

export default Layout;
