import { FC, useMemo } from 'react';

import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { MatingEventLocation } from 'generated/graphql';

import SelectField from 'components/common/form/SelectField';

export type MatingEventLocationOption = {
  value: MatingEventLocation;
  label: string;
};

const MatingEventLocationInput: FC = () => {
  const { t } = useTranslation();

  const locations: MatingEventLocationOption[] = useMemo(() => {
    return [
      {
        value: MatingEventLocation.MyFarm,
        label: t('mating.event.location.my_farm'),
      },
      {
        value: MatingEventLocation.StudFarm,
        label: t('mating.event.location.stud_farm'),
      },
    ];
  }, [t]);

  return (
    <Row>
      <Col xs={12}>
        <SelectField
          name='location'
          label={t('mating.event.table.location')}
          options={locations}
          required
          isSearchable
        />
      </Col>
    </Row>
  );
};

export default MatingEventLocationInput;
