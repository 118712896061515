import { useMemo } from 'react';

import { usePreviousDistinct } from 'react-use';

import { useSubscription } from '@apollo/client';

import BREEDERS_SUBSCRIPTION from 'graphql/subscriptions/breeders';

import { BreederProfile, Maybe, useBreedersQuery } from 'generated/graphql';

type UseBreeders = (props: { skip?: Maybe<number>; take?: Maybe<number> }) => {
  loading: boolean;
  totalCount?: number;
  breeders?: BreederProfile[];
};

const useBreeders: UseBreeders = props => {
  const variables = useMemo(() => props, [props]);

  const {
    data: { breeders: { nodes: breeders = undefined, totalCount = undefined } = {} } = {},
    loading,
    refetch,
  } = useBreedersQuery({ variables, fetchPolicy: 'cache-and-network', returnPartialData: true }); // temporarily

  const prevTotalCount = usePreviousDistinct(totalCount);

  useSubscription(BREEDERS_SUBSCRIPTION, {
    variables,
    onSubscriptionData: () => void refetch(),
  });

  return { loading, totalCount: totalCount || prevTotalCount, breeders };
};

export default useBreeders;
