/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FC, useMemo } from 'react';

import toast from 'react-hot-toast';
import { Form, FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import * as yup from 'yup';

import useConfirm from 'hooks/useConfirm';

import {
  MatingOrderField,
  MatingOutcome,
  MatingRecordFragment,
  OrderDirection,
  useCreateMatingRecordMutation,
  useDeleteMatingRecordMutation,
  useUpdateMatingRecordMutation,
} from 'generated/graphql';
import i18n from 'startup/i18next';

import Button from 'components/common/buttons/Button';

import { ROWS_PER_PAGE_OPTIONS } from 'helpers/constants';

import MATING_RECORDS_QUERY from 'graphql/queries/mating/record/matingRecords';

import MatingPedigreeInputs from './pedigree/MatingRecordPedigreeInputs';
import MatingOutcomeInput from './outcome/MatingRecordOutcomeInput';

const t = i18n.getFixedT(i18n.language);

const validationSchema = yup.object().shape({
  outcome: yup.string().required(t('mating.record.validation.outcome')),
  damId: yup.string().required(t('mating.record.validation.damId')),
  sireId: yup.string().required(t('mating.record.validation.sireId')),
  criaId: yup.string().when('outcome', {
    is: MatingOutcome.Success,
    then: yup.string().required(t('mating.record.validation.criaId')),
  }),
});

export interface MatingRecordFormValues {
  outcome: MatingOutcome;
  damId: string;
  sireId: string;
  criaId: string;
}

interface MatingRecordFormProps {
  matingRecord?: MatingRecordFragment;
}

const MatingRecordForm: FC<MatingRecordFormProps> = ({ matingRecord }) => {
  const history = useHistory();

  const mutationOptions = useMemo(() => {
    return {
      refetchQueries: [
        {
          query: MATING_RECORDS_QUERY,
          variables: {
            orderBy: [{ field: MatingOrderField.CreatedAt, direction: OrderDirection.Desc }],
            skip: 0,
            take: parseInt(ROWS_PER_PAGE_OPTIONS[0].value, 10),
          },
        },
      ],
    };
  }, []);

  /* Delete Mating on button click */
  const confirm = useConfirm({
    title: t('mating.record.form.confirm.title'),
    description: t('mating.record.form.confirm.description'),
  });

  const [deleteMatingEvent, { loading: deleteLoading }] =
    useDeleteMatingRecordMutation(mutationOptions);

  const handleDeleteMatingRecord = async () => {
    if (matingRecord?.id) {
      if (await confirm()) {
        await deleteMatingEvent({ variables: { where: { id: matingRecord.id } } });

        toast.success(t('mating.record.form.toast.delete'));

        history.push('/matings');
      }
    }
  };

  /* Create\Update Mating on submit */
  const [createMatingRecord, { loading: createLoading }] =
    useCreateMatingRecordMutation(mutationOptions);

  const [updateMatingRecord, { loading: updateLoading }] =
    useUpdateMatingRecordMutation(mutationOptions);

  const onSubmit = async (data: MatingRecordFormValues) => {
    try {
      const parsedData = {
        ...data,
        criaId: data.outcome === MatingOutcome.Success ? data.criaId : null,
      };

      if (matingRecord?.id) {
        await updateMatingRecord({
          variables: { data: parsedData, where: { id: matingRecord.id } },
        });

        toast.success(t('mating.record.form.toast.update'));
      } else {
        const { data: createMatingData } = await createMatingRecord({
          variables: { data: parsedData },
        });

        toast.success(t('mating.record.form.toast.create'));

        if (createMatingData) {
          history.push(`/matings/${createMatingData.createMating.id}/information`);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  /* Initialize Form */
  const formik = useFormik<MatingRecordFormValues>({
    initialValues: {
      outcome: matingRecord?.outcome || MatingOutcome.Success,
      damId: matingRecord?.dam?.id || '',
      sireId: matingRecord?.sire?.id || '',
      criaId: matingRecord?.cria?.id || '',
    },
    validateOnMount: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit,
  });

  const { handleSubmit } = formik;

  const isDisabled = createLoading || updateLoading || deleteLoading;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <MatingOutcomeInput />

        <MatingPedigreeInputs />

        <div className='button-group'>
          {matingRecord?.id && (
            <Button
              className='btn btn-secondary'
              color='light'
              disabled={isDisabled}
              onClick={handleDeleteMatingRecord}
            >
              {t('mating.record.form.delete')}
            </Button>
          )}

          <Button className='ml-auto' type='submit' color='primary' disabled={isDisabled}>
            {t('common.save')}
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default MatingRecordForm;
