import { useCallback } from 'react';

import {
  MarkAchievementsAsSeenMutation,
  useMarkAchievementsAsSeenMutation,
} from 'generated/graphql';

import { FetchResult } from '@apollo/client';

import ACHIEVEMENTS_QUERY from 'graphql/queries/users/achievements';

export type MarkAchievementsAsSeen = () => Promise<FetchResult<MarkAchievementsAsSeenMutation>>;

interface UseMarkAchievementsAsSeen {
  markAchievementsAsSeen: MarkAchievementsAsSeen;
  loading: boolean;
}

const useMarkAchievementsAsSeen = (): UseMarkAchievementsAsSeen => {
  const [markAchievementsAsSeenRaw, { loading }] = useMarkAchievementsAsSeenMutation({});

  const markAchievementsAsSeen: MarkAchievementsAsSeen = useCallback(
    () =>
      markAchievementsAsSeenRaw({
        update: (cache, { data }) => {
          if (!data?.markAchievementsAsSeen) return;

          try {
            const { achievements = [] } = cache.readQuery({ query: ACHIEVEMENTS_QUERY }) ?? {};

            cache.writeQuery({
              query: ACHIEVEMENTS_QUERY,
              data: achievements,
            });
          } catch (error) {}
        },
      }),
    [markAchievementsAsSeenRaw],
  );

  return { markAchievementsAsSeen, loading };
};

export default useMarkAchievementsAsSeen;
