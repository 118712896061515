import { gql } from '@apollo/client';

import MATING_RECORD_FRAGMENT from 'graphql/fragments/mating/record/matingRecordFragment';

const MATING_RECORDS_QUERY = gql`
  query matingRecords($orderBy: [OrderByMatingInput!], $skip: Int, $take: Int) {
    matings(orderBy: $orderBy, skip: $skip, take: $take) {
      nodes {
        ...matingRecord
      }

      pageInfo {
        hasNextPage
      }

      totalCount
    }
  }

  ${MATING_RECORD_FRAGMENT}
`;

export default MATING_RECORDS_QUERY;
