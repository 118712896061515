import { useHistory } from 'react-router-dom';

import Button from 'components/common/buttons/Button';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CreateMatingRecordButtonProps {
  className?: string;
}

const CreateMatingRecordButton: FC<CreateMatingRecordButtonProps> = ({ className }) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <Button
      color='primary'
      className={classNames('create-btn', className)}
      onClick={() => history.push('/matings/create-mating')}
    >
      <span className='create-btn-text'>{t('mating.record.create')}</span>
    </Button>
  );
};

export default CreateMatingRecordButton;
