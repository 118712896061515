import { FC, useMemo } from 'react';

import { Form, FormikProvider, useFormik } from 'formik';

import { toast } from 'react-hot-toast';
import i18n from 'startup/i18next';

import * as Yup from 'yup';

import useMemberWebsites from 'hooks/memberWebsite/useMemberWebsites';
import useCreateMemberWebsite from 'hooks/memberWebsite/useCreateMemberWebsite';
import useUpdateMemberWebsite from 'hooks/memberWebsite/useUpdateMemberWebsite';

import {
  ControlFormValues,
  toControlFormValues,
  toControlRequestValues,
} from 'utils/control/parseControlValues';

import useCreateMemberWebsiteAnimalsForSale from 'hooks/memberWebsite/useCreateMemberWebsiteAnimalsForSale';
import useDeleteMemberWebsiteImages from 'hooks/memberWebsite/useDeleteMemberWebsiteImages';

import AutoSave from 'components/common/form/AutoSave';
import Loading from 'components/common/Loading';

import ControlLive from './sections/live/ControlLive';
import ControlAccordions from './sections/accordions/ControlAccordions';

const t = i18n.getFixedT(i18n.language, 'auth');

const validationSchema = Yup.object().shape({
  slug: Yup.string().required(t('profile.controlPage.validation.slug')),

  contactPageEmail: Yup.string()
    .nullable()
    .transform(value => (value === '' ? null : value))
    .email(t('profile.controlPage.validation.email')),
});

const ControlForm: FC = () => {
  const { memberWebsites, loading } = useMemberWebsites();

  const { createMemberWebsite } = useCreateMemberWebsite();
  const { updateMemberWebsite } = useUpdateMemberWebsite();
  const { createMemberWebsiteAnimalsForSale } = useCreateMemberWebsiteAnimalsForSale();
  const { deleteMemberWebsiteImages } = useDeleteMemberWebsiteImages();

  const memberWebsite = useMemo(() => memberWebsites?.[0], [memberWebsites]);

  const handleUpdateMemberWebsite = async (data: ControlFormValues): Promise<void> => {
    createMemberWebsiteAnimalsForSale(data.animalsForSale, memberWebsite?.animalsForSale || []);
    deleteMemberWebsiteImages(data);

    const parsedData = await toControlRequestValues(data);

    if (memberWebsite) {
      void updateMemberWebsite(parsedData, { id: memberWebsite.id });
    } else {
      void createMemberWebsite(parsedData);
    }
  };

  const handleSubmit = (data: ControlFormValues): void => {
    void toast.promise(handleUpdateMemberWebsite(data), {
      loading: t(memberWebsite ? 'common.updating' : 'common.creating'),
      success: t(memberWebsite ? 'common.successUpdate' : 'common.successCreate', {
        item: t('profile.profile'),
      }),
      error: t(memberWebsite ? 'common.errorUpdate' : 'common.errorCreate'),
    });
  };

  const initialValues = useMemo(() => toControlFormValues(memberWebsite), [memberWebsite]);

  const formik = useFormik({
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    initialValues,
    validationSchema,
  });

  if (loading) return <Loading />;

  return (
    <FormikProvider value={formik}>
      <Form className='form breeder-form'>
        <AutoSave debounceMs={2500} />
        <ControlLive isMemberWebsiteLive={memberWebsite?.isLive} />
        <ControlAccordions memberWebsite={memberWebsite} />
      </Form>
    </FormikProvider>
  );
};

export default ControlForm;
