import { ACCESS_TOKEN_KEY } from 'helpers/constants';
import { Image } from 'generated/graphql';

import app from 'helpers/app';

import { ControlFormValues } from './parseControlValues';

const parseControlImages = (
  data: ControlFormValues,
): Promise<{ [x: string]: string | null | undefined }>[] => {
  const images = [
    {
      name: 'aboutPageHeroImage',
      value: data.aboutPageHeroImage,
      url: data.aboutPageHeroImageUrl,
    },
    {
      name: 'heroImage',
      value: data.heroImage,
      url: data.heroImageUrl,
    },
    {
      name: 'salePageHeroImage',
      value: data.salePageHeroImage,
      url: data.salePageHeroImageUrl,
    },
    {
      name: 'bookingPageHeroImage',
      value: data.bookingPageHeroImage,
      url: data.bookingPageHeroImageUrl,
    },
    {
      name: 'contactPageHeroImage',
      value: data.contactPageHeroImage,
      url: data.contactPageHeroImageUrl,
    },
  ];

  return images.map(async ({ name, value, url }) => {
    if (!url) {
      return { [name]: null };
    }

    if (!url.includes('data:image')) {
      return { [name]: value };
    }

    const formData = new FormData();

    const base64response = await fetch(url);
    const blobImage = await base64response.blob();

    formData.append('file', blobImage);
    formData.append('isMemberWebsite', 'true');

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: new Headers({ Authorization: localStorage.getItem(ACCESS_TOKEN_KEY) ?? '' }),
      body: formData,
    };

    const result: Image = await fetch(`${app.apiUrl}/images/upload`, requestOptions)
      .then(res => res.text())
      .then(res => JSON.parse(res))
      .catch(err => err);

    return { [name]: result.id };
  });
};

export default parseControlImages;
