import { FC, useCallback, useMemo, useState, Fragment, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { toast } from 'react-hot-toast';
import classNames from 'classnames';

import Button from 'components/common/buttons/Button';
import ImageCropper from 'components/common/Image/ImageCropper';

import useUploadImage from 'hooks/images/useUploadImage';
import { CropperProps } from 'react-easy-crop';

type UploadImageModalBody = {
  toggle?: () => void;
  handleUpload?: (imageBlob: Blob) => void;
  bodyClassName?: string;
  aspect?: number;
  autoload?: boolean;
  uploadImageEvent?: any;
  customLoadImageButton?: JSX.Element;
  cropperProps?: CropperProps;
  withoutMaxSide?: boolean;
  imageSize?: {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
  };
};

const UploadImageModalBody: FC<UploadImageModalBody> = ({
  toggle,
  handleUpload,
  bodyClassName,
  aspect,
  imageSize,
  autoload,
  uploadImageEvent,
  customLoadImageButton,
  cropperProps,
  withoutMaxSide,
}) => {
  const { t } = useTranslation();
  const { animalId, herdId } = useParams<{ animalId: string; herdId: string }>();
  const { uploadImage, loading } = useUploadImage();
  const [isDragZone, setDragZone] = useState(false);

  const [file, setFile] = useState<File | null>(null);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });

  const onDrop = useCallback(([droppedFile]: File[]) => {
    setFile(droppedFile);
    setDragZone(false);
  }, []);

  const entityType = useMemo(() => (animalId ? { animalId } : { herdId }), [animalId, herdId]);

  const onUpload = useCallback(
    (id?: string) => {
      if (loading) return;

      const entity = id ? { herdId: id } : entityType;

      void toast
        .promise(uploadImage({ entity, image: imageBlob as File }), {
          loading: t('common.uploading'),
          success: t('common.imageUploaded'),
          error: err => err.message,
        })
        .finally(() => entityType.animalId && toggle?.());
    },
    [entityType, imageBlob, loading, t, toggle, uploadImage],
  );

  useEffect(() => {
    if (uploadImageEvent && file) {
      uploadImageEvent.onUpload = handleUpload || onUpload;
    }
  }, [file, handleUpload, onUpload, uploadImageEvent]);

  const onDragEnter = useCallback(() => {
    setDragZone(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setDragZone(false);
  }, []);

  const isBtnDisabled = useMemo(() => {
    if (!imageSize) {
      return false;
    }

    if (imgSize.width < imageSize.minWidth || imgSize.width > imageSize.maxWidth) {
      return true;
    }

    if (imgSize.height < imageSize.minHeight || imgSize.height > imageSize.maxHeight) {
      return true;
    }

    return false;
  }, [imgSize, imageSize]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    multiple: false,
    accept: 'image/jpeg, image/png',
  });

  return (
    <div className={classNames('upload-image-modal-body', bodyClassName, { 'border-0': file })}>
      <div
        className={classNames('image-preview', {
          'cropper-image': file,
        })}
      >
        {autoload ? (
          !file ? (
            <div
              {...getRootProps()}
              className={classNames('drop-area', { 'drag-active': isDragZone })}
            >
              {customLoadImageButton || t('common.clickOrDrag')}
              <input {...getInputProps()} />
            </div>
          ) : (
            <div
              className={classNames('crop-area', { 'drag-active': isDragZone })}
              {...getRootProps()}
            >
              <ImageCropper
                file={file}
                onImageChange={setImageBlob}
                aspect={aspect}
                cropperProps={cropperProps}
                withoutMaxSide={withoutMaxSide}
                setImgSize={setImgSize}
              />
            </div>
          )
        ) : (
          <Fragment>
            <div {...getRootProps()}>
              <Button
                color='primary'
                className={classNames('dropbox-btn', {
                  compact: !!imageBlob,
                  'btn-transparent': !imageBlob,
                })}
              >
                {t('common.clickOrDrag')}

                <input {...getInputProps()} />
              </Button>
            </div>

            <ImageCropper
              file={file}
              onImageChange={setImageBlob}
              aspect={aspect}
              cropperProps={cropperProps}
              withoutMaxSide={withoutMaxSide}
              setImgSize={setImgSize}
            />
          </Fragment>
        )}
      </div>

      {imageBlob && !autoload && (
        <div className='upload-image-btns'>
          <Button
            type='submit'
            color='primary'
            disabled={isBtnDisabled}
            onClick={() => (handleUpload ? handleUpload(imageBlob) : onUpload())}
            autoFocus
          >
            {t('common.upload')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default UploadImageModalBody;
