import { FC, Fragment, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';
import Loading from 'components/common/Loading';

import AuthForm from 'components/auth/AuthForm';

import { ACCESS_TOKEN_KEY, REDIRECT_PATH } from 'helpers/constants';

import { AuthPayload } from 'generated/graphql';

export type AuthParams = { accessToken?: AuthPayload['accessToken']; redirectPath?: string };

const SignInPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [, setState] = useState<string>();

  const { accessToken, redirectPath } = useParams<AuthParams>();

  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    setState(accessToken);

    if (redirectPath) {
      localStorage.setItem(REDIRECT_PATH, redirectPath);
      history.push(`/${redirectPath}`);
    }
  }

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY) && accessToken) {
      window.location.reload();
    }
  }, [accessToken]);

  if (!redirectPath) {
    return (
      <Fragment>
        <HelmetWithTemplate title={t('route.signIn')} noIndex />

        <div className='page auth-page'>
          <AuthForm mode='SIGN_IN' />
        </div>
      </Fragment>
    );
  }

  return <Loading />;
};

export default withErrorBoundary(SignInPage);
