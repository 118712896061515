import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import Button from 'components/common/buttons/Button';

import app from 'helpers/app';

import { ReactComponent as Logo } from 'icons/google.svg';

const GoogleButton: FC<{ isSignIn?: boolean }> = ({ isSignIn }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleClick = useCallback(async () => {
    setLoading(true);

    try {
      const response = await GoogleAuth.signIn();

      if (response) {
        history.replace(`/_oauth/google?token=${response.authentication.accessToken}`);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  if (!app.googleClientId) return null;

  return (
    <Button type='button' className='auth-provider google' loading={loading} onClick={handleClick}>
      <Logo className='auth-provider-logo' />
      <b className='auth-provider-text'>
        {isSignIn ? t('auth.signIn.withGoogle') : t('auth.signUp.withGoogle')}
      </b>
    </Button>
  );
};

export default GoogleButton;
