import { useSubscription } from '@apollo/client';
import { useMemo } from 'react';

import {
  useMedicationRemindersQuery,
  OrderByMedicationReminderInput,
  WhereMedicationReminderInput,
  Maybe,
  MedicationReminderFragmentFragment,
  AnimalStatus,
} from 'generated/graphql';
import MEDICATIONS_SUBSCRIPTION from 'graphql/subscriptions/medications';

type UseMedicationReminders = (props: {
  orderBy?: Maybe<OrderByMedicationReminderInput | OrderByMedicationReminderInput[]>;
  where: WhereMedicationReminderInput;
  isActiveOnly?: boolean;
}) => {
  medicationReminders?: MedicationReminderFragmentFragment[];
  loading: boolean;
};

const useMedicationReminders: UseMedicationReminders = props => {
  const variables = useMemo(
    () => ({
      ...props,
    }),
    [props],
  );

  const {
    data: { medicationReminders } = {},
    loading,
    refetch,
  } = useMedicationRemindersQuery({
    variables,
  });

  const currentMedicationReminders = useMemo(() => {
    if (props.isActiveOnly) {
      return medicationReminders?.filter(
        rem => rem.medication.animal.status === AnimalStatus.Active,
      );
    }

    return medicationReminders;
  }, [medicationReminders, props.isActiveOnly]);

  useSubscription(MEDICATIONS_SUBSCRIPTION, {
    variables,
    onSubscriptionData: () => void refetch(),
  });

  return { medicationReminders: currentMedicationReminders, loading };
};

export default useMedicationReminders;
