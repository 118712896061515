import { Fragment } from 'react';

import i18n from 'startup/i18next';

import camelCase from 'lodash/camelCase';

import thumbUpImage from 'images/thumb-up.png';

const t = i18n.getFixedT(i18n.language);

const AchievementModalBody = (image: string, name: string): JSX.Element => (
  <Fragment>
    <div className='image-container'>
      <div className='achievement-image'>
        <img src={image || thumbUpImage} alt='Thumb-up achievements' />
      </div>
    </div>

    <h1 className='modal-title'>{t('achievements.earnAchievementTitle')}</h1>
    <p className='modal-description'>{t(`achievements.${camelCase(name)}.description`)}</p>
  </Fragment>
);

export default AchievementModalBody;
