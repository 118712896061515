/* eslint-disable react/jsx-one-expression-per-line */
import { FC, Fragment, useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';

import { FormGroup, Label, Input as RSInput } from 'reactstrap';

import DatePickerRaw from 'components/common/DatePicker/DatePickerRaw';
import DatePickerProps from 'components/common/DatePicker/DatePickerPropsType';
import Modal from 'components/common/Modal';

import useFormat from 'hooks/useFormat';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker: FC<DatePickerProps> = ({
  label,
  name,
  children,
  value,
  isModal = true,
  inputClassName,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const { formatDate, localePattern } = useFormat();

  const formattedValue = useMemo(() => (value ? formatDate(value) ?? '' : ''), [formatDate, value]);

  const handleOpen = useCallback(() => {
    setShow(true);

    // is needed to turn off the focus effect after selecting
    (document.activeElement as HTMLElement)?.blur();
  }, []);

  const handleClose = useCallback(() => setShow(false), []);

  return (
    <Fragment>
      <FormGroup className='date-picker'>
        {label && (
          <Label htmlFor={props?.id ?? name}>
            {label} {props.required && <span style={{ color: 'red' }}>*</span>}
          </Label>
        )}

        {isModal ? (
          <RSInput
            disabled={show}
            autoComplete='off'
            className={classNames('date-modal-control', inputClassName)}
            name={name}
            label={label}
            value={formattedValue}
            placeholder={localePattern}
            onClick={handleOpen}
            onChange={() => null}
          />
        ) : (
          <DatePickerRaw name={name} {...props} value={value} />
        )}
        {children}
      </FormGroup>

      {isModal && (
        <Modal
          showCloseButton
          show={show}
          className='date-modal'
          autoFocus={false}
          onClose={handleClose}
        >
          <DatePickerRaw onClose={handleClose} name={name} {...props} value={value} open />
        </Modal>
      )}
    </Fragment>
  );
};

export default DatePicker;
