import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import WelcomeShowCheckbox from 'components/welcome/WelcomeShowCheckbox';
import Header from 'components/welcome/Header';

import { ReactComponent as Empty } from 'images/empty.svg';

import app from 'helpers/app';

const { appName } = app;

const NoReminding: FC<{ handleToggleChange: () => void; checked: boolean }> = ({
  handleToggleChange,
  checked,
}) => {
  const { t } = useTranslation();

  return (
    <div className='page welcome-page'>
      <Header handleToggleChange={handleToggleChange} checked={checked} />
      <div className='page-body justify-content-center align-items-center'>
        <div className='page-content justify-content-center'>
          <div className='main-content d-flex flex-column justify-content-center align-items-center mt-0'>
            <Empty />

            <h1 className='text-center title-content'>{t('animals.medication.noMedication')}</h1>

            <p className='text-center'>
              {t('animals.medication.medicationTip', {
                animal: `${appName}s`,
                ruAnimal: t(`animals.genitive.${appName}s`).toLowerCase(),
              })}
            </p>
          </div>
        </div>
        <div className='bottom-content'>
          <WelcomeShowCheckbox
            text={t('profile.dontShowWelcome')}
            id='checkbox-welcome-page'
            isReverse
          />
        </div>
      </div>
    </div>
  );
};

export default NoReminding;
