import { FC, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { isEqual } from 'lodash';
import { toast } from 'react-hot-toast';

import { Row, Col, Label } from 'reactstrap';
import { Form, useFormik, FormikProvider } from 'formik';

import BreederModal from 'components/profile/breeder/BreederModal';
import EditorField from 'components/common/form/EditorField';
import Button from 'components/common/buttons/Button';
import Input from 'components/common/form/Input';
import SwitchToggle from 'components/common/SwitchToggle';

import schema from 'components/profile/validation';

import useCheckSubscribed from 'hooks/user/useCheckSubscribed';
import useUpdateUser from 'hooks/user/useUpdateUser';
import useEditorState from 'hooks/useEditorState';
import useUser from 'hooks/user/useUser';

import { PHONE_PLACEHOLDER } from 'helpers/constants';

const BreederForm: FC = () => {
  const { t } = useTranslation();
  const { updateUser, loading } = useUpdateUser();
  const user = useUser();
  const { convertEditorStateToString, formatEditorStateToJs, getCurrentEditorState } =
    useEditorState();
  const isSubscribed = useCheckSubscribed();
  const [showModal, setShowModal] = useState(false);

  const toggleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const [isChecked, setIsChecked] = useState(user?.breederProfile?.isPublic);

  const initialValues = useMemo(
    () => ({
      email: user?.breederProfile?.email || '',
      phone: user?.breederProfile?.phone || '',
      bio: getCurrentEditorState(user?.breederProfile?.bio),
      isPublic: user?.breederProfile?.isPublic || '',
    }),
    [getCurrentEditorState, user],
  );
  const onSubmit = useCallback(
    data => {
      if (
        !isEqual(
          { ...data, bio: formatEditorStateToJs(data.bio), isPublic: isChecked },
          { ...initialValues, bio: formatEditorStateToJs(initialValues.bio) },
        )
      ) {
        const { email: breederEmail, phone: breederPhone, bio: breederBio } = data;

        void toast.promise(
          updateUser({
            breederEmail,
            breederPhone,
            isPublic: isChecked,
            breederBio: convertEditorStateToString(breederBio),
          }),
          {
            loading: t('common.updating'),
            success: t('common.successUpdate', { item: t('profile.profile') }),
            error: t('common.errorUpdate'),
          },
        );
      } else {
        toast.success(t('common.successUpdate', { item: t('profile.profile') }));
      }
    },
    [convertEditorStateToString, formatEditorStateToJs, initialValues, isChecked, t, updateUser],
  );

  const includeBreederHandler = (): void => {
    if (isSubscribed) {
      setIsChecked(!isChecked);
    } else {
      setShowModal(true);
      setIsChecked(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema.breeder,
    onSubmit,
    validateOnMount: false,
    validateOnBlur: false,
  });

  return (
    <FormikProvider value={formik}>
      <BreederModal show={showModal} toggle={toggleShowModal} />

      <Form className='form breeder-form'>
        <Row>
          <Col xs={12} md={6}>
            <Input
              id='email'
              type='email'
              name='email'
              label={t('profile.contactEmail')}
              placeholder='email@example.com'
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              id='phone'
              type='tel'
              name='phone'
              label={t('profile.contactPhone')}
              placeholder={PHONE_PLACEHOLDER}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EditorField
              label={t('profile.breederBio')}
              name='bio'
              initialValue={initialValues.bio}
              placeholder={t('profile.breederBio')}
            />
          </Col>
        </Row>
        <Row>
          <Col />
        </Row>

        <div className='form-checkbox mb-5'>
          <Label className='form-checkbox-label custom-checkbox-label ml-3' for='isPublic'>
            {t('profile.includeBreederDirectory')}
          </Label>

          <SwitchToggle
            id='isPublic'
            checked={isChecked || false}
            onChange={includeBreederHandler}
          />
        </div>

        <Button loading={loading} type='submit' color='primary'>
          {t('common.save')}
        </Button>
      </Form>
    </FormikProvider>
  );
};

export default BreederForm;
