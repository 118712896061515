import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { HERDS_ROUTE } from 'helpers/constants';
import getRandomImage from 'helpers/getRandomImage';

import { Herd } from 'generated/graphql';

interface Props {
  herd: Herd;
}

const HerdCard: FC<Props> = ({ herd }) => {
  const herdImage = useMemo(
    () => getRandomImage('https://dvfsxesbc88ri.cloudfront.net/static/herd-cover', herd.name, 8),
    [herd.name],
  );

  const images = useMemo(
    () =>
      herd?.images.map(image => ({
        src: image.url,
        // placeholderSrc: image.thumbUrl || image.url || image.originalUrl,
      })),
    [herd?.images],
  );

  const image = useMemo(() => images?.[images.length - 1], [images]);

  return (
    <Link className='herd-card' to={`/${HERDS_ROUTE}/${herd.id}`}>
      {image ? (
        <div className='herd-image'>
          <LazyLoadImage
            key={herd.id}
            effect='blur'
            width='100%'
            alt={herd.name}
            height='100%'
            visibleByDefault={image.src === `${image.src}`}
            {...image}
          />
        </div>
      ) : (
        <div className='herd-image' style={{ backgroundImage: `url(${herdImage})` }} />
      )}

      <div className='herd-name'>{herd.name}</div>
    </Link>
  );
};

export default HerdCard;
