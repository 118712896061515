import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { throttle } from 'lodash';
import getCroppedImage from 'helpers/image/getCroppedImage';

// eslint-disable-next-line import/no-unresolved
import { Area, Point } from 'react-easy-crop/types';

import Loading from 'components/common/Loading';

interface ImageUploaderCropperProps {
  file: File | null;
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
  setImageBlob: Dispatch<SetStateAction<Blob | null>>;
  setCroppedAreaPixels: Dispatch<SetStateAction<Area>>;
}

const ImageUploaderCropper: FC<ImageUploaderCropperProps> = ({
  file,
  zoom,
  setZoom,
  setImageBlob,
  setCroppedAreaPixels,
}) => {
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

  useEffect(() => {
    const reader = new FileReader();

    reader.addEventListener('load', () => setImageUrl(reader.result as string), false);

    if (file) reader.readAsDataURL(file);
  }, [file]);

  const onCropComplete = useCallback(
    throttle(async (_: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);

      const croppedImageBlob = await getCroppedImage({
        imageUrl,
        croppedAreaPixels,
      });

      setImageBlob(croppedImageBlob);
    }, 300),
    [imageUrl],
  );

  const onMediaLoaded = useCallback(() => {
    setMediaLoaded(true);
  }, []);

  if (!imageUrl) return null;

  return (
    <div className='image-uploader-cropper'>
      {file && !mediaLoaded && <Loading className='image-uploader-cropper__loading' page />}

      <Cropper
        classes={{
          containerClassName: 'image-uploader-cropper__container',
          cropAreaClassName: 'image-uploader-cropper__area',
        }}
        objectFit='vertical-cover'
        image={imageUrl}
        aspect={16 / 9}
        minZoom={1}
        maxZoom={3}
        zoom={zoom}
        onZoomChange={setZoom}
        crop={crop}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onMediaLoaded={onMediaLoaded}
      />
    </div>
  );
};

export default ImageUploaderCropper;
