import { Maybe, NationalHerdTreeAnimal, PedigreeLinkType } from 'generated/graphql';

const getLinkType = (
  familyTree?: Maybe<NationalHerdTreeAnimal>,
  entity?: Maybe<NationalHerdTreeAnimal>,
): PedigreeLinkType => {
  switch (entity?.id) {
    case familyTree?.damId:
      return familyTree?.damLinkType as PedigreeLinkType;
    case familyTree?.sireId:
      return familyTree?.sireLinkType as PedigreeLinkType;

    default:
      return (
        (familyTree?.damLinkType as PedigreeLinkType) ||
        (familyTree?.damLinkType as PedigreeLinkType)
      );
  }
};

export default getLinkType;
