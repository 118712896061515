import { FC, Fragment, memo, useMemo } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { useTranslation } from 'react-i18next';

import capitalize from 'lodash/capitalize';

import Header from 'components/common/Header';
import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import FamilyTree from 'components/common/FamilyTree/FamilyTree';
import ButtonGoBack from 'components/common/buttons/ButtonGoBack';

import app from 'helpers/app';

type Props = {
  customTitle?: string;
};

interface Params {
  animalId: string;
}

const FamilyTreePage: FC<Props> = ({ customTitle }) => {
  const { pathname } = useLocation();

  const { animalId } = useParams<Params>();
  const { t } = useTranslation();

  const animal = capitalize(app.appName);

  const title = useMemo(() => {
    return t(`common.${pathname.replace('/', '')}`, { animal });
  }, [animal, pathname, t]);

  return (
    <Fragment>
      <HelmetWithTemplate title={customTitle || title} />

      <div className='page family-tree'>
        <Header text={customTitle || title}>
          <ButtonGoBack color='light' />
        </Header>

        <div className='page-body'>
          <ReactFlowProvider>
            <FamilyTree animalId={animalId} />
          </ReactFlowProvider>
        </div>
      </div>
    </Fragment>
  );
};

export default memo(FamilyTreePage);
