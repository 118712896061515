import { FC, Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';

import AuthForm from 'components/auth/AuthForm';

import { AuthPayload } from 'generated/graphql';

export type SignUpToken = { token: AuthPayload['accessToken'] };

const SignUpPage: FC = () => {
  const { t } = useTranslation();

  const { token } = useParams<SignUpToken>();

  return (
    <Fragment>
      <HelmetWithTemplate title={t('route.signUp')} noIndex />

      <div className='page auth-page'>
        <AuthForm mode='SIGN_UP' token={token} />
      </div>
    </Fragment>
  );
};

export default withErrorBoundary(SignUpPage);
