import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, Label } from 'reactstrap';

const rangeAccuracy = 100;

interface ImageUploaderZoomProps {
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
}

const ImageUploaderZoom: FC<ImageUploaderZoomProps> = ({ zoom, setZoom }) => {
  const { t } = useTranslation();

  const handleZoomChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setZoom(parseInt(e.currentTarget.value, 10) / rangeAccuracy);
  };

  return (
    <InputGroup className='image-uploader-zoom'>
      <Label className='image-uploader-zoom__label'>{t('common.scale')}</Label>
      <Input
        className='image-uploader-zoom__input'
        type='range'
        step={1}
        value={zoom * rangeAccuracy}
        min={1 * rangeAccuracy}
        max={3 * rangeAccuracy}
        onChange={handleZoomChange}
      />
    </InputGroup>
  );
};

export default ImageUploaderZoom;
