import { FC } from 'react';

import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import DatePickerField from 'components/common/form/DatePickerField';

const MatingEventDateInput: FC = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={12}>
        <DatePickerField required name='date' label={t('mating.event.table.date')} />
      </Col>
    </Row>
  );
};

export default MatingEventDateInput;
