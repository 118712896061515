import { useMemo } from 'react';

import useUser from 'hooks/user/useUser';

const useCheckSubscribed = (): boolean => {
  const { subscription } = useUser() || {};

  return useMemo(
    () => !!subscription?.currentPeriodEnd,

    [subscription?.currentPeriodEnd],
  );
};

export default useCheckSubscribed;
