/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMemberWebsitesQuery } from 'generated/graphql';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useMemberWebsites = () => {
  const { data: { memberWebsites } = {}, ...rest } = useMemberWebsitesQuery();

  return { memberWebsites, ...rest };
};

export default useMemberWebsites;
