import { FC, useContext } from 'react';

import { components, MenuProps, IndicatorProps } from 'react-select';

import { SelectContext } from 'providers/CurrencyProvider';

import Select, { Target } from 'components/common/select/Select';

import { ReactComponent as DropdownChevronIcon } from 'icons/dropdown-chevron.svg';

import { ReactComponent as CheckIcon } from 'icons/check.svg';

import { currencyList } from 'helpers/constants';

const SelectCurrency: FC = () => {
  const { selectedCurrency, setSelectedCurrency } = useContext(SelectContext);

  const handleChange = ({ target: { value } }: Target): void => {
    setSelectedCurrency(value || 'USD');
  };

  const currencyOptions = currencyList.map(currencyName => ({
    label: currencyName,
    value: currencyName,
  }));

  const CaretDownIcon = (): JSX.Element => {
    return <DropdownChevronIcon />;
  };

  const DropdownIndicator = (props: IndicatorProps<any, any>): JSX.Element => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const DropdownMenu = ({ children, ...props }: MenuProps<any, any>): JSX.Element => {
    return (
      <components.Menu {...props} className='language-select-menu'>
        {children}
      </components.Menu>
    );
  };

  const CustomOption = ({ children, isSelected, ...props }: any): JSX.Element => (
    <components.Option {...props}>
      <div className='selected-option'>
        {children}
        {isSelected && <CheckIcon style={{ marginRight: '8px' }} />}
      </div>
    </components.Option>
  );

  return (
    <div className='select-currency'>
      <Select
        name='currency'
        options={currencyOptions}
        initialValue='USD'
        value={selectedCurrency}
        onChange={handleChange}
        menuPlacement='top'
        className='select'
        isSearchable={false}
        components={{ DropdownIndicator, Menu: DropdownMenu, Option: CustomOption }}
        styles={{
          indicatorSeparator: () => ({ display: 'none' }),
        }}
      />
    </div>
  );
};

export default SelectCurrency;
