import { MatingEventSpitOff } from '../components/forms/spitOff/MatingEventSpitOffInput';

const MATING_EVENT_SPIT_OFF_MAP = {
  [MatingEventSpitOff.Yes]: true,
  [MatingEventSpitOff.No]: false,
  [MatingEventSpitOff.Unknown]: null,
};

const formatEventSpitOff = (spitOff: MatingEventSpitOff): boolean | null => {
  return MATING_EVENT_SPIT_OFF_MAP[spitOff];
};

const formatEventSpitOffReverse = (spitOff: boolean | null | undefined): MatingEventSpitOff => {
  if (typeof spitOff === 'boolean') {
    return spitOff ? MatingEventSpitOff.Yes : MatingEventSpitOff.No;
  }

  return MatingEventSpitOff.Unknown;
};

export { formatEventSpitOff, formatEventSpitOffReverse };
