import createHTMLImg from 'helpers/image/createHTMLImg';
import { Dispatch, SetStateAction } from 'react';

type GetCroppedImg = (props: {
  imageUrl: string;
  crop: any;
  withoutMaxSide?: boolean;
  setImgSize?: Dispatch<SetStateAction<{ width: number; height: number }>>;
  type?: string;
}) => Promise<Blob | null>;

const getCroppedImg: GetCroppedImg = async ({
  imageUrl,
  crop,
  withoutMaxSide,
  setImgSize,
  type = 'image/png',
}) => {
  const image = await createHTMLImg(imageUrl);

  if (image instanceof ErrorEvent) return null;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const MAX_SIDE = 700;

  const ratio = withoutMaxSide
    ? Math.min(crop.width, crop.height, 1)
    : Math.min(MAX_SIDE / crop.width, MAX_SIDE / crop.height, 1);

  canvas.width = crop.width * ratio;
  canvas.height = crop.height * ratio;

  setImgSize?.({ width: canvas.width, height: canvas.height });

  ctx?.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        return reject(new Error('Failed to crop image'));
      }
      resolve(blob);
    }, type);
  });
};

export default getCroppedImg;
