import React from 'react';

import useMarkAchievementsAsSeen from 'hooks/user/achievements/useMarkAchievementsAsSeen';

import { ModalContextValue } from 'providers/ModalProvider';

const useModal = (): ModalContextValue => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalBody, setModalBody] = React.useState<JSX.Element | undefined>(undefined);
  const [modalClassName, setModalClassName] = React.useState<string | undefined>('');
  const [modalBodyClassName, setModalBodyClassName] = React.useState<string | undefined>('');
  const [customHeaderClassName, setCustomHeaderClassName] = React.useState<string | undefined>('');

  const { markAchievementsAsSeen } = useMarkAchievementsAsSeen();

  const toggleModal = (
    body?: JSX.Element,
    className?: string,
    bodyClassName?: string,
    modalHeaderClassName?: string,
  ): void => {
    setShowModal(!showModal);
    setModalBody(body);
    setModalClassName(className);
    setModalBodyClassName(bodyClassName);
    setCustomHeaderClassName(modalHeaderClassName);

    if (!showModal) {
      void markAchievementsAsSeen();
    }
  };

  return {
    showModal,
    toggleModal,
    modalBody,
    modalClassName,
    modalBodyClassName,
    modalHeaderClassName: customHeaderClassName,
  };
};

export default useModal;
