import React, { FC } from 'react';

import Button from 'components/common/buttons/Button';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface UpgradeSectionProps {
  title?: string;
  description: string;
}

const UpgradeSection: FC<UpgradeSectionProps> = ({ title, description }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleUpgrade = (): void => history.push('/account');

  return (
    <div className='upgrade-section'>
      <h4 className='title'>{title || t('subscription.upgradeToSilver.title')}</h4>
      <span className='description'>{description}</span>

      <Button color='primary' className='plan-btn' onClick={handleUpgrade}>
        {t('common.upgrade')}
      </Button>
    </div>
  );
};

export default UpgradeSection;
