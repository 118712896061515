/* eslint-disable import/no-unresolved */
import React, { FC, MouseEvent, useCallback, useState } from 'react';

import { Area } from 'react-easy-crop/types';

import blobToBase64 from 'helpers/image/blobToBase64';

import ImageUploaderInput from './input/ImageUploaderInput';
import ImageUploaderModal from './modal/ImageUploaderModal';

interface ImageUploaderProps {
  disabled?: boolean;
  value: string;
  onChange: (image: string | null) => void;
}

const ImageUploader: FC<ImageUploaderProps> = ({ disabled, value, onChange }) => {
  const [zoom, setZoom] = useState(1);

  const [file, setFile] = useState<File | null>(null);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const handleUploadImage = useCallback(async (): Promise<void> => {
    setFile(null);
    setImageBlob(null);
    setCroppedAreaPixels({
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    });
    setZoom(1);

    if (imageBlob) {
      const base64Image = (await blobToBase64(imageBlob)) as string;

      onChange(base64Image);
    }
  }, [imageBlob, onChange]);

  const handleDeleteImage = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();

      onChange(null);
    },
    [onChange],
  );

  return (
    <React.Fragment>
      <ImageUploaderInput
        disabled={disabled}
        value={value}
        handleDeleteImage={handleDeleteImage}
        setFile={setFile}
      />

      <ImageUploaderModal
        file={file}
        setFile={setFile}
        zoom={zoom}
        setZoom={setZoom}
        setImageBlob={setImageBlob}
        croppedAreaPixels={croppedAreaPixels}
        setCroppedAreaPixels={setCroppedAreaPixels}
        handleUploadImage={handleUploadImage}
      />
    </React.Fragment>
  );
};

export default ImageUploader;
