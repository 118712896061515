import groupBy from 'lodash/groupBy';

import { GroupedOption } from 'components/common/select/CreatableGroupSelect';

import { RegistryType } from 'hooks/animals/pedigree/useAnimalsRegistry';

type GroupedOptions = {
  (options?: RegistryType[]): GroupedOption[];
};

const getGroupedOptions: GroupedOptions = options => {
  const groupedListOfSearch = groupBy(options, 'herdName');

  return Object.keys(groupedListOfSearch).map(key => ({
    label: key,
    options: groupedListOfSearch[key].map(v => ({
      label: v.animalName,
      value: v.animalId,
    })),
  }));
};

export default getGroupedOptions;
