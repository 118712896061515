import { useDeleteImageMutation } from 'generated/graphql';
import { ControlFormValues } from 'utils/control/parseControlValues';

const useDeleteMemberWebsiteImages = () => {
  const [deleteImage, { loading }] = useDeleteImageMutation();

  const deleteMemberWebsiteImages = (data: ControlFormValues): void => {
    const images = [
      { value: data.aboutPageHeroImage, url: data.aboutPageHeroImageUrl },
      { value: data.heroImage, url: data.heroImageUrl },
      { value: data.salePageHeroImage, url: data.salePageHeroImageUrl },
      { value: data.bookingPageHeroImage, url: data.bookingPageHeroImageUrl },
      { value: data.contactPageHeroImage, url: data.contactPageHeroImageUrl },
    ]
      // If image is new it'll have base64 code
      .map(imageObj => {
        const isImageDeleted = !imageObj.url && imageObj.value;
        const isImageUpdated = imageObj.url?.includes('data:image') && imageObj.value;

        if (isImageDeleted || isImageUpdated) {
          return imageObj.value;
        }

        return undefined;
      })
      .filter(Boolean) as string[];

    images.forEach(imageObj => {
      void deleteImage({ variables: { where: { id: imageObj } } });
    });
  };

  return { deleteMemberWebsiteImages, loading };
};

export default useDeleteMemberWebsiteImages;
