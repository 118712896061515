import { useCallback } from 'react';

import { useDeleteUserMutation } from 'generated/graphql';

import useUser from './useUser';

const useDeleteUser = () => {
  const [deleteUserMutation, { loading }] = useDeleteUserMutation();

  const user = useUser();

  const deleteUser = useCallback(() => {
    if (user) {
      return deleteUserMutation({
        optimisticResponse: {
          deleteUser: {
            id: user.id,
          },
        },
      });
    }
  }, [deleteUserMutation, user]);

  return { deleteUser, loading };
};

export default useDeleteUser;
