import { FC, Fragment, useCallback, useMemo, useState, useEffect } from 'react';

import { TableState } from 'react-table';

import { intervalToDuration } from 'date-fns';

import Avatar from 'react-avatar';

import { useReactiveVar } from '@apollo/client';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import NoReminding from 'components/welcome/NoReminding';
import Table from 'components/common/Table';
import WelcomeShowCheckbox from 'components/welcome/WelcomeShowCheckbox';

import useMedicationReminders from 'hooks/animals/medication/useMedicationReminders';
import useFormat from 'hooks/useFormat';

import isNotActiveOnlyCollectionVar from 'helpers/apolloReactiveVars';

import { MedicationOrderField, OrderDirection } from 'generated/graphql';
import Header from './Header';

const UpcomingMedication: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { formatDate } = useFormat();
  const [orderBy, setOrderBy] = useState([
    {
      field: MedicationOrderField.Date,
      direction: OrderDirection.Asc,
    },
  ]);
  const isNotActiveOnly = useReactiveVar(isNotActiveOnlyCollectionVar);

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    setOrderBy([
      {
        field: MedicationOrderField.Date,
        direction: OrderDirection.Asc,
      },
    ]);
  }, []);

  const initialTableState = useMemo(
    () =>
      ({
        sortBy: orderBy.map(({ field, direction }) => ({
          id: field,
          desc: direction !== 'desc',
        })),
      } as Partial<TableState<any>>),
    [orderBy],
  );

  const handleToggleChange = useCallback((): void => {
    isNotActiveOnlyCollectionVar({ isActive: !isActive });
    setIsActive(!isActive);
  }, [isActive]);

  const { medicationReminders, loading } = useMedicationReminders({
    isActiveOnly: isNotActiveOnly.isActive || isActive,
    orderBy,
    where: {
      isUpcoming: true,
    },
  });

  const isEmpty = useMemo(
    () => !medicationReminders?.length && !loading,
    [loading, medicationReminders?.length],
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'medication.animal',
        Header: t('common.name'),
        Cell: ({ value: { images, name } = { images: [{ url: '' }], name: '' } }) => {
          return (
            <Fragment>
              <Avatar
                className='avatar'
                round='8px'
                size='52'
                src={images?.[images.length - 1]?.url}
                name={name}
              />
              <span className='animal-name ml-3'>{name}</span>
            </Fragment>
          );
        },
        disableSortBy: true,
      },
      {
        accessor: 'medication.reminders',
        Header: t('common.date'),
        Cell: ({ value = [{ date: '' }] }) => formatDate(value[0]?.date, 'd MMM, yyyy'),
      },
      {
        accessor: 'medication.type.name',
        Header: t('animals.medication.medication'),
        Cell: ({ value = '' }) => value,
        disableSortBy: true,
      },
      {
        accessor: 'dose',
        Header: t('animals.medication.dosage'),
        Cell: ({ value = '' }) => value,
        disableSortBy: true,
      },
      {
        accessor: 'medication',
        Header: t('animals.medication.daysLeft'),
        Cell: ({ value: { reminders = [{ date: new Date() }] } = {} }) => {
          if (reminders[0]?.date) {
            const daysleft = intervalToDuration({
              start: new Date(),
              end: new Date(reminders[0]?.date),
            }).days as number;

            return daysleft + 1;
          }
        },
        disableSortBy: true,
      },
    ],
    [formatDate, t],
  );

  const onSort = useCallback(sortState => {
    setOrderBy(
      sortState.map(({ desc }: any) => ({
        field: 'date',
        direction: desc ? 'asc' : 'desc',
      })),
    );
  }, []);

  if (isEmpty) {
    return <NoReminding handleToggleChange={handleToggleChange} checked={isActive} />;
  }

  const onRowClock = ({ medication }: { medication: any }): void =>
    history.push(`herds/${medication.animal.herd.id}/${medication.animal.id}/medication`);

  return (
    <div className='page-content h-100 d-flex flex-column justify-content-between'>
      <div className='page upcoming-medication'>
        <Header handleToggleChange={handleToggleChange} checked={isActive} />

        <div className='page-body pt-md-0'>
          <Table
            columns={columns}
            data={medicationReminders ?? []}
            loading={loading}
            initialState={initialTableState}
            onSort={onSort}
            rowOnClick={onRowClock}
          />
        </div>
      </div>
      <div className='bottom-content'>
        <WelcomeShowCheckbox
          text={t('profile.dontShowWelcome')}
          id='checkbox-welcome-page'
          isReverse
        />
      </div>
    </div>
  );
};

export default UpcomingMedication;
