import { Fragment } from 'react';

import { flag } from 'country-emoji';

import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

import { Handle, HandleType, Position } from 'reactflow';

import Avatar from 'react-avatar';

import useAnimalAge from 'hooks/animals/useAnimalAge';

import { AnimalSex, Image } from 'generated/graphql';

type Handler = {
  id: number;
  position: Position;
  type: HandleType;
};

export type Details = [
  { type: 'Image'; value: Array<Image> },
  { type: 'Name'; value: string },
  { type: 'Country'; value: string | null | undefined },
  { type: 'Age'; value: string },
  { type: 'Herd'; value: string },
  { type: 'Sex'; value?: AnimalSex },
  { type: 'Color'; value?: string[] | null },
];

type CustomNode = {
  data: {
    details: Details;
    handlers: Handler[];
  };
};

const CustomNodeCard = ({ data }: CustomNode): JSX.Element => {
  const { t } = useTranslation();

  const age = data?.details?.find(({ type }) => type === 'Age')?.value;
  const birthdayString = useAnimalAge((age as string) || '');
  const animalName = data?.details?.find(({ type }) => type === 'Name')?.value;

  const getTranslate = (type: string): string => {
    switch (type) {
      case 'Herd':
        return `${upperFirst(t(`herds.${camelCase(type)}`))}:`;
      case 'Color':
        return `${upperFirst(t('animals.coloration'))}:`;
      case 'Sex':
        return `${upperFirst(t(`animals.${camelCase(type)}`))}:`;
      default:
        return t('common.unknown');
    }
  };

  return (
    <div className='tree-card'>
      {data?.handlers?.map((handler: Handler) => {
        if (handler.id) {
          return <Handle key={handler.id} position={handler.position} type={handler.type} />;
        }

        return null;
      })}

      <div className='details-container'>
        {data?.details?.map(item => (
          <Fragment key={item.type}>
            {item.type === 'Age' ? (
              <Fragment>
                <span className={`details animal-${item.type.toLowerCase()}`}>
                  {birthdayString || t('common.unknown')}
                </span>
                <hr className='separator-line' />
              </Fragment>
            ) : item.type === 'Image' ? (
              <div className='image-container'>
                <Avatar
                  className='avatar'
                  round='50%'
                  size='80'
                  src={item.value?.[item.value.length - 1]?.url}
                  name={animalName as string}
                />
              </div>
            ) : (
              <div className={`details animal-${item.type.toLowerCase()}`}>
                {item.type !== 'Name' && item.type !== 'Country' ? (
                  <Fragment>
                    <span className='item-type'>{getTranslate(item.type)}</span>
                    <span className='space-dots' />
                  </Fragment>
                ) : item.type === 'Country' ? (
                  <span className='item-value'>{item.value ? flag(item.value) : '🌏'}</span>
                ) : (
                  <span className='item-value'>{item.value}</span>
                )}

                {item.type === 'Herd' && <span className='item-value'>{item.value}</span>}

                {item.type === 'Color' && (
                  <span className='item-value'>
                    {item?.value?.map((color, _, colors) => {
                      if (colors.length <= 1) {
                        return t(`animals.colors.${color}`);
                      }

                      return `${t(`animals.colors.${color}`)}, `;
                    }) || t('common.unknown')}
                  </span>
                )}

                {item.type === 'Sex' && (
                  <span className='item-value'>
                    {item.value
                      ? t(`animals.sexOptions.${camelCase(item.value)}`)
                      : t('common.unknown')}
                  </span>
                )}
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default CustomNodeCard;
