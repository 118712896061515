import * as yup from 'yup';

import i18n from 'startup/i18next';

const t = i18n.getFixedT(i18n.language);

const validationSchema = yup.object().shape({
  isOwner: yup.string().required(),
  herdName: yup
    .string()
    .min(2, t('herds.herdNameLength'))
    .when('isOwner', { is: 'false', then: yup.string().required(t('herds.herdNameRequired')) }),
});

export default validationSchema;
