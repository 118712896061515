import { EditorState } from 'draft-js';
import i18n from 'startup/i18next';

import * as Yup from 'yup';

import { formatEditorStateToJs } from './parseEditorValues';

const t = i18n.getFixedT(i18n.language, 'auth');

const validateEditorState = Yup.mixed().test({
  name: 'editorState',
  message: t('profile.controlPage.validation.editor'),
  test: (value: EditorState | null | undefined) => {
    if (!value) return false;

    const editorState = formatEditorStateToJs(value);

    return !!Object.values(editorState).find(row => row.text.length !== 0);
  },
});

export default validateEditorState;
