const editorConfig = {
  options: ['inline', 'list', 'textAlign', 'link', 'history', 'image'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    className: 'editor-option-wrapper editor-option-wrapper-inline',
  },
  list: {
    options: ['unordered', 'ordered'],
    className: 'editor-option-wrapper editor-option-wrapper-list',
  },
  textAlign: {
    options: ['left', 'center', 'right'],
    className: 'editor-option-wrapper editor-option-wrapper-text',
  },
  link: {
    options: ['link', 'unlink'],
    className: 'editor-option-wrapper editor-option-wrapper-link',
  },

  history: {
    options: ['undo', 'redo'],
    className: 'editor-option-wrapper editor-option-wrapper-history',
  },
};

export const iconsArray = [
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9543 11.6464C15.8207 10.9963 16.575 9.94998 16.575 8.27737V8.23454C16.575 7.17137 16.2182 6.24799 15.5144 5.49C15.511 5.48636 15.5076 5.48277 15.5041 5.47925C14.5516 4.51151 13.1263 4 11.3823 4H6.51185C6.22914 4 6 4.22914 6 4.51185V19.4881C6 19.7709 6.22914 20 6.51185 20H11.6492C13.3204 20 14.7372 19.5897 15.7464 18.8135C16.8123 17.9937 17.3757 16.814 17.3757 15.4018V15.359C17.3757 13.6811 16.5434 12.4137 14.9543 11.6464ZM11.6902 17.4574H8.62506V13.1965H11.4028C12.5252 13.1965 13.4127 13.3985 13.9694 13.7806C14.4674 14.1225 14.7096 14.6038 14.7096 15.252V15.2948C14.7096 17.2466 12.598 17.4574 11.6902 17.4574ZM11.1142 10.6752H8.62506V6.5427H11.2386C12.9356 6.5427 13.9089 7.24513 13.9089 8.46988V8.51265C13.9088 10.3943 12.1579 10.6752 11.1142 10.6752Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.3998 20.0002H8.59995C8.26878 20.0002 8 19.7314 8 19.4002C8 19.0691 8.26878 18.8003 8.59995 18.8003H11.3998C11.7309 18.8003 11.9997 19.0691 11.9997 19.4002C11.9997 19.7314 11.7309 20.0002 11.3998 20.0002Z" fill="#282531"/>
<path d="M15.3994 5.20137H12.5997C12.2685 5.20137 11.9998 4.93259 11.9998 4.60142C11.9998 4.27024 12.2685 4.00146 12.5997 4.00146H15.3994C15.7306 4.00146 15.9993 4.27024 15.9993 4.60142C15.9993 4.93259 15.7306 5.20137 15.3994 5.20137Z" fill="#282531"/>
<path d="M9.99976 20C9.94776 20 9.89503 19.9936 9.84303 19.9792C9.52299 19.8935 9.33419 19.5632 9.4206 19.2441L13.4203 4.44522C13.5068 4.12444 13.8363 3.93095 14.1562 4.02294C14.4763 4.10848 14.6651 4.43877 14.5786 4.75808L10.5789 19.5568C10.507 19.8239 10.2646 20 9.99976 20Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.69771 6.28672C7.89629 6.28672 8.05729 6.12573 8.05729 5.92714V4.71915H12.0409V16.9612H11.2152C11.0166 16.9612 10.8556 17.1222 10.8556 17.3208C10.8556 17.5193 11.0166 17.6803 11.2152 17.6803H13.5857C13.7843 17.6803 13.9452 17.5193 13.9452 17.3208C13.9452 17.1222 13.7843 16.9612 13.5857 16.9612H12.76V4.71915H16.7436V5.92714C16.7436 6.12573 16.9045 6.28672 17.1031 6.28672C17.3017 6.28672 17.4627 6.12573 17.4627 5.92714V4.35958C17.4627 4.16099 17.3017 4 17.1031 4H7.69771C7.49913 4 7.33813 4.16099 7.33813 4.35958V5.92714C7.33813 6.12573 7.49913 6.28672 7.69771 6.28672Z" fill="#282531"/>
<path d="M18.4414 19.2808H6.35958C6.16099 19.2808 6 19.4418 6 19.6403C6 19.8389 6.16099 19.9999 6.35958 19.9999H18.4414C18.64 19.9999 18.801 19.8389 18.801 19.6403C18.801 19.4417 18.64 19.2808 18.4414 19.2808Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.66662 8.33337C4.74731 8.33337 4 7.58605 4 6.66662C4 5.74731 4.74731 5 5.66662 5C6.58605 5 7.33337 5.74731 7.33337 6.66662C7.33337 7.58605 6.58605 8.33337 5.66662 8.33337ZM5.66662 6C5.2987 6 5 6.2987 5 6.66662C5 7.03466 5.2987 7.33337 5.66662 7.33337C6.03466 7.33337 6.33337 7.03466 6.33337 6.66662C6.33337 6.2987 6.03466 6 5.66662 6Z" fill="#282531"/>
<path d="M5.66662 13.6667C4.74731 13.6667 4 12.9194 4 12.0001C4 11.0808 4.74731 10.3335 5.66662 10.3335C6.58605 10.3335 7.33337 11.0808 7.33337 12.0001C7.33337 12.9194 6.58605 13.6667 5.66662 13.6667ZM5.66662 11.3335C5.2987 11.3335 5 11.6321 5 12.0001C5 12.3682 5.2987 12.6667 5.66662 12.6667C6.03466 12.6667 6.33337 12.3682 6.33337 12.0001C6.33337 11.6321 6.03466 11.3335 5.66662 11.3335Z" fill="#282531"/>
<path d="M5.66662 18.9999C4.74731 18.9999 4 18.2526 4 17.3332C4 16.4138 4.74731 15.6665 5.66662 15.6665C6.58605 15.6665 7.33337 16.4138 7.33337 17.3332C7.33337 18.2526 6.58605 18.9999 5.66662 18.9999ZM5.66662 16.6665C5.2987 16.6665 5 16.9652 5 17.3332C5 17.7012 5.2987 17.9999 5.66662 17.9999C6.03466 17.9999 6.33337 17.7012 6.33337 17.3332C6.33337 16.9652 6.03466 16.6665 5.66662 16.6665Z" fill="#282531"/>
<path d="M19.5 7.1665H9.16662C8.89062 7.1665 8.66663 6.9425 8.66663 6.6665C8.66663 6.3905 8.89062 6.1665 9.16662 6.1665H19.5C19.776 6.1665 20 6.3905 20 6.6665C20 6.9425 19.776 7.1665 19.5 7.1665Z" fill="#282531"/>
<path d="M19.5 12.5H9.16662C8.89062 12.5 8.66663 12.276 8.66663 12C8.66663 11.724 8.89062 11.5 9.16662 11.5H19.5C19.776 11.5 20 11.724 20 12C20 12.276 19.776 12.5 19.5 12.5Z" fill="#282531"/>
<path d="M19.5 17.8335H9.16662C8.89062 17.8335 8.66663 17.6095 8.66663 17.3335C8.66663 17.0575 8.89062 16.8335 9.16662 16.8335H19.5C19.776 16.8335 20 17.0575 20 17.3335C20 17.6095 19.776 17.8335 19.5 17.8335Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.26082 13.7393H4.52174C4.23374 13.7393 4 13.5056 4 13.2176V12.348C4 11.6767 4.54607 11.1306 5.21735 11.1306H5.56521C5.66126 11.1306 5.73908 11.0527 5.73908 10.9568V10.7828C5.73908 10.6869 5.66126 10.6089 5.56521 10.6089H4.52174C4.23374 10.6089 4 10.3752 4 10.0872C4 9.79917 4.23374 9.56543 4.52174 9.56543H5.56521C6.23649 9.56543 6.78256 10.1115 6.78256 10.7828V10.9568C6.78256 11.6281 6.23649 12.1741 5.56521 12.1741H5.21735C5.12143 12.1741 5.04348 12.2521 5.04348 12.348V12.6959H6.26082C6.54882 12.6959 6.78256 12.9296 6.78256 13.2176C6.78256 13.5056 6.54882 13.7393 6.26082 13.7393Z" fill="#282531"/>
<path d="M5.56521 17.7391H4.86961C4.58161 17.7391 4.34787 17.5053 4.34787 17.2173C4.34787 16.9293 4.58161 16.6956 4.86961 16.6956H5.56521C5.66126 16.6956 5.73908 16.6176 5.73908 16.5216V16.3477C5.73908 16.2517 5.66126 16.1738 5.56521 16.1738H4.52174C4.23374 16.1738 4 15.9401 4 15.6521C4 15.3641 4.23374 15.1304 4.52174 15.1304H5.56521C6.23649 15.1304 6.78256 15.6764 6.78256 16.3477V16.5216C6.78256 17.193 6.23649 17.7391 5.56521 17.7391Z" fill="#282531"/>
<path d="M5.56521 19.3045H4.52174C4.23374 19.3045 4 19.0707 4 18.7827C4 18.4947 4.23374 18.261 4.52174 18.261H5.56521C5.66126 18.261 5.73908 18.1831 5.73908 18.087V17.9131C5.73908 17.8171 5.66126 17.7393 5.56521 17.7393H4.86961C4.58161 17.7393 4.34787 17.5055 4.34787 17.2175C4.34787 16.9295 4.58161 16.6958 4.86961 16.6958H5.56521C6.23649 16.6958 6.78256 17.2419 6.78256 17.9131V18.087C6.78256 18.7584 6.23649 19.3045 5.56521 19.3045Z" fill="#282531"/>
<path d="M5.56521 8.1739C5.27721 8.1739 5.04348 7.94016 5.04348 7.65216V5.04347H4.52174C4.23374 5.04347 4 4.80974 4 4.52174C4 4.23374 4.23374 4 4.52174 4H5.56521C5.85321 4 6.08695 4.23374 6.08695 4.52174V7.65216C6.08695 7.94016 5.85321 8.1739 5.56521 8.1739Z" fill="#282531"/>
<path d="M19.4783 6.95656H8.69569C8.40769 6.95656 8.17395 6.72282 8.17395 6.43482C8.17395 6.14682 8.40769 5.91309 8.69569 5.91309H19.4783C19.7663 5.91309 20.0001 6.14682 20.0001 6.43482C20.0001 6.72282 19.7663 6.95656 19.4783 6.95656Z" fill="#282531"/>
<path d="M19.4783 12.522H8.69569C8.40769 12.522 8.17395 12.2883 8.17395 12.0003C8.17395 11.7123 8.40769 11.4785 8.69569 11.4785H19.4783C19.7663 11.4785 20.0001 11.7123 20.0001 12.0003C20.0001 12.2883 19.7663 12.522 19.4783 12.522Z" fill="#282531"/>
<path d="M19.4783 18.0869H8.69569C8.40769 18.0869 8.17395 17.8532 8.17395 17.5652C8.17395 17.2772 8.40769 17.0435 8.69569 17.0435H19.4783C19.7663 17.0435 20.0001 17.2772 20.0001 17.5652C20.0001 17.8532 19.7663 18.0869 19.4783 18.0869Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.6136 5H10.4666C10.2531 5 10.0802 5.17294 10.0802 5.38637C10.0802 5.5998 10.2531 5.77274 10.4666 5.77274H21.6136C21.827 5.77274 22 5.5998 22 5.38637C22 5.17299 21.827 5 21.6136 5Z" fill="#282531"/>
<path d="M10.4666 9.03836H17.7818C17.9952 9.03836 18.1682 8.86543 18.1682 8.65199C18.1682 8.43856 17.9952 8.26562 17.7818 8.26562H10.4666C10.2531 8.26562 10.0802 8.43856 10.0802 8.65199C10.0802 8.86537 10.2531 9.03836 10.4666 9.03836Z" fill="#282531"/>
<path d="M20.6556 11.5312H10.4666C10.2531 11.5312 10.0802 11.7042 10.0802 11.9176C10.0802 12.1311 10.2531 12.304 10.4666 12.304H20.6556C20.869 12.304 21.042 12.1311 21.042 11.9176C21.042 11.7042 20.869 11.5312 20.6556 11.5312Z" fill="#282531"/>
<path d="M19.6977 14.7974H10.4666C10.2531 14.7974 10.0802 14.9703 10.0802 15.1837C10.0802 15.3972 10.2531 15.5701 10.4666 15.5701H19.6977C19.9111 15.5701 20.084 15.3972 20.084 15.1837C20.084 14.9703 19.9111 14.7974 19.6977 14.7974Z" fill="#282531"/>
<path d="M14.908 18.063H10.4666C10.2531 18.063 10.0802 18.2359 10.0802 18.4494C10.0802 18.6627 10.2531 18.8357 10.4666 18.8357H14.908C15.1214 18.8357 15.2943 18.6627 15.2943 18.4494C15.2943 18.2359 15.1213 18.063 14.908 18.063Z" fill="#282531"/>
<path d="M7.62396 12.1547C7.62957 12.1475 7.63524 12.1403 7.64029 12.1326C7.64183 12.1303 7.64317 12.1278 7.64461 12.1255C7.64997 12.1172 7.65512 12.1088 7.65981 12.1001C7.66069 12.0985 7.6613 12.0969 7.66213 12.0953C7.66713 12.0856 7.67181 12.0759 7.67609 12.0659C7.6765 12.0649 7.67681 12.0639 7.67717 12.0628C7.68145 12.0522 7.68541 12.0414 7.68881 12.0303C7.68917 12.0292 7.68938 12.028 7.68969 12.0269C7.69293 12.016 7.69577 12.0051 7.69804 11.9938C7.69865 11.9907 7.69891 11.9875 7.69943 11.9844C7.70102 11.9751 7.70272 11.9658 7.7036 11.9563C7.70489 11.9436 7.70556 11.9307 7.70556 11.9178C7.70556 11.9048 7.70489 11.8919 7.7036 11.8792C7.70262 11.8695 7.70097 11.86 7.69932 11.8504C7.69881 11.8475 7.69855 11.8446 7.69804 11.8417C7.69577 11.8304 7.69288 11.8194 7.68969 11.8085C7.68938 11.8075 7.68917 11.8063 7.68881 11.8052C7.68536 11.7938 7.68129 11.7827 7.67686 11.7718C7.6766 11.7711 7.6764 11.7705 7.67609 11.7697C7.67171 11.7592 7.66677 11.749 7.66156 11.7389C7.66094 11.7379 7.66048 11.7367 7.65991 11.7356C7.65481 11.7261 7.6492 11.7169 7.64332 11.7079C7.64229 11.7063 7.64152 11.7046 7.64044 11.7031C7.63431 11.6939 7.62766 11.6851 7.62076 11.6765C7.61973 11.6753 7.61896 11.6739 7.61788 11.6727C7.60979 11.6628 7.60119 11.6533 7.59212 11.6443L5.9231 9.97545C5.77226 9.82461 5.52761 9.82456 5.37672 9.9755C5.22583 10.1264 5.22583 10.371 5.37677 10.5219L6.38639 11.5314H2.38637C2.17299 11.5314 2 11.7044 2 11.9178C2 12.1312 2.17299 12.3042 2.38637 12.3042H6.38639L5.37667 13.3138C5.22578 13.4648 5.22578 13.7094 5.37667 13.8603C5.45214 13.9357 5.55105 13.9734 5.64991 13.9734C5.74877 13.9734 5.84768 13.9357 5.92315 13.8603L7.59238 12.191C7.59438 12.189 7.59609 12.1868 7.59804 12.1848C7.60479 12.1777 7.61149 12.1706 7.61767 12.1631C7.61994 12.1604 7.62184 12.1575 7.62396 12.1547Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3376 4H5.44681C5.19999 4 5 4.19999 5 4.44681C5 4.69363 5.19999 4.89362 5.44681 4.89362H18.3376C18.5843 4.89362 18.7844 4.69363 18.7844 4.44681C18.7844 4.20005 18.5843 4 18.3376 4Z" fill="#282531"/>
<path d="M7.66702 8.66999H16.1266C16.3734 8.66999 16.5734 8.46999 16.5734 8.22318C16.5734 7.97636 16.3734 7.77637 16.1266 7.77637H7.66702C7.42021 7.77637 7.22021 7.97636 7.22021 8.22318C7.22021 8.46993 7.42021 8.66999 7.66702 8.66999Z" fill="#282531"/>
<path d="M18.2858 11.5532H5.44681C5.19999 11.5532 5 11.7532 5 12C5 12.2469 5.19999 12.4468 5.44681 12.4468H18.2858C18.5325 12.4468 18.7326 12.2469 18.7326 12C18.7326 11.7533 18.5325 11.5532 18.2858 11.5532Z" fill="#282531"/>
<path d="M17.5675 15.3296H6.89237C6.64555 15.3296 6.44556 15.5296 6.44556 15.7764C6.44556 16.0232 6.64555 16.2232 6.89237 16.2232H17.5675C17.8143 16.2232 18.0143 16.0232 18.0143 15.7764C18.0143 15.5296 17.8143 15.3296 17.5675 15.3296Z" fill="#282531"/>
<path d="M14.2487 19.1064H9.11258C8.86576 19.1064 8.66577 19.3064 8.66577 19.5533C8.66577 19.8 8.86576 20.0001 9.11258 20.0001H14.2487C14.4956 20.0001 14.6956 19.8 14.6956 19.5533C14.6956 19.3064 14.4955 19.1064 14.2487 19.1064Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.6136 5H10.4666C10.2532 5 10.0802 5.17294 10.0802 5.38637C10.0802 5.5998 10.2532 5.77274 10.4666 5.77274H21.6136C21.827 5.77274 22 5.5998 22 5.38637C22 5.17299 21.827 5 21.6136 5Z" fill="#282531"/>
<path d="M10.4666 9.03837H17.7818C17.9952 9.03837 18.1682 8.86543 18.1682 8.652C18.1682 8.43856 17.9952 8.26562 17.7818 8.26562H10.4666C10.2532 8.26562 10.0802 8.43856 10.0802 8.652C10.0802 8.86537 10.2532 9.03837 10.4666 9.03837Z" fill="#282531"/>
<path d="M20.6556 11.5312H10.4666C10.2532 11.5312 10.0802 11.7042 10.0802 11.9176C10.0802 12.1311 10.2532 12.304 10.4666 12.304H20.6556C20.869 12.304 21.042 12.1311 21.042 11.9176C21.042 11.7042 20.869 11.5312 20.6556 11.5312Z" fill="#282531"/>
<path d="M19.6977 14.7974H10.4666C10.2532 14.7974 10.0802 14.9703 10.0802 15.1837C10.0802 15.3972 10.2532 15.5701 10.4666 15.5701H19.6977C19.911 15.5701 20.084 15.3972 20.084 15.1837C20.084 14.9703 19.911 14.7974 19.6977 14.7974Z" fill="#282531"/>
<path d="M14.908 18.063H10.4666C10.2532 18.063 10.0802 18.2359 10.0802 18.4494C10.0802 18.6627 10.2532 18.8357 10.4666 18.8357H14.908C15.1213 18.8357 15.2943 18.6627 15.2943 18.4494C15.2943 18.2359 15.1213 18.063 14.908 18.063Z" fill="#282531"/>
<path d="M7.31918 11.5315H3.31917L4.32883 10.5219C4.47972 10.3709 4.47972 10.1263 4.32883 9.97543C4.17794 9.82459 3.93329 9.82459 3.78245 9.97543L2.11318 11.6447C2.11241 11.6455 2.11179 11.6463 2.11102 11.6471C2.10293 11.6553 2.0951 11.6638 2.08778 11.6727C2.08583 11.6752 2.08413 11.6778 2.08217 11.6802C2.0763 11.6877 2.07047 11.6952 2.06517 11.7032C2.06373 11.7053 2.06259 11.7076 2.0612 11.7097C2.05579 11.7182 2.05043 11.7268 2.04569 11.7356C2.04482 11.7372 2.0442 11.7389 2.04338 11.7405C2.03838 11.7501 2.03369 11.7598 2.02947 11.7698C2.02906 11.7709 2.02869 11.7721 2.02828 11.7731C2.02401 11.7836 2.02009 11.7944 2.01674 11.8053C2.01633 11.8066 2.01612 11.808 2.01571 11.8093C2.01257 11.82 2.00974 11.8308 2.00752 11.8419C2.0069 11.845 2.00665 11.8481 2.00608 11.8513C2.00448 11.8606 2.00283 11.8698 2.00185 11.8794C2.00067 11.8921 2 11.905 2 11.9179C2 11.9308 2.00067 11.9437 2.00196 11.9564C2.00294 11.966 2.00453 11.9752 2.00618 11.9846C2.00675 11.9877 2.00701 11.9908 2.00762 11.994C2.00984 12.0051 2.01267 12.0158 2.01582 12.0265C2.01623 12.0278 2.01643 12.0292 2.01685 12.0305C2.02014 12.0414 2.02411 12.0521 2.02833 12.0626C2.0288 12.0637 2.02911 12.0649 2.02957 12.066C2.03374 12.076 2.03843 12.0857 2.04343 12.0953C2.04425 12.0969 2.04492 12.0986 2.04575 12.1002C2.05054 12.1091 2.05584 12.1177 2.06125 12.1261C2.06264 12.1283 2.06378 12.1306 2.06522 12.1327C2.07053 12.1406 2.07635 12.1481 2.08222 12.1556C2.08418 12.1581 2.08583 12.1607 2.08783 12.1631C2.09515 12.1721 2.10298 12.1805 2.11107 12.1888C2.11184 12.1895 2.11246 12.1904 2.11323 12.1911L3.78245 13.8603C3.85787 13.9357 3.95678 13.9734 4.05564 13.9734C4.1545 13.9734 4.25341 13.9357 4.32883 13.8602C4.47972 13.7093 4.47972 13.4647 4.32883 13.3138L3.31922 12.3043H7.31923C7.53261 12.3043 7.7056 12.1313 7.7056 11.9179C7.70555 11.7045 7.53256 11.5315 7.31918 11.5315Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.2669 14.2989C16.1274 14.2989 15.9877 14.2458 15.8816 14.1389C15.6685 13.9258 15.6685 13.5804 15.8816 13.3673L17.8197 11.4292C18.5229 10.7324 18.9099 9.80146 18.9099 8.8051C18.9099 6.75711 17.2437 5.09091 15.1957 5.09091C14.1994 5.09091 13.2684 5.4771 12.5731 6.17889L10.6335 8.11862C10.4205 8.33169 10.075 8.33169 9.86183 8.11862C9.64876 7.90542 9.64876 7.55998 9.86183 7.34691L11.8001 5.40878C12.699 4.50031 13.9063 4 15.1949 4C17.8444 4 20 6.15559 20 8.8051C20 10.0946 19.4989 11.3019 18.5891 12.2029L16.6525 14.1389C16.5455 14.2458 16.4066 14.2989 16.2669 14.2989Z" fill="#282531"/>
<path d="M8.8051 19.9999C6.15559 19.9999 4 17.8443 4 15.1948C4 13.9053 4.50111 12.6981 5.41091 11.797L7.34771 9.86097C7.56078 9.6479 7.90622 9.6479 8.11929 9.86097C8.33235 10.074 8.33235 10.4195 8.11929 10.6325L6.18116 12.5708C5.4771 13.2667 5.09091 14.1977 5.09091 15.1948C5.09091 17.2427 6.75711 18.9089 8.8051 18.9089C9.80146 18.9089 10.7324 18.5228 11.4277 17.821L13.3673 15.8814C13.5804 15.6683 13.9258 15.6683 14.1389 15.8814C14.3521 16.0944 14.3521 16.4399 14.1389 16.6529L12.2008 18.5912C11.3011 19.4995 10.0939 19.9999 8.8051 19.9999Z" fill="#282531"/>
<path d="M9.09084 15.4546C8.95128 15.4546 8.81159 15.4015 8.70546 15.2947C8.49239 15.0815 8.49239 14.736 8.70546 14.523L14.5236 8.70472C14.7368 8.49165 15.0822 8.49165 15.2953 8.70472C15.5083 8.91793 15.5083 9.26336 15.2953 9.47643L9.47703 15.2947C9.3701 15.4015 9.23054 15.4546 9.09084 15.4546Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0098 15.5558C14.8703 15.5558 14.7306 15.5028 14.6244 15.3958C14.4113 15.1828 14.4113 14.8372 14.6244 14.6241L17.8192 11.4294C18.5224 10.7327 18.9094 9.80176 18.9094 8.80543C18.9094 6.75751 17.2432 5.09136 15.1953 5.09136C14.199 5.09136 13.268 5.47754 12.5728 6.17931L9.37287 9.37856C9.15981 9.59163 8.81439 9.59163 8.60132 9.37856C8.38826 9.16537 8.38826 8.81994 8.60132 8.60688L11.7983 5.40989C12.6986 4.50078 13.9059 4.00049 15.1945 4.00049C17.8439 4.00049 19.9995 6.15601 19.9995 8.80543C19.9995 10.0949 19.4984 11.3021 18.5886 12.2031L15.396 15.3958C15.2891 15.502 15.1495 15.5558 15.0098 15.5558Z" fill="#282531"/>
<path d="M8.80494 20C6.15552 20 4 17.8445 4 15.1951C4 13.9056 4.50109 12.6985 5.41087 11.7974L7.3476 9.86146C7.56066 9.64839 7.90609 9.64839 8.11915 9.86146C8.33221 10.0745 8.33221 10.4199 8.11915 10.633L6.18109 12.5712C5.47705 13.2671 5.09088 14.1981 5.09088 15.1951C5.09088 17.2431 6.75702 18.9091 8.80494 18.9091C9.80127 18.9091 10.7322 18.5229 11.4275 17.8212L13.367 15.8816C13.5801 15.6686 13.9255 15.6686 14.1385 15.8816C14.3517 16.0947 14.3517 16.4401 14.1385 16.6532L12.2005 18.5914C11.3008 19.4997 10.0937 20 8.80494 20Z" fill="#282531"/>
<path d="M11.9998 12.5459C11.8601 12.5459 11.7205 12.4928 11.6143 12.3858C11.4012 12.1728 11.4012 11.8274 11.6143 11.6143L14.5232 8.70521C14.7364 8.49214 15.0818 8.49214 15.2949 8.70521C15.508 8.9184 15.508 9.26383 15.2949 9.47689L12.3859 12.3858C12.279 12.4928 12.1393 12.5459 11.9998 12.5459Z" fill="#282531"/>
<path d="M9.0907 15.4549C8.95115 15.4549 8.81146 15.4018 8.70533 15.295C8.49227 15.0818 8.49227 14.7364 8.70533 14.5233L10.3271 12.9015C10.5402 12.6884 10.8856 12.6884 11.0987 12.9015C11.3117 13.1147 11.3117 13.4601 11.0987 13.6732L9.47688 15.295C9.36995 15.4018 9.23039 15.4549 9.0907 15.4549Z" fill="#282531"/>
<path d="M19.4541 20C19.3146 20 19.1749 19.947 19.0688 19.8401L4.16004 4.93148C3.94698 4.71828 3.94698 4.37286 4.16004 4.1598C4.37324 3.94673 4.71866 3.94673 4.93172 4.1598L19.8403 19.0684C20.0534 19.2816 20.0534 19.627 19.8403 19.8401C19.7334 19.947 19.5938 20 19.4541 20Z" fill="#282531"/>
</svg>
`,

  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.83337 10.6667H4.5C4.224 10.6667 4 10.4427 4 10.1667V5.8335C4 5.5575 4.224 5.3335 4.5 5.3335C4.776 5.3335 5 5.5575 5 5.8335V9.66675H8.83337C9.10938 9.66675 9.33337 9.89075 9.33337 10.1667C9.33337 10.4427 9.10938 10.6667 8.83337 10.6667Z" fill="#282531"/>
<path d="M12.0002 20C9.86423 20 7.85556 19.1674 6.34494 17.6553C6.14951 17.46 6.14951 17.1433 6.34494 16.948C6.54086 16.752 6.85751 16.7533 7.05222 16.948C8.37424 18.2714 10.1308 19 12.0002 19C15.8602 19 19.0002 15.86 19.0002 12C19.0002 8.14001 15.8602 5 12.0002 5C8.69223 5 5.74216 7.22339 4.98618 10.2866C4.91953 10.5553 4.65024 10.7186 4.38083 10.6527C4.11289 10.5861 3.94956 10.3153 4.01486 10.0474C4.88022 6.54272 8.23825 4 12.0002 4C16.4116 4 20.0002 7.58862 20.0002 12C20.0002 16.4114 16.4116 20 12.0002 20Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.5001 10.6664H15.1667C14.8907 10.6664 14.6667 10.4424 14.6667 10.1664C14.6667 9.89038 14.8907 9.66638 15.1667 9.66638H19.0001V5.83301C19.0001 5.55701 19.2241 5.33301 19.5001 5.33301C19.7761 5.33301 20.0001 5.55701 20.0001 5.83301V10.1664C20.0001 10.4424 19.7761 10.6664 19.5001 10.6664Z" fill="#282531"/>
<path d="M12 20C7.58862 20 4 16.4113 4 12C4 7.58862 7.58862 4 12 4C15.7619 4 19.12 6.5426 19.9859 10.0466C20.052 10.3146 19.8887 10.5859 19.62 10.652C19.352 10.7179 19.0807 10.5546 19.0146 10.286C18.258 7.22327 15.308 5 12 5C8.14001 5 5 8.13989 5 12C5 15.86 8.14001 19 12 19C13.8694 19 15.626 18.2712 16.9486 16.9486C17.144 16.7533 17.4607 16.7533 17.656 16.9486C17.8513 17.1439 17.8513 17.4606 17.656 17.656C16.1446 19.1672 14.136 20 12 20Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0892 20.5C15.9633 20.5 15.8343 20.4842 15.7068 20.4505L4.10963 17.3448C3.31464 17.1258 2.84065 16.3023 3.04914 15.5073L4.51238 10.0534C4.56638 9.85311 4.77188 9.73686 4.97137 9.78786C5.17162 9.84111 5.29012 10.0474 5.23687 10.2469L3.77439 15.6993C3.67014 16.0968 3.90864 16.5108 4.30688 16.621L15.8995 19.7252C16.2977 19.8302 16.7087 19.5932 16.8122 19.1973L17.398 17.0268C17.452 16.8265 17.6575 16.7073 17.8577 16.762C18.058 16.816 18.1757 17.0223 18.1225 17.2218L17.5375 19.3892C17.3612 20.0567 16.7537 20.5 16.0892 20.5Z" fill="#282531"/>
<path d="M19.4994 15.9999H7.4995C6.67225 15.9999 5.99951 15.3271 5.99951 14.4999V5.49998C5.99951 4.67274 6.67225 4 7.4995 4H19.4994C20.3266 4 20.9993 4.67274 20.9993 5.49998V14.4999C20.9993 15.3271 20.3266 15.9999 19.4994 15.9999ZM7.4995 4.74999C7.08625 4.74999 6.7495 5.08674 6.7495 5.49998V14.4999C6.7495 14.9131 7.08625 15.2499 7.4995 15.2499H19.4994C19.9126 15.2499 20.2494 14.9131 20.2494 14.4999V5.49998C20.2494 5.08674 19.9126 4.74999 19.4994 4.74999H7.4995Z" fill="#282531"/>
<path d="M9.74949 9.24996C8.92225 9.24996 8.24951 8.57722 8.24951 7.74998C8.24951 6.92274 8.92225 6.25 9.74949 6.25C10.5767 6.25 11.2495 6.92274 11.2495 7.74998C11.2495 8.57722 10.5767 9.24996 9.74949 9.24996ZM9.74949 6.99999C9.33625 6.99999 8.9995 7.33674 8.9995 7.74998C8.9995 8.16323 9.33625 8.49997 9.74949 8.49997C10.1627 8.49997 10.4995 8.16323 10.4995 7.74998C10.4995 7.33674 10.1627 6.99999 9.74949 6.99999Z" fill="#282531"/>
<path d="M6.42718 15.1975C6.33119 15.1975 6.23519 15.1608 6.16169 15.088C6.01544 14.9418 6.01544 14.704 6.16169 14.5578L9.70389 11.0156C10.1284 10.5911 10.8701 10.5911 11.2946 11.0156L12.3491 12.07L15.2681 8.56759C15.4803 8.31335 15.7923 8.1656 16.1246 8.1626H16.1328C16.4613 8.1626 16.7726 8.3051 16.9871 8.55484L20.9095 13.1313C21.0445 13.288 21.0265 13.525 20.869 13.66C20.7123 13.795 20.476 13.7778 20.3403 13.6195L16.4178 9.04309C16.3451 8.95909 16.2446 8.91259 16.1328 8.91259C16.0548 8.90584 15.9176 8.95984 15.8448 9.04759L12.6626 12.8658C12.5951 12.9468 12.4969 12.9955 12.3911 13C12.2846 13.0075 12.1834 12.9655 12.1091 12.8905L10.7644 11.5458C10.6226 11.4048 10.3759 11.4048 10.2341 11.5458L6.69193 15.088C6.61918 15.1608 6.52318 15.1975 6.42718 15.1975Z" fill="#282531"/>
</svg>
`,
];

export default editorConfig;
