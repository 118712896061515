import gql, { disableFragmentWarnings } from 'graphql-tag';

disableFragmentWarnings();

const ANIMAL_FRAGMENT = gql`
  fragment animalFragment on Animal {
    id
    #animalId
    name
    sex
    birthday
    deathDate
    coloration
    status
    country
    microchip

    sireLinkType
    damLinkType

    dam {
      animalId
      animalName
      herdName
    }

    draft {
      id
      status
      animalDetails
    }

    profile {
      ... on AlpacaProfile {
        otherId
        otherIssuer
        phenotype
      }
    }

    sire {
      animalId
      animalName
      herdName
    }

    images {
      id
      name
      thumbUrl
      url
      updatedAt
    }
  }
`;

export default ANIMAL_FRAGMENT;
