import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { toast } from 'react-hot-toast';

import {
  SignInWithApple,
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';

import Button from 'components/common/buttons/Button';

import useAuth from 'hooks/user/useAuth';

import app from 'helpers/app';

import { AuthMethod } from 'generated/graphql';

import { Capacitor } from '@capacitor/core';
import { ReactComponent as Logo } from 'icons/apple.svg';
import _ from 'lodash';

interface AppleButtonProps {
  onClick: (e?: any) => void;
  disabled?: boolean;
}

const cleanUpObject = (obj: any) => {
  if (_.isNull(obj)) return null;

  // Use _.transform() to recursively clean nested objects
  const cleanedObject = _.transform(
    obj,
    (result: any, value, key) => {
      if (_.isObject(value)) {
        // Recursively clean nested objects
        const cleanedValue = cleanUpObject(value);

        if (!_.isEmpty(cleanedValue)) {
          result[key] = cleanedValue;
        }
      } else if (!_.isNull(value)) {
        // Omit properties with null values
        result[key] = value;
      }
    },
    {},
  );

  return _.isEmpty(cleanedObject) ? null : cleanedObject;
};

const AppleButton: FC<{ isSignIn?: boolean }> = ({ isSignIn }) => {
  const { t } = useTranslation();

  const { signIn, loading } = useAuth();

  const onClick = () => {
    const clientId = Capacitor.getPlatform() === 'ios' ? app.appleClientIdApp : app.appleClientId;

    const options: SignInWithAppleOptions = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clientId,
      redirectURI: `${window.location.origin}/_oauth/apple`,
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    };

    return SignInWithApple.authorize(options)
      .then((result: SignInWithAppleResponse) => {
        const { email, givenName: firstName, familyName: lastName } = result.response;

        const user = cleanUpObject({ email, name: { firstName, lastName } });

        signIn({
          clientId,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          user,
          appleAccessCode: result.response.authorizationCode,
          method: AuthMethod.Apple,
        }).catch(() => null);
      })
      .catch(error => {
        if (error?.error === 'popup_closed_by_user') return null;

        return toast.error(error?.details || error?.error || t('common.appleError'));
      });
  };

  return (
    <Button
      type='button'
      className='auth-provider apple'
      onClick={onClick}
      // disabled={disabled}
      loading={loading}
    >
      <Logo className='auth-provider-logo' />
      <b className='auth-provider-text'>
        {isSignIn ? t('auth.signIn.withApple') : t('auth.signUp.withApple')}
      </b>
    </Button>
  );
};

export default AppleButton;
