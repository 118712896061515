import { FC, ReactNode, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';

import { useField, ErrorMessage } from 'formik';
import {
  FormGroup,
  Input as RSInput,
  InputProps as RSInputProps,
  FormFeedback,
  Label,
} from 'reactstrap';

import InputSkeleton from '../skeletons/form/InputSkeleton';

export interface InputProps extends RSInputProps {
  name: string;
  validateWithoutTouch?: boolean;
  label?: string;
  labelClassName?: string;
  loading?: boolean;
  inputClassName?: string;
  staticValue?: ReactNode;
  isRequired?: boolean;
}

const initialRef: any = null;

const Input: FC<InputProps> = ({
  label,
  labelClassName,
  placeholder: customPlaceholder,
  children,
  className,
  loading,
  inputClassName,
  staticValue,
  validateWithoutTouch,
  isRequired,
  onStaticClick,
  ...props
}) => {
  const [field, { error, touched }] = useField(props.name);
  const inputGroup = useRef(initialRef);
  const [onFocus, setOnFocus] = useState(false);

  const placeholder = useMemo(() => customPlaceholder ?? label, [customPlaceholder, label]);

  if (loading) return <InputSkeleton />;

  const customFocus = (): void => {
    if (inputGroup.current) {
      setOnFocus(true);
      inputGroup.current.style.border = '2px solid #FFD07A';
    }
  };

  const customHover = (): void => {
    if (inputGroup.current) {
      inputGroup.current.style.borderColor = '#FFD07A';
    }
  };

  const customMouseMove = (): void => {
    if (inputGroup.current && !onFocus) {
      inputGroup.current.style.borderColor = '#ced4da';
    }
  };

  const customBlur = (): void => {
    if (inputGroup.current) {
      inputGroup.current.style.border = '1px solid #ced4da';
    }
  };

  return (
    <FormGroup className={className ?? ''} onMouseLeave={customMouseMove}>
      {label && (
        <Label htmlFor={props?.id ?? props.name} className={classnames('label', labelClassName)}>
          {label}
          {isRequired && <span style={{ color: '#DA1414' }}>*</span>}
        </Label>
      )}
      <div className='input-group flex-nowrap'>
        {staticValue && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className={classnames('input-group-prepend form-control', {
              'is-invalid-input': !!(validateWithoutTouch ? error : error && touched),
            })}
            ref={inputGroup}
            onClick={onStaticClick}
          >
            {staticValue}
          </div>
        )}
        <RSInput
          invalid={!!(validateWithoutTouch ? error : error && touched)}
          {...field}
          {...props}
          placeholder={placeholder}
          className={inputClassName}
          onFocus={props.onFocus || customFocus}
          onBlur={props.onBlur || customBlur}
          onMouseEnter={props.onMouseEnter || customHover}
          onMouseLeave={props.onMouseLeave || customMouseMove}
        />
      </div>
      {children}
      {validateWithoutTouch ? (
        error && <FormFeedback>{error}</FormFeedback>
      ) : (
        <ErrorMessage name={props.name}>
          {message => <FormFeedback>{message}</FormFeedback>}
        </ErrorMessage>
      )}
    </FormGroup>
  );
};

export default Input;
