import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { useHistory } from 'react-router-dom';

import { MatingRecordFragment } from 'generated/graphql';

import { ROWS_PER_PAGE_OPTIONS } from 'helpers/constants';

import Table from 'components/common/Table';
import PaginationControls from 'components/common/PaginationControls';

interface MatingRecordsTableProps {
  matingRecords?: MatingRecordFragment[];

  totalCount?: number;
  loading: boolean;

  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const MatingRecordsTable: FC<MatingRecordsTableProps> = ({
  matingRecords,

  totalCount,
  loading,

  rowsPerPage,
  setRowsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const columns = useMemo(() => {
    return [
      {
        Header: t('mating.record.table.sire'),
        accessor: 'sire.name',
        disableSortBy: true,
      },
      {
        Header: t('mating.record.table.dam'),
        accessor: 'dam.name',
        disableSortBy: true,
      },
      {
        Header: t('mating.record.table.outcome'),
        accessor: 'outcome',
        Cell: ({ value }: CellProps<MatingRecordFragment>) =>
          t(`mating.record.outcome.${value.toLowerCase()}`),
        disableSortBy: true,
      },
      {
        Header: t('mating.record.table.cria'),
        accessor: 'cria.name',
        disableSortBy: true,
      },
    ];
  }, [t]);

  const onChangeCurrentPage = useCallback(
    pageNumber => {
      setCurrentPage(pageNumber - 1);
    },
    [setCurrentPage],
  );

  const onChangeRowsPerPage = useCallback(
    ({ target: { value } }) => {
      if (value) {
        setRowsPerPage(parseInt(value, 10));
      }
    },
    [setRowsPerPage],
  );

  return (
    <React.Fragment>
      <Table
        className='animals-table'
        columns={columns}
        data={matingRecords ?? []}
        rowOnClick={({ id }) => history.push(`/matings/${id}/information`)}
      />

      <PaginationControls
        className='animals-table-pagination'
        pagination={{
          total: totalCount,
          loading,
          current: currentPage + 1,
          pageSize: rowsPerPage,
          onChange: onChangeCurrentPage,
        }}
        rowsPerPage={{
          loading,
          options: ROWS_PER_PAGE_OPTIONS,
          value: `${rowsPerPage}`,
          onChange: onChangeRowsPerPage,
        }}
      />
    </React.Fragment>
  );
};

export default MatingRecordsTable;
