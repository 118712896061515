import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Label } from 'reactstrap';

interface ControlFieldProps {
  children: ReactNode;
  htmlFor?: string;
  className?: string;
  required?: boolean;
  label: {
    text: string;
    helper?: string;
  };
}

const ControlField: FC<ControlFieldProps> = ({ label, htmlFor, className, required, children }) => {
  return (
    <div className={classNames('control-field', className)}>
      <div className='control-field__label'>
        <Label htmlFor={htmlFor} className='control-field__label-text'>
          {label.text}
          <span className='control-field__label-required'>{required && ' *'}</span>
        </Label>

        <Label htmlFor={htmlFor} className='control-field__label-helper'>
          {label.helper}
        </Label>
      </div>

      <div className='control-field__input'>{children}</div>
    </div>
  );
};

export default ControlField;
