import { FC, Fragment, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Capacitor } from '@capacitor/core';

import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import EmptyMessage from 'components/common/EmptyMessage';
import PaginationControls from 'components/common/PaginationControls';
import Table from 'components/common/Table';
import Button from 'components/common/buttons/Button';
import UpgradeToDirectoryModalBody from 'components/common/modal/UpgradeToDirectoryModalBody';

import useBreeders from 'hooks/breeders/useBreeders';
import useModalFromProvider from 'hooks/modal/useModalFromProvider';
import useCurrentTariff from 'hooks/user/useCurrentTariff';

import { ROWS_PER_PAGE_OPTIONS } from 'helpers/constants';

const BreederDirectoryPage: FC = () => {
  const { t } = useTranslation();

  const { isSilver } = useCurrentTariff();

  const { toggleModal } = useModalFromProvider();

  const [currentPage, setCurrentPage] = useState(0);

  const [take, setTake] = useState(ROWS_PER_PAGE_OPTIONS[0].value);

  const { totalCount, breeders, loading } = useBreeders({
    take: parseInt(take, 10),
    skip: !take ? 0 : parseInt(take, 10) * currentPage,
  });

  const isNativePlatform = Capacitor.isNativePlatform();

  const columns = useMemo(
    () => [
      {
        accessor: '',
        Header: t('subscription.grade'),
        Cell: 'Silver',
        disableSortBy: true,
      },
      {
        accessor: 'animalsForSaleCount',
        Header: t('animals.forSaleStatusOptions.forSale'),
        Cell: ({ value = '' }) => value,
        disableSortBy: true,
      },
      {
        accessor: 'breeders',
        Header: t('common.name'),
        Cell: ({
          row: {
            original: { firstName = '', lastName = '' },
          },
        }) => `${firstName} ${lastName}`,
        disableSortBy: true,
      },
      {
        accessor: 'city',
        Header: t('profile.city'),
        Cell: ({ value = '' }) => value,
        disableSortBy: true,
      },
      {
        accessor: 'email',
        Header: t('common.email'),
        Cell: ({ value = '' }) => value,
        disableSortBy: true,
      },
      {
        accessor: 'phone',
        Header: t('common.phone'),
        Cell: ({ value = '' }) => value,
        disableSortBy: true,
      },
    ],
    [t],
  );

  const emptyData = useMemo(() => !loading && !totalCount, [loading, totalCount]);

  const onChangeCurrentPage = useCallback(pageNumber => setCurrentPage(pageNumber - 1), []);
  const onChangeRowsPerPage = useCallback(({ target: { value } }) => value && setTake(value), []);

  return (
    <Fragment>
      <HelmetWithTemplate title='Breeder Directory' />

      <div className='page international-herd'>
        <div className='header'>
          <h1 className='heading'>{t('profile.breederDirectory')}</h1>

          {!isSilver && !isNativePlatform && (
            <Button
              className='directory-alpaca-btn'
              color='primary'
              onClick={() =>
                toggleModal(
                  UpgradeToDirectoryModalBody,
                  'modal-upgrade',
                  'body-upgrade',
                  'header-upgrade',
                )
              }
            >
              <span className='directory-alpaca-btn'>{t('encourage.addToDirectory')}</span>
            </Button>
          )}
        </div>

        {emptyData ? (
          <EmptyMessage message={t('common.notFound')} />
        ) : (
          <div className='page-body'>
            <Table columns={columns} data={breeders ?? []} loading={loading} />

            <PaginationControls
              pagination={{
                total: totalCount,
                onChange: onChangeCurrentPage,
                current: currentPage + 1,
                loading: totalCount === undefined && loading,
                pageSize: parseInt(take, 10),
              }}
              rowsPerPage={{
                value: `${take}`,
                options: ROWS_PER_PAGE_OPTIONS,
                onChange: onChangeRowsPerPage,
                loading: totalCount === undefined && loading,
              }}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default BreederDirectoryPage;
