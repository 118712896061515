export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AnimalForSaleProfile: ['AlpacaForSaleProfile'],
    AnimalProfile: ['AlpacaProfile', 'LlamaProfile', 'SheepProfile'],
    HerdInvitationResult: ['HerdInvitation', 'HerdMember'],
    SignUpResponse: ['AuthPayload', 'SignUpStatus'],
  },
};

export default result;
