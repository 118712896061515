import React, { FC, Fragment, ReactElement } from 'react';

import ReactTooltip from 'react-tooltip';

type Props = {
  id: string;
  content: ReactElement;
  place?: 'top' | 'right' | 'bottom' | 'left';
};

const Tooltip: FC<Props> = ({ children, content, place, id }) => {
  return (
    <Fragment>
      <div data-tip data-for={id}>
        {children}
      </div>
      <ReactTooltip id={id} type='dark' effect='solid' className='tooltip' place={place}>
        {content}
      </ReactTooltip>
    </Fragment>
  );
};

export default Tooltip;
