import React, { FC, useCallback, useMemo, useState } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { AnimalSex, MatingOutcome, PedigreeLinkType } from 'generated/graphql';

import CreateAlpacaModal from 'components/animals/createAnimal/CreateAlpacaModal';
import CreatableAnimalGroupSelectField from 'components/common/form/CreatableAnimalGroupSelectField';

import useAnimalFromProvider from 'hooks/animals/animal/useAnimalFromProvider';

import { MatingRecordFormValues } from '../MatingRecordForm';

const MatingRecordPedigreeInputs: FC = () => {
  const { setFieldValue, values } = useFormikContext<MatingRecordFormValues>();

  const { t } = useTranslation();

  const { animal } = useAnimalFromProvider();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [sireName, setSireName] = useState<string | null>(null);
  const [damName, setDamName] = useState<string | null>(null);

  const [createdAnimal, setCreatedAnimal] = useState<any>(null);
  const [currentPedigreeType, setCurrentPedigreeType] = useState('');
  const [unclaimedAnimalOption, setUnclaimedAnimalOption] = useState({
    label: '',
    value: '',
  });

  const toggleModalVisibility = useCallback(() => {
    setIsModalVisible(prev => !prev);
  }, []);

  const handleCreateAnimal = useCallback((newCreatedAnimal: any, pedigreeType: string) => {
    setCreatedAnimal(newCreatedAnimal);
    setCurrentPedigreeType(pedigreeType);

    toggleModalVisibility();
  }, []);

  const alpacaModalMessage = useMemo(() => {
    if (currentPedigreeType !== 'Cria') {
      return;
    }

    if (sireName && damName) {
      return t('mating.pedigree.message.full', {
        animalName: createdAnimal?.animalName,
        sireName,
        damName,
      });
    }

    if (sireName || damName) {
      return t('mating.pedigree.message.piece', {
        animalName: createdAnimal?.animalName,
        parentName: sireName || damName,
      });
    }

    return t('mating.pedigree.message.default', {
      animalName: createdAnimal?.animalName,
    });
  }, [createdAnimal, currentPedigreeType, damName, sireName, t]);

  const { outcome } = values;

  return (
    <React.Fragment>
      <CreateAlpacaModal
        allowChangeHerd
        herdId=''
        show={isModalVisible}
        toggle={toggleModalVisibility}
        animal={createdAnimal}
        pedigreeType={currentPedigreeType}
        setHerdIdCallback={setUnclaimedAnimalOption}
        setSelectorValue={setFieldValue}
        title={`${t('common.create')} ${t('animals.alpaca')}`}
        message={alpacaModalMessage}
      />

      <Row>
        <Col xs={12} md={6}>
          <CreatableAnimalGroupSelectField
            isClearable
            required
            id='sireId'
            name='sireId'
            type='text'
            label={t('mating.record.table.sire')}
            placeholder={t('mating.record.table.sire')}
            unclaimedAnimal={null}
            unclaimedAnimalOption={unclaimedAnimalOption}
            filter={(animalReg: any) => animalReg.sex === AnimalSex.Male}
            onChange={(option: any) => {
              setFieldValue('sireId', option?.target?.value);
              setSireName(option?.target?.label);
            }}
            inputOptions={{
              value: animal?.sire?.animalId,
              label: animal?.sire?.animalName,
            }}
            onCreateOption={(inputValue: string) => {
              handleCreateAnimal({ name: inputValue, sex: AnimalSex.Male }, 'Sire');
            }}
          />
        </Col>

        <Col xs={12} md={6}>
          <CreatableAnimalGroupSelectField
            isClearable
            required
            id='damId'
            name='damId'
            type='text'
            label={t('mating.record.table.dam')}
            placeholder={t('mating.record.table.dam')}
            unclaimedAnimal={null}
            unclaimedAnimalOption={unclaimedAnimalOption}
            filter={(animalReg: any) =>
              animalReg.sex === AnimalSex.Female || animalReg.sex === AnimalSex.NonBreedingFemale
            }
            onChange={(option: any) => {
              setFieldValue('damId', option?.target?.value);
              setDamName(option?.target?.label);
            }}
            inputOptions={{
              value: animal?.dam?.animalId,
              label: animal?.dam?.animalName,
            }}
            onCreateOption={(inputValue: string) => {
              handleCreateAnimal({ name: inputValue, sex: AnimalSex.Female }, 'Dam');
            }}
          />
        </Col>
      </Row>

      {outcome === MatingOutcome.Success && (
        <Row>
          <Col xs={12}>
            <CreatableAnimalGroupSelectField
              isClearable
              required
              id='criaId'
              name='criaId'
              type='text'
              label={t('mating.record.table.cria')}
              placeholder={t('mating.record.table.cria')}
              unclaimedAnimal={null}
              unclaimedAnimalOption={unclaimedAnimalOption}
              inputOptions={{
                value: animal?.sire?.animalId,
                label: animal?.sire?.animalName,
              }}
              onCreateOption={(inputValue: string) => {
                handleCreateAnimal(
                  {
                    name: inputValue,
                    damId: values.damId,
                    sireId: values.sireId,
                    damLinkType: PedigreeLinkType.Challenged,
                    sireLinkType: PedigreeLinkType.Challenged,
                  },
                  'Cria',
                );
              }}
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default MatingRecordPedigreeInputs;
