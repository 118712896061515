import { gql } from '@apollo/client';

import MATING_EVENT_FRAGMENT from 'graphql/fragments/mating/event/matingEventFragment';

const MATING_EVENTS_QUERY = gql`
  query matingEvents($orderBy: [OrderByMatingEventInput!]!, $where: WhereMatingEventInput!) {
    matingEvents(orderBy: $orderBy, where: $where) {
      ...matingEvent
    }
  }

  ${MATING_EVENT_FRAGMENT}
`;

export default MATING_EVENTS_QUERY;
