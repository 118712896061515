import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

import { useUpdateSubscriptionMutation } from 'generated/graphql';

interface UseUpgradeSubscriptionResult {
  loading: boolean;
  updateSubscription: (planId: string) => Promise<void>;
}

const useUpdateSubscription = (): UseUpgradeSubscriptionResult => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [updateSubscriptionRaw] = useUpdateSubscriptionMutation();

  const updateSubscription = useCallback(
    async planId => {
      setLoading(true);

      try {
        await updateSubscriptionRaw({
          variables: {
            data: { planId },
          },
        });

        setLoading(false);
      } catch {
        toast.error(t('billingPlans.upgradeError'));
        setLoading(false);
      }
    },
    [t, updateSubscriptionRaw],
  );

  return { loading, updateSubscription };
};

export default useUpdateSubscription;
