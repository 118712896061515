import { gql } from '@apollo/client';

import ACHIEVEMENTS_FRAGMENT from 'graphql/fragments/user/achievements';

const ACHIEVEMENTS_QUERY = gql`
  query achievements {
    achievements {
      ...achievementsFragment
      
      ${ACHIEVEMENTS_FRAGMENT}
    }
  }
`;

export default ACHIEVEMENTS_QUERY;
