import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';

import {
  CreateAnimalTransferRequestMutation,
  useCreateAnimalTransferRequestMutation,
} from 'generated/graphql';

type CreateAnimalTransferRequest = (
  recipientEmail: string,
  animalId: string,
) => Promise<FetchResult<CreateAnimalTransferRequestMutation>>;

type UseCreateAnimalTransferRequest = () => {
  createAnimalTransferRequest: CreateAnimalTransferRequest;
  loading: boolean;
};

const useCreateAnimalTransferRequest: UseCreateAnimalTransferRequest = () => {
  const [createAnimalTransferRequestRaw, { loading }] = useCreateAnimalTransferRequestMutation();

  const createAnimalTransferRequest = useCallback<CreateAnimalTransferRequest>(
    (recipientEmail, animalId) => {
      return createAnimalTransferRequestRaw({
        variables: { data: { recipientEmail, animalId } },
        refetchQueries: ['animalTransferRequests'],
        awaitRefetchQueries: true,
      });
    },

    [createAnimalTransferRequestRaw],
  );

  return { createAnimalTransferRequest, loading };
};

export default useCreateAnimalTransferRequest;
