import { FC } from 'react';
import Helmet from 'react-helmet';

import app from 'helpers/app';

interface Props {
  title: string;
  showTemplate?: boolean;
  noIndex?: boolean;
}

const HelmetWithTemplate: FC<Props> = ({ title, showTemplate = true, noIndex = false }) => (
  <Helmet titleTemplate={showTemplate ? `${app.appTitle} | %s` : undefined} title={title}>
    {noIndex && <meta name='robots' content='noindex' />}
  </Helmet>
);

export default HelmetWithTemplate;
