import React, { FC } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import {
  CreateMatingRecordPage,
  EditMatingRecordPage,
  MatingRecordsPage,
} from 'modules/mating/record/pages';
import useCurrentTariff from 'hooks/user/useCurrentTariff';

const MatingRoutes: FC = () => {
  const { isSilver } = useCurrentTariff();

  return (
    <Switch>
      <Route exact path='/matings' component={MatingRecordsPage} />

      {isSilver && (
        <React.Fragment>
          <Route exact path='/matings/create-mating' component={CreateMatingRecordPage} />
          <Route exact path='/matings/:matingId/:tabName' component={EditMatingRecordPage} />
        </React.Fragment>
      )}

      <Route render={() => <Redirect to='/matings' />} />
    </Switch>
  );
};

export default MatingRoutes;
