import { gql } from '@apollo/client';

import ANIMAL_FRAGMENT from 'graphql/fragments/animals/animal';

const ANIMAL_PROFILE_FRAGMENT = gql`
  fragment animalProfileFragment on Animal {
    ...animalFragment

    saleDate
    statusNotes
    transferredTo
  }

  ${ANIMAL_FRAGMENT}
`;

export default ANIMAL_PROFILE_FRAGMENT;
