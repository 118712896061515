import { FC, memo } from 'react';

import Avatar, { Cache, ConfigProvider } from 'react-avatar';

import { Image } from 'generated/graphql';

const cache = new Cache({
  sourceTTL: 7 * 24 * 3600 * 1000,
  sourceSize: 20,
});

const AnimalNameCell: FC<{ name?: string; images?: Image[] }> = ({ name, images }) => (
  <div className='animal-name-cell'>
    <ConfigProvider cache={cache}>
      <Avatar
        className='avatar'
        round='8px'
        size='52'
        src={images?.[images.length - 1]?.thumbUrl}
        name={name}
      />
    </ConfigProvider>
    <span className='animal-name'>{name}</span>
  </div>
);

export default memo(AnimalNameCell);
