import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import * as Sentry from '@sentry/react';

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'helpers/constants';

import ME_QUERY from 'graphql/queries/users/me';

const useLogout = () => {
  const client = useApolloClient();

  const logout = useCallback((): void => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);

    client.writeQuery({
      query: ME_QUERY,
      data: { me: null },
    });

    Sentry.configureScope(scope => scope.setUser(null));
    void client.resetStore();
  }, [client]);

  return { logout };
};

export default useLogout;
