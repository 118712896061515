import { FC, useMemo } from 'react';

import { Col, Row } from 'reactstrap';

import SelectField from 'components/common/form/SelectField';
import { useTranslation } from 'react-i18next';

export enum MatingEventSpitOff {
  Yes = 'YES',
  No = 'NO',
  Unknown = 'UNKNOWN',
}

export type MatingEventSpitOffOption = {
  value: MatingEventSpitOff;
  label: string;
};

const MatingEventSpitOffInput: FC = () => {
  const { t } = useTranslation();

  const spitOffs: MatingEventSpitOffOption[] = useMemo(() => {
    return [
      {
        value: MatingEventSpitOff.Yes,
        label: t('mating.event.spitOff.yes'),
      },
      {
        value: MatingEventSpitOff.No,
        label: t('mating.event.spitOff.no'),
      },
      {
        value: MatingEventSpitOff.Unknown,
        label: t('mating.event.spitOff.unknown'),
      },
    ];
  }, [t]);

  return (
    <Row>
      <Col xs={12}>
        <SelectField
          name='spitOff'
          label={t('mating.event.table.spitOff')}
          options={spitOffs}
          required
          isSearchable
        />
      </Col>
    </Row>
  );
};

export default MatingEventSpitOffInput;
