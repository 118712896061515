import { FC, useState, useCallback } from 'react';

import { Option } from 'components/common/select/Select';
import { OptionTypeBase } from 'react-select/src/types';

import { FormGroup, Label } from 'reactstrap';
import ReactSelect, {
  Props as ReactSelectProps,
  OptionsType,
  components as RsComponents,
} from 'react-select';

import useUpdateWhenResize from 'hooks/useUpdateWhenResize';

const GroupHeading = (props: any): JSX.Element => {
  const { selectProps, data, children } = props;

  return (
    <RsComponents.GroupHeading {...props}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <input
          type='checkbox'
          checked={selectProps.value.find(({ value }: { value: string }) => value === data.id)}
          value={selectProps.value}
          onChange={() => {
            if (selectProps.value.find(({ value }: { value: string }) => value === data.id)) {
              selectProps.onChange([
                ...selectProps.value.filter(({ value }: { value: string }) => value !== data.id),
              ]);
            } else {
              selectProps.onChange([
                ...selectProps.value.filter(
                  (option: OptionTypeBase) =>
                    !data.options.find((opt: OptionTypeBase) => opt.value === option.value),
                ),
                { label: data.label, value: data.id, options: data.options },
              ]);
            }
          }}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className='ml-2 mb-0'>{children}</label>
      </div>
    </RsComponents.GroupHeading>
  );
};

const Options = (props: any): JSX.Element => {
  const { selectProps, data, label, isSelected } = props;

  return (
    <RsComponents.Option {...props} className='pb-0'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '15px',
          marginBottom: '5px',
        }}
      >
        <input
          type='checkbox'
          value={selectProps.value}
          onChange={() => {
            if (selectProps.value.find((v: OptionTypeBase) => v.value === data.value)) {
              selectProps.onChange([
                ...selectProps.value.filter((val: OptionTypeBase) => val.value !== data.value),
              ]);
            } else if (
              selectProps.value.find((option: OptionTypeBase) =>
                option.options?.find((opt: OptionTypeBase) => opt.value === data.value),
              )
            ) {
              const children = selectProps.value
                .filter((option: OptionTypeBase) =>
                  option?.options?.find((opt: OptionTypeBase) => opt.value === data.value),
                )
                ?.map((option: OptionTypeBase) => option.options)
                .flat(1)
                ?.filter((option: OptionTypeBase) => option.value !== data.value);

              selectProps.onChange([
                ...selectProps.value.filter(
                  (val: OptionTypeBase) =>
                    !val.options?.find((option: OptionTypeBase) => option.value === data.value),
                ),
                ...children,
              ]);
            } else {
              selectProps.onChange([...selectProps.value, data]);
            }
          }}
          checked={
            isSelected ||
            selectProps.value?.find((values: OptionTypeBase) =>
              values?.options?.find((val: OptionTypeBase) => val.value === data.value),
            )
          }
        />

        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className='ml-2 mb-0'>{label}</label>
      </div>
    </RsComponents.Option>
  );
};

const components = {
  DropdownIndicator: null,
  GroupHeading,
  Option: Options,
};

interface Target {
  target: { value: string[] | string; name: string };
}

interface Props extends Omit<ReactSelectProps<Option>, 'onChange' | 'value'> {
  label?: string;
  value: string[] | string;
  onChange?: (target: Target) => void;
  options: Option[];
}

const MultiSelectCheckbox: FC<Props> = ({
  id,
  label,
  value: initialValue,
  onChange,
  options,
  name,
  children,
  className,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleOnMenuOpen = useCallback(() => setMenuIsOpen(true), []);
  const toggleOnMenuClose = useCallback(() => setMenuIsOpen(false), []);
  const [value, setValue] = useState<OptionTypeBase>(
    Array.isArray(initialValue) ? (initialValue as OptionTypeBase) : [initialValue],
  );

  useUpdateWhenResize(menuIsOpen);

  const handleChange = useCallback(
    (newValue: OptionsType<Option>): void => {
      const newValueArray = newValue.length ? newValue.map((option: Option) => option.value) : '';

      setValue(newValue);

      if (onChange) onChange({ target: { value: newValueArray, name } });

      // is needed to turn off the focus effect after selecting
      // (document.activeElement as HTMLElement).blur();
    },
    [name, onChange],
  );

  const colourStyles = {
    option: (styles: { styles: any }) => {
      return {
        ...styles,
        backgroundColor: 'transparent !important',
      };
    },
  };

  return (
    <FormGroup>
      {label && <Label htmlFor={id}>{label}</Label>}
      <ReactSelect
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        className={`react-custom-select-container ${className}`}
        classNamePrefix='react-custom-select'
        menuPosition='absolute'
        menuPortalTarget={document.body}
        components={components}
        value={value}
        styles={colourStyles}
        options={options}
        onChange={handleChange}
        onMenuOpen={toggleOnMenuOpen}
        onMenuClose={toggleOnMenuClose}
        isMulti
        {...props}
      />
      {children}
    </FormGroup>
  );
};

export default MultiSelectCheckbox;
