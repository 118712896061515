import common from 'locales/en/common.json';
import auth from 'locales/en/auth.json';
import route from 'locales/en/route.json';
import herds from 'locales/en/herds.json';
import animals from 'locales/en/animals.json';
import profile from 'locales/en/profile.json';
import subscription from 'locales/en/subscription.json';
import notes from 'locales/en/notes.json';
import errors from 'locales/en/errors.json';
import members from 'locales/en/members.json';
import billingPlans from 'locales/en/billingPlans.json';
import library from 'locales/en/library.json';
import achievements from 'locales/en/achievements.json';
import encourage from 'locales/en/encourage.json';
import mating from 'locales/en/mating.json';

export default {
  common,
  auth,
  route,
  herds,
  animals,
  profile,
  subscription,
  notes,
  errors,
  members,
  billingPlans,
  library,
  achievements,
  encourage,
  mating,
};
