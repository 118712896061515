import { FC } from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MatingEventOrderField, OrderDirection, useMatingEventsQuery } from 'generated/graphql';

import { MatingEventsTable } from 'modules/mating/event/components';

import useDataFromProvider from 'hooks/useDataFromProvider';

import Loading from 'components/common/Loading';

import { MatingRecordForm } from '../forms';

const MatingRecordProfileTab: FC = () => {
  const { t } = useTranslation();

  const { matingId } = useParams<{ matingId: string }>();

  const { data: matingRecord } = useDataFromProvider();

  const { data, loading } = useMatingEventsQuery({
    variables: {
      orderBy: [{ field: MatingEventOrderField.Date, direction: OrderDirection.Asc }],
      where: { matingId },
    },
  });

  if (loading) {
    return <Loading page />;
  }

  return (
    <div className='tab-content-body'>
      <div className='alpaca-guild pedigree'>
        <h2 className='tab-content-heading' id='tab-content-heading'>
          {t('mating.record.tabs.information')}
        </h2>
      </div>

      <MatingRecordForm matingRecord={matingRecord} />

      <div className='alpaca-guild pedigree mt-5'>
        <h2 className='tab-content-heading' id='tab-content-heading'>
          {t('mating.event.title')}
        </h2>
      </div>

      <MatingEventsTable matingId={matingId} matingEvents={data?.matingEvents} />
    </div>
  );
};

export default MatingRecordProfileTab;
