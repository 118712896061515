import { useContext } from 'react';

import { AchievementsFragmentFragment, Maybe } from 'generated/graphql';

import { AchievementsContext } from 'providers/AchievementsProvider';

type AchievementsHook = Maybe<AchievementsFragmentFragment[]>;

const useAchievements = (): AchievementsHook => useContext(AchievementsContext);

export default useAchievements;
