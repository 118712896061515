import { Props as ReactSelectProps } from 'react-select';

import { FC, useMemo, useState } from 'react';
import { throttle } from 'lodash';
import i18n from 'startup/i18next';
import getGroupedOptions from 'helpers/getGrupedOptions';
import useUnclaimedAnimals from 'hooks/animals/animal/useUnclaimedAnimals';
import useAnimalsRegistry from 'hooks/animals/pedigree/useAnimalsRegistry';

import CreatableGroupSelectField from './CreatableGroupSelectField';
import { GroupedOption } from '../select/CreatableGroupSelect';

const t = i18n.getFixedT(i18n.language);

const formatCreateLabel = (inputValue: string): JSX.Element => {
  return (
    <div>
      {t('common.create')}
      <b>{` ${inputValue} `}</b>
    </div>
  );
};

const formatGroupLabel = (data: GroupedOption): JSX.Element => (
  <span className='react-custom-select group-label'>{data.label}</span>
);

interface CreatableAnimalGroupSelectFieldProps extends Omit<ReactSelectProps, 'value'> {
  name: string;

  unclaimedAnimal: string | null;
  unclaimedAnimalOption: any;

  inputOptions?: any;

  filter?: (animalReg: any) => boolean;

  onCreateOption: (inputValue: string) => void;
}

const CreatableAnimalGroupSelectField: FC<CreatableAnimalGroupSelectFieldProps> = ({
  name,

  unclaimedAnimal,
  unclaimedAnimalOption,

  inputOptions,

  filter,

  onCreateOption,

  ...props
}) => {
  /* Search for correct animal */
  const [registrySearch, setRegistrySearch] = useState(null);

  const handleInputChange = useMemo(() => {
    return throttle(newValue => setRegistrySearch(newValue || null), 500, {
      leading: false,
    });
  }, []);

  /* Get all the animals and group them for SelectField */
  const { unclaimedAnimals: unclaimedAnimalsData, loading: unclaimedAnimalLoading } =
    useUnclaimedAnimals(unclaimedAnimal);

  const { registrySearch: animalsFromRegistry, loading: registrySearchLoading } =
    useAnimalsRegistry(registrySearch);

  const groupedUnclaimedAnimalsOptions = useMemo(() => {
    const filteredUnclaimedAnimalsData = unclaimedAnimalsData?.filter(filter || Boolean);

    return getGroupedOptions(filteredUnclaimedAnimalsData);
  }, [unclaimedAnimalsData, filter]);

  const groupedOptions = useMemo(() => {
    const filteredAnimalsFromRegistry = animalsFromRegistry?.filter(filter || Boolean);

    return getGroupedOptions(filteredAnimalsFromRegistry);
  }, [animalsFromRegistry, filter]);

  const groupedAnimalsFromRegistry = useMemo(() => {
    if (unclaimedAnimalOption.value !== '') {
      return [
        ...groupedUnclaimedAnimalsOptions,
        ...groupedOptions,
        { label: 'New', options: [unclaimedAnimalOption] },
      ];
    }

    return [...groupedUnclaimedAnimalsOptions, ...groupedOptions];
  }, [groupedOptions, groupedUnclaimedAnimalsOptions, unclaimedAnimalOption]);

  return (
    <CreatableGroupSelectField
      isCreatable
      allowCreateWhileLoading
      createOptionPosition='first'
      name={name}
      inputOptions={inputOptions}
      onCreateOption={onCreateOption}
      formatCreateLabel={formatCreateLabel}
      formatGroupLabel={formatGroupLabel}
      defaultOptions={groupedOptions}
      options={groupedAnimalsFromRegistry}
      isLoading={registrySearchLoading || unclaimedAnimalLoading}
      onInputChange={handleInputChange}
      {...props}
    />
  );
};

export default CreatableAnimalGroupSelectField;
