import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Col, Row } from 'reactstrap';

import WelcomeShowCheckbox from 'components/welcome/WelcomeShowCheckbox';

const ShowWelcomeBlock: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='form show-form'>
      <Row>
        <Col xs={12}>
          <WelcomeShowCheckbox text={t('profile.showWelcomePage')} id='checkbox-welcome-profile' />
        </Col>
      </Row>
    </div>
  );
};

export default ShowWelcomeBlock;
