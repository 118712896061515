import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import useConfirm from 'hooks/useConfirm';

import Button from 'components/common/buttons/Button';
import useDeleteAccount from 'hooks/user/useDeleteAccount';

const DeleteAccountBtn: FC = () => {
  const { t } = useTranslation();

  const { deleteAccount } = useDeleteAccount();

  const confirmDeletion = useConfirm({
    title: t('profile.deleteAccount.title'),
    description: t('profile.deleteAccount.description'),
  });

  const handleDeleteAccountOnConfirm = async (): Promise<void> => {
    const isConfirmed = await confirmDeletion();

    if (isConfirmed) deleteAccount();
  };

  return (
    <Button onClick={handleDeleteAccountOnConfirm} className='delete-account-btn' color='danger'>
      {t('profile.deleteAccount.name')}
    </Button>
  );
};

export default DeleteAccountBtn;
