import { gql } from '@apollo/client';

const ACHIEVEMENTS_FRAGMENT = gql`
  fragment achievementsFragment on Achievement {
    description
    id
    imageUrl
    isEarned
    name
    isSeen
  }
`;

export default ACHIEVEMENTS_FRAGMENT;
