import { FC, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-hot-toast';

import { Form, FormikProvider, useFormik } from 'formik';

import Button from 'components/common/buttons/Button';
import Modal from 'components/common/Modal';

import useCloseAnimalTransferRequest from 'hooks/animals/transfer/useCloseAnimalTransferRequest';
import useUser from 'hooks/user/useUser';

import { TransferStatus } from 'generated/graphql';

interface Props {
  show: boolean;
  toggle: () => void;
  transferRejectData: {
    id: string;
    ownerName: string;
    name: string;
  };
}

const RejectTransferModal: FC<Props> = ({ show, toggle, transferRejectData }) => {
  const { t } = useTranslation();

  const { id, name, ownerName } = transferRejectData;

  const user = useUser();
  const { push } = useHistory();

  const { closeAnimalTransferRequest, loading } = useCloseAnimalTransferRequest();

  const onSubmit = useCallback((): void => {
    void toast.promise(closeAnimalTransferRequest(id, TransferStatus.Rejected), {
      loading: t('common.updating'),
      success: () => {
        toggle();

        if (!user?.transfers?.length) push('/herds');

        return t('common.successUpdate');
      },
      error: t('common.errorUpdate'),
    });
  }, [closeAnimalTransferRequest, id, push, t, toggle, user?.transfers?.length]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      void onSubmit();
    },
    validateOnMount: false,
    validateOnBlur: false,
  });

  return (
    <Modal
      show={show}
      onClose={toggle}
      size='lg'
      className='transfer-reject-modal'
      title={t('common.deleteConfirm')}
      showCloseButton
    >
      <FormikProvider value={formik}>
        <Form>
          {/* temporary */}
          <p style={{ whiteSpace: 'pre-line' }}>
            {` Do you really want to reject the transfer of ${name} from ${ownerName}.
            This action cannot be undone.
            `}
          </p>

          <Button
            loading={loading}
            type='submit'
            color='danger'
            disabled={loading}
            className='w-100 mt-5'
          >
            {t('animals.transferring.reject')}
          </Button>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default RejectTransferModal;
