import { gql } from '@apollo/client';

import ANIMAL_FRAGMENT from 'graphql/fragments/animals/animal';

const ANIMALS_SUBSCRIPTION = gql`
  subscription animalsSubscription {
    animals {
      mutationType
      animal {
        ...animalFragment
      }
    }
  }

  ${ANIMAL_FRAGMENT}
`;

export default ANIMALS_SUBSCRIPTION;
