import { gql } from '@apollo/client';

const BILLING_PLANS_QUERY = gql`
  query billingPlans($where: WhereBillingPlansInput!) {
    billingPlans(where: $where) {
      id
      name
      price
      currency
    }
  }
`;

export default BILLING_PLANS_QUERY;
