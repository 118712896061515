import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/Modal';

import app from 'helpers/app';

import { MatingEventFragment } from 'generated/graphql';

import MatingEventForm from '../forms/MatingEventForm';

interface Props {
  show: boolean;
  toggle: () => void;
  matingId: string;
  matingEvent?: MatingEventFragment;
}

const MatingEventModal: FC<Props> = ({ show, toggle, matingId, matingEvent }) => {
  const { t } = useTranslation();

  return (
    <Modal
      showCloseButton
      autoFocus={false}
      show={show}
      title={t(`mating.event.modal.${matingEvent ? 'editTitle' : 'createTitle'}`)}
      onClose={toggle}
    >
      <MatingEventForm matingId={matingId} matingEvent={matingEvent} toggle={toggle} />
    </Modal>
  );
};

export default MatingEventModal;
