import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Maybe } from 'generated/graphql';

export const convertEditorStateToString = (state: EditorState): string => {
  try {
    return JSON.stringify(convertToRaw(state.getCurrentContent()));
  } catch (err) {
    return '';
  }
};

export const formatEditorStateToJs = (state: EditorState): Record<string, any> => {
  return state.getCurrentContent().toJS().blockMap;
};

export const getCurrentEditorState = (state: Maybe<string>): EditorState => {
  if (state?.length) {
    try {
      return EditorState.createWithContent(convertFromRaw(JSON.parse(state)));
    } catch (err) {
      return EditorState.createEmpty();
    }
  }

  return EditorState.createEmpty();
};
