import classnames from 'classnames';

import { FC } from 'react';

import { Input, Label } from 'reactstrap';

import SwitchToggle from 'components/common/SwitchToggle';

import useWelcomeCheckFromProvider from 'hooks/user/useShowWelcomePageFromProvider';

interface WelcomeShowCheckboxProps {
  text: string;
  id: string;
  isReverse?: boolean;
}

const WelcomeShowCheckbox: FC<WelcomeShowCheckboxProps> = ({ text, id, isReverse }) => {
  const { isShow, toggleIsShow } = useWelcomeCheckFromProvider();

  const showWelcomePageHandler = (): void => {
    toggleIsShow(!isShow);
  };

  return (
    <div className={classnames('form-checkbox pt-4', { 'justify-content-center': isReverse })}>
      {!isReverse && (
        <Label className='form-checkbox-label' for={id}>
          {text}
        </Label>
      )}

      {isReverse ? (
        <Input
          id={id}
          value={1}
          type='checkbox'
          className='mr-2'
          onChange={showWelcomePageHandler}
          addon
        />
      ) : (
        <SwitchToggle
          id={id}
          onChange={showWelcomePageHandler}
          checked={isReverse ? !isShow : isShow}
        />
      )}

      {isReverse && (
        <Label className='form-checkbox-label' for={id}>
          {text}
        </Label>
      )}
    </div>
  );
};

export default WelcomeShowCheckbox;
