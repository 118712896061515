import { FC, useCallback, useMemo } from 'react';

import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider, Form } from 'formik';
import { isEqual } from 'lodash';

import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import Button from 'components/common/buttons/Button';
import SelectField from 'components/common/form/SelectField';
import EditorField from 'components/common/form/EditorField';

import { saleValidationSchema } from 'components/animals/form/validation';

import useEditorState from 'hooks/useEditorState';
import useAnimalOptions from 'hooks/animals/useAnimalOptions';
import useAnimalFromProvider from 'hooks/animals/animal/useAnimalFromProvider';
import useCreateAnimalForSaleDraft from 'hooks/animals/sale/useCreateAnimalForSaleDraft';

import { AnimalForSaleStatus } from 'generated/graphql';

const AnimalSaleForm: FC = () => {
  const { t } = useTranslation();
  const { animal } = useAnimalFromProvider();

  const { draft } = animal || {};

  const { animalId } = useParams<{ animalId: string }>();

  const { convertEditorStateToString, getCurrentEditorState, formatEditorStateToJs } =
    useEditorState();

  const { createAnimalForSaleDraft, loading } = useCreateAnimalForSaleDraft();

  const initialValues = useMemo(
    () => ({
      saleStatus: draft?.status || '',
      alpacaDetails: getCurrentEditorState(draft?.animalDetails),
    }),
    [draft?.animalDetails, draft?.status, getCurrentEditorState],
  );

  const onSubmit = useCallback(
    ({ saleStatus = initialValues.saleStatus, alpacaDetails = initialValues.alpacaDetails }) => {
      if (
        !isEqual(
          {
            saleStatus,
            alpacaDetails: formatEditorStateToJs(alpacaDetails),
          },
          { ...initialValues, alpacaDetails: formatEditorStateToJs(initialValues.alpacaDetails) },
        )
      ) {
        void toast.promise(
          createAnimalForSaleDraft({
            animalId,
            animalDetails: convertEditorStateToString(alpacaDetails),
            status: saleStatus as AnimalForSaleStatus,
          }),
          {
            loading: t('common.updating'),
            success: t('common.successUpdate', { item: 'Animal' }),
            error: t('common.errorUpdate'),
          },
        );
      } else {
        toast.success(t('common.successUpdate', { item: 'Animal' }));
      }
    },
    [
      animalId,
      convertEditorStateToString,
      createAnimalForSaleDraft,
      formatEditorStateToJs,
      initialValues,
      t,
    ],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: saleValidationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const { handleSubmit } = formik;

  const { forSaleStatusOptions } = useAnimalOptions(initialValues.saleStatus);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <div className='tab-content-body'>
          <Row>
            <Col xl={3} md={8} lg={5} xs={12}>
              <SelectField
                id='saleStatus'
                name='saleStatus'
                options={forSaleStatusOptions}
                label={t('animals.saleStatus')}
                openMenuOnFocus
                isSearchable={false}
              />
            </Col>
          </Row>

          <EditorField
            name='alpacaDetails'
            label={t('animals.alpacaDetails')}
            placeholder={t('animals.alpacaDetails')}
            initialValue={initialValues.alpacaDetails}
          />

          <div className='d-flex align-items-start flex-column flex-md-row'>
            <span className='tip-badge mb-2 mb-md-0'>{t('animals.saleTip')}</span>

            <Button loading={loading} type='submit' color='primary'>
              {t('common.save')}
            </Button>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default AnimalSaleForm;
