export const CONTROL_EDITOR_CONFIG = {
  options: ['inline', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    className: 'editor-option-wrapper editor-option-wrapper-inline',
  },
  list: {
    options: ['unordered', 'ordered'],
    className: 'editor-option-wrapper editor-option-wrapper-list',
  },
  link: {
    options: ['link', 'unlink'],
    className: 'editor-option-wrapper editor-option-wrapper-link',
  },
};

export const CONTROL_EDITOR_ICONS = [
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9543 11.6464C15.8207 10.9963 16.575 9.94998 16.575 8.27737V8.23454C16.575 7.17137 16.2182 6.24799 15.5144 5.49C15.511 5.48636 15.5076 5.48277 15.5041 5.47925C14.5516 4.51151 13.1263 4 11.3823 4H6.51185C6.22914 4 6 4.22914 6 4.51185V19.4881C6 19.7709 6.22914 20 6.51185 20H11.6492C13.3204 20 14.7372 19.5897 15.7464 18.8135C16.8123 17.9937 17.3757 16.814 17.3757 15.4018V15.359C17.3757 13.6811 16.5434 12.4137 14.9543 11.6464ZM11.6902 17.4574H8.62506V13.1965H11.4028C12.5252 13.1965 13.4127 13.3985 13.9694 13.7806C14.4674 14.1225 14.7096 14.6038 14.7096 15.252V15.2948C14.7096 17.2466 12.598 17.4574 11.6902 17.4574ZM11.1142 10.6752H8.62506V6.5427H11.2386C12.9356 6.5427 13.9089 7.24513 13.9089 8.46988V8.51265C13.9088 10.3943 12.1579 10.6752 11.1142 10.6752Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.3998 20.0002H8.59995C8.26878 20.0002 8 19.7314 8 19.4002C8 19.0691 8.26878 18.8003 8.59995 18.8003H11.3998C11.7309 18.8003 11.9997 19.0691 11.9997 19.4002C11.9997 19.7314 11.7309 20.0002 11.3998 20.0002Z" fill="#282531"/>
<path d="M15.3994 5.20137H12.5997C12.2685 5.20137 11.9998 4.93259 11.9998 4.60142C11.9998 4.27024 12.2685 4.00146 12.5997 4.00146H15.3994C15.7306 4.00146 15.9993 4.27024 15.9993 4.60142C15.9993 4.93259 15.7306 5.20137 15.3994 5.20137Z" fill="#282531"/>
<path d="M9.99976 20C9.94776 20 9.89503 19.9936 9.84303 19.9792C9.52299 19.8935 9.33419 19.5632 9.4206 19.2441L13.4203 4.44522C13.5068 4.12444 13.8363 3.93095 14.1562 4.02294C14.4763 4.10848 14.6651 4.43877 14.5786 4.75808L10.5789 19.5568C10.507 19.8239 10.2646 20 9.99976 20Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.69771 6.28672C7.89629 6.28672 8.05729 6.12573 8.05729 5.92714V4.71915H12.0409V16.9612H11.2152C11.0166 16.9612 10.8556 17.1222 10.8556 17.3208C10.8556 17.5193 11.0166 17.6803 11.2152 17.6803H13.5857C13.7843 17.6803 13.9452 17.5193 13.9452 17.3208C13.9452 17.1222 13.7843 16.9612 13.5857 16.9612H12.76V4.71915H16.7436V5.92714C16.7436 6.12573 16.9045 6.28672 17.1031 6.28672C17.3017 6.28672 17.4627 6.12573 17.4627 5.92714V4.35958C17.4627 4.16099 17.3017 4 17.1031 4H7.69771C7.49913 4 7.33813 4.16099 7.33813 4.35958V5.92714C7.33813 6.12573 7.49913 6.28672 7.69771 6.28672Z" fill="#282531"/>
<path d="M18.4414 19.2808H6.35958C6.16099 19.2808 6 19.4418 6 19.6403C6 19.8389 6.16099 19.9999 6.35958 19.9999H18.4414C18.64 19.9999 18.801 19.8389 18.801 19.6403C18.801 19.4417 18.64 19.2808 18.4414 19.2808Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.66662 8.33337C4.74731 8.33337 4 7.58605 4 6.66662C4 5.74731 4.74731 5 5.66662 5C6.58605 5 7.33337 5.74731 7.33337 6.66662C7.33337 7.58605 6.58605 8.33337 5.66662 8.33337ZM5.66662 6C5.2987 6 5 6.2987 5 6.66662C5 7.03466 5.2987 7.33337 5.66662 7.33337C6.03466 7.33337 6.33337 7.03466 6.33337 6.66662C6.33337 6.2987 6.03466 6 5.66662 6Z" fill="#282531"/>
<path d="M5.66662 13.6667C4.74731 13.6667 4 12.9194 4 12.0001C4 11.0808 4.74731 10.3335 5.66662 10.3335C6.58605 10.3335 7.33337 11.0808 7.33337 12.0001C7.33337 12.9194 6.58605 13.6667 5.66662 13.6667ZM5.66662 11.3335C5.2987 11.3335 5 11.6321 5 12.0001C5 12.3682 5.2987 12.6667 5.66662 12.6667C6.03466 12.6667 6.33337 12.3682 6.33337 12.0001C6.33337 11.6321 6.03466 11.3335 5.66662 11.3335Z" fill="#282531"/>
<path d="M5.66662 18.9999C4.74731 18.9999 4 18.2526 4 17.3332C4 16.4138 4.74731 15.6665 5.66662 15.6665C6.58605 15.6665 7.33337 16.4138 7.33337 17.3332C7.33337 18.2526 6.58605 18.9999 5.66662 18.9999ZM5.66662 16.6665C5.2987 16.6665 5 16.9652 5 17.3332C5 17.7012 5.2987 17.9999 5.66662 17.9999C6.03466 17.9999 6.33337 17.7012 6.33337 17.3332C6.33337 16.9652 6.03466 16.6665 5.66662 16.6665Z" fill="#282531"/>
<path d="M19.5 7.1665H9.16662C8.89062 7.1665 8.66663 6.9425 8.66663 6.6665C8.66663 6.3905 8.89062 6.1665 9.16662 6.1665H19.5C19.776 6.1665 20 6.3905 20 6.6665C20 6.9425 19.776 7.1665 19.5 7.1665Z" fill="#282531"/>
<path d="M19.5 12.5H9.16662C8.89062 12.5 8.66663 12.276 8.66663 12C8.66663 11.724 8.89062 11.5 9.16662 11.5H19.5C19.776 11.5 20 11.724 20 12C20 12.276 19.776 12.5 19.5 12.5Z" fill="#282531"/>
<path d="M19.5 17.8335H9.16662C8.89062 17.8335 8.66663 17.6095 8.66663 17.3335C8.66663 17.0575 8.89062 16.8335 9.16662 16.8335H19.5C19.776 16.8335 20 17.0575 20 17.3335C20 17.6095 19.776 17.8335 19.5 17.8335Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.26082 13.7393H4.52174C4.23374 13.7393 4 13.5056 4 13.2176V12.348C4 11.6767 4.54607 11.1306 5.21735 11.1306H5.56521C5.66126 11.1306 5.73908 11.0527 5.73908 10.9568V10.7828C5.73908 10.6869 5.66126 10.6089 5.56521 10.6089H4.52174C4.23374 10.6089 4 10.3752 4 10.0872C4 9.79917 4.23374 9.56543 4.52174 9.56543H5.56521C6.23649 9.56543 6.78256 10.1115 6.78256 10.7828V10.9568C6.78256 11.6281 6.23649 12.1741 5.56521 12.1741H5.21735C5.12143 12.1741 5.04348 12.2521 5.04348 12.348V12.6959H6.26082C6.54882 12.6959 6.78256 12.9296 6.78256 13.2176C6.78256 13.5056 6.54882 13.7393 6.26082 13.7393Z" fill="#282531"/>
<path d="M5.56521 17.7391H4.86961C4.58161 17.7391 4.34787 17.5053 4.34787 17.2173C4.34787 16.9293 4.58161 16.6956 4.86961 16.6956H5.56521C5.66126 16.6956 5.73908 16.6176 5.73908 16.5216V16.3477C5.73908 16.2517 5.66126 16.1738 5.56521 16.1738H4.52174C4.23374 16.1738 4 15.9401 4 15.6521C4 15.3641 4.23374 15.1304 4.52174 15.1304H5.56521C6.23649 15.1304 6.78256 15.6764 6.78256 16.3477V16.5216C6.78256 17.193 6.23649 17.7391 5.56521 17.7391Z" fill="#282531"/>
<path d="M5.56521 19.3045H4.52174C4.23374 19.3045 4 19.0707 4 18.7827C4 18.4947 4.23374 18.261 4.52174 18.261H5.56521C5.66126 18.261 5.73908 18.1831 5.73908 18.087V17.9131C5.73908 17.8171 5.66126 17.7393 5.56521 17.7393H4.86961C4.58161 17.7393 4.34787 17.5055 4.34787 17.2175C4.34787 16.9295 4.58161 16.6958 4.86961 16.6958H5.56521C6.23649 16.6958 6.78256 17.2419 6.78256 17.9131V18.087C6.78256 18.7584 6.23649 19.3045 5.56521 19.3045Z" fill="#282531"/>
<path d="M5.56521 8.1739C5.27721 8.1739 5.04348 7.94016 5.04348 7.65216V5.04347H4.52174C4.23374 5.04347 4 4.80974 4 4.52174C4 4.23374 4.23374 4 4.52174 4H5.56521C5.85321 4 6.08695 4.23374 6.08695 4.52174V7.65216C6.08695 7.94016 5.85321 8.1739 5.56521 8.1739Z" fill="#282531"/>
<path d="M19.4783 6.95656H8.69569C8.40769 6.95656 8.17395 6.72282 8.17395 6.43482C8.17395 6.14682 8.40769 5.91309 8.69569 5.91309H19.4783C19.7663 5.91309 20.0001 6.14682 20.0001 6.43482C20.0001 6.72282 19.7663 6.95656 19.4783 6.95656Z" fill="#282531"/>
<path d="M19.4783 12.522H8.69569C8.40769 12.522 8.17395 12.2883 8.17395 12.0003C8.17395 11.7123 8.40769 11.4785 8.69569 11.4785H19.4783C19.7663 11.4785 20.0001 11.7123 20.0001 12.0003C20.0001 12.2883 19.7663 12.522 19.4783 12.522Z" fill="#282531"/>
<path d="M19.4783 18.0869H8.69569C8.40769 18.0869 8.17395 17.8532 8.17395 17.5652C8.17395 17.2772 8.40769 17.0435 8.69569 17.0435H19.4783C19.7663 17.0435 20.0001 17.2772 20.0001 17.5652C20.0001 17.8532 19.7663 18.0869 19.4783 18.0869Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.2669 14.2989C16.1274 14.2989 15.9877 14.2458 15.8816 14.1389C15.6685 13.9258 15.6685 13.5804 15.8816 13.3673L17.8197 11.4292C18.5229 10.7324 18.9099 9.80146 18.9099 8.8051C18.9099 6.75711 17.2437 5.09091 15.1957 5.09091C14.1994 5.09091 13.2684 5.4771 12.5731 6.17889L10.6335 8.11862C10.4205 8.33169 10.075 8.33169 9.86183 8.11862C9.64876 7.90542 9.64876 7.55998 9.86183 7.34691L11.8001 5.40878C12.699 4.50031 13.9063 4 15.1949 4C17.8444 4 20 6.15559 20 8.8051C20 10.0946 19.4989 11.3019 18.5891 12.2029L16.6525 14.1389C16.5455 14.2458 16.4066 14.2989 16.2669 14.2989Z" fill="#282531"/>
<path d="M8.8051 19.9999C6.15559 19.9999 4 17.8443 4 15.1948C4 13.9053 4.50111 12.6981 5.41091 11.797L7.34771 9.86097C7.56078 9.6479 7.90622 9.6479 8.11929 9.86097C8.33235 10.074 8.33235 10.4195 8.11929 10.6325L6.18116 12.5708C5.4771 13.2667 5.09091 14.1977 5.09091 15.1948C5.09091 17.2427 6.75711 18.9089 8.8051 18.9089C9.80146 18.9089 10.7324 18.5228 11.4277 17.821L13.3673 15.8814C13.5804 15.6683 13.9258 15.6683 14.1389 15.8814C14.3521 16.0944 14.3521 16.4399 14.1389 16.6529L12.2008 18.5912C11.3011 19.4995 10.0939 19.9999 8.8051 19.9999Z" fill="#282531"/>
<path d="M9.09084 15.4546C8.95128 15.4546 8.81159 15.4015 8.70546 15.2947C8.49239 15.0815 8.49239 14.736 8.70546 14.523L14.5236 8.70472C14.7368 8.49165 15.0822 8.49165 15.2953 8.70472C15.5083 8.91793 15.5083 9.26336 15.2953 9.47643L9.47703 15.2947C9.3701 15.4015 9.23054 15.4546 9.09084 15.4546Z" fill="#282531"/>
</svg>
`,
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0098 15.5558C14.8703 15.5558 14.7306 15.5028 14.6244 15.3958C14.4113 15.1828 14.4113 14.8372 14.6244 14.6241L17.8192 11.4294C18.5224 10.7327 18.9094 9.80176 18.9094 8.80543C18.9094 6.75751 17.2432 5.09136 15.1953 5.09136C14.199 5.09136 13.268 5.47754 12.5728 6.17931L9.37287 9.37856C9.15981 9.59163 8.81439 9.59163 8.60132 9.37856C8.38826 9.16537 8.38826 8.81994 8.60132 8.60688L11.7983 5.40989C12.6986 4.50078 13.9059 4.00049 15.1945 4.00049C17.8439 4.00049 19.9995 6.15601 19.9995 8.80543C19.9995 10.0949 19.4984 11.3021 18.5886 12.2031L15.396 15.3958C15.2891 15.502 15.1495 15.5558 15.0098 15.5558Z" fill="#282531"/>
<path d="M8.80494 20C6.15552 20 4 17.8445 4 15.1951C4 13.9056 4.50109 12.6985 5.41087 11.7974L7.3476 9.86146C7.56066 9.64839 7.90609 9.64839 8.11915 9.86146C8.33221 10.0745 8.33221 10.4199 8.11915 10.633L6.18109 12.5712C5.47705 13.2671 5.09088 14.1981 5.09088 15.1951C5.09088 17.2431 6.75702 18.9091 8.80494 18.9091C9.80127 18.9091 10.7322 18.5229 11.4275 17.8212L13.367 15.8816C13.5801 15.6686 13.9255 15.6686 14.1385 15.8816C14.3517 16.0947 14.3517 16.4401 14.1385 16.6532L12.2005 18.5914C11.3008 19.4997 10.0937 20 8.80494 20Z" fill="#282531"/>
<path d="M11.9998 12.5459C11.8601 12.5459 11.7205 12.4928 11.6143 12.3858C11.4012 12.1728 11.4012 11.8274 11.6143 11.6143L14.5232 8.70521C14.7364 8.49214 15.0818 8.49214 15.2949 8.70521C15.508 8.9184 15.508 9.26383 15.2949 9.47689L12.3859 12.3858C12.279 12.4928 12.1393 12.5459 11.9998 12.5459Z" fill="#282531"/>
<path d="M9.0907 15.4549C8.95115 15.4549 8.81146 15.4018 8.70533 15.295C8.49227 15.0818 8.49227 14.7364 8.70533 14.5233L10.3271 12.9015C10.5402 12.6884 10.8856 12.6884 11.0987 12.9015C11.3117 13.1147 11.3117 13.4601 11.0987 13.6732L9.47688 15.295C9.36995 15.4018 9.23039 15.4549 9.0907 15.4549Z" fill="#282531"/>
<path d="M19.4541 20C19.3146 20 19.1749 19.947 19.0688 19.8401L4.16004 4.93148C3.94698 4.71828 3.94698 4.37286 4.16004 4.1598C4.37324 3.94673 4.71866 3.94673 4.93172 4.1598L19.8403 19.0684C20.0534 19.2816 20.0534 19.627 19.8403 19.8401C19.7334 19.947 19.5938 20 19.4541 20Z" fill="#282531"/>
</svg>
`,
];
