import { FC } from 'react';

import { Position, ReactFlowProvider } from 'reactflow';

import uniqueId from 'lodash/uniqueId';

import Flow from 'components/common/FamilyTree/Flow';
import Loading from 'components/common/Loading';
import CustomNodeCard from 'components/react-flow/CustomNodeCard';
import CustomEdge from 'components/react-flow/CustomEdge';

import useAnimalFamilyTree from 'hooks/animals/animal/useAnimalFamilyTree';

import getInitialNodes, { Data, InitialNode } from 'helpers/react-flow/getInitialNodes';
import getInitialEdges, { CustomNode } from 'helpers/react-flow/getInitialEdges';
import getCardDetails from 'helpers/react-flow/getCardDetails';
import getLayoutedElements from 'helpers/react-flow/getLayoutedElements';

type Props = {
  animalId: string;
};

const nodeWidth = 270;
const nodeHeight = 450;

const initialPosition = {
  x: 0,
  y: 0,
};

const getNode = (type: string, nodes: InitialNode[]): CustomNode[] => {
  const defaultCondition = type === 'parents';

  const position = (condition = defaultCondition): Position => {
    return condition ? Position.Top : Position.Bottom;
  };

  const typeNode = (condition = defaultCondition): string => {
    return condition ? 'target' : 'source';
  };

  const relationString = (index: number, _index: number): string => {
    return defaultCondition ? `p-p${index}-p${_index}` : `c-c${index}-c${_index}`;
  };

  const mainNodes =
    nodes?.map((node: InitialNode) => ({
      ...node,
      type: 'customNode',
      data: {
        handlers: [
          (node as any)[type]?.length && {
            id: uniqueId(),
            type: typeNode(defaultCondition),
            position: position(defaultCondition),
          },
          {
            id: uniqueId(),
            type: typeNode(!defaultCondition),
            position: position(!defaultCondition),
          },
        ],
        details: getCardDetails(node),
      },
      id: `${defaultCondition ? 'p' : 'c'}-${node.id}`,
    })) || [];

  mainNodes?.forEach(
    (_node, index) =>
      (_node as any)[type]?.length &&
      mainNodes?.push(
        ...(_node as any)[type].map((__node: InitialNode, _index: number) => ({
          ...__node,
          id: relationString(index, _index),
          child: defaultCondition && `p-${index}`,
          parent: !defaultCondition && `c-${index}`,
          type: 'customNode',
          position: initialPosition,
          data: {
            handlers: [
              {
                id: uniqueId(),
                type: typeNode(!defaultCondition),
                position: position(!defaultCondition),
              },
            ],
            details: getCardDetails(__node),
          },
        })),
      ),
  );

  return mainNodes as CustomNode[];
};

const FamilyTree: FC<Props> = ({ animalId }) => {
  const { familyTree, loading } = useAnimalFamilyTree(animalId);

  const nodeTypes = {
    customNode: CustomNodeCard,
  };

  const edgeTypes = {
    customEdge: CustomEdge,
  };

  const { childrenNodes, parentsNodes } = getInitialNodes(familyTree) || {};

  const mainNode = {
    id: '0',
    position: initialPosition,
    selected: true,
    data: {
      details: getCardDetails({ data: familyTree as Data }),
      handlers: [
        parentsNodes?.length && { id: uniqueId(), type: 'target', position: Position.Top },
        childrenNodes?.length && {
          id: uniqueId(),
          type: 'source',
          position: Position.Bottom,
        },
      ],
    },
    type: 'customNode',
  };

  const nodes = [
    mainNode,
    ...getNode('children', childrenNodes),
    ...getNode('parents', parentsNodes),
  ] as CustomNode[];

  const edges = getInitialEdges(nodes).slice(1);

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    nodeHeight,
    nodeWidth,
    'TB',
    nodes,
    edges,
  );

  if (loading) return <Loading page />;

  return (
    <ReactFlowProvider>
      <Flow
        nodes={layoutedNodes}
        edges={layoutedEdges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
      />
    </ReactFlowProvider>
  );
};

export default FamilyTree;
