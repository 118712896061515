import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Switch, Route, Redirect } from 'react-router-dom';

import InternationalHerdPage from 'pages/private/InternationalHerdPage';
import FamilyTreePage from 'pages/private/FamilyTreePage';

const InternationalRoutes: FC = () => {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route exact path='/international-herd' component={InternationalHerdPage} />
      <Route
        exact
        path='/international-herd/family-tree/:animalId'
        render={() => <FamilyTreePage customTitle={t('animals.familyTree')} />}
      />

      <Route render={() => <Redirect to='/international-herd' />} />
    </Switch>
  );
};

export default InternationalRoutes;
