import * as yup from 'yup';

import i18n from 'startup/i18next';

import {
  // intNumberFieldValidation,
  numberFieldValidation,
} from 'components/common/form/commonValidation';

const t = i18n.getFixedT(i18n.language);

const pedigreeValidation = {
  isNotEmpty(field: string, requiredField: string): any {
    return yup
      .string()
      .ensure()
      .when(field, {
        is: (inputValue: string) => !!inputValue?.length,
        then: yup.string().required(t('common.fieldIsRequired', { context: requiredField })),
      });
  },
  isUnique(field: string, requiredField: string, parentType: string): any {
    return this.isNotEmpty(field, requiredField).when(parentType, {
      is: (parentId: string) => parentId !== '',
      then: yup.string().notOneOf([yup.ref(parentType)], t('animals.uniqueParents')),
    });
  },
};

export const animalProfileValidationSchema = yup.object().shape(
  {
    name: yup
      .string()
      .min(2, t('animals.form.animalNameLength'))
      .required(t('animals.form.animalNameRequired')),
    birthday: yup
      .string()
      .min(4, t('animals.form.birthdayRequired'))
      .required(t('animals.form.birthdayRequired'))
      .nullable(true),
    country: yup.string().required(t('common.form.countryRequired')),
    damId: pedigreeValidation.isUnique('damLinkType', 'Dam', 'sireId'),
    sireId: pedigreeValidation.isUnique('sireLinkType', 'Sire', 'damId'),
    otherId: yup.string(),
    sireLinkType: pedigreeValidation.isNotEmpty('sireId', 'Sire link type'),
    damLinkType: pedigreeValidation.isNotEmpty('damId', 'Dam link type'),
  },
  [
    ['damId', 'damLinkType'],
    ['sireId', 'sireLinkType'],
    ['sireId', 'damId'],
  ],
);

export const weightsValidationSchema = yup.object().shape({
  weight: numberFieldValidation.required(t('animals.form.weightRequired')),
});

export const saleValidationSchema = yup.object().shape({
  saleStatus: yup
    .string()
    .required(t('common.fieldIsRequired', { context: t('animals.saleStatus') })),
  alpacaDetails: yup
    .object()
    .required(t('common.fieldIsRequired', { context: t('animals.alpacaDetails') })),
});
