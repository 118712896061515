import { FC } from 'react';

import getAppBrand from 'helpers/getAppLogo';

import { ReactComponent as LogoIcon } from 'icons/logo.svg';

const Logo: FC = () => {
  return (
    <a className='logo' href='/' rel='noopener noreferrer'>
      <LogoIcon width={24} height={24} />
      <h6 className='logo-title'>{getAppBrand()}</h6>
    </a>
  );
};

export default Logo;
