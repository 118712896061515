import { FC, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';

interface Props {
  onEdit?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onAccept?: () => void;
  onReject?: () => void;
  loading?: boolean;
}

const ActionsCell: FC<Props> = ({ onEdit, onDelete, onCancel, onAccept, onReject, loading }) => {
  const { t } = useTranslation();

  const onEditHandle = useCallback(
    e => {
      e.stopPropagation();

      if (onEdit) onEdit();
    },
    [onEdit],
  );

  const onDeleteHandle = useCallback(
    e => {
      e.stopPropagation();

      if (onDelete) onDelete();
    },
    [onDelete],
  );

  const onCancelHandle = useCallback(
    e => {
      e.stopPropagation();
      if (onCancel) onCancel();
    },
    [onCancel],
  );

  const onAcceptHandle = useCallback(
    e => {
      e.stopPropagation();
      if (onAccept) onAccept();
    },
    [onAccept],
  );

  const onRejectHandle = useCallback(
    e => {
      e.stopPropagation();
      if (onReject) onReject();
    },
    [onReject],
  );

  return (
    <div className='actions-cell'>
      {onEdit && (
        <button type='button' className='action edit btn' onClick={onEditHandle}>
          <EditIcon />
        </button>
      )}

      {onDelete && (
        <button type='button' className='action delete btn' onClick={onDeleteHandle}>
          <DeleteIcon />
        </button>
      )}

      {onReject && (
        <button
          type='button'
          className='action reject btn'
          onClick={onRejectHandle}
          disabled={loading}
        >
          {t('animals.transferring.reject')}
        </button>
      )}

      {onAccept && (
        <button
          type='button'
          className='action accept btn p-0 mr-4'
          onClick={onAcceptHandle}
          disabled={loading}
        >
          {t('animals.transferring.accept')}
        </button>
      )}

      {onCancel && (
        <button
          type='button'
          className='action cancel btn p-0'
          onClick={onCancelHandle}
          disabled={loading}
        >
          {t('common.cancel')}
        </button>
      )}
    </div>
  );
};

export default ActionsCell;
