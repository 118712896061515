import { FC, useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { ControlFormValues } from 'utils/control/parseControlValues';
import { link } from 'helpers/constants';

import { ReactComponent as NewTabIcon } from 'icons/common/new-tab.svg';
import { ReactComponent as CircleCrossIcon } from 'icons/common/circle-cross.svg';

interface ControlLiveTextProps {
  isCompleted: boolean;
  isMemberWebsiteLive?: boolean;
}

const ControlLiveText: FC<ControlLiveTextProps> = ({ isCompleted, isMemberWebsiteLive }) => {
  const { values } = useFormikContext<ControlFormValues>();
  const { t } = useTranslation();
  const { slug } = values;

  const Icon = useMemo(() => (isCompleted ? NewTabIcon : CircleCrossIcon), [isCompleted]);

  if (!isCompleted) {
    return (
      <div className='control-live__text'>
        <span>{t('profile.controlPage.websiteLink.empty.first')}</span>
        <Icon className='control-live__text-icon --red' />
        <span>{t('profile.controlPage.websiteLink.empty.second')}</span>
      </div>
    );
  }

  if (!isMemberWebsiteLive) {
    return (
      <div className='control-live__text'>
        <span>{t('profile.controlPage.websiteLink.completed')}</span>
        <a
          className='control-live__text-link'
          href={`${link}${slug}`}
          target='_blank'
          rel='noreferrer'
        >
          {t('profile.controlPage.websiteLink.link')}
          <Icon className='control-live__text-icon' />
        </a>
      </div>
    );
  }

  return (
    <div className='control-live__text'>
      <span>{t('profile.controlPage.websiteLink.live')}</span>
      <a
        className='control-live__text-link'
        href={`${link}${slug}`}
        target='_blank'
        rel='noreferrer'
      >
        {t('profile.controlPage.websiteLink.link')}
        <Icon className='control-live__text-icon' />
      </a>
    </div>
  );
};

export default ControlLiveText;
