import { FC } from 'react';
import { Field, useFormikContext, FieldProps } from 'formik';
import { ReactSwitchProps } from 'react-switch';
import SwitchToggle from '../SwitchToggle';

interface SwitchToggleFieldProps extends Omit<ReactSwitchProps, 'checked' | 'onChange'> {
  name: string;
}

const SwitchToggleField: FC<SwitchToggleFieldProps> = ({ name, ...props }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <SwitchToggle
          {...field}
          {...props}
          checked={field.value}
          onChange={isChecked => setFieldValue(name, isChecked)}
        />
      )}
    </Field>
  );
};

export default SwitchToggleField;
