import { FC, useCallback, ChangeEvent, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import DatePicker from 'react-datepicker';

import DatePickerHeader from 'components/common/DatePicker/DatePickerHeader';
import DatePickerProps from 'components/common/DatePicker/DatePickerPropsType';
import Button from 'components/common/buttons/Button';

import useUser from 'hooks/user/useUser';
import useFormat from 'hooks/useFormat';

const patterns: { [key: string]: string[] } = {
  en: ['dd/MM/yyyy', 'dd-MM-yyyy', 'dd.MM.yyyy', 'dd MM yyyy'],
  fr: ['dd/MM/yyyy', 'dd-MM-yyyy', 'dd.MM.yyyy', 'dd MM yyyy'],
  ru: ['dd.MM.yyyy', 'dd/MM/yyyy', 'dd-MM-yyyy', 'dd MM yyyy'],
  de: ['dd.MM.yyyy', 'dd/MM/yyyy', 'dd-MM-yyyy', 'dd MM yyyy'],
  nl: ['dd-MM-yyyy', 'dd.MM.yyyy', 'dd/MM/yyyy', 'dd MM yyyy'],
};

const DatePickerRaw: FC<DatePickerProps> = ({
  className,
  onChange,
  name,
  value,
  open,
  onClose,
  ...props
}) => {
  const { weekStartsOn, localePattern } = useFormat();
  const { language = 'en' } = useUser() ?? {};
  const { t } = useTranslation();

  const selected = useMemo(() => (value ? new Date(value) : null), [value]);

  const handleChange = useCallback(
    (date: Date): void => {
      if (onChange)
        onChange({
          target: { value: date.toISOString(), name },
          type: 'change',
        } as ChangeEvent<HTMLInputElement>);
    },
    [name, onChange],
  );

  const TodayButton = useCallback(() => <Button color='primary'>{t('common.today')}</Button>, [t]);

  return (
    <DatePicker
      open={open}
      autoComplete='off'
      nextYearButtonLabel='next'
      popperClassName='date'
      className={`react-date-picker-input form-control ${className}`}
      calendarStartDay={weekStartsOn}
      dateFormat={patterns[language]}
      selected={selected}
      placeholderText={localePattern}
      id={name}
      onSelect={onClose}
      onChange={handleChange}
      todayButton={<TodayButton />}
      renderCustomHeader={params => <DatePickerHeader {...params} />}
      onDayMouseEnter={() => null}
      {...props}
    />
  );
};

export default DatePickerRaw;
