const getNumber = (curNum: number, maxLen: number): number => {
  if (curNum > maxLen && curNum > 0) {
    curNum = Math.floor(curNum / 1.9) + 1;

    return getNumber(curNum, maxLen);
  }

  return curNum > 0 ? curNum : maxLen;
};

export const getRandomImage = (link: string, herdName: string, totalImages: number): string => {
  return `${link}/${
    herdName.length > totalImages
      ? getNumber(herdName.length, totalImages)
      : herdName.length ?? totalImages
  }.jpeg`;
};

export default getRandomImage;
