import { gql } from '@apollo/client';

import MEDICATION_REMINDER_FRAGMENT from 'graphql/fragments/animals/medicationReminder';

const MEDICATION_REMINDERS_QUERY = gql`
  query medicationReminders(
    $where: WhereMedicationReminderInput!
    $orderBy: [OrderByMedicationReminderInput!]
  ) {
    medicationReminders(where: $where, orderBy: $orderBy) {
      ...medicationReminderFragment
    }
  }

  ${MEDICATION_REMINDER_FRAGMENT}
`;

export default MEDICATION_REMINDERS_QUERY;
