import { FC } from 'react';

import NotesList from 'components/notes/NotesList';
import NoteForm from 'components/notes/NoteForm';

const AnimalNotesForm: FC = () => (
  <div className='notes-tab'>
    <NoteForm />
    <NotesList />
  </div>
);

export default AnimalNotesForm;
