import { FC, Fragment, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Avatar from 'react-avatar';

import { Capacitor } from '@capacitor/core';

import isEmpty from 'lodash/isEmpty';

import LogoutButton from 'components/common/buttons/LogoutButton';
import Header from 'components/common/Header';
import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';
import Achievements from 'components/profile/Achievements';
import Aside from 'components/profile/Aside';
import BreederForm from 'components/profile/breeder/BreederForm';
import ChangePasswordForm from 'components/profile/ChangePasswordForm';
import ShowWelcomeBlock from 'components/profile/ShowWelcomeBlock';
import PersonalForm from 'components/profile/PersonalForm';
import ControlForm from 'components/profile/controlPage/ControlForm';
import DeleteAccountBtn from 'components/profile/DeleteAccountBtn';
import UpgradeSection from 'components/common/UpgradeSection';

import useGreeting from 'hooks/useGreeting';
import useUser from 'hooks/user/useUser';
import useUserName from 'hooks/user/useUserName';
import useCurrentTariff from 'hooks/user/useCurrentTariff';

import app from 'helpers/app';

import TabsRoutes, { TabData } from 'routes/TabsRoutes';

type UserGroups = string[];

const userGroups: UserGroups = [];

const ProfileSettings: FC = () => {
  return (
    <div className='page-body'>
      <PersonalForm />
      {app.brand && <Achievements />}
      <ChangePasswordForm />
      {app.brand && <ShowWelcomeBlock />}

      {Capacitor.getPlatform() === 'ios' && <DeleteAccountBtn />}

      <Aside />
    </div>
  );
};

const ProfilePage: FC = () => {
  const { t } = useTranslation();
  const user = useUser();
  const userName = useUserName();
  const greeting = useGreeting();

  const { isSilver } = useCurrentTariff();

  const { tabName } = useParams<{ tabName: string }>();

  const isNativePlatform = Capacitor.isNativePlatform();

  const tabsData: TabData[] = useMemo(() => {
    return [
      { key: 'personal-profile', title: t('profile.personalProfile'), component: ProfileSettings },
      {
        key: 'breeder-form',
        title: t('profile.breederProfile'),
        hidden: !isSilver && isNativePlatform,
        component: () => {
          if (!isSilver) {
            return (
              <div className='d-flex h-100 w-100 align-items-center justify-content-center'>
                <UpgradeSection description={t('subscription.upgradeToSilver.descriptionPromo')} />
              </div>
            );
          }

          return (
            <div className='page-body'>
              <BreederForm />

              <Aside />
            </div>
          );
        },
      },
      {
        key: 'control-page',
        title: t('profile.controlPage.tabName'),
        hidden: !isSilver && isNativePlatform,
        component: () => {
          if (!isSilver) {
            return (
              <div className='d-flex h-100 w-100 align-items-center justify-content-center'>
                <UpgradeSection description={t('subscription.upgradeToSilver.descriptionPromo')} />
              </div>
            );
          }

          return (
            <div className='page-body control-page'>
              <ControlForm />

              <Aside />
            </div>
          );
        },
      },
    ]
      .filter(({ key }) =>
        app.brand
          ? ['personal-profile', 'breeder-form', 'control-page'].includes(key)
          : ['personal-profile'].includes(key),
      )
      .filter(({ hidden }) => !hidden);
  }, [isNativePlatform, isSilver, t]);

  const [, setSelectedTab] = useState(tabsData.find(({ key }) => key === tabName));

  const onChangeTab = useCallback((tabData: TabData): void => setSelectedTab(tabData), []);

  return (
    <Fragment>
      <HelmetWithTemplate title={t('profile.profile')} />

      <div className='page profile'>
        <Header text={t('profile.profile')}>
          <LogoutButton />
        </Header>

        <div className='profile-info'>
          <Avatar className='avatar profile-avatar' src='' name={userName} />
          <div className='profile-info-text'>
            <h2 className='profile-greeting'>
              {t(`profile.${greeting}`)}
              <span className='profile-greeting-span'>{` ${userName}`}</span>
            </h2>
            <p className='profile-email'>{user?.email}</p>
          </div>
        </div>
        <ul className='profile-group-list'>
          {!isEmpty(userGroups) &&
            userGroups.map(item => (
              <li className='profile-group-list-item' key={item}>
                {item}
              </li>
            ))}
        </ul>

        <TabsRoutes
          rootPath='/settings'
          tabsData={tabsData}
          redirect={{
            to: '/settings/personal-profile',
          }}
          onChange={onChangeTab}
          isProfile
        />
      </div>
    </Fragment>
  );
};

export default withErrorBoundary(ProfilePage);
