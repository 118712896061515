/* eslint-disable import/no-unresolved */
import { Dispatch, FC, SetStateAction, useMemo } from 'react';

import { Area } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/Modal';
import Button from 'components/common/buttons/Button';

import ImageUploaderCropper from '../cropper/ImageUploaderCropper';
import ImageUploaderZoom from '../zoom/ImageUploaderZoom';

const imageSize = {
  minWidth: 1006,
  minHeight: 566,
  maxWidth: 3840,
  maxHeight: 2160,
};

interface ImageUploaderModalProps {
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
  setImageBlob: Dispatch<SetStateAction<Blob | null>>;
  croppedAreaPixels: Area;
  setCroppedAreaPixels: Dispatch<SetStateAction<Area>>;
  handleUploadImage: () => void;
}

const ImageUploaderModal: FC<ImageUploaderModalProps> = ({
  file,
  setFile,
  zoom,
  setZoom,
  setImageBlob,
  croppedAreaPixels,
  setCroppedAreaPixels,
  handleUploadImage,
}) => {
  const { t } = useTranslation();

  const handleCloseModal = (): void => {
    setFile(null);
  };

  const isBtnDisabled = useMemo(() => {
    if (
      croppedAreaPixels.width < imageSize.minWidth ||
      croppedAreaPixels.width > imageSize.maxWidth ||
      croppedAreaPixels.height < imageSize.minHeight ||
      croppedAreaPixels.height > imageSize.maxHeight
    ) {
      return true;
    }

    return false;
  }, [croppedAreaPixels]);

  return (
    <Modal
      unmountOnClose
      showCloseButton
      title='Crop Image'
      show={!!file}
      onClose={handleCloseModal}
    >
      <ImageUploaderCropper
        file={file}
        zoom={zoom}
        setZoom={setZoom}
        setImageBlob={setImageBlob}
        setCroppedAreaPixels={setCroppedAreaPixels}
      />

      <ImageUploaderZoom zoom={zoom} setZoom={setZoom} />

      <Button
        className='image-uploader-modal__btn'
        type='submit'
        color='primary'
        disabled={isBtnDisabled}
        onClick={handleUploadImage}
      >
        {t('common.upload')}
      </Button>

      {isBtnDisabled && (
        <p className='image-uploader-modal__error'>{t('profile.controlPage.hero.helper')}</p>
      )}
    </Modal>
  );
};

export default ImageUploaderModal;
