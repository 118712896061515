import { useEffect, useMemo } from 'react';

import { usePreviousDistinct } from 'react-use';

import useUpdateQuery from 'hooks/useUpdateQuery';
import useSortAnimalsByStatus from 'hooks/animals/useSortAnimalsByStatus';

import ANIMALS_SUBSCRIPTION from 'graphql/subscriptions/animals';

import { useAnimalsQuery, Maybe, Animal, AnimalStatus } from 'generated/graphql';

type UseAnimals = (props: {
  herdId?: string;
  sortByStatus?: boolean;
  status?: AnimalStatus;
  take?: number;
  skip?: number;
}) => {
  animals?: Maybe<Animal[]>;
  totalCount?: number;
  loading: boolean;
};

const useAnimals: UseAnimals = ({ herdId, sortByStatus, status, ...params }) => {
  const updateQuery = useUpdateQuery('animal', true);

  const variables = useMemo(() => {
    return {
      variables: {
        where: { herdId, status },
        ...params,
      },
    };
  }, [herdId, params, status]);

  const {
    data: { animals: { nodes: animals = null, totalCount = undefined } = {} } = {},
    loading,
    subscribeToMore,
  } = useAnimalsQuery({ ...variables });

  useEffect(() => {
    if (subscribeToMore) subscribeToMore({ document: ANIMALS_SUBSCRIPTION, updateQuery });
  }, [subscribeToMore, updateQuery]);

  const sortedAnimals = useSortAnimalsByStatus(animals as Animal[]);

  const currentAnimalsList = useMemo(() => {
    if (sortByStatus) return sortedAnimals;

    return animals;
  }, [animals, sortByStatus, sortedAnimals]);

  const prevTotalCount = usePreviousDistinct(totalCount);

  return {
    animals: (currentAnimalsList ?? null) as Animal[],
    totalCount: totalCount ?? prevTotalCount,
    loading,
  };
};

export default useAnimals;
