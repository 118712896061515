import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';

import {
  MemberWebsiteFragmentFragment,
  MemberWebsitesQuery,
  UpdateMemberWebsiteInput,
  UpdateMemberWebsiteMutation,
  WhereUniqueMemberWebsiteInput,
  useUpdateMemberWebsiteMutation,
} from 'generated/graphql';
import MEMBER_WEBSITE_FRAGMENT from 'graphql/fragments/memberWebsite';
import MEMBER_WEBSITES_QUERY from 'graphql/queries/memberWebsite/memberWebsites';

type UpdateMemberWebsite = (
  data: UpdateMemberWebsiteInput,
  where: WhereUniqueMemberWebsiteInput,
) => Promise<FetchResult<UpdateMemberWebsiteMutation>>;

interface UseUpdateMemberWebsite {
  updateMemberWebsite: UpdateMemberWebsite;
  loading: boolean;
}

const useUpdateMemberWebsite = (): UseUpdateMemberWebsite => {
  const [updateMemberWebsiteRaw, { loading }] = useUpdateMemberWebsiteMutation();

  const updateMemberWebsite = useCallback(
    (data, where) =>
      updateMemberWebsiteRaw({
        variables: { data, where },
        update: (cache, result) => {
          if (!result.data?.updateMemberWebsite) {
            return;
          }

          try {
            const { memberWebsites = [] } =
              cache.readQuery<MemberWebsitesQuery>({ query: MEMBER_WEBSITES_QUERY }) ?? {};

            cache.writeQuery({
              query: MEMBER_WEBSITES_QUERY,
              data: { memberWebsites: [...memberWebsites, result.data?.updateMemberWebsite] },
            });
          } catch (error) {
            console.error(error);
          }

          try {
            const cachedMemberWebsite = cache.readFragment<MemberWebsiteFragmentFragment>({
              id: `MemberWebsite:${where.id}`,
              fragment: MEMBER_WEBSITE_FRAGMENT,
              fragmentName: 'memberWebsiteFragment',
            });

            if (cachedMemberWebsite) {
              cache.writeFragment({
                id: `MemberWebsite:${where.id}`,
                fragment: MEMBER_WEBSITE_FRAGMENT,
                fragmentName: 'memberWebsiteFragment',
                data: {
                  ...cachedMemberWebsite,
                  ...result.data?.updateMemberWebsite,
                },
              });
            }
          } catch (error) {
            console.error(error);
          }
        },
      }),
    [updateMemberWebsiteRaw],
  );

  return { updateMemberWebsite, loading };
};

export default useUpdateMemberWebsite;
