import { Dispatch, FC, SetStateAction, useState } from 'react';

import { MemberWebsiteFragmentFragment } from 'generated/graphql';

import HostingAccordion from './hosting/HostingAccordion';
import HomeAccordion from './home/HomeAccordion';
import ContactAccordion from './contact/ContactAccordion';
import AboutAccordion from './about/AboutAccordion';
import BookingAccordion from './booking/BookingAccordion';
import SaleAccordion from './sale/SaleAccordion';

export interface ControlAccordionProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

interface ControlAccordionsProps {
  memberWebsite?: MemberWebsiteFragmentFragment;
}

const ControlAccordions: FC<ControlAccordionsProps> = ({ memberWebsite }) => {
  const [currentAccordion, setCurrentAccordion] = useState(0);

  const handleToggleCurrentAccordion = (newCurrentAccordion: number): void => {
    setCurrentAccordion(newCurrentAccordion === currentAccordion ? -1 : newCurrentAccordion);
  };

  return (
    <div className='control-page-accordions'>
      <HostingAccordion
        memberWebsite={memberWebsite}
        isOpen={currentAccordion === 0}
        setIsOpen={() => handleToggleCurrentAccordion(0)}
      />

      <HomeAccordion
        isOpen={currentAccordion === 1}
        setIsOpen={() => handleToggleCurrentAccordion(1)}
      />

      <ContactAccordion
        isOpen={currentAccordion === 2}
        setIsOpen={() => handleToggleCurrentAccordion(2)}
      />

      <AboutAccordion
        isOpen={currentAccordion === 3}
        setIsOpen={() => handleToggleCurrentAccordion(3)}
      />

      <BookingAccordion
        isOpen={currentAccordion === 4}
        setIsOpen={() => handleToggleCurrentAccordion(4)}
      />

      <SaleAccordion
        isOpen={currentAccordion === 5}
        setIsOpen={() => handleToggleCurrentAccordion(5)}
      />
    </div>
  );
};

export default ControlAccordions;
