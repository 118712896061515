import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';

import {
  CloseAnimalTransferRequestMutation,
  TransferStatus,
  useCloseAnimalTransferRequestMutation,
} from 'generated/graphql';

type CloseAnimalTransferRequest = (
  id: string,
  status: TransferStatus,
  herdId?: string,
  herdName?: string,
) => Promise<FetchResult<CloseAnimalTransferRequestMutation>>;

type UseCloseAnimalTransferRequest = () => {
  closeAnimalTransferRequest: CloseAnimalTransferRequest;
  loading: boolean;
};

const useCloseAnimalTransferRequest: UseCloseAnimalTransferRequest = () => {
  const [closeAnimalTransferRequestRaw, { loading }] = useCloseAnimalTransferRequestMutation();

  const closeAnimalTransferRequest = useCallback<CloseAnimalTransferRequest>(
    (id, status, herdName, herdId) =>
      closeAnimalTransferRequestRaw({
        variables: { data: { status, herdName, herdId }, where: { id } },
        refetchQueries: ['me', 'animalTransferRequests'],
        awaitRefetchQueries: true,
      }),
    [closeAnimalTransferRequestRaw],
  );

  return { closeAnimalTransferRequest, loading };
};

export default useCloseAnimalTransferRequest;
