import { useMemo } from 'react';

import useSubscriptionPlan from 'hooks/user/useSubscrtiptionPlan';

const useCurrentTariff = (): {
  isSilver: boolean;
  isBronze: boolean;
  isFree: boolean;
  hasNotChosen: boolean;
} => {
  const currentPlan = useSubscriptionPlan();

  const isSilver = useMemo(() => currentPlan === 'Silver', [currentPlan]);
  const isBronze = useMemo(() => currentPlan === 'Bronze', [currentPlan]);
  const isFree = useMemo(() => currentPlan === 'Free', [currentPlan]);

  const hasNotChosen = useMemo(() => !currentPlan, [currentPlan]);

  return { isSilver, isBronze, isFree, hasNotChosen };
};

export default useCurrentTariff;
