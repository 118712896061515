import { useMemo } from 'react';

import i18n from 'startup/i18next';

import map from 'lodash/map';
import camelCase from 'lodash/camelCase';

import { Option } from 'components/common/select/Select';

import {
  AnimalStatus,
  AnimalSex,
  AnimalForSaleStatus,
  Maybe,
  AlpacaPhenotype,
  PedigreeLinkType,
} from 'generated/graphql';

interface useAnimalOptionsResult {
  animalStatusOptions: Option[];
  forSaleStatusOptions: Option[];
  animalSexOptions: Option[];
  phenotypeOptions: Option[];
  linkTypesOptions: Option[];
  otherIssuerOptions: Option[];
}

const t = i18n.getFixedT(i18n.language);

const linkTypes = [
  {
    value: PedigreeLinkType.Challenged,
    label: t('animals.pedigreeLink.type.challenged'),
    tooltip: t('animals.pedigreeLink.tooltip.challenged'),
  },
  {
    value: PedigreeLinkType.Claimed,
    label: t('animals.pedigreeLink.type.claimed'),
    tooltip: t('animals.pedigreeLink.tooltip.claimed'),
  },
  {
    value: PedigreeLinkType.DnaVerified,
    label: t('animals.pedigreeLink.type.dnaVerified'),
    tooltip: t('animals.pedigreeLink.tooltip.dnaVerified'),
  },
];

const otherIssuerTypes = [t('common.other'), 'AANZ', 'BAS', 'IAR'];

const useAnimalOptions = (sex?: Maybe<string>): useAnimalOptionsResult => {
  const animalStatusOptions = useMemo(
    () =>
      map(AnimalStatus, (value, key) => ({
        value,
        label: t(`animals.statusOptions.${camelCase(key)}`, {
          context: camelCase(sex ?? ''),
        }),
      })),
    [sex],
  );

  const forSaleStatusOptions = useMemo(
    () =>
      map(AnimalForSaleStatus, (value, key) => ({
        value,
        label: t(`animals.forSaleStatusOptions.${camelCase(key)}`),
      })),
    [],
  );

  const animalSexOptions = useMemo(
    () =>
      map(AnimalSex, (value, key) => ({
        value,
        label: t(`animals.sexOptions.${camelCase(key)}`),
      })),
    [],
  );

  const phenotypeOptions = useMemo(
    () =>
      map(AlpacaPhenotype, (value, key) => ({
        value,
        label: key,
      })),
    [],
  );

  const linkTypesOptions = useMemo(() => linkTypes, []);

  const otherIssuerOptions = useMemo(
    () => otherIssuerTypes.map(value => ({ value: value.toLowerCase(), label: value })),
    [],
  );

  return {
    animalStatusOptions,
    forSaleStatusOptions,
    animalSexOptions,
    phenotypeOptions,
    linkTypesOptions,
    otherIssuerOptions,
  };
};

export default useAnimalOptions;
