import gql from 'graphql-tag';

const MATING_EVENT_FRAGMENT = gql`
  fragment matingEvent on MatingEvent {
    id

    spitOff
    setting
    location
    date

    createdAt
    updatedAt
  }
`;

export default MATING_EVENT_FRAGMENT;
