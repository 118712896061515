import { useCallback, useMemo, useState, Fragment, FC } from 'react';

import find from 'lodash/find';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import EmptyMessage from 'components/common/EmptyMessage';
import Table from 'components/common/Table';
import ActionsCell from 'components/common/Table/ActionsCell';
import Button from 'components/common/buttons/Button';
import TransferModal from 'components/animals/transfer/TransferModal';

import useFormat from 'hooks/useFormat';
import useAnimalTransferRequests from 'hooks/animals/transfer/useAnimalTransferRequests';
import useCloseAnimalTransferRequest from 'hooks/animals/transfer/useCloseAnimalTransferRequest';
import useUser from 'hooks/user/useUser';
import useAnimalFromProvider from 'hooks/animals/animal/useAnimalFromProvider';

import app from 'helpers/app';

import { HerdMemberRole, TransferStatus } from 'generated/graphql';

const TransferForm: FC = () => {
  const { t } = useTranslation();
  const user = useUser();
  const { animal } = useAnimalFromProvider();

  const isOwner = useMemo(
    () =>
      find(animal?.herd?.members, ({ user: { id } }) => id === user?.id)?.role ===
      HerdMemberRole.Owner,
    [animal?.herd?.members, user?.id],
  );

  const { animalId } = useParams<{ animalId: string }>();

  const { formatDate } = useFormat();

  const { animalTransferRequests, loading } = useAnimalTransferRequests();

  const [showModal, setShowModal] = useState(false);

  const { closeAnimalTransferRequest, loading: cancelTransferLoading } =
    useCloseAnimalTransferRequest();

  const handleCancel = useCallback(
    (id: string): void => {
      void closeAnimalTransferRequest(id, TransferStatus.Canceled); // add toast
    },
    [closeAnimalTransferRequest],
  );

  const toggleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const isEmpty = useMemo(
    () => !animalTransferRequests?.length && !loading,
    [loading, animalTransferRequests?.length],
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'createdAt',
        Header: t('common.date'),
        Cell: ({ value = '' }) => formatDate(value, 'd MMM, yyyy'),
        disableSortBy: true,
      },
      {
        accessor: 'ownerName',
        Header: t('animals.transferring.from'),
        disableSortBy: true,
      },
      {
        accessor: '',
        Header: t('animals.transferring.to'),
        Cell: ({
          row: {
            original: { recipientEmail = '', recipientName = '' },
          },
        }) => recipientName || recipientEmail,
        disableSortBy: true,
      },
      {
        accessor: '',
        Header: t('animals.status'),
        Cell: ({
          row: {
            original: { status = '' },
          },
        }) => {
          // temroary
          return status !== 'DONE' ? (
            <span style={{ color: '#FFC04C' }}>{t('animals.transferring.inProgress')}</span>
          ) : (
            <span style={{ color: '#5DBA39' }}>{t('animals.transferring.successfull')}</span>
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('common.action'),
        accessor: 'actions',
        disableSortBy: true,
        style: { width: 92 },
        Cell: ({ row: { original: { id, status } = { id: '', status: '' } } }) =>
          status === TransferStatus.Pending && (
            <ActionsCell onCancel={() => handleCancel(id)} loading={cancelTransferLoading} />
          ),
      },
    ],
    [cancelTransferLoading, formatDate, handleCancel, t],
  );

  return (
    // temporary
    isOwner ? (
      <Fragment>
        <div className='tab-content-header' style={{ paddingTop: 40 }}>
          <h2 className='tab-content-heading d-block'>{t('animals.transfer')}</h2>
          {!isEmpty && isOwner && (
            <Button
              className='btn-secondary btn-light btn-custom transfer-btn'
              color='secondary'
              onClick={toggleShowModal}
            >
              <span className='btn-text'>{t('animals.transferring.transferNow')}</span>
            </Button>
          )}
        </div>
        <div className='tab-content-body'>
          {isEmpty ? (
            <EmptyMessage
              message={t('animals.transferring.noTransferAnimal', {
                animal: t(`animals.${app.appName}`).toLocaleLowerCase(),
              })}
              hint={isOwner ? t('animals.transferring.transferHint') : undefined}
            >
              {isOwner && (
                <Button
                  className='btn-secondary btn-light btn-custom transfer-btn'
                  color='secondary'
                  onClick={toggleShowModal}
                >
                  <span className='btn-text'>{t('animals.transferring.transferNow')}</span>
                </Button>
              )}
            </EmptyMessage>
          ) : (
            <Table columns={columns} data={animalTransferRequests ?? []} loading={loading} />
          )}

          <TransferModal show={showModal} toggle={toggleShowModal} animalId={animalId} />
        </div>
      </Fragment>
    ) : null
  );
};

export default TransferForm;
