import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';

import toast from 'react-hot-toast';
import camelCase from 'lodash/camelCase';
import { SelectContext } from 'providers/CurrencyProvider';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import LogoutButton from 'components/common/buttons/LogoutButton';
import AuthHeader from 'components/auth/AuthHeader';
import Loading from 'components/common/Loading';
import Button from 'components/common/buttons/Button';

import useUpgradeSubscription from 'hooks/useUpgradeSubscription';

import { ReactComponent as CheckIcon } from 'icons/check-list.svg';

import app from 'helpers/app';

import BILLING_PLANS_QUERY from 'graphql/queries/users/billingPlans';

const features = {
  bronze: [
    'Online tools for secure and timely management of your herd data.',
    'Unlimited registrations in the International Registry at no additional cost.',
    'Medication reminders.',
    'Fleece statistics and historic recording.',
    'Weights and other relevant data for your alpaca health.',
  ],
  silver: [
    'Online tools for secure and timely management of your herd data.',
    'Unlimited registrations in the International Registry at no additional cost.',
    'Medication reminders.',
    'Fleece statistics and historic recording.',
    'Weights and other relevant data for your alpaca health.',
    'Sell your alpaca online and promote your farm.',
    'Free website hosting with automatic updates of your alpaca for sale',
    'Include your farm in the Breeder Directory page to reach more customers.',
  ],
};

const TrialPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { upgradeSubscription, loading: upgradeSubscriptionLoading } = useUpgradeSubscription();
  const [loadingPlanId, setLoadingPlanId] = useState<string | null>(null);
  const { selectedCurrency } = useContext(SelectContext);

  useEffect(() => {
    history.replace('/trial-period');
  }, [history]);

  const variables = useMemo(() => {
    return {
      variables: {
        where: {
          currency: selectedCurrency,
        },
      },
    };
  }, [selectedCurrency]);

  const { data: { billingPlans = [] } = {}, loading: loadingPlans } = useQuery(
    BILLING_PLANS_QUERY,
    { ...variables, fetchPolicy: 'network-only' }, // ADD WIP network-only because id for bronze don't change
  );

  const bronzePrice = t('billingPlans.prices.trial', {
    context: `${billingPlans[1]?.price} ${billingPlans[1]?.currency}`,
  });

  const silverPrice = t('billingPlans.prices.trial', {
    context: `${billingPlans[2]?.price} ${billingPlans[2]?.currency}`,
  });

  const appNameTranslated = t(`animals.${app.appName}`);

  const commonLocaleOptions = {
    appName: appNameTranslated,
    appNameLowerCase: appNameTranslated.toLowerCase(),
    herd: t(app.appName === 'sheep' ? 'herds.flock' : 'herds.herd').toLowerCase(),
    appNameLowerAccusativeCase: t(`animals.accusativeCase.${app.appName}s`).toLowerCase(), // for RU
  };

  const handleChoosePlan = (id: string) => () => {
    setLoadingPlanId(id);
    upgradeSubscription(id)
      .then(() => {
        setLoadingPlanId(null);
      })
      .catch(err => {
        toast.error(err.message);
        setLoadingPlanId(null);
      });
  };

  return (
    <Fragment>
      <HelmetWithTemplate title={t('subscription.trialPeriod')} />
      <AuthHeader />
      <div className='page auth-page trial-page'>
        {loadingPlans ? (
          <Loading page />
        ) : (
          <Fragment>
            <LogoutButton withIcon buttonClassName='logout-trial' className='trial-container' />
            <h1 className='trial-heading'>{`${t('billingPlans.trial.select')} 🦙`}</h1>
            <div className='tariff-cards-container'>
              <div className='tariff-card'>
                <div className='section'>
                  <h3 className='title'>
                    {`${t('billingPlans.names.bronze')} ${t('billingPlans.plan')}`}
                  </h3>
                  <p className='description'>
                    {t(
                      `billingPlans.descriptions.${camelCase(billingPlans[1]?.name).replace(
                        /sheep|llama/gi,
                        'alpaca',
                      )}`,
                      commonLocaleOptions,
                    )}
                  </p>
                  <Button
                    onClick={handleChoosePlan(billingPlans[1].id)}
                    color='primary'
                    outline
                    className='custom-link'
                    disabled={upgradeSubscriptionLoading || loadingPlans}
                    loading={loadingPlanId === billingPlans[1].id}
                  >
                    {t('billingPlans.trial.start')}
                  </Button>
                  <span className='price'>{bronzePrice}</span>
                </div>
                <div className='section'>
                  <ul className='features-list'>
                    {features.bronze.map(item => (
                      <li key={item} className='features'>
                        <CheckIcon />
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='tariff-card'>
                <div className='section'>
                  <h3 className='title'>
                    {`${t('billingPlans.names.silver')} ${t('billingPlans.plan')}`}
                  </h3>
                  <p className='description'>
                    {t(
                      `billingPlans.descriptions.${camelCase(billingPlans[2]?.name).replace(
                        /sheep|llama/gi,
                        'alpaca',
                      )}`,
                      commonLocaleOptions,
                    )}
                  </p>
                  <Button
                    onClick={handleChoosePlan(billingPlans[2].id)}
                    color='primary'
                    outline
                    className='custom-link'
                    disabled={upgradeSubscriptionLoading || loadingPlans}
                    loading={loadingPlanId === billingPlans[2].id}
                  >
                    {t('billingPlans.trial.start')}
                  </Button>
                  <span className='price'>{silverPrice}</span>
                </div>
                <div className='section'>
                  <ul className='features-list'>
                    {features.silver.map(item => (
                      <li key={item} className='features'>
                        <CheckIcon />
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default TrialPage;
