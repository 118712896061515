import { Fragment } from 'react';

import i18n from 'startup/i18next';

import { ReactComponent as ComingSoonIcon } from 'images/coming-soon.svg';

const t = i18n.getFixedT(i18n.language);

const ComingSoonModalBody = (
  <Fragment>
    <ComingSoonIcon className='modal-image' />
    <h1 className='modal-title'>
      {t('common.comingSoon')}
      ...
    </h1>
    <p className='modal-description'>{t('common.soonUpgrading')}</p>
  </Fragment>
);

export default ComingSoonModalBody;
