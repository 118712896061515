import { FC, Fragment, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import Button from 'components/common/buttons/Button';
import Loading from 'components/common/Loading';
import HerdModal from 'components/herds/HerdModal/HerdModal';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';
import EmptyMessage from 'components/common/EmptyMessage';
import HerdsList from 'components/herds/HerdsList';
import CreateHerdModalBody from 'components/common/modal/CreateHerdModalBody';

import useHerds from 'hooks/herds/useHerds';
import useCheckSubscribed from 'hooks/user/useCheckSubscribed';
import useModalFromProvider from 'hooks/modal/useModalFromProvider';

import { ReactComponent as StartUpImage } from 'images/start-up.svg';

import app from 'helpers/app';

import { Herd } from 'generated/graphql';

const HerdsPage: FC = () => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const { toggleModal } = useModalFromProvider();
  const { push } = useHistory();

  const isSubscribed = useCheckSubscribed();

  const { herds, loading } = useHerds();

  const myHerdsTitle = useMemo(
    () => t(`herds.${app.appName === 'sheep' ? 'myFlocks' : 'myHerds'}`),
    [t],
  );

  const isHerdsExists = useMemo(() => !!herds?.length, [herds]);

  if (!herds) {
    if (loading) return <Loading page />;

    return null;
  }

  const toggleModalShow = (): void => {
    if (isSubscribed) {
      setModalShow(!modalShow);
    } else toggleModal(CreateHerdModalBody, 'modal-upgrade', 'body-upgrade', 'header-upgrade');
  };

  return (
    <Fragment>
      <HelmetWithTemplate title={myHerdsTitle} />

      <HerdModal show={modalShow} toggle={toggleModalShow} />

      <div className='page herds-page'>
        <div className='header'>
          <h1 className='heading'>{myHerdsTitle}</h1>

          {isHerdsExists && (
            <Button className='create-btn' color='primary' onClick={toggleModalShow}>
              <span className='create-btn-text'>
                {t('herds.createHerd', { context: app.appName })}
              </span>
            </Button>
          )}
        </div>

        <div className='page-body'>
          {isHerdsExists ? (
            <HerdsList herds={herds as Herd[]} />
          ) : isSubscribed ? (
            <EmptyMessage
              message={t('herds.emptyHerdsMessage')}
              hint={t('herds.emptyHerdsHint', { context: app.appName })}
            >
              <Button className='create-btn empty' color='primary' onClick={toggleModalShow}>
                <span className='create-btn-text'>
                  {t('herds.createHerd', { context: app.appName })}
                </span>
              </Button>
            </EmptyMessage>
          ) : (
            <div className='upgrade-message'>
              <div className='image-container'>
                <StartUpImage />
              </div>

              <div className='upgrade-message-text'>
                {t('encourage.createHerdMessage', {
                  herds: t(`herds.${app.appName === 'sheep' ? 'flocks' : 'herds'}`),
                  animal: t(`animals.${app.appName}`).toLowerCase(),
                  herdGenitiveCase: t(
                    `herds.${
                      app.appName === 'sheep' ? 'genitiveCase.flocks' : 'genitiveCase.herds'
                    }`,
                  ),
                  animalGenitive: t(`animals.genitive.${app.appName}s`).toLowerCase(),
                })}
              </div>

              <div className='upgrade-message-hint'>
                {t('encourage.createHerdHint', {
                  herds: t(`herds.${app.appName === 'sheep' ? 'flocks' : 'herds'}`),
                  animal: t(`animals.${app.appName}`).toLowerCase(),
                  herdsAccusativeCase: t(
                    `herds.${
                      app.appName === 'sheep' ? 'accusativeCase.flocks' : 'accusativeCase.herds'
                    }`,
                  ),
                  animalAccusativeCase: t(`animals.accusativeCase.${app.appName}s`).toLowerCase(),
                })}
              </div>

              <Button className='upgrade-btn' color='primary' onClick={() => push('/account')}>
                <span className='upgrade-text'>{t('common.upgrade')}</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default withErrorBoundary(HerdsPage);
