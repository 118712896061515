import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';

import CreateMemberWebsite from 'graphql/mutations/memberWebsite/createMemberWebsite';

import {
  CreateMemberWebsiteInput,
  CreateMemberWebsiteMutation,
  MemberWebsitesQuery,
  useCreateMemberWebsiteMutation,
} from 'generated/graphql';

import MEMBER_WEBSITES_QUERY from 'graphql/queries/memberWebsite/memberWebsites';

type CreateMemberWebsite = (
  data: CreateMemberWebsiteInput,
) => Promise<FetchResult<CreateMemberWebsiteMutation>>;

interface UseCreateMemberWebsite {
  createMemberWebsite: CreateMemberWebsite;
  loading: boolean;
}

const useCreateMemberWebsite = (): UseCreateMemberWebsite => {
  const [createMemberWebsiteRaw, { loading }] = useCreateMemberWebsiteMutation();

  const createMemberWebsite: CreateMemberWebsite = useCallback(
    data => {
      return createMemberWebsiteRaw({
        variables: { data },
        update: (cache, { data: response }) => {
          try {
            const { memberWebsites = [] } =
              cache.readQuery<MemberWebsitesQuery>({ query: MEMBER_WEBSITES_QUERY }) ?? {};

            cache.writeQuery({
              query: MEMBER_WEBSITES_QUERY,
              data: { memberWebsites: [...memberWebsites, response?.createMemberWebsite] },
            });
          } catch (error) {
            console.log(error);
          }
        },
      });
    },
    [createMemberWebsiteRaw],
  );

  return { createMemberWebsite, loading };
};

export default useCreateMemberWebsite;
