import { FC } from 'react';

import UpcomingMedication from 'components/welcome/UpcomingMedication';
import Welcome from 'components/welcome/Welcome';

import useCheckSubscribed from 'hooks/user/useCheckSubscribed';

const MainContent: FC<{ title: string }> = ({ title }) => {
  const isSubscribed = useCheckSubscribed();

  return isSubscribed ? <UpcomingMedication /> : <Welcome title={title} />;
};

export default MainContent;
