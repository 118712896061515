import common from 'locales/nl/common.json';
import auth from 'locales/nl/auth.json';
import route from 'locales/nl/route.json';
import herds from 'locales/nl/herds.json';
import profile from 'locales/nl/profile.json';
import subscription from 'locales/nl/subscription.json';
import animals from 'locales/nl/animals.json';
import notes from 'locales/nl/notes.json';
import errors from 'locales/nl/errors.json';
import members from 'locales/nl/members.json';
import billingPlans from 'locales/nl/billingPlans.json';
import library from 'locales/nl/library.json';
import achievements from 'locales/nl/achievements.json';
import encourage from 'locales/nl/encourage.json';
import mating from 'locales/nl/mating.json';

export default {
  common,
  auth,
  route,
  herds,
  profile,
  subscription,
  animals,
  notes,
  errors,
  members,
  billingPlans,
  library,
  achievements,
  encourage,
  mating,
};
