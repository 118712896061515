import { FC } from 'react';

import { useField, ErrorMessage } from 'formik';

import classnames from 'classnames';

import { FormFeedback } from 'reactstrap';

import DatePicker from 'components/common/DatePicker';

import DatePickerProps from 'components/common/DatePicker/DatePickerPropsType';

const DatePickerField: FC<DatePickerProps> = ({ name, className, ...props }) => {
  const [field, { error, touched }] = useField(name);

  return (
    <DatePicker
      {...props}
      {...field}
      className={classnames(className, { 'is-invalid': !!error && touched })}
    >
      <ErrorMessage name={name}>{message => <FormFeedback>{message}</FormFeedback>}</ErrorMessage>
    </DatePicker>
  );
};

export default DatePickerField;
