import { FC } from 'react';

import ContentLoader from 'react-content-loader';

const ResourceSkeleton: FC = () => (
  <ContentLoader
    className='resource-item'
    speed={2}
    backgroundColor='#FFFFFF'
    foregroundColor='#D4D4D4'
    height='44'
  >
    <rect x='0' y='0' rx='8' width='558' height='44' />
  </ContentLoader>
);

export default ResourceSkeleton;
