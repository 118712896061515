/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useCallback, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import toast from 'react-hot-toast';

import { MemberWebsiteFragmentFragment } from 'generated/graphql';

import { ReactComponent as XIcon } from 'icons/x-cross.svg';
import { ReactComponent as CheckIcon } from 'icons/check.svg';

import Table from 'components/common/Table';
import Button from 'components/common/buttons/Button';

import MEMBER_WEBSITES_QUERY from 'graphql/queries/memberWebsite/memberWebsites';
import classNames from 'classnames';

interface NSRecordsTableProps {
  className?: string;
  memberWebsite?: MemberWebsiteFragmentFragment;
}

const NSRecordsTable: FC<NSRecordsTableProps> = ({ className, memberWebsite }) => {
  const [isRefetching, setIsRefetching] = useState(false);

  const { t } = useTranslation();

  const client = useApolloClient();

  const handleCopyText = useCallback(
    (value: string): void => {
      navigator.clipboard
        .writeText(value)
        .then(() => toast.success(t('profile.controlPage.ns.successOnCopy')))
        .catch(() => toast.error(t('profile.controlPage.ns.errorOnCopy')));
    },
    [t],
  );

  const data = useMemo(() => {
    // Show only those nsRecords that match the current domain
    const filteredNsRecords =
      memberWebsite?.nsRecords.filter(record =>
        memberWebsite?.domains.find(domain => domain === record.domain),
      ) || [];

    return filteredNsRecords;
  }, [memberWebsite?.domains, memberWebsite?.nsRecords]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'name',
        disableSortBy: true,
        Header: t('profile.controlPage.ns.name'),
        Cell: ({ value }: CellProps<MemberWebsiteFragmentFragment>) => (
          <p className='m-0 cursor-pointer' onClick={() => handleCopyText(value)}>
            {value}
          </p>
        ),
      },
      {
        accessor: 'type',
        Header: t('profile.controlPage.ns.type'),
      },
      {
        accessor: 'value',
        Header: t('profile.controlPage.ns.value'),
        Cell: ({ value }: CellProps<MemberWebsiteFragmentFragment>) => (
          <p className='m-0 cursor-pointer' onClick={() => handleCopyText(value)}>
            {value}
          </p>
        ),
      },
      {
        accessor: 'status',
        Header: t('profile.controlPage.ns.status'),
        Cell: ({ ok }: any) => {
          const Icon = useMemo(() => (ok ? CheckIcon : XIcon), [ok]);

          return <Icon color={ok ? 'green' : 'red'} width={24} height={24} />;
        },
      },
    ];
  }, [t]);

  const shouldRender = useMemo(() => {
    if (memberWebsite?.nsRecords.length === 3) {
      const isWWWRecordOk = !!memberWebsite.nsRecords.find(
        record => record.name.toLowerCase() === 'www' && record.ok === true,
      );

      const isAnyOtherRecordOk = !!memberWebsite.nsRecords.find(
        record => record.name.toLowerCase() !== 'www' && record.ok === true,
      );

      return !(isWWWRecordOk && isAnyOtherRecordOk);
    }

    const hasDomain = memberWebsite?.domains.filter(Boolean).length !== 0;
    const hasDisabledRecord = memberWebsite?.nsRecords.some(record => !record.ok);

    return hasDomain && hasDisabledRecord;
  }, [memberWebsite?.domains, memberWebsite?.nsRecords]);

  const refetchMemberWebsitesQuery = async (): Promise<void> => {
    const toastLoadingId = toast.loading(t('profile.controlPage.ns.loadingCheckRecords'));

    setIsRefetching(true);

    await client.refetchQueries({ include: [MEMBER_WEBSITES_QUERY] });

    setIsRefetching(false);

    toast.remove(toastLoadingId);
  };

  if (!shouldRender) {
    return null;
  }

  return (
    <div className={classNames('control-table', className)}>
      <div className='control-table__header'>
        <h4 className='control-table__title mb-2'>{t('profile.controlPage.ns.titleSupports')}</h4>
        <h4 className='control-table__title'>{t('profile.controlPage.ns.titleNotSupports')}</h4>
      </div>

      <Table className='mt-3' columns={columns} data={data} />

      <Button
        onClick={refetchMemberWebsitesQuery}
        disabled={isRefetching}
        className='w-100 mt-3'
        type='button'
        color='primary'
      >
        {t('profile.controlPage.ns.checkRecords')}
      </Button>
    </div>
  );
};

export default NSRecordsTable;
