import { FC } from 'react';

import { Capacitor } from '@capacitor/core';

import MySubscription from 'components/common/MySubscription';
import PopupGetAccess from 'components/common/PopupGetAccess';

const Aside: FC = () => {
  const isNativePlatform = Capacitor.isNativePlatform();

  if (isNativePlatform) {
    return null;
  }

  return (
    <aside className='profile-aside'>
      <MySubscription />
      <PopupGetAccess />
    </aside>
  );
};

export default Aside;
