import { FC } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import { ControlFormValues } from 'utils/control/parseControlValues';
import validateEditorState from 'utils/editor/validateEditorState';

import { CONTROL_EDITOR_CONFIG, CONTROL_EDITOR_ICONS } from 'constants/control';

import EditorField from 'components/common/form/EditorField';
import ImageUploaderField from 'components/common/image-uploader/ImageUploaderField';

import { ControlAccordionProps } from '../ControlAccordions';

import ControlAccordion from '../../../common/ControlAccordion';
import ControlField from '../../../common/ControlField';

export const bookingValidationSchema = Yup.object().shape({
  bookingPageContent: validateEditorState,
  bookingPageHeroImageUrl: Yup.string().required(),
});

const BookingAccordion: FC<ControlAccordionProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ControlFormValues>();

  const isCompleted = bookingValidationSchema.isValidSync(values);
  const isDisabled = !values.bookingPageLive;

  return (
    <ControlAccordion
      title={t('profile.controlPage.booking.title')}
      toggle='bookingPageLive'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      disabled={isDisabled}
      completed={isCompleted}
    >
      <ControlField
        required
        label={{
          text: t('profile.controlPage.hero.title'),
          helper: t('profile.controlPage.hero.helper'),
        }}
      >
        <ImageUploaderField name='bookingPageHeroImageUrl' disabled={isDisabled} />
      </ControlField>

      <ControlField
        required
        className='mt-3'
        label={{
          text: t('profile.controlPage.content.title'),
          helper: t('profile.controlPage.content.helper'),
        }}
      >
        <EditorField
          name='bookingPageContent'
          placeholder={t('profile.controlPage.content.placeholder')}
          readOnly={isDisabled}
          toolbar={CONTROL_EDITOR_CONFIG}
          editorIcons={CONTROL_EDITOR_ICONS}
        />
      </ControlField>
    </ControlAccordion>
  );
};

export default BookingAccordion;
