import { Node, Edge } from 'reactflow';

export interface CustomNode extends Node {
  parent: string;
  child: string;
  linkType: string;
}

const getTypeOfLink = (linkType: string): { stroke: string; strokeDasharray?: string } => {
  switch (linkType) {
    case 'DNA_VERIFIED':
      return {
        stroke: '#6CD743',
      };
    case 'CLAIMED':
      return { stroke: '#FFC04C' };
    case 'CHALLENGED':
      return {
        stroke: '#FFC04C',
        strokeDasharray: '4',
      };
    default:
      return { stroke: '#D4D4D4' };
  }
};

const getInitialEdges = (nodes: CustomNode[]): Edge[] => {
  return nodes?.map((node: CustomNode) => {
    if (/^c-c\d-/.test(node.id))
      return {
        id: `e${node.id}`,
        source: node?.parent,
        target: node.id,
        type: 'customEdge',
        data: { text: node.linkType },
        style: getTypeOfLink(node.linkType),
      };

    switch (node.id.match('^[a-z]-([a-z]?)?')?.[0]) {
      case 'p-p':
        return {
          id: `ep-${node.id}`,
          source: node.id,
          target: node.child,
          type: 'customEdge',
          data: { text: node.linkType },
          style: getTypeOfLink(node.linkType),
        };
      case 'p-':
        return {
          id: `e${node.id}-0`,
          source: node.id,
          target: '0',
          type: 'customEdge',
          data: { text: node.linkType },
          style: getTypeOfLink(node.linkType),
        };
      case 'c-':
        return {
          id: `e0-${node.id}`,
          source: '0',
          target: node.id,
          type: 'customEdge',
          data: { text: node.linkType },
          style: getTypeOfLink(node.linkType),
        };
      default:
        break;
    }

    return { id: '', source: '', target: '' };
  });
};

export default getInitialEdges;
