import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { components, MenuProps, IndicatorProps } from 'react-select';

import Select, { Target } from 'components/common/select/Select';

import { ReactComponent as DropdownChevronIcon } from 'icons/dropdown-chevron.svg';

import { ReactComponent as CheckIcon } from 'icons/check.svg';

import { LANGUAGES } from 'helpers/constants';

const SelectLanguage: FC = () => {
  const { i18n } = useTranslation();

  const handleChange = ({ target: { value } }: Target): void => {
    if (value) void i18n.changeLanguage(value);
  };

  const CaretDownIcon = (): JSX.Element => {
    return <DropdownChevronIcon />;
  };

  const DropdownIndicator = (props: IndicatorProps<any, any>): JSX.Element => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const DropdownMenu = ({ children, ...props }: MenuProps<any, any>): JSX.Element => {
    return (
      <components.Menu {...props} className='language-select-menu'>
        {children}
      </components.Menu>
    );
  };

  const CustomOption = ({ children, isSelected, ...props }: any): JSX.Element => (
    <components.Option {...props}>
      <div className='selected-option'>
        {children}
        {isSelected && <CheckIcon style={{ marginRight: '8px' }} />}
      </div>
    </components.Option>
  );

  return (
    <div className='select-language'>
      <Select
        name='language'
        options={LANGUAGES}
        initialValue='en'
        onChange={handleChange}
        value={i18n.language.slice(0, 2)}
        menuPlacement='top'
        className='select'
        isSearchable={false}
        components={{ DropdownIndicator, Menu: DropdownMenu, Option: CustomOption }}
        styles={{
          indicatorSeparator: () => ({ display: 'none' }),
        }}
      />
    </div>
  );
};

export default SelectLanguage;
