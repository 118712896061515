import { createContext, FC, useMemo } from 'react';

import Loading from 'components/common/Loading';

import { AchievementsFragmentFragment, Maybe, useAchievementsQuery } from 'generated/graphql';

export const AchievementsContext = createContext<Maybe<AchievementsFragmentFragment[]>>(null);

const AchievementsProvider: FC = ({ children }) => {
  const { data, loading } = useAchievementsQuery();

  const { achievements = null } = data || {};

  const value = useMemo(() => achievements, [achievements]);

  if (loading) return <Loading fullscreen />;

  return <AchievementsContext.Provider value={value}>{children}</AchievementsContext.Provider>;
};

export default AchievementsProvider;
