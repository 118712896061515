import { FC } from 'react';

import Modal from 'components/common/Modal';
import CreateAlpacaForm from 'components/animals/createAnimal/CreateAlpacaForm';

interface Props {
  show: boolean;
  toggle: () => void;
  animal?: any;
  animalName?: string;
  herdId: string;
  title: string;
  pedigreeType: string;
  setHerdIdCallback: any;
  setSelectorValue: any;
  message?: string;
  allowChangeHerd?: boolean;
}

const CreateAlpacaModal: FC<Props> = ({
  show,
  toggle,
  animal,
  animalName,
  message,
  herdId,
  title,
  pedigreeType,
  setHerdIdCallback,
  setSelectorValue,
  allowChangeHerd,
}) => {
  return (
    <Modal
      showCloseButton
      autoFocus={false}
      show={show}
      onClose={toggle}
      className='pedigree-modal'
      title={title}
    >
      <CreateAlpacaForm
        allowChangeHerd={allowChangeHerd}
        animal={animal}
        animalName={animalName}
        herdId={herdId}
        closeModal={toggle}
        pedigreeType={pedigreeType}
        setHerdIdCallback={setHerdIdCallback}
        setSelectorValue={setSelectorValue}
        message={message}
      />
    </Modal>
  );
};

export default CreateAlpacaModal;
