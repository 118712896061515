import { gql } from '@apollo/client';

import MEMBER_WEBSITE_FRAGMENT from 'graphql/fragments/memberWebsite';

const MEMBER_WEBSITES_QUERY = gql`
  query memberWebsites {
    memberWebsites {
      ...memberWebsiteFragment
    }
  }
  ${MEMBER_WEBSITE_FRAGMENT}
`;

export default MEMBER_WEBSITES_QUERY;
