import { FC, memo } from 'react';

import { Cell as RTCell } from 'react-table';

import classnames from 'classnames';

import LineSkeleton from 'components/common/skeletons/LineSkeleton';

import useDataFromProvider from 'hooks/useDataFromProvider';

const Cell: FC<
  RTCell<any, any> & { column: { style?: { [key: string]: any }; cellClassName?: string } }
> = ({ getCellProps, render, column: { style, cellClassName = {} } }) => {
  const { loading } = useDataFromProvider();

  return (
    <td {...getCellProps()} style={style}>
      <div className={classnames('cell-wrap', cellClassName)}>
        {loading ? <LineSkeleton /> : render('Cell')}
      </div>
    </td>
  );
};

export default memo(Cell);
