import { FC, useEffect } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import * as Yup from 'yup';

import { ControlFormValues } from 'utils/control/parseControlValues';

import SwitchToggleField from 'components/common/form/SwitchToggleField';

import { homeValidationSchema } from '../accordions/home/HomeAccordion';
import { contactValidationSchema } from '../accordions/contact/ContactAccordion';

import ControlLiveText from './text/ControlLiveText';

const isLiveToggleEnabled = (values: ControlFormValues): boolean => {
  return (
    !!values.slug &&
    Yup.object().concat(homeValidationSchema).concat(contactValidationSchema).isValidSync(values)
  );
};

interface ControlLiveProps {
  isMemberWebsiteLive?: boolean;
}

const ControlLive: FC<ControlLiveProps> = ({ isMemberWebsiteLive }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<ControlFormValues>();

  const isCompleted = isLiveToggleEnabled(values);

  useEffect(() => {
    if (values.isLive && !isCompleted) setFieldValue('isLive', false);
  }, [isCompleted, setFieldValue, values]);

  return (
    <div className={classNames('control-live', { '--is-completed': isCompleted })}>
      <div className='control-live__content'>
        <div className='control-live__title'>
          <h4>{t('profile.controlPage.live')}</h4>
          {isMemberWebsiteLive && <div className='control-live__pulse pulse' />}
        </div>
        <ControlLiveText isMemberWebsiteLive={isMemberWebsiteLive} isCompleted={isCompleted} />
      </div>

      <SwitchToggleField
        name='isLive'
        className='control-live__toggle'
        width={44}
        height={24}
        handleDiameter={20}
        disabled={!isCompleted}
      />
    </div>
  );
};

export default ControlLive;
