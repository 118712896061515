import { FC, Fragment, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';
import Table from 'components/common/Table';
import ActionsCell from 'components/common/Table/ActionsCell';
import RejectTransferModal from 'components/animals/transfer/RejectTransferModal';
import TransferModal from 'components/animals/transfer/TransferModal';

import useFormat from 'hooks/useFormat';
import useAnimalTransferRequests from 'hooks/animals/transfer/useAnimalTransferRequests';

const TransferPage: FC = () => {
  const { t } = useTranslation();

  const [transferRejectData, setTransferRejectData] = useState({ id: '', ownerName: '', name: '' });

  const [transferId, setTransferId] = useState('');

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const toggleShowAcceptModal = useCallback(() => {
    setShowAcceptModal(!showAcceptModal);
  }, [showAcceptModal]);

  const [showRejectModal, setShowRejectModal] = useState(false);
  const toggleRejectModal = useCallback(() => {
    setShowRejectModal(!showRejectModal);
  }, [showRejectModal]);

  const { formatDate } = useFormat();

  const { animalTransferRequests, loading } = useAnimalTransferRequests();

  const handleReject = useCallback(
    (id, ownerName, name) => {
      setTransferRejectData({ id, ownerName, name });
      toggleRejectModal();
    },
    [toggleRejectModal],
  );

  const handleAccept = useCallback(
    id => {
      toggleShowAcceptModal();
      setTransferId(id);
    },
    [toggleShowAcceptModal],
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'createdAt',
        Header: t('common.date'),
        Cell: ({ value = '' }) => formatDate(value, 'd MMM, yyyy'),
        disableSortBy: true,
      },
      {
        accessor: 'ownerName',
        Header: t('animals.transferring.from'),
        disableSortBy: true,
      },
      {
        accessor: '',
        Header: t('animals.animalName'),
        Cell: ({
          row: {
            original: { animal: { name = '' } = {} },
          },
        }) => {
          return name || '';
        },
        disableSortBy: true,
      },
      {
        Header: t('common.action'),
        accessor: 'actions',
        disableSortBy: true,
        style: { width: 92 },
        Cell: ({
          row: {
            original: { id, ownerName, animal } = {
              id: '',
              ownerName: '',
              animal: { name: '' },
            },
          },
        }) => (
          <Fragment>
            <ActionsCell onAccept={() => handleAccept(id)} />
            <ActionsCell onReject={() => handleReject(id, ownerName, animal?.name)} />
          </Fragment>
        ),
      },
    ],
    [formatDate, handleAccept, handleReject, t],
  );

  return (
    <Fragment>
      <HelmetWithTemplate title={t('animals.transfer')} />

      <div className='page transfer'>
        <div className='header'>
          <h1 className='heading'>{t('animals.transfer')}</h1>
        </div>

        <div className='page-body'>
          <Table columns={columns} data={animalTransferRequests ?? []} loading={loading} />
        </div>
      </div>

      <RejectTransferModal
        show={showRejectModal}
        toggle={toggleRejectModal}
        transferRejectData={transferRejectData}
      />

      <TransferModal
        show={showAcceptModal}
        toggle={toggleShowAcceptModal}
        transferId={transferId}
      />
    </Fragment>
  );
};

export default withErrorBoundary(TransferPage);
