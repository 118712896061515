import { FC, Fragment, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Row, Col } from 'reactstrap';

import { Editor } from 'react-draft-wysiwyg';

import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';

import Avatar from 'components/common/Avatar';
import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';
import EmptyPage from 'components/common/EmptyPage';
import Loading from 'components/common/Loading';
import ButtonGoBack from 'components/common/buttons/ButtonGoBack';

import useAnimalForSale from 'hooks/animals/sale/useAnimalForSale';
import useAnimalAge from 'hooks/animals/useAnimalAge';
import useEditorState from 'hooks/useEditorState';
import useCountry from 'hooks/useCountry';
import useUser from 'hooks/user/useUser';

import app from 'helpers/app';

import { AlpacaPhenotype, SaleFragmentFragment, User } from 'generated/graphql';

const { appName } = app;

const AlpacaForSale: FC = () => {
  const { language = 'en' } = useUser() ?? {};
  const { countriesLocale } = useCountry(language);

  const getFullCountry = useCallback(
    (code: string): string =>
      Object.entries(countriesLocale).find(([key]) => key === code)?.[1] || code,
    [countriesLocale],
  );

  const { t } = useTranslation();
  const { animalForSale, loading } = useAnimalForSale();

  const {
    name,
    sex,
    country,
    owner: { firstName, lastName, breederProfile } = {} as User,
    images = [],
    coloration,
    birthday,
    profile,
    details: { animalDetails } = {},
  } = animalForSale || ({} as SaleFragmentFragment);

  const { bio, email, phone } = breederProfile || {};
  const { phenotype = AlpacaPhenotype.Huacaya } = profile || {};

  const birthdayString = useAnimalAge(birthday);

  const { getCurrentEditorState } = useEditorState();

  const getTextFromEditorState = useCallback(
    state => getCurrentEditorState(state).getCurrentContent().getPlainText('\u0001'),
    [getCurrentEditorState],
  );

  const bioState = getCurrentEditorState(bio);
  const detailsState = getCurrentEditorState(animalDetails);

  const avatarImages = images.map(image => ({
    src: image.url,
    placeholderSrc: image.thumbUrl || image.url,
  }));

  if (!animalForSale) {
    if (loading) return <Loading page />;

    return (
      <EmptyPage
        description={`${t(`animals.${app.appName}`)} ${t('common.notFound', {
          context: 'female',
        }).toLocaleLowerCase()} 😢`}
        title={t('common.notFound', {
          context: 'female',
        })}
      />
    );
  }

  return (
    <Fragment>
      <HelmetWithTemplate title='Alpaca for sale' />

      <div className='page sale-page-profile'>
        <div className='header'>
          <h1 className='heading'>{t('herds.animalsForSale', { context: appName })}</h1>
          <ButtonGoBack color='light' />
        </div>

        <div className='page-body'>
          <div className='content'>
            <div className='animal-info'>
              <div className='d-flex align-items-center align-items-md-start'>
                <Avatar name={name} images={avatarImages} className='image' />
                <div className='animal-name d-md-none d-xs-block'>
                  <h3 className='m-0 ml-3'>{name}</h3>
                </div>
              </div>

              <div className='description'>
                <div className='animal-name d-none d-md-block'>
                  <h3>{name}</h3>
                </div>

                <div className='details'>
                  <Row noGutters>
                    {phenotype && (
                      <Col xl={2} lg={4} md={4} xs={3} className='type-column'>
                        <span className='label-muted'>{t('animals.type')}</span>
                        <p>{capitalize(phenotype || '')}</p>
                      </Col>
                    )}

                    {sex && (
                      <Col xl={1} lg={4} md={4} xs={2} className='sex-column'>
                        <span className='label-muted'>{t('animals.sex')}</span>
                        <p>{t(`animals.sexOptions.${camelCase(sex ?? 'unknown')}`)}</p>
                      </Col>
                    )}

                    {coloration.length > 0 && (
                      <Col xl={2} lg={4} md={12} xs={6}>
                        <span className='label-muted'>{t('animals.coloration')}</span>
                        <p>
                          {coloration?.map((color, index, array) => {
                            return `${t(`animals.colors.${color}`)}${
                              array?.length > ++index ? ', ' : ''
                            } `;
                          })}
                        </p>
                      </Col>
                    )}

                    {birthdayString && (
                      <Col xl={6} lg={12} md={12} xs={12}>
                        <span className='label-muted'>{t('animals.age.age')}</span>
                        <p>{birthdayString}</p>
                      </Col>
                    )}

                    {getTextFromEditorState(animalDetails) && (
                      <Col md={12}>
                        <span className='label-muted'>{t('animals.alpacaDetails')}</span>
                        <Editor editorState={detailsState} readOnly toolbarHidden />
                      </Col>
                    )}
                  </Row>
                </div>

                <div className='breeder-info'>
                  <h4 className='breeder-information-label'>{t('profile.breederInformation')}</h4>

                  <Row noGutters>
                    {firstName && (
                      <Col xl={3} lg={8} md={6} sm={5} xs={4}>
                        <span className='label-muted'>{t('common.name')}</span>
                        <p className='name'>{`${firstName} ${lastName}`}</p>
                      </Col>
                    )}

                    {country && (
                      <Col xl={2} lg={4} md={6} sm={7} xs={6}>
                        <span className='label-muted'>{t('profile.country')}</span>
                        <p>{getFullCountry(country || '')}</p>
                      </Col>
                    )}

                    {phone && (
                      <Col xl={3} lg={12}>
                        <span className='label-muted'>{t('profile.contactPhone')}</span>
                        <p>{phone}</p>
                      </Col>
                    )}

                    {email && (
                      <Col xl={3} lg={12}>
                        <span className='label-muted'>{t('profile.contactEmail')}</span>
                        <p>{email}</p>
                      </Col>
                    )}
                  </Row>

                  <Row noGutters className='mt-2'>
                    {getTextFromEditorState(bio) && (
                      <Col sm={12}>
                        <span className='label-muted'>{t('profile.breederBio')}</span>
                        <Editor editorState={bioState} readOnly toolbarHidden />
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withErrorBoundary(AlpacaForSale);
