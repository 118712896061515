import { FC } from 'react';

import useHerdsOptions from 'hooks/herds/useHerdOptions';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import SelectField from '../../SelectField';

const HerdSelectField: FC = () => {
  const { t } = useTranslation();

  const { herdsOptions } = useHerdsOptions();

  return (
    <SelectField
      name='herdId'
      options={herdsOptions ?? []}
      label={capitalize(t('herds.herd'))}
      isSearchable
    />
  );
};

export default HerdSelectField;
