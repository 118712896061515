import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from 'react';

import { Collapse } from 'reactstrap';
import classNames from 'classnames';

import { ReactComponent as TickIcon } from 'icons/common/tick.svg';
import { ReactComponent as CircleCrossIcon } from 'icons/common/circle-cross.svg';
import { ReactComponent as ChevronDownIcon } from 'icons/common/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'icons/common/chevron-up.svg';

import SwitchToggleField from 'components/common/form/SwitchToggleField';

interface ControlAccordionProps {
  title: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  toggle?: string;
  disabled?: boolean;
  completed?: boolean;
  children?: ReactNode;
}

const ControlAccordion: FC<ControlAccordionProps> = ({
  title,
  isOpen,
  setIsOpen,
  toggle,
  disabled,
  completed,
  children,
}) => {
  const Icon = useMemo(() => (completed ? TickIcon : CircleCrossIcon), [completed]);
  const ChevronIcon = useMemo(() => (isOpen ? ChevronUpIcon : ChevronDownIcon), [isOpen]);

  return (
    <div className={classNames('control-accordion', { isOpen, disabled })}>
      <button onClick={() => setIsOpen(!isOpen)} type='button' className='control-accordion-header'>
        <div className='control-accordion-header__content'>
          <div
            className={classNames('control-accordion-header__icon', [completed ? 'green' : 'red'])}
          >
            <Icon width={24} height={24} />
          </div>

          <h4 className='control-accordion-header__title'>{title}</h4>

          {toggle && (
            <SwitchToggleField
              name={toggle}
              className='control-accordion-header__toggle'
              width={44}
              height={24}
              handleDiameter={20}
            />
          )}
        </div>

        <div className='control-accordion-header__chevron'>
          <ChevronIcon width={24} height={24} />
        </div>
      </button>

      <Collapse isOpen={isOpen}>
        <div className='control-accordion-body'>{children}</div>
      </Collapse>
    </div>
  );
};

export default ControlAccordion;
