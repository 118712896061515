import { gql } from '@apollo/client';

import SUBSCRIPTION_FRAGMENT from 'graphql/fragments/user/subscription';
import ANIMAL_TRANSFER from 'graphql/fragments/animals/animalTransfer';

const USER_FRAGMENT = gql`
  fragment userFragment on User {
    id
    email
    city
    country
    firstName
    lastName
    language
    showWelcomePage

    transfers {
      ...animalTransferFragment
    }

    breederProfile {
      bio
      email
      phone
      isPublic
    }
    ...subscriptionFragment
  }

  ${ANIMAL_TRANSFER}
  ${SUBSCRIPTION_FRAGMENT}
`;

export default USER_FRAGMENT;
