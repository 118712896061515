import { useParams } from 'react-router-dom';

import { useMemo } from 'react';

import {
  AnimalTransferFragmentFragment,
  AnimalTransferRequestDirection,
  TransferStatus,
  useAnimalTransferRequestsQuery,
} from 'generated/graphql';

type UseAnimalTransferRequests = () => {
  animalTransferRequests?: AnimalTransferFragmentFragment[];
  loading: boolean;
};

const useAnimalTransferRequests: UseAnimalTransferRequests = () => {
  const { animalId } = useParams<{ animalId: string }>();

  const variables = useMemo(
    () => ({
      where: {
        animalId,
        direction: animalId
          ? AnimalTransferRequestDirection.All
          : AnimalTransferRequestDirection.Incoming,
        status: !animalId ? TransferStatus.Pending : undefined,
      },
    }),
    [animalId],
  );

  const { data: { animalTransferRequests } = {}, loading } = useAnimalTransferRequestsQuery({
    variables,
    pollInterval: 15 * 1000,
  });

  return { animalTransferRequests, loading };
};

export default useAnimalTransferRequests;
