import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type Sanity_AgPost = Sanity_Document & {
  __typename?: 'AgPost';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
  authors?: Maybe<Array<Maybe<Sanity_AuthorReference>>>;
  bodyRaw?: Maybe<Scalars['JSON']>;
  categories?: Maybe<Array<Maybe<Sanity_Category>>>;
  excerptRaw?: Maybe<Scalars['JSON']>;
  mainImage?: Maybe<Sanity_MainImage>;
  /** This can be used to schedule post for publishing */
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Sanity_Slug>;
  /** Titles should be catchy, descriptive, and not too long */
  title?: Maybe<Scalars['String']>;
};

export type Sanity_AgPostFilter = {
  /** All documents that are equal to given value */
  _createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  _id?: InputMaybe<Scalars['ID']>;
  _id_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _id_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _id_not?: InputMaybe<Scalars['ID']>;
  _id_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _key?: InputMaybe<Scalars['String']>;
  _key_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _key_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _key_not?: InputMaybe<Scalars['String']>;
  _key_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _rev?: InputMaybe<Scalars['String']>;
  _rev_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _rev_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _rev_not?: InputMaybe<Scalars['String']>;
  _rev_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _type?: InputMaybe<Scalars['String']>;
  _type_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _type_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _type_not?: InputMaybe<Scalars['String']>;
  _type_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are drafts */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents that are equal to given value */
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  title?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  title_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  title_not?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
};

export type Sanity_Author = Sanity_Document & {
  __typename?: 'Author';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
  bioRaw?: Maybe<Scalars['JSON']>;
  image?: Maybe<Sanity_MainImage>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Sanity_Slug>;
};

export type Sanity_AuthorFilter = {
  /** All documents that are equal to given value */
  _createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  _id?: InputMaybe<Scalars['ID']>;
  _id_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _id_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _id_not?: InputMaybe<Scalars['ID']>;
  _id_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _key?: InputMaybe<Scalars['String']>;
  _key_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _key_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _key_not?: InputMaybe<Scalars['String']>;
  _key_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _rev?: InputMaybe<Scalars['String']>;
  _rev_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _rev_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _rev_not?: InputMaybe<Scalars['String']>;
  _rev_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _type?: InputMaybe<Scalars['String']>;
  _type_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _type_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _type_not?: InputMaybe<Scalars['String']>;
  _type_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are drafts */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents that are equal to given value */
  name?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  name_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  name_not?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
};

export type Sanity_AuthorReference = {
  __typename?: 'AuthorReference';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  author?: Maybe<Sanity_Author>;
};

export type Sanity_AuthorReferenceOrSpan = Sanity_AuthorReference | Sanity_Span;

export type Sanity_Block = {
  __typename?: 'Block';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<Sanity_Span>>>;
  list?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
};

export type Sanity_BlockOrMainImage = Sanity_Block | Sanity_MainImage;

export type Sanity_Category = Sanity_Document & {
  __typename?: 'Category';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Sanity_CategoryFilter = {
  /** All documents that are equal to given value */
  _createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  _id?: InputMaybe<Scalars['ID']>;
  _id_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _id_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _id_not?: InputMaybe<Scalars['ID']>;
  _id_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _key?: InputMaybe<Scalars['String']>;
  _key_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _key_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _key_not?: InputMaybe<Scalars['String']>;
  _key_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _rev?: InputMaybe<Scalars['String']>;
  _rev_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _rev_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _rev_not?: InputMaybe<Scalars['String']>;
  _rev_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _type?: InputMaybe<Scalars['String']>;
  _type_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _type_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _type_not?: InputMaybe<Scalars['String']>;
  _type_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are drafts */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents that are equal to given value */
  title?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  title_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  title_not?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
};

/** A Sanity document */
export type Sanity_Document = {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Sanity_Feature = {
  __typename?: 'Feature';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<Sanity_MainImageOrVideo>>>;
  /** Add a title to the section */
  title?: Maybe<Scalars['String']>;
};

export type Sanity_File = {
  __typename?: 'File';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  asset?: Maybe<Sanity_SanityFileAsset>;
};

export type Sanity_Geopoint = {
  __typename?: 'Geopoint';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  alt?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type Sanity_Image = {
  __typename?: 'Image';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  asset?: Maybe<Sanity_SanityImageAsset>;
  crop?: Maybe<Sanity_SanityImageCrop>;
  hotspot?: Maybe<Sanity_SanityImageHotspot>;
};

export type Sanity_MainImage = {
  __typename?: 'MainImage';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  /** Important for SEO and accessiblity. */
  alt?: Maybe<Scalars['String']>;
  asset?: Maybe<Sanity_SanityImageAsset>;
  caption?: Maybe<Scalars['String']>;
  crop?: Maybe<Sanity_SanityImageCrop>;
  hotspot?: Maybe<Sanity_SanityImageHotspot>;
};

export type Sanity_MainImageOrVideo = Sanity_MainImage | Sanity_Video;

export type Sanity_Post = Sanity_Document & {
  __typename?: 'Post';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
  authors?: Maybe<Array<Maybe<Sanity_AuthorReference>>>;
  bodyRaw?: Maybe<Scalars['JSON']>;
  categories?: Maybe<Array<Maybe<Sanity_Category>>>;
  excerptRaw?: Maybe<Scalars['JSON']>;
  mainImage?: Maybe<Sanity_MainImage>;
  /** This can be used to schedule post for publishing */
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Sanity_Slug>;
  /** Titles should be catchy, descriptive, and not too long */
  title?: Maybe<Scalars['String']>;
};

export type Sanity_PostFilter = {
  /** All documents that are equal to given value */
  _createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  _id?: InputMaybe<Scalars['ID']>;
  _id_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _id_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _id_not?: InputMaybe<Scalars['ID']>;
  _id_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _key?: InputMaybe<Scalars['String']>;
  _key_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _key_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _key_not?: InputMaybe<Scalars['String']>;
  _key_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _rev?: InputMaybe<Scalars['String']>;
  _rev_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _rev_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _rev_not?: InputMaybe<Scalars['String']>;
  _rev_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _type?: InputMaybe<Scalars['String']>;
  _type_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _type_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _type_not?: InputMaybe<Scalars['String']>;
  _type_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are drafts */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents that are equal to given value */
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  title?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  title_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  title_not?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
};

export type Sanity_ResourceToolkit = Sanity_Document & {
  __typename?: 'ResourceToolkit';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Sanity_File>;
  /** Document name */
  name?: Maybe<Scalars['String']>;
};

export type Sanity_ResourceToolkitFilter = {
  /** All documents that are equal to given value */
  _createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  _id?: InputMaybe<Scalars['ID']>;
  _id_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _id_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _id_not?: InputMaybe<Scalars['ID']>;
  _id_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _key?: InputMaybe<Scalars['String']>;
  _key_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _key_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _key_not?: InputMaybe<Scalars['String']>;
  _key_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _rev?: InputMaybe<Scalars['String']>;
  _rev_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _rev_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _rev_not?: InputMaybe<Scalars['String']>;
  _rev_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _type?: InputMaybe<Scalars['String']>;
  _type_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _type_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _type_not?: InputMaybe<Scalars['String']>;
  _type_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are drafts */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents that are equal to given value */
  name?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  name_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  name_not?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
};

export type Sanity_RootQuery = {
  __typename?: 'RootQuery';
  AgPost?: Maybe<Sanity_AgPost>;
  Author?: Maybe<Sanity_Author>;
  Category?: Maybe<Sanity_Category>;
  Post?: Maybe<Sanity_Post>;
  ResourceToolkit?: Maybe<Sanity_ResourceToolkit>;
  SanityFileAsset?: Maybe<Sanity_SanityFileAsset>;
  SanityImageAsset?: Maybe<Sanity_SanityImageAsset>;
  SiteSettings?: Maybe<Sanity_SiteSettings>;
  Subscriptions?: Maybe<Sanity_Subscriptions>;
  allAgPosts: Array<Sanity_AgPost>;
  allAuthors: Array<Sanity_Author>;
  allCategories: Array<Sanity_Category>;
  allPosts: Array<Sanity_Post>;
  allResourceToolkits: Array<Sanity_ResourceToolkit>;
  allSanityFileAssets: Array<Sanity_SanityFileAsset>;
  allSanityImageAssets: Array<Sanity_SanityImageAsset>;
  allSiteSettings: Array<Sanity_SiteSettings>;
  allSubscriptions: Array<Sanity_Subscriptions>;
};


export type Sanity_RootQueryAgPostArgs = {
  id: Scalars['ID'];
};


export type Sanity_RootQueryAuthorArgs = {
  id: Scalars['ID'];
};


export type Sanity_RootQueryCategoryArgs = {
  id: Scalars['ID'];
};


export type Sanity_RootQueryPostArgs = {
  id: Scalars['ID'];
};


export type Sanity_RootQueryResourceToolkitArgs = {
  id: Scalars['ID'];
};


export type Sanity_RootQuerySanityFileAssetArgs = {
  id: Scalars['ID'];
};


export type Sanity_RootQuerySanityImageAssetArgs = {
  id: Scalars['ID'];
};


export type Sanity_RootQuerySiteSettingsArgs = {
  id: Scalars['ID'];
};


export type Sanity_RootQuerySubscriptionsArgs = {
  id: Scalars['ID'];
};


export type Sanity_RootQueryAllAgPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Sanity_AgPostFilter>;
};


export type Sanity_RootQueryAllAuthorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Sanity_AuthorFilter>;
};


export type Sanity_RootQueryAllCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Sanity_CategoryFilter>;
};


export type Sanity_RootQueryAllPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Sanity_PostFilter>;
};


export type Sanity_RootQueryAllResourceToolkitsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Sanity_ResourceToolkitFilter>;
};


export type Sanity_RootQueryAllSanityFileAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Sanity_SanityFileAssetFilter>;
};


export type Sanity_RootQueryAllSanityImageAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Sanity_SanityImageAssetFilter>;
};


export type Sanity_RootQueryAllSiteSettingsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Sanity_SiteSettingsFilter>;
};


export type Sanity_RootQueryAllSubscriptionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Sanity_SubscriptionsFilter>;
};

export type Sanity_SanityAssetSourceData = {
  __typename?: 'SanityAssetSourceData';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  /** The unique ID for the asset within the originating source so you can programatically find back to it */
  id?: Maybe<Scalars['String']>;
  /** A canonical name for the source this asset is originating from */
  name?: Maybe<Scalars['String']>;
  /** A URL to find more information about this asset in the originating source */
  url?: Maybe<Scalars['String']>;
};

export type Sanity_SanityFileAsset = Sanity_Document & {
  __typename?: 'SanityFileAsset';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
  assetId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  sha1hash?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  source?: Maybe<Sanity_SanityAssetSourceData>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Sanity_SanityFileAssetFilter = {
  /** All documents that are equal to given value */
  _createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  _id?: InputMaybe<Scalars['ID']>;
  _id_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _id_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _id_not?: InputMaybe<Scalars['ID']>;
  _id_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _key?: InputMaybe<Scalars['String']>;
  _key_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _key_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _key_not?: InputMaybe<Scalars['String']>;
  _key_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _rev?: InputMaybe<Scalars['String']>;
  _rev_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _rev_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _rev_not?: InputMaybe<Scalars['String']>;
  _rev_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _type?: InputMaybe<Scalars['String']>;
  _type_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _type_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _type_not?: InputMaybe<Scalars['String']>;
  _type_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  assetId?: InputMaybe<Scalars['String']>;
  assetId_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  assetId_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  assetId_not?: InputMaybe<Scalars['String']>;
  assetId_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  description?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  description_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  description_not?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  extension?: InputMaybe<Scalars['String']>;
  extension_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  extension_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  extension_not?: InputMaybe<Scalars['String']>;
  extension_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are drafts */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents that are equal to given value */
  label?: InputMaybe<Scalars['String']>;
  label_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  label_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  label_not?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  mimeType?: InputMaybe<Scalars['String']>;
  mimeType_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  mimeType_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  mimeType_not?: InputMaybe<Scalars['String']>;
  mimeType_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  originalFilename?: InputMaybe<Scalars['String']>;
  originalFilename_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  originalFilename_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  originalFilename_not?: InputMaybe<Scalars['String']>;
  originalFilename_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  path?: InputMaybe<Scalars['String']>;
  path_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  path_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  path_not?: InputMaybe<Scalars['String']>;
  path_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  sha1hash?: InputMaybe<Scalars['String']>;
  sha1hash_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  sha1hash_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  sha1hash_not?: InputMaybe<Scalars['String']>;
  sha1hash_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  size?: InputMaybe<Scalars['Float']>;
  /** All documents are greater than given value */
  size_gt?: InputMaybe<Scalars['Float']>;
  /** All documents are greater than or equal to given value */
  size_gte?: InputMaybe<Scalars['Float']>;
  /** All documents are less than given value */
  size_lt?: InputMaybe<Scalars['Float']>;
  /** All documents are less than or equal to given value */
  size_lte?: InputMaybe<Scalars['Float']>;
  /** All documents that are not equal to given value */
  size_not?: InputMaybe<Scalars['Float']>;
  /** All documents that are equal to given value */
  title?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  title_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  title_not?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  url?: InputMaybe<Scalars['String']>;
  url_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  url_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  url_not?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<Scalars['String']>>;
};

export type Sanity_SanityImageAsset = Sanity_Document & {
  __typename?: 'SanityImageAsset';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
  assetId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  metadata?: Maybe<Sanity_SanityImageMetadata>;
  mimeType?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  sha1hash?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  source?: Maybe<Sanity_SanityAssetSourceData>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Sanity_SanityImageAssetFilter = {
  /** All documents that are equal to given value */
  _createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  _id?: InputMaybe<Scalars['ID']>;
  _id_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _id_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _id_not?: InputMaybe<Scalars['ID']>;
  _id_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _key?: InputMaybe<Scalars['String']>;
  _key_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _key_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _key_not?: InputMaybe<Scalars['String']>;
  _key_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _rev?: InputMaybe<Scalars['String']>;
  _rev_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _rev_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _rev_not?: InputMaybe<Scalars['String']>;
  _rev_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _type?: InputMaybe<Scalars['String']>;
  _type_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _type_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _type_not?: InputMaybe<Scalars['String']>;
  _type_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  assetId?: InputMaybe<Scalars['String']>;
  assetId_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  assetId_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  assetId_not?: InputMaybe<Scalars['String']>;
  assetId_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  description?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  description_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  description_not?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  extension?: InputMaybe<Scalars['String']>;
  extension_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  extension_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  extension_not?: InputMaybe<Scalars['String']>;
  extension_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are drafts */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents that are equal to given value */
  label?: InputMaybe<Scalars['String']>;
  label_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  label_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  label_not?: InputMaybe<Scalars['String']>;
  label_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  mimeType?: InputMaybe<Scalars['String']>;
  mimeType_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  mimeType_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  mimeType_not?: InputMaybe<Scalars['String']>;
  mimeType_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  originalFilename?: InputMaybe<Scalars['String']>;
  originalFilename_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  originalFilename_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  originalFilename_not?: InputMaybe<Scalars['String']>;
  originalFilename_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  path?: InputMaybe<Scalars['String']>;
  path_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  path_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  path_not?: InputMaybe<Scalars['String']>;
  path_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  sha1hash?: InputMaybe<Scalars['String']>;
  sha1hash_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  sha1hash_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  sha1hash_not?: InputMaybe<Scalars['String']>;
  sha1hash_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  size?: InputMaybe<Scalars['Float']>;
  /** All documents are greater than given value */
  size_gt?: InputMaybe<Scalars['Float']>;
  /** All documents are greater than or equal to given value */
  size_gte?: InputMaybe<Scalars['Float']>;
  /** All documents are less than given value */
  size_lt?: InputMaybe<Scalars['Float']>;
  /** All documents are less than or equal to given value */
  size_lte?: InputMaybe<Scalars['Float']>;
  /** All documents that are not equal to given value */
  size_not?: InputMaybe<Scalars['Float']>;
  /** All documents that are equal to given value */
  title?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  title_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  title_not?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  url?: InputMaybe<Scalars['String']>;
  url_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  url_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  url_not?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<Scalars['String']>>;
};

export type Sanity_SanityImageCrop = {
  __typename?: 'SanityImageCrop';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  bottom?: Maybe<Scalars['Float']>;
  left?: Maybe<Scalars['Float']>;
  right?: Maybe<Scalars['Float']>;
  top?: Maybe<Scalars['Float']>;
};

export type Sanity_SanityImageDimensions = {
  __typename?: 'SanityImageDimensions';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type Sanity_SanityImageHotspot = {
  __typename?: 'SanityImageHotspot';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export type Sanity_SanityImageMetadata = {
  __typename?: 'SanityImageMetadata';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Sanity_SanityImageDimensions>;
  hasAlpha?: Maybe<Scalars['Boolean']>;
  isOpaque?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Sanity_Geopoint>;
  lqip?: Maybe<Scalars['String']>;
  palette?: Maybe<Sanity_SanityImagePalette>;
};

export type Sanity_SanityImagePalette = {
  __typename?: 'SanityImagePalette';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  darkMuted?: Maybe<Sanity_SanityImagePaletteSwatch>;
  darkVibrant?: Maybe<Sanity_SanityImagePaletteSwatch>;
  dominant?: Maybe<Sanity_SanityImagePaletteSwatch>;
  lightMuted?: Maybe<Sanity_SanityImagePaletteSwatch>;
  lightVibrant?: Maybe<Sanity_SanityImagePaletteSwatch>;
  muted?: Maybe<Sanity_SanityImagePaletteSwatch>;
  vibrant?: Maybe<Sanity_SanityImagePaletteSwatch>;
};

export type Sanity_SanityImagePaletteSwatch = {
  __typename?: 'SanityImagePaletteSwatch';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  background?: Maybe<Scalars['String']>;
  foreground?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type Sanity_SiteSettings = Sanity_Document & {
  __typename?: 'SiteSettings';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
  /** Publish an author and set a reference to them here. */
  author?: Maybe<Sanity_Author>;
  /** Describe your blog for search engines and social media. */
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<Sanity_Feature>>>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  mainImage?: Maybe<Sanity_MainImage>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Sanity_SiteSettingsFilter = {
  /** All documents that are equal to given value */
  _createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  _id?: InputMaybe<Scalars['ID']>;
  _id_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _id_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _id_not?: InputMaybe<Scalars['ID']>;
  _id_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _key?: InputMaybe<Scalars['String']>;
  _key_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _key_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _key_not?: InputMaybe<Scalars['String']>;
  _key_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _rev?: InputMaybe<Scalars['String']>;
  _rev_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _rev_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _rev_not?: InputMaybe<Scalars['String']>;
  _rev_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _type?: InputMaybe<Scalars['String']>;
  _type_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _type_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _type_not?: InputMaybe<Scalars['String']>;
  _type_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  author?: InputMaybe<Scalars['ID']>;
  /** All documents that are drafts */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents that are equal to given value */
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  subtitle_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  title?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  title_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  title_not?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
};

export type Sanity_Slug = {
  __typename?: 'Slug';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['String']>;
};

export type Sanity_Span = {
  __typename?: 'Span';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  marks?: Maybe<Array<Maybe<Scalars['String']>>>;
  text?: Maybe<Scalars['String']>;
};

export type Sanity_Subscriptions = Sanity_Document & {
  __typename?: 'Subscriptions';
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']>;
  _key?: Maybe<Scalars['String']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']>;
  /** Document type */
  _type?: Maybe<Scalars['String']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']>;
  buttonAction?: Maybe<Scalars['String']>;
  buttonLabel?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
};

export type Sanity_SubscriptionsFilter = {
  /** All documents that are equal to given value */
  _createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  _id?: InputMaybe<Scalars['ID']>;
  _id_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _id_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _id_not?: InputMaybe<Scalars['ID']>;
  _id_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _key?: InputMaybe<Scalars['String']>;
  _key_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _key_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _key_not?: InputMaybe<Scalars['String']>;
  _key_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _rev?: InputMaybe<Scalars['String']>;
  _rev_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _rev_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _rev_not?: InputMaybe<Scalars['String']>;
  _rev_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _type?: InputMaybe<Scalars['String']>;
  _type_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  _type_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  _type_not?: InputMaybe<Scalars['String']>;
  _type_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  _updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than given value */
  _updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are greater than or equal to given value */
  _updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than given value */
  _updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All documents are less than or equal to given value */
  _updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are not equal to given value */
  _updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All documents that are equal to given value */
  buttonAction?: InputMaybe<Scalars['String']>;
  buttonAction_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  buttonAction_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  buttonAction_not?: InputMaybe<Scalars['String']>;
  buttonAction_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  buttonLabel?: InputMaybe<Scalars['String']>;
  buttonLabel_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  buttonLabel_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  buttonLabel_not?: InputMaybe<Scalars['String']>;
  buttonLabel_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are drafts */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** All documents that are equal to given value */
  name?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  name_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  name_not?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  position?: InputMaybe<Scalars['Float']>;
  /** All documents are greater than given value */
  position_gt?: InputMaybe<Scalars['Float']>;
  /** All documents are greater than or equal to given value */
  position_gte?: InputMaybe<Scalars['Float']>;
  /** All documents are less than given value */
  position_lt?: InputMaybe<Scalars['Float']>;
  /** All documents are less than or equal to given value */
  position_lte?: InputMaybe<Scalars['Float']>;
  /** All documents that are not equal to given value */
  position_not?: InputMaybe<Scalars['Float']>;
  /** All documents that are equal to given value */
  price?: InputMaybe<Scalars['String']>;
  price_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  price_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  price_not?: InputMaybe<Scalars['String']>;
  price_not_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents that are equal to given value */
  tagline?: InputMaybe<Scalars['String']>;
  tagline_in?: InputMaybe<Array<Scalars['String']>>;
  /** All documents contain (match) the given word/words */
  tagline_matches?: InputMaybe<Scalars['String']>;
  /** All documents that are not equal to given value */
  tagline_not?: InputMaybe<Scalars['String']>;
  tagline_not_in?: InputMaybe<Array<Scalars['String']>>;
};

export type Sanity_Video = {
  __typename?: 'Video';
  _key?: Maybe<Scalars['String']>;
  _type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Sanity_AllAgPostsQueryVariables = Exact<{ [key: string]: never; }>;


export type Sanity_AllAgPostsQuery = { __typename?: 'RootQuery', allAgPosts: Array<{ __typename?: 'AgPost', _id?: string | null, title?: string | null, publishedAt?: any | null, slug?: { __typename?: 'Slug', current?: string | null } | null, mainImage?: { __typename?: 'MainImage', asset?: { __typename?: 'SanityImageAsset', url?: string | null } | null } | null }> };

export type Sanity_AllResourceToolkitsQueryVariables = Exact<{ [key: string]: never; }>;


export type Sanity_AllResourceToolkitsQuery = { __typename?: 'RootQuery', allResourceToolkits: Array<{ __typename?: 'ResourceToolkit', _id?: string | null, name?: string | null, file?: { __typename?: 'File', asset?: { __typename?: 'SanityFileAsset', url?: string | null } | null } | null }> };


export const AllAgPostsDocument = gql`
    query allAgPosts @api(name: "sanity") {
  allAgPosts {
    _id
    title
    publishedAt
    slug {
      current
    }
    mainImage {
      asset {
        url
      }
    }
  }
}
    `;

/**
 * __useAllAgPostsQuery__
 *
 * To run a query within a React component, call `useAllAgPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAgPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAgPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAgPostsQuery(baseOptions?: Apollo.QueryHookOptions<Sanity_AllAgPostsQuery, Sanity_AllAgPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Sanity_AllAgPostsQuery, Sanity_AllAgPostsQueryVariables>(AllAgPostsDocument, options);
      }
export function useAllAgPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Sanity_AllAgPostsQuery, Sanity_AllAgPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Sanity_AllAgPostsQuery, Sanity_AllAgPostsQueryVariables>(AllAgPostsDocument, options);
        }
export type AllAgPostsQueryHookResult = ReturnType<typeof useAllAgPostsQuery>;
export type AllAgPostsLazyQueryHookResult = ReturnType<typeof useAllAgPostsLazyQuery>;
export type AllAgPostsQueryResult = Apollo.QueryResult<Sanity_AllAgPostsQuery, Sanity_AllAgPostsQueryVariables>;
export const AllResourceToolkitsDocument = gql`
    query allResourceToolkits @api(name: "sanity") {
  allResourceToolkits {
    _id
    name
    file {
      asset {
        url
      }
    }
  }
}
    `;

/**
 * __useAllResourceToolkitsQuery__
 *
 * To run a query within a React component, call `useAllResourceToolkitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllResourceToolkitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllResourceToolkitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllResourceToolkitsQuery(baseOptions?: Apollo.QueryHookOptions<Sanity_AllResourceToolkitsQuery, Sanity_AllResourceToolkitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Sanity_AllResourceToolkitsQuery, Sanity_AllResourceToolkitsQueryVariables>(AllResourceToolkitsDocument, options);
      }
export function useAllResourceToolkitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Sanity_AllResourceToolkitsQuery, Sanity_AllResourceToolkitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Sanity_AllResourceToolkitsQuery, Sanity_AllResourceToolkitsQueryVariables>(AllResourceToolkitsDocument, options);
        }
export type AllResourceToolkitsQueryHookResult = ReturnType<typeof useAllResourceToolkitsQuery>;
export type AllResourceToolkitsLazyQueryHookResult = ReturnType<typeof useAllResourceToolkitsLazyQuery>;
export type AllResourceToolkitsQueryResult = Apollo.QueryResult<Sanity_AllResourceToolkitsQuery, Sanity_AllResourceToolkitsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AuthorReferenceOrSpan": [
      "AuthorReference",
      "Span"
    ],
    "BlockOrMainImage": [
      "Block",
      "MainImage"
    ],
    "Document": [
      "AgPost",
      "Author",
      "Category",
      "Post",
      "ResourceToolkit",
      "SanityFileAsset",
      "SanityImageAsset",
      "SiteSettings",
      "Subscriptions"
    ],
    "MainImageOrVideo": [
      "MainImage",
      "Video"
    ]
  }
};
      export default result;
    