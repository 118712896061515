import { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';

import Modal from 'components/common/Modal';

import UploadImageModalBody from 'components/common/Image/UploadImageModalBody';
import { CropperProps } from 'react-easy-crop';

interface UploadImageModalProps {
  show: boolean;
  toggle: () => void;
  aspect?: number;
  handleUpload?: (imageBlob: Blob) => void;
  cropperProps?: CropperProps;
  className?: string;
  withoutMaxSide?: boolean;
  imageSize?: {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
  };
}

const UploadImageModal: FC<UploadImageModalProps> = ({
  show,
  toggle,
  aspect,
  handleUpload,
  cropperProps,
  className,
  withoutMaxSide,
  imageSize,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      showCloseButton
      className={className}
      autoFocus={false}
      show={show}
      title={t('common.uploadImage')}
      onClose={toggle}
    >
      <UploadImageModalBody
        aspect={aspect}
        toggle={toggle}
        handleUpload={handleUpload}
        cropperProps={cropperProps}
        withoutMaxSide={withoutMaxSide}
        imageSize={imageSize}
      />
    </Modal>
  );
};

export default memo(UploadImageModal);
