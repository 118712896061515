import React, { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { MatingOrderField, OrderDirection, useMatingRecordsQuery } from 'generated/graphql';

import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import Loading from 'components/common/Loading';
import EmptyMessage from 'components/common/EmptyMessage';
import UpgradeSection from 'components/common/UpgradeSection';

import useCurrentTariff from 'hooks/user/useCurrentTariff';

import { ROWS_PER_PAGE_OPTIONS } from 'helpers/constants';

import MatingRecordsTable from '../components/tables/MatingRecordsTable';
import CreateMatingRecordButton from '../components/buttons/CreateMatingRecordButton';

const MatingRecordsPage: FC = () => {
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(parseInt(ROWS_PER_PAGE_OPTIONS[0].value, 10));
  const [currentPage, setCurrentPage] = useState(0);

  const { loading, data } = useMatingRecordsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      orderBy: [{ field: MatingOrderField.CreatedAt, direction: OrderDirection.Desc }],
      skip: rowsPerPage * currentPage,
      take: rowsPerPage,
    },
  });

  const { nodes = [], totalCount } = data?.matings || {};

  /* Don't show the page if user doesn't have "Silver" subscription */
  const { isSilver } = useCurrentTariff();

  if (!isSilver) {
    return (
      <div className='d-flex h-100 w-100 align-items-center justify-content-center'>
        <UpgradeSection
          title={t('mating.upgrade.title')}
          description={t('mating.upgrade.description')}
        />
      </div>
    );
  }

  /* Show the page with preloaders */
  if (loading) {
    return <Loading page />;
  }

  if (!nodes.length) {
    return (
      <EmptyMessage
        message={t('mating.record.empty.title')}
        hint={t('mating.record.empty.multiple')}
      >
        <CreateMatingRecordButton className='empty' />
      </EmptyMessage>
    );
  }

  return (
    <React.Fragment>
      <HelmetWithTemplate title='Matings' />

      <div className='page mating'>
        <div className='header'>
          <h1 className='heading'>{t('mating.link')}</h1>

          <CreateMatingRecordButton />
        </div>

        <div className='page-body'>
          <MatingRecordsTable
            matingRecords={nodes}
            loading={loading}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MatingRecordsPage;
