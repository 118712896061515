import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { MatingEventFragment } from 'generated/graphql';

import Button from 'components/common/buttons/Button';

import MatingEventModal from '../modals/MatingEventModal';

interface CreateMatingEventButtonProps {
  className?: string;

  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;

  currentMatingEvent: MatingEventFragment | undefined;
  setCurrentMatingEvent: Dispatch<SetStateAction<MatingEventFragment | undefined>>;

  matingId: string;
}

const CreateMatingEventButton: FC<CreateMatingEventButtonProps> = ({
  className,

  showModal,
  setShowModal,

  currentMatingEvent,
  setCurrentMatingEvent,

  matingId,
}) => {
  const { t } = useTranslation();

  const open = useCallback(() => {
    setCurrentMatingEvent(undefined);
    setShowModal(true);
  }, [setCurrentMatingEvent, setShowModal]);

  const toggle = useCallback(() => setShowModal(prev => !prev), [setShowModal]);

  return (
    <React.Fragment>
      <div className='flex justify-content-end'>
        <Button className={classNames('create-btn', className)} color='primary' onClick={open}>
          <span className='create-btn-text'>{t('mating.event.create')}</span>
        </Button>
      </div>

      <MatingEventModal
        show={showModal}
        toggle={toggle}
        matingId={matingId}
        matingEvent={currentMatingEvent}
      />
    </React.Fragment>
  );
};

export default CreateMatingEventButton;
