import { FC, ContextType, WheelEvent, MouseEvent, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import ImageCard from 'components/library/ImageCard';
import { LeftArrow, RightArrow } from 'components/library/Arrows';

import useLocalStorageFromProvider from 'hooks/useLocalStorageFromProvider';

import useDrag from 'hooks/useDrag';
import useFormat from 'hooks/useFormat';

import app from 'helpers/app';

import { Sanity_AgPost } from 'generated/sanity_graphql';

type scrollVisibilityApiType = ContextType<typeof VisibilityContext>;

const BlogMenu: FC<{ blogs: Sanity_AgPost[] }> = ({ blogs }) => {
  const { t } = useTranslation();

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const { formatDate } = useFormat();

  const { blogs: blogsLs, setBlogs, removeBlogs } = useLocalStorageFromProvider();

  const hasVisited = useMemo(() => {
    if (blogsLs.length) {
      return blogsLs.filter(({ isNew }: { isNew: string }) => !isNew);
    }

    return false;
  }, [blogsLs]);

  const sortedBlog = useMemo(() => {
    return blogs && sortBy(blogs, ({ publishedAt }) => publishedAt).reverse();
  }, [blogs]);

  useEffect(() => {
    if (blogs.length !== blogsLs.length && hasVisited) {
      const formatedBlogs = blogs.map(({ _id }) => ({ id: _id, isNew: true }));

      const uniqueArray = [
        ...new Map([...formatedBlogs, ...blogsLs].map(item => [item.id, item])).values(),
      ];

      setTimeout(() => setBlogs(() => uniqueArray), 0);
    }
  }, [blogs, blogsLs, hasVisited, setBlogs]);

  const blogStatuses: { id: string; isNew: boolean }[] = [];

  /* eslint-disable */
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });
  /* eslint-enable */

  const onWheel = (apiObj: scrollVisibilityApiType, e: WheelEvent): void => {
    const isTouchpad = Math.abs(e.deltaX) !== 0 || Math.abs(e.deltaY) < 15;

    if (isTouchpad) {
      e.stopPropagation();

      return;
    }

    if (e.deltaY < 0) {
      apiObj.scrollNext();
    } else if (e.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const handleItemClick = (publishedAt: string, current: string, _id: string) => () => {
    if (dragging) {
      return false;
    }

    const configurePostUrl = formatDate(new Date(publishedAt), 'yyyy/LL');

    if (blogsLs?.length) {
      const updatedBlog = blogsLs.map(({ id, isNew }: { id: string; isNew: boolean }) => {
        if (id === _id) {
          return { id, isNew: false };
        }

        return { id, isNew };
      });

      removeBlogs();
      setBlogs(() => updatedBlog);
    }

    window.open(
      `${app.landingUrl}/blog/${configurePostUrl}/${current}/`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  return (
    <div className='blog-menu' onMouseLeave={dragStop}>
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onWheel={onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
      >
        {blogs.length ? (
          sortedBlog?.map(({ _id, publishedAt, slug, mainImage, title }) => {
            if (!blogsLs.length) {
              blogStatuses.push({ id: _id || '', isNew: true });
              setTimeout(() => setBlogs(() => blogStatuses), 0);
            }

            return (
              /* eslint-disable */
                <ImageCard
                  key={_id}
                  id={_id || ''}
                  image={mainImage?.asset?.url || ''}
                  title={title || ''}
                  date={publishedAt}
                  onClick={handleItemClick(publishedAt, slug?.current || '', _id || '')}
                  badgeNew={
                    blogsLs.length
                      ? blogsLs.find(
                          ({ id, isNew }: { id: string; isNew: string }) => id === _id && isNew,
                        )
                      : false
                  }
                />
                /* eslint-enable */
            );
          })
        ) : (
          <span>
            {t('common.notFound', {
              context: 'many',
            })}
          </span>
        )}
      </ScrollMenu>
    </div>
  );
};

export default BlogMenu;
