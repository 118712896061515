import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';

import {
  CreateMedicationsInput,
  CreateMedicationsMutation,
  MedicationOrderField,
  OrderDirection,
  useCreateMedicationsMutation,
} from 'generated/graphql';
import MEDICATION_REMINDERS_QUERY from 'graphql/queries/animals/medications/medicationReminders';

type CreateMedications = (
  values: CreateMedicationsInput,
) => Promise<FetchResult<CreateMedicationsMutation>>;

type UseCreateMedications = () => { createMedications: CreateMedications; loading: boolean };

const useCreateMedications: UseCreateMedications = () => {
  const [createMedicationsRaw, { loading }] = useCreateMedicationsMutation();

  const createMedications = useCallback<CreateMedications>(
    values =>
      createMedicationsRaw({
        variables: { data: { ...values } },
        refetchQueries: [
          'medications',
          {
            query: MEDICATION_REMINDERS_QUERY,
            variables: {
              orderBy: [
                {
                  field: MedicationOrderField.Date,
                  direction: OrderDirection.Asc,
                },
              ],
              where: {
                isUpcoming: true,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      }),
    [createMedicationsRaw],
  );

  return { createMedications, loading };
};

export default useCreateMedications;
