import { FC } from 'react';

import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';

import useAchievements from 'hooks/user/achievements/useAchievements';
import Tooltip from 'components/common/Tooltip';

import thumbUpImage from 'images/thumb-up.png';

import { AchievementsFragmentFragment } from 'generated/graphql';

const hasTwoYearAchievement = (
  currentAchievement: AchievementsFragmentFragment['name'],
  achievements: AchievementsFragmentFragment[],
): boolean | undefined => {
  if (
    currentAchievement &&
    achievements.find(achievement => achievement?.name === 'Two Year Membership')?.isEarned
  ) {
    return true;
  }
};

const hasActiveAchievements = (achievements: AchievementsFragmentFragment[] | null): boolean => {
  return !!achievements?.find(achievement => achievement.isEarned);
};

const Achievements: FC = () => {
  const { t } = useTranslation();

  const achievements = useAchievements();

  return hasActiveAchievements(achievements) ? (
    <div className='form achievements-list'>
      <h6 className='form-heading'>{t('achievements.achievements')}</h6>

      <Row className='achievement-items' noGutters>
        {achievements?.map(achievement => {
          if (achievement?.name === 'One Year Membership') {
            if (hasTwoYearAchievement(achievement.name, achievements)) {
              return null;
            }
          }

          return (
            achievement.isEarned && (
              <Col className='achievement-item' key={achievement.id} md={2}>
                <Tooltip
                  id={achievement.id}
                  content={t(`achievements.${camelCase(achievement.name)}.name`)}
                  place='bottom'
                >
                  <div className='image-container'>
                    <img src={achievement.imageUrl || thumbUpImage} alt='Thumb-up achievements' />
                  </div>
                </Tooltip>
              </Col>
            )
          );
        })}
      </Row>
    </div>
  ) : null;
};

export default Achievements;
