import useDeleteUser from './useDeleteUser';
import useLogout from './useLogout';

const useDeleteAccount = () => {
  const { deleteUser } = useDeleteUser();
  const { logout } = useLogout();

  const deleteAccount = (): void => {
    void deleteUser()?.then(() => logout());
  };

  return { deleteAccount };
};

export default useDeleteAccount;
