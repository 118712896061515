import { useContext, ReactNode } from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Arrow = ({
  children,
  disabled,
  onClick,
  direction,
}: {
  children: ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  direction: 'left' | 'right';
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        opacity: disabled ? '0' : '1',
        userSelect: 'none',
        right: direction === 'right' ? '1%' : undefined,
        left: direction === 'left' ? '2%' : undefined,
        border: 'none',
        background: 'none',
        position: 'absolute',
        height: '195px',
        zIndex: 999,
      }}
    >
      {children}
    </button>
  );
};

export const LeftArrow = (): JSX.Element => {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()} direction='left'>
      <ChevronIcon stroke='white' style={{ height: 40, width: 40, transform: 'rotate(90deg)' }} />
    </Arrow>
  );
};

export const RightArrow = (): JSX.Element => {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <Arrow
      disabled={isLastItemVisible}
      onClick={() => {
        scrollNext();
      }}
      direction='right'
    >
      <ChevronIcon stroke='white' style={{ height: 40, width: 40, transform: 'rotate(270deg)' }} />
    </Arrow>
  );
};
