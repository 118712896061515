/* eslint-disable @typescript-eslint/no-shadow */
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';

import validationSchema from 'components/animals/createAnimal/validation';
import Input from 'components/common/form/Input';
import Button from 'components/common/buttons/Button';
import HerdSelectField from 'components/common/form/select/herd/HerdSelectField';

import useCreateAnimal from 'hooks/animals/animal/useCreateAnimal';

import app from 'helpers/app';

import { AnimalRegistryType } from 'generated/graphql';
import { FormGroup, Label } from 'reactstrap';

interface Values {
  herdName: string;
  herdId: string;
  isOwner: string;
}

type Props = {
  animal?: any;
  animalName?: string;
  herdId: string;
  closeModal: () => void;
  pedigreeType: string;
  setHerdIdCallback: any;
  setSelectorValue: any;
  message?: string;
  allowChangeHerd?: boolean;
};

type OnSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => void;

type CreateAnimalHandler = (
  values: {
    name: string;
    registry?: AnimalRegistryType;
    registryHerdName?: string;
  },
  herdId?: string,
) => void;

const CreateAlpacaForm: FC<Props> = ({
  animal,
  animalName,
  herdId,
  closeModal,
  message,
  pedigreeType,
  setHerdIdCallback,
  setSelectorValue,
  allowChangeHerd = false,
}) => {
  const { t } = useTranslation();

  const { createAnimal } = useCreateAnimal();

  const createAnimalHandler: CreateAnimalHandler = (...args) => {
    void toast.promise(createAnimal(...args), {
      loading: toast.success(t('common.creating')),
      success: ({ data }) => {
        closeModal();

        setHerdIdCallback({ label: animal.name || animalName, value: data?.createAnimal.id });
        setSelectorValue(`${pedigreeType.toLowerCase()}Id`, data?.createAnimal.id);

        return t('herds.createAnimal_alpaca');
      },
      error: t('common.errorSaving'),
    });
  };

  const onSubmit: OnSubmit = ({ herdName, isOwner, herdId }) => {
    if (isOwner === 'true') {
      createAnimalHandler({ name: animal.name || animalName, ...animal }, herdId);
    } else {
      createAnimalHandler({
        name: animal.name || animalName,
        registry: AnimalRegistryType.AlpacaGuild,
        registryHerdName: herdName,
        ...animal,
      });
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      herdName: '',
      herdId,
      isOwner: 'true',
    },
    onSubmit: (...args) => {
      void onSubmit(...args);
    },
    validateOnMount: false,
    validateOnBlur: false,
  });

  const {
    values: { herdName, isOwner },
    handleSubmit,
    handleChange,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <div className='body-message'>
          {message ||
            t('animals.createPedigree', {
              parentType: pedigreeType,
              animalName,
            })}
        </div>

        <FormGroup className='form-group-radio'>
          <FormGroup className='form-group-item'>
            <Label className='form-label'>
              <Input
                className='form-check-input'
                id='isOwner'
                name='isOwner'
                type='radio'
                value='true'
                checked={isOwner === 'true'}
                onChange={handleChange}
              />
              {t('common.yes')}
            </Label>
          </FormGroup>

          <FormGroup className='form-group-item'>
            <Label className='form-label'>
              <Input
                className='form-check-input'
                id='notOwner'
                name='isOwner'
                type='radio'
                value='false'
                checked={isOwner === 'false'}
                onChange={handleChange}
              />
              {t('common.no')}
            </Label>
          </FormGroup>
        </FormGroup>

        {isOwner === 'false' && (
          <Input
            autoFocus
            name='herdName'
            label={t('herds.herdName', { context: app.appName })}
            placeholder=''
            value={herdName}
          />
        )}

        {isOwner !== 'false' && allowChangeHerd && <HerdSelectField />}

        <div className='button-container'>
          <Button type='submit' color='primary'>
            {t('common.create')}
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default CreateAlpacaForm;
