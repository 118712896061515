import { createContext, FC, useMemo } from 'react';

import CustomModal from 'components/common/CustomModal';

import useModal from 'hooks/modal/useModal';

export interface ModalContextValue {
  toggleModal: (
    body?: JSX.Element,
    className?: string,
    bodyClassName?: string,
    modalHeaderClassName?: string,
  ) => void;
  showModal: boolean;
  modalBody?: JSX.Element;
  modalClassName?: string;
  modalBodyClassName?: string;
  modalHeaderClassName?: string;
}

export const ModalContext = createContext<ModalContextValue>({
  showModal: false,
  toggleModal: () => {},
  modalBody: undefined,
  modalClassName: '',
  modalBodyClassName: '',
  modalHeaderClassName: '',
});

const ModalProvider: FC = ({ children }) => {
  const {
    showModal,
    toggleModal,
    modalBody,
    modalClassName,
    modalBodyClassName,
    modalHeaderClassName,
  } = useModal();

  const value = useMemo(
    () => ({
      showModal,
      toggleModal,
      modalBody,
      modalClassName,
      modalBodyClassName,
      modalHeaderClassName,
    }),
    [modalBody, modalBodyClassName, modalClassName, modalHeaderClassName, showModal, toggleModal],
  );

  return (
    <ModalContext.Provider value={value}>
      <CustomModal
        show={showModal}
        toggle={toggleModal}
        modalBody={modalBody}
        className={modalClassName}
        bodyClassName={modalBodyClassName}
        customHeaderClassName={modalHeaderClassName}
      />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
