import { FC, Fragment, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import Header from 'components/common/Header';
import ButtonGoBack from 'components/common/buttons/ButtonGoBack';
import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';

import MatingRecordForm from '../components/forms/MatingRecordForm';

const CreateMatingRecordPage: FC = () => {
  const { t } = useTranslation();

  const heading = useMemo(() => t('mating.record.create'), [t]);

  return (
    <Fragment>
      <HelmetWithTemplate title={heading} />

      <div className='page create-mating-page'>
        <Header text={heading}>
          <ButtonGoBack color='light' backPath='/matings' />
        </Header>

        <div className='page-body'>
          <MatingRecordForm />
        </div>
      </div>
    </Fragment>
  );
};

export default withErrorBoundary(CreateMatingRecordPage);
