import { FC, useCallback, useState, Fragment } from 'react';

import { useFormik, FormikProvider, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import * as yup from 'yup';

import Button from 'components/common/buttons/Button';
import Input from 'components/common/form/Input';

import { email } from 'components/auth/validation';

import useGATracker from 'hooks/useGATracker';

import { useForgotPasswordMutation } from 'generated/graphql';

const ForgotPasswordForm: FC = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [forgotPassword, { loading }] = useForgotPasswordMutation();
  const { push } = useHistory();

  const { customEvent } = useGATracker();

  const onSubmit = useCallback(
    async data => {
      try {
        const isOk = await forgotPassword({ variables: { data } });

        customEvent('method');

        setIsChecked(!!isOk);
      } catch (err) {}
    },
    [customEvent, forgotPassword],
  );

  const handleClick = useCallback(() => push('/sign-in'), [push]);

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object().shape({
      email,
    }),
    onSubmit,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const { handleSubmit } = formik;

  return (
    <Fragment>
      <FormikProvider value={formik}>
        <Form className='auth-form' id='recovery-form' onSubmit={handleSubmit} noValidate>
          <h2 className='auth-form-heading' id='recovery-form-heading'>
            {isChecked ? t('auth.checkEmail') : t('auth.forgotYourPassword')}
          </h2>
          <p className='recovery-text'>
            {isChecked ? t('auth.checkInstruction') : t('auth.recoveryMessage')}
          </p>
          {!isChecked && (
            <Input
              name='email'
              label='Email'
              labelClassName='custom-label'
              placeholder='john@mail.com'
              type='email'
              autoFocus
            />
          )}
          {isChecked ? (
            <Button
              color='primary'
              outline
              className='custom-submit'
              style={{ marginTop: 0 }}
              onClick={handleClick}
            >
              {t('common.okay')}
            </Button>
          ) : (
            <Button
              type='submit'
              color='primary'
              outline
              className='custom-submit'
              loading={loading}
            >
              {t('auth.resetPassword')}
            </Button>
          )}
        </Form>
      </FormikProvider>

      {!isChecked && (
        <div className='actions'>
          <div className='action'>
            <Link className='link' to='sign-in'>
              {t('common.goBack')}
            </Link>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ForgotPasswordForm;
