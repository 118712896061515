import { FC, createContext } from 'react';

export interface LocalStageContextValue {
  [key: string]: any;
}

export const LocalStageContext = createContext<LocalStageContextValue>({});

const LocalStageProvider: FC<LocalStageContextValue> = ({ children, value }) => {
  return <LocalStageContext.Provider value={value}>{children}</LocalStageContext.Provider>;
};

export default LocalStageProvider;
