import { useMemo } from 'react';

import { usePreviousDistinct } from 'react-use';

import {
  Maybe,
  NationalHerdAnimalFragment,
  useNationalHerdListQuery,
  WhereNationalHerdListInput,
} from 'generated/graphql';

type UseNationalHerdsList = (props: {
  skip?: Maybe<number>;
  take?: Maybe<number>;
  where: WhereNationalHerdListInput;
}) => {
  loading: boolean;
  nationalHerdList?: NationalHerdAnimalFragment[];
  totalCount?: number;
};

const useNationalHerdsList: UseNationalHerdsList = props => {
  const variables = useMemo(() => props, [props]);

  const {
    data: {
      nationalHerdList: { nodes: nationalHerdList = undefined, totalCount = undefined } = {},
    } = {},
    loading,
  } = useNationalHerdListQuery({
    variables,
  });

  const prevTotalCount = usePreviousDistinct(totalCount);

  return { loading, nationalHerdList, totalCount: totalCount || prevTotalCount };
};

export default useNationalHerdsList;
