/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FC, useMemo } from 'react';

import toast from 'react-hot-toast';
import { Form, FormikProvider, useFormik } from 'formik';

import * as yup from 'yup';

import useConfirm from 'hooks/useConfirm';

import MATING_EVENTS_QUERY from 'graphql/queries/mating/event/matingEvents';

import {
  MatingEventFragment,
  MatingEventLocation,
  MatingEventOrderField,
  MatingEventSetting,
  OrderDirection,
  useCreateMatingEventMutation,
  useDeleteMatingEventMutation,
  useUpdateMatingEventMutation,
} from 'generated/graphql';
import i18n from 'startup/i18next';

import Button from 'components/common/buttons/Button';

import MatingEventDateInput from './date/MatingEventDateInput';
import MatingEventLocationInput from './location/MatingEventLocationInput';
import MatingEventSettingInput from './setting/MatingEventSettingInput';
import MatingEventSpitOffInput, { MatingEventSpitOff } from './spitOff/MatingEventSpitOffInput';
import { formatEventSpitOff, formatEventSpitOffReverse } from '../../utils/formatEventSpitOff';

const t = i18n.getFixedT(i18n.language);

const validationSchema = yup.object().shape({
  date: yup.string().required(t('mating.event.validation.date')),
  location: yup.string().required(t('mating.event.validation.location')),
  setting: yup.string().required(t('mating.event.validation.setting')),
  spitOff: yup.string().required(t('mating.event.validation.spitOff')),
});

export interface MatingEventFormValues {
  date: Date;
  location: MatingEventLocation;
  setting: MatingEventSetting;
  spitOff: MatingEventSpitOff;
}

interface MatingEventFormProps {
  matingId: string;
  matingEvent?: MatingEventFragment;
  toggle?: () => void;
}

const MatingEventForm: FC<MatingEventFormProps> = ({ matingId, matingEvent, toggle }) => {
  const mutationOptions = useMemo(() => {
    return {
      refetchQueries: [
        {
          query: MATING_EVENTS_QUERY,
          variables: {
            orderBy: [{ field: MatingEventOrderField.Date, direction: OrderDirection.Asc }],
            where: { matingId },
          },
        },
      ],
    };
  }, [matingId]);

  /* Delete Event on button click */
  const confirm = useConfirm({
    title: t('mating.event.form.confirm.title'),
    description: t('mating.event.form.confirm.description'),
  });

  const [deleteMatingEvent, { loading: deleteLoading }] =
    useDeleteMatingEventMutation(mutationOptions);

  const handleDeleteMatingEvent = async () => {
    if (matingEvent?.id) {
      if (await confirm()) {
        await deleteMatingEvent({ variables: { where: { id: matingEvent.id } } });

        toast.success(t('mating.event.form.toast.delete'));
      }
    }

    toggle?.();
  };

  /* Create\Update Event on submit */
  const [createMatingEvent, { loading: createLoading }] =
    useCreateMatingEventMutation(mutationOptions);

  const [updateMatingEvent, { loading: updateLoading }] =
    useUpdateMatingEventMutation(mutationOptions);

  const onSubmit = async (data: MatingEventFormValues) => {
    try {
      const parsedData = {
        ...data,
        spitOff: formatEventSpitOff(data.spitOff),
        matingId,
      };

      if (matingEvent?.id) {
        await updateMatingEvent({ variables: { data: parsedData, where: { id: matingEvent.id } } });

        toast.success(t('mating.event.form.toast.update'));
      } else {
        await createMatingEvent({ variables: { data: parsedData } });

        toast.success(t('mating.event.form.toast.create'));
      }

      toggle?.();
    } catch (err) {
      console.error(err);
    }
  };

  /* Initialize Form */
  const formik = useFormik<MatingEventFormValues>({
    initialValues: {
      date: matingEvent?.date || '',
      location: matingEvent?.location || MatingEventLocation.MyFarm,
      setting: matingEvent?.setting || MatingEventSetting.Paddock,
      spitOff: formatEventSpitOffReverse(matingEvent?.spitOff) || MatingEventSpitOff.Unknown,
    },
    validateOnMount: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit,
  });

  const { handleSubmit } = formik;

  const isDisabled = createLoading || updateLoading || deleteLoading;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <MatingEventDateInput />
        <MatingEventLocationInput />
        <MatingEventSettingInput />
        <MatingEventSpitOffInput />

        <div className='button-group'>
          {matingEvent?.id && (
            <Button
              className='btn btn-secondary'
              color='light'
              disabled={isDisabled}
              onClick={handleDeleteMatingEvent}
            >
              {t('mating.event.form.delete')}
            </Button>
          )}

          <Button className='ml-auto' type='submit' color='primary' disabled={isDisabled}>
            {t('common.save')}
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default MatingEventForm;
