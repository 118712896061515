import { FC, useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { Capacitor } from '@capacitor/core';

import WelcomeShowCheckbox from 'components/welcome/WelcomeShowCheckbox';

import useCheckSubscribed from 'hooks/user/useCheckSubscribed';
import useUser from 'hooks/user/useUser';
import useSubscriptionPlan from 'hooks/user/useSubscrtiptionPlan';

import { ReactComponent as WelcomeImage } from 'images/welcome.svg';

const Welcome: FC<{ title: string }> = ({ title }) => {
  const user = useUser();
  const isSubscribed = useCheckSubscribed();

  const currentPlan = useSubscriptionPlan();

  const isBronze = useMemo(() => currentPlan === 'Bronze', [currentPlan]);

  const { t } = useTranslation();

  const { lastName, firstName, city, country } = user || {};

  const isNativePlatform = Capacitor.isNativePlatform();

  const membership = useMemo(
    () => ({
      notComplete: (
        <Trans i18nKey='subscription.notComplete'>
          Please complete
          <Link to='settings/personal-profile' className='profile-link'>
            to unlock the features that are right for you.
          </Link>
        </Trans>
      ),
      free: t('subscription.free'),
      bronze: t('subscription.bronze'),
      silver: t('subscription.silver'),
    }),
    [t],
  );

  const isNotComplete = useMemo(() => {
    return [lastName, firstName, city, country].some(field => field === '' || field === null);
  }, [city, country, firstName, lastName]);

  return (
    <div className='page welcome-page'>
      <div className='page-body'>
        <div className='page-content'>
          <div className='main-content'>
            <WelcomeImage />

            <h1 className='text-center title-content'>
              {title}
              <br />
              {!isNotComplete && firstName}
            </h1>

            <p className='text-center'>{t('subscription.alpacaGuild')}</p>

            <p className='text-center'>
              {isNotComplete && !isNativePlatform && membership.notComplete}
              {!isSubscribed && !isNativePlatform && membership.free}
              {isSubscribed && !isNotComplete && isBronze && membership.bronze}
              {isSubscribed && !isNotComplete && !isBronze && membership.silver}
            </p>
          </div>

          <div className='bottom-content'>
            <WelcomeShowCheckbox
              text={t('profile.dontShowWelcome')}
              id='checkbox-welcome-page'
              isReverse
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
