import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect } from 'react';

interface AutoSaveProps {
  debounceMs: number;
}

const AutoSave: FC<AutoSaveProps> = ({ debounceMs }) => {
  const formik = useFormikContext();

  const debouncedSubmit = useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [debounceMs, formik.submitForm],
  );

  useEffect(() => {
    if (formik.dirty) {
      void debouncedSubmit();
    }
  }, [debouncedSubmit, formik.dirty, formik.values]);

  return null;
};

export default AutoSave;
