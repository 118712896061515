import { FC } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import ImageUploader from './ImageUploader';

interface ImageUploaderFieldProps {
  name: string;
  disabled?: boolean;
}

const ImageUploaderField: FC<ImageUploaderFieldProps> = ({ name, ...props }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <ImageUploader
          {...field}
          {...props}
          value={field.value}
          onChange={file => setFieldValue(name, file)}
        />
      )}
    </Field>
  );
};

export default ImageUploaderField;
