import { FC, useCallback } from 'react';

import ReactFlow, { useReactFlow, Edge, Node, NodeTypes, EdgeTypes, Controls } from 'reactflow';

type Flow = {
  nodes?: Node[];
  edges?: Edge[];
  nodeTypes?: NodeTypes;
  edgeTypes?: EdgeTypes;
};

const Flow: FC<Flow> = ({ nodes, edges, nodeTypes, edgeTypes }) => {
  const reactFlowInstance = useReactFlow();

  const setCenterOnClick = useCallback(
    (node: Node): void => {
      reactFlowInstance.setCenter(node.position.x + 100, node.position.y + 140, {
        zoom: 2,
        duration: 200,
      });
    },
    [reactFlowInstance],
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      fitView
      nodesDraggable={false}
      nodesConnectable={false}
      onNodeClick={(_, node) => setCenterOnClick(node)}
      defaultViewport={{ x: 0, y: 0, zoom: 1 }}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
    >
      <Controls showInteractive={false} />
    </ReactFlow>
  );
};

export default Flow;
