import { gql } from '@apollo/client';

import ANIMAL_FRAGMENT from 'graphql/fragments/animals/animal';

const ANIMALS_QUERY = gql`
  query animals($where: WhereAnimalInput!, $take: Int, $skip: Int) {
    animals(where: $where, take: $take, skip: $skip) {
      nodes {
        ...animalFragment
      }
      totalCount
    }
  }

  ${ANIMAL_FRAGMENT}
`;

export default ANIMALS_QUERY;
