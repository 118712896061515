import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';

import {
  DeleteMedicationMutation,
  MedicationOrderField,
  OrderDirection,
  useDeleteMedicationMutation,
} from 'generated/graphql';
import MEDICATION_REMINDERS_QUERY from 'graphql/queries/animals/medications/medicationReminders';

type UseDeleteMedication = () => {
  deleteMedication: (id: string) => Promise<FetchResult<DeleteMedicationMutation>>;
  loading: boolean;
};

const useDeleteMedication: UseDeleteMedication = () => {
  const [deleteMedicationRaw, { loading }] = useDeleteMedicationMutation();

  const deleteMedication = useCallback(
    id =>
      deleteMedicationRaw({
        variables: { where: { id } },
        refetchQueries: [
          'medications',
          {
            query: MEDICATION_REMINDERS_QUERY,
            variables: {
              orderBy: [
                {
                  field: MedicationOrderField.Date,
                  direction: OrderDirection.Asc,
                },
              ],
              where: {
                isUpcoming: true,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      }),
    [deleteMedicationRaw],
  );

  return { deleteMedication, loading };
};

export default useDeleteMedication;
