import { gql } from '@apollo/client';

import ANIMAL_FRAGMENT from 'graphql/fragments/animals/animal';
import HERD_MAIN_FRAGMENT from 'graphql/fragments/herdMain';

const HERD_FRAGMENT = gql`
  fragment herdFragment on Herd {
    ...herdMainFragment

    members {
      id
      role
      user {
        id
        email
      }
    }

    invitations {
      id
      recipientEmail
    }

    animals {
      ...animalFragment
    }
  }

  ${ANIMAL_FRAGMENT}
  ${HERD_MAIN_FRAGMENT}
`;

export default HERD_FRAGMENT;
