import { FC, useMemo, useCallback } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';

import classnames from 'classnames';

import CollapseWithHeader from 'components/common/CollapseWithHeader';
import BurgerMenu from 'components/common/BurgerMenu';

import SidebarLink from 'components/sidebar/SidebarLink';
import SidebarUser from 'components/sidebar/SidebarUser';
import AppTitle from 'components/sidebar/AppTitle';
// import PopupGetAccessMini from 'components/common/PopupGetAccessMini';

import useHerdsFromProvider from 'hooks/herds/useHerdsFromProvider';
import useDataFromProvider from 'hooks/useDataFromProvider';
import useCheckSubscribed from 'hooks/user/useCheckSubscribed';
import useWelcomeCheckFromProvider from 'hooks/user/useShowWelcomePageFromProvider';
import useLocalStorageFromProvider from 'hooks/useLocalStorageFromProvider';
import useCurrentTariff from 'hooks/user/useCurrentTariff';

import { HERDS_ROUTE } from 'helpers/constants';
import app from 'helpers/app';

import { useMeQuery } from 'generated/graphql';
import { useAllAgPostsQuery } from 'generated/sanity_graphql';

interface ISidebarLink {
  icon?: string;
  title: string;
  to: string;
  className?: string;
  subLinks?: Omit<ISidebarLink, 'subLinks'>[];
  badgeNew?: boolean;
  hidden?: boolean;
}

const { appName, brand } = app;

const Sidebar: FC = () => {
  const { active, visible } = useDataFromProvider();

  const { data } = useMeQuery();
  const { me = null } = data || {};

  const { blogs } = useLocalStorageFromProvider();
  const { data: { allAgPosts = [] } = {} } = useAllAgPostsQuery();

  const { isSilver } = useCurrentTariff();

  const isNativePlatform = Capacitor.isNativePlatform();

  const isNewLibrary = useMemo(() => {
    if (blogs.length) {
      const formatedBlogs = allAgPosts.map(({ _id }) => ({ id: _id, isNew: true }));

      const uniqueArray = [
        ...new Map([...formatedBlogs, ...blogs].map(item => [item.id, item])).values(),
      ];

      return uniqueArray.find(({ isNew }: { isNew: string }) => isNew);
    }

    return true;
  }, [allAgPosts, blogs]);

  const { t } = useTranslation();
  const match = useRouteMatch({
    path: `/${HERDS_ROUTE}`,
    exact: true,
  });
  const { isShow } = useWelcomeCheckFromProvider();
  const { herds } = useHerdsFromProvider();

  const isSubscribed = useCheckSubscribed();

  const genealogicalTreeLink = useMemo<ISidebarLink>(
    () => ({
      icon: '/icons/genealogical-tree.svg',
      title: t('herds.internationalHerd'),
      to: '/international-herd',
    }),
    [t],
  );

  const herdsLink = useMemo<ISidebarLink>(
    () => ({
      icon: '/icons/barn.svg',
      title: t(`herds.${appName === 'sheep' ? 'myFlocks' : 'myHerds'}`),
      to: `/${HERDS_ROUTE}`,
      className: classnames('collapsible-links', { active: match }),
      subLinks: herds?.map(h => ({ to: `/${HERDS_ROUTE}/${h.id}`, title: h.name })),
      hidden: !isSubscribed && isNativePlatform,
    }),
    [t, match, herds, isSubscribed, isNativePlatform],
  );

  const transferLink = useMemo<ISidebarLink>(
    () => ({
      icon: '/icons/transfer.svg',
      title: t('animals.transfer'),
      to: '/transfer',
      badgeNew: true,
    }),
    [t],
  );

  const matingsLink = useMemo<ISidebarLink>(
    () => ({
      icon: '/icons/matings.svg',
      title: t('mating.link'),
      to: '/matings',
      hidden: !isSilver && isNativePlatform,
    }),
    [isNativePlatform, isSilver, t],
  );

  const welcomePageLink = useMemo<ISidebarLink>(
    () => ({ icon: '/icons/welcome.svg', title: t('profile.welcome'), to: '/welcome' }),
    [t],
  );

  const isShowingWelcome = useCallback(
    (link: ISidebarLink): boolean | ISidebarLink => {
      if (brand && link.to === '/welcome') {
        return isShow;
      }

      return link;
    },
    [isShow],
  );

  const libraryPageLink = useMemo(
    () => ({
      icon: '/icons/book.svg',
      title: t('library.library'),
      to: '/library',
      badgeNew: isNewLibrary,
    }),
    [isNewLibrary, t],
  );

  const breederDirectory = useMemo(
    () => ({
      icon: '/icons/members.svg',
      title: t('profile.breederDirectory'),
      to: '/breeder-directory',
    }),
    [t],
  );

  const salePageLink = useMemo(
    () => ({
      icon: '/icons/label.svg',
      title: t('herds.animalsForSale', { context: appName }),
      to: '/sale',
    }),
    [t],
  );

  const isBrandLinks = useMemo(() => {
    if (brand) {
      return [
        welcomePageLink,
        genealogicalTreeLink,
        herdsLink,
        matingsLink,
        salePageLink,
        breederDirectory,
        libraryPageLink,
      ].filter(link => isShowingWelcome(link));
    }

    return [herdsLink];
  }, [
    breederDirectory,
    genealogicalTreeLink,
    herdsLink,
    matingsLink,
    isShowingWelcome,
    libraryPageLink,
    salePageLink,
    welcomePageLink,
  ]);

  const links = useMemo<ISidebarLink[] | null>(() => {
    if (isSubscribed) {
      return [
        transferLink,
        welcomePageLink,
        genealogicalTreeLink,
        herdsLink,
        matingsLink,
        salePageLink,
        breederDirectory,
        libraryPageLink,
        { icon: '/icons/notes.svg', title: t('herds.notes'), to: '/notes' },
        // { icon: '/icons/fleece.svg', title: t('herds.fleeceManagement'), to: '/fleece' },
      ].filter(link => {
        if (appName !== 'alpaca')
          return (
            link.to !== '/international-herd' &&
            link.to !== '/sale' &&
            link.to !== '/welcome' &&
            link.to !== '/library' &&
            link.to !== '/transfer' &&
            link.to !== '/breeder-directory'
          );
        if (!brand) return link.to !== '/international-herd' && link.to !== '/welcome';
        if (brand && link.to === '/welcome') return isShow;
        if (!me?.transfers?.length) return link.to !== '/transfer';

        return link;
      });
    }

    return isBrandLinks || null;
  }, [
    isSubscribed,
    isBrandLinks,
    transferLink,
    welcomePageLink,
    matingsLink,
    genealogicalTreeLink,
    herdsLink,
    salePageLink,
    breederDirectory,
    libraryPageLink,
    t,
    isShow,
    me?.transfers?.length,
  ]);

  if (!visible) return null;

  return (
    <div className={classnames('sidebar', { active })}>
      <div className='sidebar-container'>
        <BurgerMenu />

        <div className='sidebar-block header'>
          <AppTitle />
        </div>

        <div className='sidebar-block body'>
          <div className='sidebar-links'>
            {links?.map(({ icon, title, to, subLinks, className, badgeNew, hidden }) => {
              if (hidden) {
                return null;
              }

              if (subLinks?.[0]) {
                return (
                  <CollapseWithHeader
                    key={to}
                    to={to}
                    icon={icon}
                    text={title}
                    className={className}
                  >
                    {subLinks.map(subLinkProps => (
                      <SidebarLink key={subLinkProps.to} badgeNew={badgeNew} {...subLinkProps} />
                    ))}
                  </CollapseWithHeader>
                );
              }

              return (
                <SidebarLink
                  className={className}
                  key={to}
                  to={to}
                  icon={icon}
                  title={title}
                  badgeNew={badgeNew}
                />
              );
            })}
            {/* {!isSubscribed && ( */}
            {/*  <PopupGetAccessMini */}
            {/*    text={t('profile.tryPlan', { context: app.appName })} */}
            {/*    title={t('common.getAccessMini', { */}
            {/*      ability: t('common.all'), */}
            {/*      ruContext: t('common.ruAll'), */}
            {/*    })} */}
            {/*  /> */}
            {/* )} */}
          </div>
        </div>

        <div className='sidebar-block footer'>
          <SidebarUser />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
