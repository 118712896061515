import { FC, Fragment } from 'react';

import { useHistory } from 'react-router-dom';

import i18n from 'startup/i18next';

import Button from 'components/common/buttons/Button';

import useModalFromProvider from 'hooks/modal/useModalFromProvider';

const t = i18n.getFixedT(i18n.language);

const UpgradeButton: FC = () => {
  const history = useHistory();
  const { toggleModal } = useModalFromProvider();

  return (
    <Button
      className='upgrade-btn'
      color='primary'
      onClick={() => {
        history?.push('/account');
        // eslint-disable-next-line no-use-before-define
        toggleModal(UpgradeToDirectoryModalBody);
      }}
    >
      <span className='upgrade-text'>{t('common.upgrade')}</span>
    </Button>
  );
};

const UpgradeToDirectoryModalBody = (
  <Fragment>
    <h1 className='title'>{t('encourage.upgradeHerd')}</h1>

    <p className='description text-left'>{t('encourage.upgradeToDirectory')}</p>

    <UpgradeButton />
  </Fragment>
);

export default UpgradeToDirectoryModalBody;
