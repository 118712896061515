import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { MatingOutcome } from 'generated/graphql';

import SelectField from 'components/common/form/SelectField';

export type MatingRecordOutcomeOption = {
  value: MatingOutcome;
  label: string;
};

const MatingRecordOutcomeInput: FC = () => {
  const { t } = useTranslation();

  const outcomes: MatingRecordOutcomeOption[] = useMemo(() => {
    return [
      {
        value: MatingOutcome.Aborted,
        label: t('mating.record.outcome.aborted'),
      },
      {
        value: MatingOutcome.Failed,
        label: t('mating.record.outcome.failed'),
      },
      {
        value: MatingOutcome.Ongoing,
        label: t('mating.record.outcome.ongoing'),
      },
      {
        value: MatingOutcome.Success,
        label: t('mating.record.outcome.success'),
      },
    ];
  }, [t]);

  return (
    <Row>
      <Col xs={12}>
        <SelectField
          name='outcome'
          label={t('mating.record.table.outcome')}
          options={outcomes}
          required
          isSearchable
        />
      </Col>
    </Row>
  );
};

export default MatingRecordOutcomeInput;
