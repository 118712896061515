import { FC } from 'react';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Modal from 'components/common/Modal';
import Button from 'components/common/buttons/Button';

interface Props {
  show: boolean;
  toggle: () => void;
}

const BreederModal: FC<Props> = ({ show, toggle }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <Modal
      showCloseButton
      size='md'
      autoFocus={false}
      show={show}
      className='breeder-modal'
      bodyClassName='d-flex flex-column justify-content-between'
      title={t('profile.breederDirectory')}
      onClose={toggle}
    >
      {t('profile.breederSupport')}
      <Button
        color='primary'
        className='plan-btn mt-4'
        onClick={() => {
          push('/account');
        }}
      >
        {t('common.upgrade')}
      </Button>
    </Modal>
  );
};

export default BreederModal;
