import { FC, memo } from 'react';

import Modal from 'components/common/Modal';

interface Props {
  show: boolean;
  toggle: (comp?: JSX.Element) => void;
  modalBody: JSX.Element | undefined;
  className?: string;
  bodyClassName?: string;
  customHeaderClassName?: string;
}

const CustomModal: FC<Props> = ({
  show,
  toggle,
  modalBody,
  className,
  bodyClassName,
  customHeaderClassName,
}) => (
  <Modal
    showCloseButton
    size='lg'
    autoFocus={false}
    show={show}
    className={className || 'coming-soon-modal'}
    bodyClassName={bodyClassName || 'coming-soon-body'}
    modalClassName='modal-blur-backdrop'
    backdropClassName='modal-backdrop-color'
    headerClassName={customHeaderClassName}
    onClose={() => {
      toggle();
      // to fix problem with many modals in que after closing
      document.querySelector('body')?.classList.remove('modal-open');
    }}
  >
    {modalBody}
  </Modal>
);

export default memo(CustomModal);
