import { FC, Fragment, useMemo, useCallback, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Col, Row } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { Form, useFormik, FormikProvider } from 'formik';

import Input from 'components/common/form/Input';
import Button from 'components/common/buttons/Button';
import Loading from 'components/common/Loading';

import AppleButton from 'components/auth/AppleButton';
import GoogleButton from 'components/auth/GoogleButton';
import PasswordInput from 'components/auth/PasswordInput';

import useAuth from 'hooks/user/useAuth';
import useGATracker from 'hooks/useGATracker';

import { schema, AuthMode } from 'components/auth/validation';

import { SignUpToken } from 'pages/public/SignUpPage';
import { Capacitor } from '@capacitor/core';

interface AuthFormProps {
  mode: AuthMode;
  token?: SignUpToken['token'];
}

const initialValues = {
  email: '',
  password: '',
};

const AuthForm: FC<AuthFormProps> = ({ mode, token }) => {
  const { t } = useTranslation();
  const { signIn, signUp, verifyEmail, loading } = useAuth();
  const { push } = useHistory();
  const { customEvent } = useGATracker();
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (token) {
      verifyEmail({ token })
        .then(() => customEvent('method'))
        .catch(() => null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSignIn = useMemo(() => mode === 'SIGN_IN', [mode]);

  const onSubmit = useCallback(
    ({ email, password, firstName, lastName }) => {
      if (isSignIn) {
        signIn({ email, password })
          .then(() => customEvent('method'))
          .catch(() => null);
      } else {
        signUp({ email, password, firstName, lastName })
          .then(({ data }) => {
            if (data?.signUp.__typename === 'SignUpStatus') push('/verify-email');
          })
          .catch(() => null);
      }
    },
    [customEvent, isSignIn, push, signIn, signUp],
  );

  const formik = useFormik({
    initialValues: isSignIn
      ? { ...initialValues }
      : { ...initialValues, firstName: '', lastName: '' },
    validationSchema: schema[mode],
    onSubmit,
    validateOnMount: false,
    validateOnBlur: isDirty,
  });

  const { handleSubmit, dirty } = formik;

  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty]);

  return token && loading ? (
    <Loading page />
  ) : (
    <Fragment>
      <FormikProvider value={formik}>
        <Form className='auth-form' onSubmit={handleSubmit}>
          <h2 className='auth-form-heading'>
            {mode === 'SIGN_IN' ? t('auth.greeting') : t('common.createAccount')}
          </h2>

          <Fragment>
            <div className='auth-providers'>
              {Capacitor.getPlatform() !== 'android' && (
                <AppleButton isSignIn={mode === 'SIGN_IN'} />
              )}
              <GoogleButton isSignIn={mode === 'SIGN_IN'} />
            </div>

            <div className='divider'>
              <span className='divider-text'>
                {mode === 'SIGN_IN' ? t('auth.typeOfAuth.signIn') : t('auth.typeOfAuth.signUp')}
              </span>
            </div>
          </Fragment>

          {mode === 'SIGN_UP' && (
            <Row>
              <Col xs={12} sm={6}>
                <Input
                  name='firstName'
                  labelClassName='custom-label'
                  label={t('auth.firstName')}
                  maxLength={30}
                  placeholder='John'
                  autoFocus
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  name='lastName'
                  labelClassName='custom-label'
                  label={t('auth.lastName')}
                  maxLength={30}
                  placeholder='Alpaca'
                />
              </Col>
            </Row>
          )}
          <Input
            name='email'
            label='Email'
            labelClassName='custom-label'
            placeholder='john@mail.com'
            type='email'
            autoFocus={mode === 'SIGN_IN'}
          />
          <PasswordInput name='password' labelClassName='custom-label' />
          <Button type='submit' color='primary' outline loading={loading} className='custom-submit'>
            {mode === 'SIGN_IN' ? t('auth.signIn.withEmail') : t('auth.signUp.withEmail')}
          </Button>
        </Form>
      </FormikProvider>
      <div className='actions'>
        {mode === 'SIGN_IN' ? (
          <Fragment>
            <div className='action'>
              <span>
                {t('auth.forgotPassword')}
                {'? '}
              </span>
              <Link className='link' to='forgot-password'>
                {t('common.reset')}
              </Link>
            </div>
            <div className='action'>
              <span>
                {t('auth.noAccount')}
                &nbsp;
              </span>
              <Link className='link' to='sign-up'>
                {t('route.signUp')}
              </Link>
            </div>
          </Fragment>
        ) : (
          <div className='action'>
            <span>
              {t('auth.haveAccount')}
              &nbsp;
            </span>
            <Link to='/sign-in' className='link'>
              {t('route.signIn')}
            </Link>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default AuthForm;
