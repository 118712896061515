import { FC } from 'react';

import SwitchToggle from 'components/common/SwitchToggle';
import { useTranslation } from 'react-i18next';

import app from 'helpers/app';

const { appName } = app;

const Header: FC<{ handleToggleChange: () => void; checked: boolean }> = ({
  handleToggleChange,
  checked,
}) => {
  const { t } = useTranslation();

  const animalType = t(`animals.genitive.${appName}s`)?.toLowerCase();

  return (
    <div className='header'>
      <h1 className='heading'>{t('animals.medication.upcomingMedications')}</h1>

      <div className='slider-container'>
        <label htmlFor='active-animal-checkbox' className='page-body-title'>
          {t('animals.activeAnimalsOnly', {
            animals: `${appName?.toLowerCase()}s`,
            ruAnimals: animalType,
          })}
        </label>
        <SwitchToggle onChange={handleToggleChange} checked={checked} id='active-animal-checkbox' />
      </div>
    </div>
  );
};

export default Header;
