import React, { useEffect, FC, useMemo, Fragment } from 'react';

import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { Capacitor } from '@capacitor/core';

import ProfilePage from 'pages/private/ProfilePage';
import AccountPage from 'pages/private/AccountPage';
import WelcomePage from 'pages/private/WelcomePage';
import LibraryPage from 'pages/private/LibraryPage';
import TransferPage from 'pages/private/TransferPage';
import BreederDirectoryPage from 'pages/private/BreederDirectoryPage';
import TrialPage from 'pages/private/TrialPage';

import NotesRoutes from 'routes/private/NotesRoutes';
import SaleRoutes from 'routes/private/SaleRoutes';
import HerdsRoutes from 'routes/private/HerdsRoutes';
import MatingRoutes from 'routes/private/MatingRoutes';
import InternationalRoutes from 'routes/private/InternationalRoutes';

import Layout from 'components/layout/Layout';
import UserListener from 'components/common/UserListener';
// import UnderDevelopmentPlug from 'components/common/UnderDevelopmentPlug';
import AchievementModalBody from 'components/common/modal/AchievementModalBody';

import useGATracker from 'hooks/useGATracker';
import useScrollToTop from 'hooks/useScrollToTop';
import useAchievements from 'hooks/user/achievements/useAchievements';
import useCheckSubscribed from 'hooks/user/useCheckSubscribed';
import useModalFromProvider from 'hooks/modal/useModalFromProvider';

import HerdsProvider from 'providers/HerdsProvider';
import LocalStageProvider from 'providers/LocalStorageProvider';

import app from 'helpers/app';
import { HERDS_ROUTE, REDIRECT_PATH } from 'helpers/constants';

import { UserFragmentFragment } from 'generated/graphql';

const { brand, appName } = app;

const AppRouter: FC<{ user: UserFragmentFragment }> = ({ user }) => {
  const { pageView, getTimings } = useGATracker();
  const isSubscribed = useCheckSubscribed();
  const { push } = useHistory();

  const achievements = useAchievements();
  const { toggleModal } = useModalFromProvider();

  const redirectPath = localStorage.getItem(REDIRECT_PATH);
  const userHasSubscription = user.subscription !== null;
  const isNativePlatform = Capacitor.isNativePlatform();
  const shouldRedirectToTrialPage = !userHasSubscription && !isNativePlatform;

  useEffect(() => {
    if (brand) {
      achievements?.forEach(({ isSeen, isEarned, name, imageUrl }) => {
        if (!isSeen && isEarned) {
          toggleModal(
            AchievementModalBody(imageUrl || '', name || ''),
            'achievements-modal',
            'achievements-modal-body',
            'achievements-modal-header',
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achievements]);

  const initialBlogValue = [] as any;
  const [blogs, setBlogs, removeBlogs] = useLocalStorage('blog', initialBlogValue);

  const { showWelcomePage } = user || {};

  useScrollToTop();

  useEffect(() => {
    if (document.title !== 'Loading') {
      pageView();
      getTimings();
    }
  }, [getTimings, pageView]);

  const redirectTo = useMemo((): string => {
    if (shouldRedirectToTrialPage) return '/trial-period';
    if (!brand && !isSubscribed) return '/settings/personal-profile';
    if (showWelcomePage && brand) return '/welcome';
    if (brand && !isSubscribed) return '/settings/personal-profile';
    if (brand && !user?.transfers?.length) return '/herds';

    return `/${HERDS_ROUTE}`;
  }, [isSubscribed, shouldRedirectToTrialPage, showWelcomePage, user?.transfers?.length]);

  useEffect(() => {
    if (redirectPath) {
      localStorage.removeItem(REDIRECT_PATH);
      push(redirectPath);
    }
  }, [push, redirectPath]);

  return (
    <Fragment>
      <Switch>
        {shouldRedirectToTrialPage && <Route key='trial-period' path='/' component={TrialPage} />}
        {shouldRedirectToTrialPage && (
          <Route key='trial-period' path='/trial-period' component={TrialPage} />
        )}
      </Switch>

      <HerdsProvider>
        <LocalStageProvider value={{ blogs, setBlogs, removeBlogs }}>
          <Layout trialBlock={shouldRedirectToTrialPage}>
            <Switch>
              {(isSubscribed || !isNativePlatform) && (
                <Route key='herds' path={`/${HERDS_ROUTE}`} component={HerdsRoutes} />
              )}

              {(isSubscribed || !isNativePlatform) && (
                <Route key='matings' path='/matings' component={MatingRoutes} />
              )}

              {brand && showWelcomePage && <Route path='/welcome' component={WelcomePage} />}
              {brand && [
                appName === 'alpaca' && <Route key='sale' path='/sale' component={SaleRoutes} />,
                <Route
                  key='international-herd'
                  path='/international-herd'
                  component={InternationalRoutes}
                />,
                <Route
                  key='breeder-directory'
                  path='/breeder-directory'
                  component={BreederDirectoryPage}
                />,
              ]}

              <Route exact path='/settings' component={ProfilePage} />
              <Route exact path='/settings/:tabName' component={ProfilePage} />
              <Route exact path='/account' component={AccountPage} />
              <Route exact path='/library' component={LibraryPage} />

              {isSubscribed && [
                brand && user?.transfers?.length && (
                  <Route key='transfer' path='/transfer' component={TransferPage} />
                ),
                <Route key='notes' path='/notes' component={NotesRoutes} />,
                // <Route key='fleece' exact path={['/fleece']} component={UnderDevelopmentPlug} />,
              ]}

              <Route render={() => <Redirect to={redirectTo} />} />
            </Switch>
            <UserListener />
          </Layout>
        </LocalStageProvider>
      </HerdsProvider>
    </Fragment>
  );
};

export default AppRouter;
