import { FC } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import { ControlFormValues } from 'utils/control/parseControlValues';

import ImageUploaderField from 'components/common/image-uploader/ImageUploaderField';
import Input from 'components/common/form/Input';

import { ControlAccordionProps } from '../ControlAccordions';
import ControlAccordion from '../../../common/ControlAccordion';
import ControlField from '../../../common/ControlField';

export const contactValidationSchema = Yup.object().shape({
  contactPageEmail: Yup.string().email().required(),
  contactPageHeroImageUrl: Yup.string().required(),
});

const ContactAccordion: FC<ControlAccordionProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ControlFormValues>();

  const isCompleted = contactValidationSchema.isValidSync(values);

  return (
    <ControlAccordion
      title={t('profile.controlPage.contact.title')}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      completed={isCompleted}
    >
      <ControlField
        required
        label={{
          text: t('profile.controlPage.hero.title'),
          helper: t('profile.controlPage.hero.helper'),
        }}
      >
        <ImageUploaderField name='contactPageHeroImageUrl' />
      </ControlField>

      <ControlField
        required
        className='mt-3'
        label={{
          text: t('profile.controlPage.contact.email.title'),
          helper: t('profile.controlPage.contact.email.helper'),
        }}
      >
        <Input
          isRequired
          name='contactPageEmail'
          className='m-0'
          placeholder='example@example.com'
        />
      </ControlField>
    </ControlAccordion>
  );
};

export default ContactAccordion;
