import { FC, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';

import { Form, FormikProvider, useFormik } from 'formik';

import Modal from 'components/common/Modal';
import Input from 'components/common/form/Input';
import Button from 'components/common/buttons/Button';
import CreatableSelectField from 'components/common/form/CreatableSelectField';

import { email as emailValidation } from 'components/auth/validation';

import useCreateAnimalTransferRequest from 'hooks/animals/transfer/useCreateAnimalTransferRequest';
import useCloseAnimalTransferRequest from 'hooks/animals/transfer/useCloseAnimalTransferRequest';
import useHerdsOptions from 'hooks/herds/useHerdOptions';

import app from 'helpers/app';

import { TransferStatus } from 'generated/graphql';
import useUser from 'hooks/user/useUser';
import { useHistory } from 'react-router-dom';

interface Props {
  show: boolean;
  toggle: () => void;
  animalId?: string;
  transferId?: string;
}

const TransferModal: FC<Props> = ({ show, toggle, animalId, transferId }) => {
  const { t } = useTranslation();

  const user = useUser();
  const { push } = useHistory();

  const { createAnimalTransferRequest, loading: createTransferLoading } =
    useCreateAnimalTransferRequest();

  const { closeAnimalTransferRequest, loading: transferAnimal } = useCloseAnimalTransferRequest();

  const { herdsOptions } = useHerdsOptions();

  const onCreateLabelSelect = (inputVale: string): JSX.Element => (
    <div>
      {t('common.create')}
      <b>{` ${inputVale}`}</b>
    </div>
  );

  const onSubmit = useCallback(
    (email?: string, herdId?: string): void => {
      if (animalId && email) {
        void toast.promise(createAnimalTransferRequest(email, animalId), {
          loading: t('common.updating'),
          success: () => {
            toggle();

            return t('common.successUpdate');
          },
          error: t('common.errorUpdate'),
        });
      }

      const herdName = !herdsOptions?.find(({ value }) => value === herdId) ? herdId : undefined;

      if (transferId) {
        void toast.promise(
          closeAnimalTransferRequest(
            transferId,
            TransferStatus.Done,
            herdName,
            (herdId = herdName ? undefined : herdId),
          ),
          {
            loading: t('common.updating'),
            success: () => {
              toggle();

              if (!user?.transfers?.length) push('/herds');

              return t('common.successUpdate');
            },
            error: t('common.errorUpdate'),
          },
        );
      }
    },
    [
      animalId,
      closeAnimalTransferRequest,
      createAnimalTransferRequest,
      herdsOptions,
      push,
      t,
      toggle,
      transferId,
      user?.transfers?.length,
    ],
  );

  const formik = useFormik({
    validationSchema: animalId && yup.object().shape({ email: emailValidation }),
    initialValues: { email: '', herdId: '' },
    onSubmit: ({ email, herdId }: { email: string; herdId: string }) => {
      void onSubmit(email, herdId);
      formik.resetForm();
    },
    validateOnMount: false,
    validateOnBlur: false,
  });

  const { handleSubmit, handleChange } = formik;

  return (
    <Modal
      show={show}
      onClose={toggle}
      size='lg'
      className='transfer-modal'
      title={t('animals.transferring.transferAnimal', { animal: app.appName })}
      showCloseButton
    >
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit} noValidate>
          {animalId && (
            <Input
              name='email'
              type='email'
              label={t('animals.transferring.recipientEmail')}
              placeholder='email@example.com'
              onChange={handleChange}
            />
          )}

          {transferId && (
            <CreatableSelectField
              name='herdId'
              options={herdsOptions ?? []}
              label={`${t('common.select')} ${t('herds.herd')}`}
              placeholder={t('herds.herdNameRequired')}
              openMenuOnFocus
              formatCreateLabel={onCreateLabelSelect}
              createOptionPosition='first'
              allowCreateWhileLoading
            />
          )}

          <Button
            loading={createTransferLoading || transferAnimal}
            type='submit'
            color='primary'
            disabled={createTransferLoading || transferAnimal}
          >
            {t('animals.transfer')}
          </Button>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default TransferModal;
