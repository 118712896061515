import { gql } from '@apollo/client';

const MEDICATION_REMINDER_FRAGMENT = gql`
  fragment medicationReminderFragment on MedicationReminder {
    id
    date
    dose

    medication {
      id
      type {
        id
        name
      }

      reminders {
        id
        date
        dose
        medication {
          type {
            id
            name
          }
        }
      }

      animal {
        status
        id
        name
        herd {
          id
        }
        images {
          id
          url
        }
      }
    }
  }
`;

export default MEDICATION_REMINDER_FRAGMENT;
