import { FC, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Button from 'components/common/buttons/Button';

import useConfirm from 'hooks/useConfirm';
import useLogout from 'hooks/user/useLogout';

import { ReactComponent as ExitIcon } from 'icons/exit.svg';

type Props = {
  className?: string;
  withIcon?: boolean;
  buttonClassName?: string;
};

const LogoutButton: FC<Props> = ({ withIcon, className, buttonClassName }) => {
  const { t } = useTranslation();

  const { logout } = useLogout();

  const confirm = useConfirm({
    title: t('profile.signOut'),
    description: t('profile.confirmLogout'),
  });

  const handleClick = useCallback(async () => {
    const isConfirmed = await confirm();

    if (isConfirmed) logout();
  }, [confirm, logout]);

  return (
    <div className={classnames('logout-container', className)}>
      {withIcon && <ExitIcon />}
      <Button
        className={classnames('logout-btn btn-secondary', buttonClassName)}
        color='light'
        onClick={handleClick}
      >
        {t('profile.logout')}
      </Button>
    </div>
  );
};

export default LogoutButton;
