/* eslint-disable react/jsx-one-expression-per-line */
import classNames from 'classnames';
import React, { Dispatch, FC, MouseEvent, SetStateAction, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as ImageUploadIcon } from 'icons/common/image-upload.svg';
import { ReactComponent as DeleteIcon } from 'icons/common/delete.svg';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface ImageUploaderInputProps {
  disabled?: boolean;
  value: string;
  handleDeleteImage: (e: MouseEvent<HTMLButtonElement>) => void;
  setFile: Dispatch<SetStateAction<File | null>>;
}

const ImageUploaderInput: FC<ImageUploaderInputProps> = ({
  disabled,
  value,
  setFile,
  handleDeleteImage,
}) => {
  const { t } = useTranslation();
  const [isDragZone, setDragZone] = useState(false);

  const onDrop = useCallback(([droppedFile]: File[]) => {
    setFile(droppedFile);
    setDragZone(false);
  }, []);

  const onDragEnter = useCallback(() => {
    setDragZone(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setDragZone(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    disabled,
    multiple: false,
    accept: 'image/jpeg, image/png',
    maxSize: 5242880,
    onDropRejected(fileRejections) {
      if (fileRejections[0].errors[0].code === 'file-too-large') {
        toast.error(t('common.imageUploader.large'));
      }
    },
  });

  return (
    <div
      {...getRootProps()}
      className={classNames('image-uploader', { active: isDragZone, disabled, uploaded: value })}
    >
      <div className='image-uploader__content'>
        {value ? (
          <React.Fragment>
            <img className='image-uploader__img' src={value} alt='Uploaded' />

            <div className='image-uploader__info'>
              <h4 className='image-uploader__title'>{t('common.imageUploader.uploaded')}</h4>

              <button
                disabled={disabled}
                onClick={handleDeleteImage}
                type='button'
                className='image-uploader__delete'
              >
                <DeleteIcon width={20} height={20} />
                {t('common.imageUploader.delete')}
              </button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ImageUploadIcon className='image-uploader__icon' width={40} height={40} />

            <h4 className='image-uploader__title'>
              {t('common.imageUploader.drop')} <span>{t('common.imageUploader.browse')}</span>
            </h4>

            <h5 className='image-uploader__subtitle'>{t('common.imageUploader.maximum')}</h5>
          </React.Fragment>
        )}
      </div>

      <input {...getInputProps()} />
    </div>
  );
};

export default ImageUploaderInput;
