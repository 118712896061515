import { EditorState } from 'react-draft-wysiwyg';

import { Maybe, MemberWebsiteFragmentFragment, UpdateMemberWebsiteInput } from 'generated/graphql';

import { convertEditorStateToString, getCurrentEditorState } from 'utils/editor/parseEditorValues';

import parseControlImages from './parseControlImages';

export interface ControlFormValues {
  slug: string;
  domain: string;
  isLive: boolean;

  farmName?: Maybe<string>;
  heroImage?: Maybe<string>;
  heroImageUrl?: Maybe<string>;
  gaId?: Maybe<string>;

  homePageContent: EditorState;

  aboutPageLive: boolean;
  aboutPageHeroImage?: Maybe<string>;
  aboutPageHeroImageUrl?: Maybe<string>;
  aboutPageContent: EditorState;

  bookingPageLive: boolean;
  bookingPageHeroImage?: Maybe<string>;
  bookingPageHeroImageUrl?: Maybe<string>;
  bookingPageContent: EditorState;

  contactPageHeroImage?: Maybe<string>;
  contactPageHeroImageUrl?: Maybe<string>;
  contactPageEmail?: Maybe<string>;

  salePageLive: boolean;
  salePageHeroImage?: Maybe<string>;
  salePageHeroImageUrl?: Maybe<string>;
  salePageContent: EditorState;

  animalsForSale: string[];
}

export const toControlFormValues = (data?: MemberWebsiteFragmentFragment): ControlFormValues => {
  return {
    slug: data?.slug || '',
    domain: data?.domains?.join(', ') || '',
    isLive: data?.isLive || false,

    farmName: data?.farmName,
    heroImage: data?.heroImage,
    heroImageUrl: data?.heroImageUrl,
    gaId: data?.gaId,

    homePageContent: getCurrentEditorState(data?.homePageContent || ''),

    aboutPageLive: data?.aboutPageLive || false,
    aboutPageHeroImage: data?.aboutPageHeroImage,
    aboutPageHeroImageUrl: data?.aboutPageHeroImageUrl,
    aboutPageContent: getCurrentEditorState(data?.aboutPageContent || ''),

    bookingPageLive: data?.bookingPageLive || false,
    bookingPageHeroImage: data?.bookingPageHeroImage,
    bookingPageHeroImageUrl: data?.bookingPageHeroImageUrl,
    bookingPageContent: getCurrentEditorState(data?.bookingPageContent || ''),

    contactPageEmail: data?.contactPageEmail,
    contactPageHeroImage: data?.contactPageHeroImage,
    contactPageHeroImageUrl: data?.contactPageHeroImageUrl,

    salePageLive: data?.salePageLive || false,
    salePageHeroImage: data?.salePageHeroImage,
    salePageHeroImageUrl: data?.salePageHeroImageUrl,
    salePageContent: getCurrentEditorState(data?.salePageContent || ''),

    animalsForSale: data?.animalsForSale.map(animal => animal.id) || [],
  };
};

export const toControlRequestValues = async (
  data: ControlFormValues,
): Promise<UpdateMemberWebsiteInput> => {
  const parsedImages = await Promise.all(parseControlImages(data));

  const parsedData = {
    slug: data.slug,
    domains: data.domain.trim() !== '' ? data.domain.split(', ') : [],
    isLive: data.isLive,

    farmName: data.farmName,
    gaId: data.gaId,

    homePageContent: convertEditorStateToString(data.homePageContent),

    aboutPageLive: data.aboutPageLive,
    aboutPageContent: convertEditorStateToString(data.aboutPageContent),

    bookingPageLive: data.bookingPageLive,
    bookingPageContent: convertEditorStateToString(data.bookingPageContent),

    contactPageEmail: data.contactPageEmail,

    salePageLive: data.salePageLive,
    salePageContent: convertEditorStateToString(data.salePageContent),
  };

  Object.assign(parsedData, ...parsedImages);

  return parsedData;
};
