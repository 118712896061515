import { Position } from 'reactflow';

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getNodeIntersection(intersectionNode: any, targetNode: any) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    position: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.position;

  const w = intersectionNodeWidth / 2; // 2
  const h = intersectionNodeHeight / 3; // 2

  const x2 = (intersectionNodePosition.x as number) + w;
  const y2 = (intersectionNodePosition.y as number) + h;
  const x1 = (targetPosition.x as number) + w;
  const y1 = (targetPosition.y as number) + h;

  const xx1 = (x1 - x2) / (20 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (10 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getEdgePosition(node: any, intersectionPoint: any) {
  const n = { ...node.position, ...node };
  const ny = Math.round(n.y);
  const py = Math.round(intersectionPoint.y);

  if (py <= ny + 1) {
    return Position.Top;
  }
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,import/prefer-default-export,@typescript-eslint/explicit-function-return-type
export function getEdgeParams(source: any, target: any) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}
