import { useAnimalFamilyTreeQuery, Maybe, NationalHerdTreeAnimal } from 'generated/graphql';

type UseAnimalFamilyTree = (animalId: string) => {
  familyTree?: Maybe<NationalHerdTreeAnimal>;
  loading?: boolean;
};

const useAnimalFamilyTree: UseAnimalFamilyTree = animalId => {
  const { data: { nationalHerdTree } = {}, loading } = useAnimalFamilyTreeQuery({
    variables: { where: { id: animalId } },
  });

  return {
    familyTree: nationalHerdTree as NationalHerdTreeAnimal,
    loading,
  };
};

export default useAnimalFamilyTree;
