import { FC, Fragment, useCallback, useMemo, useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { flag } from 'country-emoji';
import Avatar from 'react-avatar';

import { Form, FormikProvider, useFormik } from 'formik';

import { useTranslation } from 'react-i18next';

import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';

import Table from 'components/common/Table';
import withErrorBoundary from 'components/common/sentry/withErrorBoundary';
import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import ColorationPin from 'components/common/animals/ColorationPin';
import PaginationControls from 'components/common/PaginationControls';
import EmptyMessage from 'components/common/EmptyMessage';
import Input from 'components/common/form/Input';
import Tooltip from 'components/common/Tooltip';

import useNationalHerdsList from 'hooks/herds/useNationalHerdsList';
import useAnimalAge from 'hooks/animals/useAnimalAge';
import useCountry from 'hooks/useCountry';
import useUser from 'hooks/user/useUser';

import { ROWS_PER_PAGE_OPTIONS } from 'helpers/constants';

const InternationalHerdPage: FC<RouteComponentProps> = ({
  history: {
    push,
    location: { pathname },
  },
}) => {
  const { t } = useTranslation();
  const { language = 'en' } = useUser() ?? {};
  const { countriesLocale } = useCountry(language);

  const [searchValue, setSearchValue] = useState('');

  const [currentPage, setCurrentPage] = useState(0);

  const [take, setTake] = useState(ROWS_PER_PAGE_OPTIONS[0].value);
  const { nationalHerdList, loading, totalCount } = useNationalHerdsList({
    take: parseInt(take, 10),
    skip: !take ? 0 : parseInt(take, 10) * currentPage,
    where: { searchValue },
  });

  const getCountry = useCallback(
    (code: string): { emoji: string; name: string } => ({
      emoji: flag(code) || '🌏',
      name: Object.entries(countriesLocale).find(([key]) => key === code)?.[1] || '',
    }),
    [countriesLocale],
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: t('common.name'),
        Cell: ({ row: { original: { name = '', images = [{ url: undefined }] } = {} } }) => (
          <Fragment>
            <Avatar
              className='avatar'
              round='8px'
              size='52'
              src={images?.[images.length - 1]?.url}
              name={name}
            />
            <span className='animal-name'>{name}</span>
          </Fragment>
        ),
        disableSortBy: true,
      },
      {
        accessor: 'country',
        Header: t('profile.country'),
        headerClassName: 'country-header',
        cellClassName: 'country-cell',
        Cell: ({ value = '' }) => {
          return (
            <Tooltip
              id={value}
              content={<span>{getCountry(value).name || t('common.unknown')}</span>}
              place='right'
            >
              <p className='country-emoji' data-tip='look up'>
                {getCountry(value).emoji}
              </p>
            </Tooltip>
          );
        },
        disableSortBy: true,
      },
      {
        accessor: 'sex',
        Header: t('animals.sex'),
        Cell: ({ value = null }) => (value ? t(`animals.sexOptions.${camelCase(value)}`) : ''),
        disableSortBy: true,
      },
      {
        accessor: '',
        Header: t('animals.age.age'),
        disableSortBy: true,
        Cell: ({ row: { original: { birthday = '', deathday = '' } = {} } }) =>
          useAnimalAge(birthday, deathday, true),
      },
      {
        accessor: 'coloration',
        Header: t('animals.coloration'),
        Cell: ({ value: coloration = [] }) => <ColorationPin coloration={coloration} />,
        style: { maxWidth: 120 },
        disableSortBy: true,
      },
    ],
    [getCountry, t],
  );

  const notFound = useMemo(() => !loading && totalCount === 0, [loading, totalCount]);
  const emptyData = useMemo(() => !loading && !totalCount, [loading, totalCount]);

  const onChangeCurrentPage = useCallback(pageNumber => setCurrentPage(pageNumber - 1), []);
  const onChangeRowsPerPage = useCallback(({ target: { value } }) => value && setTake(value), []);

  const formik = useFormik({
    initialValues: { inputValue: searchValue },
    onSubmit: ({ inputValue }) => {
      setSearchValue(inputValue);
    },
    validateOnMount: false,
    validateOnBlur: false,
  });

  const {
    values: { inputValue },
    handleChange,
    handleSubmit,
  } = formik;

  return (
    <Fragment>
      <HelmetWithTemplate title='International Herd' />

      <FormikProvider value={formik}>
        <Form className='international-herd-form'>
          <div className='page international-herd'>
            <div className='header  --international'>
              <h1 className='heading'>{t('herds.internationalHerd')}</h1>

              <Input
                id='international-herd-search-input'
                className='search-input'
                placeholder={t('common.search')}
                name='inputValue'
                type='search'
                value={inputValue}
                onChange={e => {
                  handleChange(e);
                  debounce(handleSubmit, 1000)();
                }}
              />
            </div>
            {emptyData ? (
              <EmptyMessage message={t('common.notFound')} />
            ) : notFound ? (
              <EmptyMessage message={t('common.notFound')} />
            ) : (
              <div className='page-body'>
                <Table
                  columns={columns}
                  data={nationalHerdList ?? []}
                  loading={loading}
                  rowOnClick={({ id }) => push(`${pathname}/family-tree/${id}`)}
                />

                <PaginationControls
                  pagination={{
                    total: totalCount,
                    onChange: onChangeCurrentPage,
                    current: currentPage + 1,
                    loading: totalCount === undefined && loading,
                    pageSize: parseInt(take, 10),
                  }}
                  rowsPerPage={{
                    value: `${take}`,
                    options: ROWS_PER_PAGE_OPTIONS,
                    onChange: onChangeRowsPerPage,
                    loading: totalCount === undefined && loading,
                  }}
                />
              </div>
            )}
          </div>
        </Form>
      </FormikProvider>
    </Fragment>
  );
};

export default withErrorBoundary(InternationalHerdPage);
