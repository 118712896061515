import { FC, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import TabsRoutes, { TabData } from 'routes/TabsRoutes';

import Header from 'components/common/Header';
import Loading from 'components/common/Loading';
import ButtonGoBack from 'components/common/buttons/ButtonGoBack';
import HelmetWithTemplate from 'components/common/HelmetWithTemplate';
import EmptyPage from 'components/common/EmptyPage';

import DataProvider from 'providers/DataProvider';

import useMatingRecord from '../hooks/useMatingRecord';

import MatingRecordProfileTab from '../components/tabs/MatingRecordProfileTab';

const EditMatingRecordPage: FC = () => {
  const { t } = useTranslation();

  const { matingId, tabName } = useParams<{ matingId: string; tabName: string }>();

  const { matingRecord, loading } = useMatingRecord(matingId);

  /* Set current tab */
  const tabsData: TabData[] = useMemo(() => {
    return [
      {
        key: 'information',
        title: t('mating.record.tabs.information'),
        component: MatingRecordProfileTab,
      },
    ];
  }, [t]);

  const [selectedTab, setSelectedTab] = useState(tabsData.find(({ key }) => key === tabName));

  const handleChangeSelectedTab = useCallback((tabData: TabData) => {
    setSelectedTab(tabData);
  }, []);

  if (!matingRecord) {
    if (loading) {
      return <Loading page />;
    }

    return (
      <EmptyPage
        title={t('mating.record.empty.title')}
        description={t('mating.record.empty.description')}
      />
    );
  }

  return (
    <DataProvider value={{ data: matingRecord }}>
      <HelmetWithTemplate title={`${t('mating.single')} | ${selectedTab?.title}`} />

      <div className='page mating'>
        <Header text={t('mating.single')}>
          <ButtonGoBack color='light' backPath='/matings' />
        </Header>

        <div className='page-body with-tabs'>
          <TabsRoutes
            rootPath='/matings/:matingId'
            redirect={{ to: '/matings/:matingId/profile' }}
            tabsData={tabsData}
            onChange={handleChangeSelectedTab}
          />
        </div>
      </div>
    </DataProvider>
  );
};

export default EditMatingRecordPage;
