import { useMemo } from 'react';

import { Option } from 'components/common/select/Select';

import useUser from 'hooks/user/useUser';
import useCountry from 'hooks/useCountry';

const useCountriesOptions = (): Option[] => {
  const { language = 'en' } = useUser() ?? {};

  const { countriesLocale } = useCountry(language);

  return useMemo(
    () =>
      Object.entries(countriesLocale).map(([key, value]) => ({
        value: key,
        label: value,
      })),
    [countriesLocale],
  );
};

export default useCountriesOptions;
