import { Details } from 'components/react-flow/CustomNodeCard';

import { Data } from 'helpers/react-flow/getInitialNodes';

import { AnimalSex } from 'generated/graphql';

const getCardDetails = (details: { data: Data }): Details => [
  {
    type: 'Image',
    value: details?.data?.images,
  },
  {
    type: 'Name',
    value: details?.data?.name,
  },
  {
    type: 'Country',
    value: details?.data?.country,
  },
  {
    type: 'Age',
    value: details?.data?.birthday as string,
  },
  {
    type: 'Herd',
    value: details?.data?.herd
      ? details?.data?.herd?.name
      : (details?.data?.registryHerdName as string),
  },
  {
    type: 'Sex',
    value: details?.data?.sex as AnimalSex,
  },
  {
    type: 'Color',
    value: details?.data?.coloration?.length ? details?.data?.coloration : null,
  },
];

export default getCardDetails;
