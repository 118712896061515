import getLinkType from 'helpers/react-flow/getLinkType';

import {
  AnimalSex,
  Image,
  Maybe,
  NationalHerdTreeAnimal,
  NationalHerdTreeHerd,
} from 'generated/graphql';

export type Data = {
  images: Array<Image>;
  name: string;
  birthday?: string;
  country?: string | null;
  herd?: NationalHerdTreeHerd | null;
  sex?: AnimalSex | null;
  coloration: string[];
  registryHerdName?: string;
};

interface CustomNode {
  id: number;
  data: Data;
  position: {
    x: number;
    y: number;
  };
}

export interface InitialNode extends CustomNode {
  parents?: CustomNode[];
  children?: CustomNode[];
  linkType?: string;
}

interface GetInitialNodes {
  (familyTree?: Maybe<NationalHerdTreeAnimal>): {
    parentsNodes: InitialNode[];
    childrenNodes: InitialNode[];
  };
}

const getData = (data: NationalHerdTreeAnimal): Data => ({
  images: data.images,
  name: data.name,
  country: data?.country,
  birthday: data.birthday,
  herd: data.herd,
  sex: data.sex,
  coloration: data.coloration,
  registryHerdName: data.registryHerdName as string,
});

const position = {
  x: 0,
  y: 0,
};

const getInitialNodes: GetInitialNodes = familyTree => {
  const { parents, children } = familyTree || {};

  const initialParentsNodes = (_parents: NationalHerdTreeAnimal['parents']): InitialNode[] =>
    _parents?.map((parent, index) => ({
      id: index,
      data: getData(parent),
      position,
      linkType: getLinkType(familyTree, parent),
      parents: parent.parents.map((_parent, _index) => ({
        id: _index,
        data: getData(_parent),
        position,
        linkType: getLinkType(parent, _parent),
      })),
    }));

  const parentsNodes = initialParentsNodes(parents as NationalHerdTreeAnimal[])?.map(
    (parent): InitialNode => ({
      ...parent,
      position,
    }),
  );

  const initialChildrenNodes = (_children: NationalHerdTreeAnimal['children']): InitialNode[] =>
    _children?.map((child, index) => ({
      id: index,
      data: getData(child),
      position,
      linkType: getLinkType(child, child),
      children: child.children.map((_child, _index) => ({
        id: _index,
        data: getData(_child),
        position,
        linkType: getLinkType(_child, _child),
      })),
    }));

  const childrenNodes = initialChildrenNodes(children as NationalHerdTreeAnimal[])?.map(
    (child): InitialNode => ({
      ...child,
      position,
    }),
  );

  return { parentsNodes, childrenNodes };
};

export default getInitialNodes;
