import { gql } from '@apollo/client';

const MEMBER_WEBSITE_FRAGMENT = gql`
  fragment memberWebsiteFragment on MemberWebsite {
    aboutPageContent
    aboutPageHeroImage
    aboutPageHeroImageUrl
    aboutPageLive
    animalsForSale {
      birthday
      coloration
      country
      id
      name
      sex
      details {
        animalDetails
      }
    }
    nsRecords {
      domain
      type
      name
      value
      ok
    }
    bookingPageContent
    bookingPageHeroImage
    bookingPageHeroImageUrl
    bookingPageLive
    contactPageEmail
    contactPageHeroImage
    contactPageHeroImageUrl
    createdAt
    domains
    farmName
    gaId
    heroImage
    heroImageUrl
    homePageContent
    id
    isLive
    salePageContent
    salePageHeroImage
    salePageHeroImageUrl
    salePageLive
    slug
    userId
  }
`;

export default MEMBER_WEBSITE_FRAGMENT;
