import { createContext, FC, useState, useEffect, useMemo, useCallback } from 'react';

import useUser from 'hooks/user/useUser';
import useUpdateUser from 'hooks/user/useUpdateUser';

export interface WelcomeContextValue {
  isShow: boolean;
  toggleIsShow: (isShowing: boolean) => void;
}

export const ShowWelcomeContext = createContext({ isShow: true, toggleIsShow: () => {} });

const ShowWelcomeProvider: FC = ({ children }) => {
  const user = useUser();

  const { showWelcomePage } = user || {};

  const [isShow, setIsShow] = useState(showWelcomePage);

  useEffect(() => {
    setIsShow(showWelcomePage);
  }, [showWelcomePage]);

  const { updateUser } = useUpdateUser();

  const toggleIsShow = useCallback((): void => {
    setIsShow(!isShow);

    void updateUser({
      showWelcomePage: !isShow,
    });
  }, [isShow, updateUser]);

  const value = useMemo(
    () => ({ isShow: isShow as boolean, toggleIsShow }),
    [isShow, toggleIsShow],
  );

  return <ShowWelcomeContext.Provider value={value}>{children}</ShowWelcomeContext.Provider>;
};

export default ShowWelcomeProvider;
