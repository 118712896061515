import { FC } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import { ControlFormValues } from 'utils/control/parseControlValues';
import validateEditorState from 'utils/editor/validateEditorState';

import { CONTROL_EDITOR_CONFIG, CONTROL_EDITOR_ICONS } from 'constants/control';

import EditorField from 'components/common/form/EditorField';
import Input from 'components/common/form/Input';
import ImageUploaderField from 'components/common/image-uploader/ImageUploaderField';

import { ControlAccordionProps } from '../ControlAccordions';
import ControlAccordion from '../../../common/ControlAccordion';
import ControlField from '../../../common/ControlField';

export const homeValidationSchema = Yup.object().shape({
  homePageContent: validateEditorState,
  heroImageUrl: Yup.string().required(),
  farmName: Yup.string().required(),
});

const HomeAccordion: FC<ControlAccordionProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ControlFormValues>();

  const isCompleted = homeValidationSchema.isValidSync(values);

  return (
    <ControlAccordion
      title={t('profile.controlPage.home.title')}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      completed={isCompleted}
    >
      <ControlField
        required
        label={{
          text: t('profile.controlPage.hero.title'),
          helper: t('profile.controlPage.hero.helper'),
        }}
      >
        <ImageUploaderField name='heroImageUrl' />
      </ControlField>

      <ControlField
        required
        className='mt-3'
        label={{
          text: t('profile.controlPage.home.name.title'),
          helper: t('profile.controlPage.home.name.helper'),
        }}
      >
        <Input
          name='farmName'
          className='m-0'
          placeholder={t('profile.controlPage.home.name.placeholder')}
        />
      </ControlField>

      <ControlField
        required
        className='mt-3'
        label={{
          text: t('profile.controlPage.content.title'),
          helper: t('profile.controlPage.content.helper'),
        }}
      >
        <EditorField
          name='homePageContent'
          placeholder={t('profile.controlPage.content.placeholder')}
          toolbar={CONTROL_EDITOR_CONFIG}
          editorIcons={CONTROL_EDITOR_ICONS}
        />
      </ControlField>
    </ControlAccordion>
  );
};

export default HomeAccordion;
