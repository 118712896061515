import { gql } from '@apollo/client';

import MEMBER_WEBSITE_FRAGMENT from 'graphql/fragments/memberWebsite';

const CREATE_MEMBER_WEBSITE = gql`
  mutation createMemberWebsite($data: CreateMemberWebsiteInput!) {
    createMemberWebsite(data: $data) {
      ...memberWebsiteFragment
    }
  }
  ${MEMBER_WEBSITE_FRAGMENT}
`;

export default CREATE_MEMBER_WEBSITE;
