import app from 'helpers/app';
import capitalize from 'lodash/capitalize';

const getAppBrand = (): string => {
  if (app.brand) {
    return 'Alpaca Guild';
  }

  return `${capitalize(app.appName)} Manager and Registry ®`;
};

export default getAppBrand;
