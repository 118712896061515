import common from 'locales/de/common.json';
import auth from 'locales/de/auth.json';
import route from 'locales/de/route.json';
import herds from 'locales/de/herds.json';
import profile from 'locales/de/profile.json';
import animals from 'locales/de/animals.json';
import notes from 'locales/de/notes.json';
import errors from 'locales/de/errors.json';
import members from 'locales/de/members.json';
import billingPlans from 'locales/de/billingPlans.json';
import library from 'locales/de/library.json';
import achievements from 'locales/de/achievements.json';
import encourage from 'locales/de/encourage.json';
import subscription from 'locales/de/subscription.json';
import mating from 'locales/de/mating.json';

export default {
  common,
  auth,
  route,
  herds,
  profile,
  animals,
  notes,
  errors,
  members,
  billingPlans,
  library,
  achievements,
  encourage,
  subscription,
  mating,
};
