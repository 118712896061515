import { CSSProperties, Fragment, useCallback } from 'react';

import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';

import classnames from 'classnames';

import { EdgeText, getBezierPath, getSimpleBezierPath, useStore } from 'reactflow'; // getSmoothStepPath
import { useTranslation } from 'react-i18next';

import { getEdgeParams } from 'helpers/react-flow/utils';

const getTextColor = (linkType?: string): { fill: string } => {
  switch (linkType) {
    case 'DNA_VERIFIED':
      return {
        fill: '#6CD743',
      };
    case 'CLAIMED':
      return {
        fill: '#FFC04C',
      };
    case 'CHALLENGED':
      return {
        fill: '#FFC04C',
      };
    default:
      return { fill: '#D4D4D4' };
  }
};

const CustomEdge = ({
  id,
  style,
  data,
  target,
  source,
}: {
  id: string;
  target: string;
  source: string;
  style?: CSSProperties | undefined;
  data?: { text: string };
}): JSX.Element | null => {
  const { t } = useTranslation();

  const sourceNode = useStore(useCallback(store => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback(store => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getSimpleBezierPath({
    // getSmoothStepPath
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
    // borderRadius: 8,
  });

  const [, centerX, centerY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  return (
    <Fragment>
      <g className='react-flow__connection'>
        <path id={id} style={style} className='react-flow__edge-path' d={edgePath} />
      </g>
      <EdgeText
        x={centerX}
        y={centerY}
        label={t(`animals.pedigreeLink.type.${camelCase(data?.text)}`, t('common.unknown'))}
        labelShowBg
        labelBgStyle={{ fill: '#FFFFFF' }}
        labelStyle={getTextColor(data?.text)}
        labelBgPadding={[8, 7]}
        labelBgBorderRadius={8}
        className={classnames('edge-labels', kebabCase(data?.text))}
      />
    </Fragment>
  );
};

export default CustomEdge;
