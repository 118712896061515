import { FC, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/common/buttons/Button';

import useCurrentTariff from 'hooks/user/useCurrentTariff';
import useCheckSubscribed from 'hooks/user/useCheckSubscribed';

import app from 'helpers/app';

const PopupGetAccess: FC = () => {
  const { push } = useHistory();

  const { t } = useTranslation();

  const handleClick = useCallback((): void => push('/account'), [push]);

  const isSubscribed = useCheckSubscribed();

  const { isSilver, isBronze } = useCurrentTariff();

  const planToSubscribeNext = useMemo(() => {
    if (!isSubscribed) {
      return t('profile.tryPlan_bronze', { context: app.appName });
    }

    if (isSubscribed && isBronze) {
      return t('profile.tryPlan', { context: app.appName });
    }

    if (isSubscribed && isSilver) {
      return t('billingPlans.accountPageMessage');
    }
  }, [isBronze, isSilver, isSubscribed, t]);

  return (
    <div className='popup-access'>
      <div className='popup-access-container'>
        <h3 className='popup-access-title'>
          {isSilver ? t('billingPlans.accountPageTitle') : t('common.getAccess')}
        </h3>
        <p className='popup-access-text'>{planToSubscribeNext}</p>
      </div>

      <Button className='btn-arrow btn-primary' onClick={handleClick} color='primary'>
        &rarr;
      </Button>
    </div>
  );
};

export default PopupGetAccess;
