import { useCallback } from 'react';

import { FetchResult } from '@apollo/client';

import client from 'startup/apollo';

import { ClaimAnimalMutation, useClaimAnimalMutation } from 'generated/graphql';

import ANIMAL_QUERY from 'graphql/queries/animals/animal';

type ClaimAnimal = {
  (herdId: string, id: string): Promise<FetchResult<ClaimAnimalMutation>>;
};

interface UseClaimAnimal {
  claimAnimal: ClaimAnimal;
  loading: boolean;
}

const useClaimAnimal = (): UseClaimAnimal => {
  const [claimAnimalRaw, { loading }] = useClaimAnimalMutation();

  const claimAnimal: ClaimAnimal = useCallback(
    (herdId, id) => {
      return claimAnimalRaw({
        variables: { data: { herdId }, where: { id } },
      }).then(({ data }) => {
        client.writeQuery({
          query: ANIMAL_QUERY,
          variables: { where: { id } },
          data: { animal: data?.claimAnimal || {} },
        });

        return data || {};
      });
    },
    [claimAnimalRaw],
  );

  return { claimAnimal, loading };
};

export default useClaimAnimal;
