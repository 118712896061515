import { FC } from 'react';

import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

import AnimalSaleForm from 'components/animals/form/AnimalSaleForm';
import TransferForm from 'components/animals/transfer/TransferForm';
import UpgradeSection from 'components/common/UpgradeSection';

import useSubscriptionPlan from 'hooks/user/useSubscrtiptionPlan';

import app from 'helpers/app';

const SaleTab: FC = () => {
  const { t } = useTranslation();

  const currentPlan = useSubscriptionPlan();

  if (currentPlan !== 'Silver') {
    return (
      <div className='d-flex h-100 w-100 align-items-center justify-content-center'>
        <UpgradeSection description={t('subscription.upgradeToSilver.description')} />
      </div>
    );
  }

  return (
    <div className='tab-content-body'>
      <div className='tab-content-header'>
        <h2 className='tab-content-heading d-block'>
          {`${capitalize(t('animals.sale'))} ${t('common.settings').toLowerCase()}`}
        </h2>
      </div>

      <AnimalSaleForm />
      {app.brand && <TransferForm />}
    </div>
  );
};

export default SaleTab;
