import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Card } from 'reactstrap';

import useFormat from 'hooks/useFormat';

type Props = {
  title: string;
  date: string;
  image: string;
  id: string;
  onClick: () => void;
  badgeNew?: boolean;
};

const ImageCard: FC<Props> = ({ title, date, image, id, onClick, badgeNew, ...props }) => {
  const { formatDate } = useFormat();
  const { t } = useTranslation();

  return (
    <Card onClick={onClick}>
      <LazyLoadImage
        key={id}
        src={image}
        alt={title}
        width={271}
        height={195}
        effect='blur'
        placeholderSrc='icons/imagePlaceholder.svg'
        {...props}
      />
      <div className='card-img-overlay'>
        {badgeNew && <span className='badge badge-danger custom-badge'>{t('common.new')}</span>}
        <h6 className='card-title'>{title}</h6>
        <span className='card-text'>{formatDate(new Date(date), 'MMMM do, yyyy')}</span>
      </div>
    </Card>
  );
};

export default ImageCard;
