import { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import classnames from 'classnames';

export interface SidebarLinkProps extends NavLinkProps {
  icon?: string;
  title: string;
  badgeNew?: boolean;
  className?: string;
}

const SidebarLink: FC<SidebarLinkProps> = ({ className, icon = '', title, badgeNew, ...props }) => {
  const { t } = useTranslation();

  return (
    <NavLink title={title} className={classnames('sidebar-link', className)} {...props}>
      {icon && <img src={icon} className='sidebar-link-icon' alt='' />}
      <span className='sidebar-link-span'>{title}</span>
      {!!badgeNew && <span className='badge badge-danger custom-badge'>{t('common.new')}</span>}
    </NavLink>
  );
};

export default SidebarLink;
