import { FC, useEffect, memo } from 'react';

import { useHistory } from 'react-router-dom';

import Loading from 'components/common/Loading';

import useQueryParams from 'hooks/useQueryParams';
import useAuth from 'hooks/user/useAuth';
import useGATracker from 'hooks/useGATracker';

import app from 'helpers/app';

import { AuthMethod } from 'generated/graphql';

const GoogleAuthPage: FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { code, token } = useQueryParams();
  const { push } = useHistory();

  const { customEvent } = useGATracker();

  const { signIn } = useAuth();

  useEffect(() => {
    if (code || token)
      signIn({
        clientId: app.googleClientId,
        googleAccessCode: code,
        googleAccessToken: token,
        method: AuthMethod.Google,
        redirectUri: `${window.location.origin}/_oauth/google`,
      })
        .then(() => customEvent('method'))
        .catch(() => push('sign-in'));
  }, [code, token]);

  return <Loading fullscreen />;
};

export default memo(GoogleAuthPage);
